// import { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';

// Validators
import {
  isPositiveNumberOrEmail,
  isPhoneNumber,
  normalizePhone,
} from '../../services/validators';

import { ActiveButton, DisabledButton } from '../WE-Button';

const OtpEmailFrom = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, error, valid, submitFailed } = props;

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <div className="inputs-wrapper">
        <span>{t('labelSendOtp')}</span>
        <Field
          name="employeeId"
          containerClass="employee-id"
          type="text"
          component={InputField}
          validate={[isPositiveNumberOrEmail]}
          placeholder={t('employeeIdOrEmail')}
          icon="icon-employee-card"
        />
        <span>-- {t('or')} --</span>
        <Field
          name={`phone`}
          containerClass="two-columns phone"
          type="tel"
          component={InputField}
          validate={[isPhoneNumber]}
          placeholder={t('phonePH')}
          normalize={normalizePhone}
          icon="phone-call"
        />

        <div className="flex-column">
          {submitFailed && !valid ? (
            <DisabledButton
              label={t('sendEmailOtp')}
              additionalClassName={'btn'}
            />
          ) : (
            <ActiveButton
              type="submit"
              additionalClassName={'btn'}
              label={t('sendEmailOtp')}
              arrow={true}
            />
          )}
        </div>
      </div>

      {error && <div className="form-error">{error}</div>}
    </Form>
  );
};

export default reduxForm({
  form: 'otpEmail',
  initialValues: {},
})(OtpEmailFrom);
