import React, { useState, useEffect, useRef } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import DishImage from '../client/DishImage';
import WEIcon from '../../components/WE-IconButton';

import Switch from 'react-switch';
import { sleep } from '../../services/utils';
import { Alert, DialogWithInput } from '../../components/Dialogs/SwalDialogs';
import WEBackButton from '../../components/WE-BackButton';
import WEToggle from '../../components/WE-Toggle';
import MealSummary from '../../components/Kitchen/MealSummary';
import ViewMealReportDetail from './ViewMealReportDetail';
import AddEditDishPopup from './AddEditdishPopup';

const MealReport = ({
  setLayoutOptions,
  kitchen,
  MealDishStatuses,
  useLoader,
  useFetchingLoader,
  MealStatuses,
  DishStatuses,
  MealTypes,
  languages,
  DishCategories,
}) => {
  const InitialValues = {
    image: null,
    dishCategoryId: 1,
    translations: languages.map((lang) => {
      return { languageId: lang.id };
    }),
    price: 0,
  };
  const { t } = useTranslation();
  const { mealTypeId, date } = useParams();

  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);

  const [data, setData] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [translations, setTranslations] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [values, setValues] = useState(InitialValues);
  const [dish, setDish] = useState();
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [fileValue, setFileValue] = useState();
  const [errors, setErrors] = useState({});
  const [dummy, setDummy] = useState(false);

  const MealDishStatusMap = MealDishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});
  const [actionDropdown, setActionDropdown] = useState(false);
  const [openModel, setOpenModel] = useState();
  const handleCloseModel = () => {
    setOpenModel(false);
  };
  const handleDropdownClick = (id) => {
    setActionDropdown(id);
    setOpenModel(true);
  };
  const dropdownRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setActionDropdown(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    const fetchData1 = async () => {
      setLayoutOptions({
        title: t('mealReport'),
        showDatePicker: true,
        isDatePickerClickable: false,
      });
      if (!kitchen) return;
      const { data } = await useFetchingLoader(
        async () =>
          await http.get(`mealsDishes`, {
            filter: {
              mealTypeId: mealTypeId,
              date: new Date(date),
              kitchenId: kitchen.id,
              mealDishStatusId: [
                MealDishStatusMap.active,
                MealDishStatusMap.blocked,
              ],
            },
          })
      );

      setData(data);
      setTotalOrders(data.reduce((total, dish) => total + dish.ordered, 0));
    };
    fetchData1();
  }, [kitchen, addEditPopup]);

  const handleInventoryChange = async (mealId, inventory) => {
    try {
      await http.put(`mealsDishes/${mealId}`, {
        inventory: parseInt(inventory),
      });
      const mealIndex = data.findIndex((m) => m.id === mealId);
      data[mealIndex].inventory = inventory;
      // Update the meal inventory with the response from the db
      setData([...data]);
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleBlockMeal = async (mealDishId, mealDishStatusId) => {
    let changeToStatus =
      MealDishStatusMap.active === mealDishStatusId
        ? MealDishStatusMap.blocked
        : MealDishStatusMap.active;
    try {
      await useFetchingLoader(
        async () =>
          await http.put(`mealsDishes/${mealDishId}`, {
            mealDishStatusId: changeToStatus,
          })
      );
      setData((state) =>
        state.map((mealDish) =>
          mealDish.id === mealDishId
            ? { ...mealDish, mealDishStatusId: changeToStatus }
            : mealDish
        )
      );
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  useEffect(() => {
    const fetchData1 = async () => {
      const { data: _tags } = await useFetchingLoader(async () => {
        return await http.get('tags');
      });
      setTags(_tags.filter((tag) => tag.status));

      // if (dishId) {
      //   const {
      //     image,
      //     dishCategoryId,
      //     translations,
      //     price,
      //     tags: selectedTag,
      //   } = await useFetchingLoader(
      //     async () => await http.get(`dishes/${dishId}`, { language: 'all' })
      //   );
      //   setValues({
      //     image: makeImage(image),
      //     dishCategoryId,
      //     translations,
      //     price,
      //     tags: selectedTag.map((tag) => tag.id),
      //   });
      //   setSelectedTags(selectedTag.map((tag) => tag.id));
      // } else {
      //   setValues(InitialValues);
      // }
    };
    fetchData1();
  }, []);

  const handleAddEdit = (id) => {
    setDish(id);
    setValues(InitialValues);
    setAddEditPopup(true);
  };
  const handleClose = () => {
    setDummy(false);
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setAddEditPopup(!addEditPopup);
    setDish(null);
  };

  const updateData = () => {
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setAddEditPopup(!addEditPopup);
    setDish(null);
    fetchDataFromAPI();
  };
  return (
    <>
      {/* <WEBackButton /> */}

      {data && (
        <div className={'meal-report menu-construction-page'}>
          <div className="container">
            <div className={'menu-construction'}>
              {data.length > 0 ? (
                <div className={'dishes'}>
                  <div className="header-listing">
                    <h6>{t('name')}</h6>
                    <h6>{t('inventory')}</h6>
                    <h6>{t('orders')}</h6>
                    <h6>{t('realized')}</h6>
                    {/* <h6>{t('chefDish')}</h6> */}
                    {/* <h6>{t('category')}</h6> */}
                    <h6></h6>
                  </div>
                  {data
                    .sort((d1, d2) =>
                      d1?.dish?.name.localeCompare(d2?.dish?.name)
                    )
                    .map((data) => (
                      <MealDish
                        key={data.id}
                        data={data}
                        MealDishStatusMap={MealDishStatusMap}
                        handleChange={handleInventoryChange}
                        handleOpenToOrders={handleBlockMeal}
                        useFetchingLoader={useFetchingLoader}
                        setActionDropdown={setActionDropdown}
                        actionDropdown={actionDropdown}
                        setOpenModel={setOpenModel}
                        openModel={openModel}
                        handleDropdownClick={handleDropdownClick}
                        handleCloseModel={handleCloseModel}
                        wrapperRef={wrapperRef}
                        handleAddEdit={handleAddEdit}
                      />
                    ))}
                </div>
              ) : (
                <div className="no-dishes">
                  <p>{t('noDishes')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {addEditPopup && (
        <AddEditDishPopup
          show={addEditPopup}
          handleClose={handleClose}
          DishStatuses={DishStatuses}
          languages={languages}
          DishCategories={DishCategories}
          kitchen={kitchen}
          setAddEditPopup={setAddEditPopup}
          translations={translations}
          setTranslations={setTranslations}
          tags={tags}
          setTags={setTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          setValues={setValues}
          values={values}
          InitialValues={InitialValues}
          dish={dish}
          setDish={setDish}
          fileValue={fileValue}
          setFileValue={setFileValue}
          errors={errors}
          setErrors={setErrors}
          setDummy={setDummy}
          updateData={updateData}
        />
      )}
    </>
  );
};

const MealDish = ({
  data,
  MealDishStatusMap,
  handleChange,
  handleOpenToOrders,
  useFetchingLoader,
  setActionDropdown,
  actionDropdown,
  setOpenModel,
  openModel,
  handleDropdownClick,
  handleCloseModel,
  wrapperRef,
  handleAddEdit,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [inventoryValue, setInventoryValue] = useState(data.inventory);
  const isNonNegNumber = (value) => Number.parseInt(value) > -1;

  useEffect(() => {
    setInventoryValue(data.inventory);
  }, [data.inventory]);

  const handleInventoryChange = async (inventory) => {
    try {
      if (isNonNegNumber(inventory)) {
        // Entered value is undefined or negative
        if (inventory >= data.ordered) {
          await useFetchingLoader(() => handleChange(data.id, inventory));
        } else {
          Alert({ error: { content: t('inventoryLessThanOrdered') } });
        }
      } else {
        Alert({ error: { content: t('inventoryLessThanZero') } });
      }
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const percentToDisplay = (realized, orders) =>
    orders > 0 ? Number.parseInt((realized / orders) * 100) : 0;

  const handlePlusClick = async () => handleInventoryChange(inventoryValue + 1);
  const handleMinusClick = async () =>
    handleInventoryChange(inventoryValue - 1);

  const openInventoryModal = (dishName = '') => {
    DialogWithInput({
      title: `${t('enterInventoryFor')} ${dishName}`,
      type: 'number',
      onConfirm: async (data) =>
        await handleInventoryChange(Number(data.value)),
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };

  return (
    <div className={'meal-dish mealdish-mob'}>
      {/* <div className={'image'}>
        <DishImage fileName={data.dish.image} />
      </div> */}

      {/* Name and Rating */}
      <section className={'dish-name'}>
        <p className={'name'}>{data.dish.name}</p>
        {/* <MealSummary mealData={data} /> */}
      </section>

      {/* Inventory */}

      <section className="inventroy">
        <div className={'inventory'}>
          <i class="las la-plus" onClick={handlePlusClick}></i>

          <input
            className={'inv-input'}
            type={'number'}
            min={0}
            value={inventoryValue}
            onClick={() => openInventoryModal(data?.dish?.name)}
            // onChange={event => handleInventoryChange(Number.parseInt(event.target.value))}
            // onBlur={(e) =>  handleInventoryChange(Number.parseInt(e.target.value))}
          />
          <i
            onClick={handleMinusClick}
            className={`las la-minus ${
              inventoryValue < 1 ? 'inventroy-disable' : ''
            }`}
          ></i>
        </div>
      </section>
      <section className="orders">
        <PercentData
          label={t('orders')}
          value={data?.ordered}
          outOfValue={data?.inventory}
        />
      </section>
      <section className="realized">
        <PercentData
          label={t('realized')}
          value={data?.realized}
          outOfValue={data?.inventory}
        />
      </section>

      {/* Inventory and Deletion */}
      <section className={''}>
        {/* <div className={'inventory'}>
          <p className={'label'}>{t('inventory')}: </p>
          <div className={'inventory-input'}>
            <WEIcon
              icon={'plus'}
              backgroundColor={'green'}
              iconSize={'small'}
              cb={handlePlusClick}
            />
            <input
              className={'inv-input'}
              type={'number'}
              min={0}
              value={inventoryValue}
              onClick={() => openInventoryModal(data?.dish?.name)}
              // onChange={event => handleInventoryChange(Number.parseInt(event.target.value))}
              // onBlur={(e) =>  handleInventoryChange(Number.parseInt(e.target.value))}
            />
            <WEIcon
              icon={'minus'}
              backgroundColor={'green'}
              iconSize={'small'}
              cb={handleMinusClick}
              isDisabled={inventoryValue < 1}
            />
          </div>
        </div> */}

        <div className={'actions'}>
          {/* <WEIcon
            icon={'edit'}
            iconSize={'small'}
            text={t('edit')}
            cb={() => handleAddEdit(data?.dish.id)}
          /> */}
          <i
            class="las la-pen"
            onClick={() => handleAddEdit(data?.dish.id)}
          ></i>

          <WEToggle
            onChange={() => handleOpenToOrders(data.id, data.mealDishStatusId)}
            checked={data.mealDishStatusId === MealDishStatusMap.active}
            onColor={'#14cd31'}
            label={
              MealDishStatusMap.blocked === data.mealDishStatusId
                ? `${t('open')} ${t('toOrders')}`
                : `${t('hide')} ${t('toOrders')}`
            }
            size={'small'}
          />
        </div>
        <div className="mob-icon">
          <div className="custom-dropdown">
            <div className="dropdown-menu-icon">
              <i
                class="las la-angle-right"
                onClick={() => handleDropdownClick(data?.id)}
              ></i>
            </div>
            {actionDropdown === data?.id && (
              <ViewMealReportDetail
                open={openModel}
                handleCloseModel={handleCloseModel}
                setActionDropdown={setActionDropdown}
                actionDropdown={actionDropdown}
                data={data}
                useFetchingLoader={useFetchingLoader}
                handleDropdownClick={handleDropdownClick}
                wrapperRef={wrapperRef}
                inventoryValue={inventoryValue}
                handleAddEdit={handleAddEdit}
                handlePlusClick={handlePlusClick}
                handleMinusClick={handleMinusClick}
                handleInventoryChange={handleInventoryChange}
                setOpenModel={setOpenModel}
                MealDishStatusMap={MealDishStatusMap}
                handleOpenToOrders={handleOpenToOrders}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const percentToDisplay = (value, outOfValue) =>
  outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;
const PercentData = ({ value, outOfValue, label }) => {
  value = value ?? 0;
  outOfValue = outOfValue ?? 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>
          {value} {`(${percentToDisplay(value, outOfValue)}%)`}
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  MealDishStatuses: state.app.enums.MealDishStatus,
  MealStatuses: state.app.enums.MealStatus,
  DishStatuses: state.app.enums.DishStatus,
  MealTypes: state.app.enums.MealType,
  languages: state.languages.options,
  DishCategories: state.app.enums.DishCategory,
});

const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(MealReport);
