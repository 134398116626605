import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setLayoutOptions } from '../../../store/app/actions';
import { Alert, SuccessDailog } from '../../../components/Dialogs/SwalDialogs';
import SecurityForm from '../../../components/Forms/SecurityForm';
import * as SwalDialogs from '../../../components/Dialogs/SwalDialogs';
import { saveSecuritySetting } from '../../../store/securitySetting/actions';

// styles
import styles from './Security.module.scss';
import { isIPv4 } from '../../../services/validators';
import { useEffect, useState } from 'react';
import http from '../../../services/http';
import ProfileLayout from '../../profile/profileLayout';

const Security = ({ user, setLayoutOptions, saveSecuritySetting }) => {
  const { t } = useTranslation();
  const [securityData, setSecurityData] = useState();
  const [kitchen, setKitchen] = useState();
  useEffect(() => {
    const fetchData1 = async () => {
      let url = window.location.href;
      let kitchenId = url.split('?')[1];
      setKitchen(kitchenId);
      const data = await http.get(`securitySettings/getByCompanyId`, {
        kitchenId: kitchenId,
      });

      setSecurityData(data);
    };
    fetchData1();
  }, []);
  setLayoutOptions({ title: t('security'), showKitchen: false });

  const onSubmit = async (form) => {
    try {
      const { whitelistIPs: strlist } = form;
      const whitelistIPs = strlist?.split(',');

      if (whitelistIPs) {
        if (whitelistIPs[0]) {
          for (const whiteList of whitelistIPs) {
            if (!isIPv4(whiteList)) {
              Alert({ error: t('ipv4FormatIncorrect') });
              return;
            }
          }
        }
      }

      await saveSecuritySetting({ whiteLists: strlist, kitchenId: kitchen });

      SuccessDailog({
        title: t('securityFormSuccess'),
        type: 'success',
      });
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handleSubmit = async (form) => {
    SwalDialogs.Dialog({
      title: t('confirmModalTitle'),
      type: 'warning',
      buttons: {
        showCancelButton: true,
        confirmButtonText: t('confirm'),
      },
      onConfirm: () => onSubmit(form),
    });
  };

  return (
    <ProfileLayout>
      <div className="security-page">
        <div className="page-heading">
          <h3>Security</h3>
          <p>{t('securityFormTitle')}</p>
        </div>
        <SecurityForm
          onSubmit={handleSubmit}
          securityData={securityData}
          initialValues={{ whitelistIPs: securityData?.whitelistIPs }}
        />
      </div>
    </ProfileLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLayoutOptions,
  saveSecuritySetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
