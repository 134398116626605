import React from 'react';
import { ActiveButton } from './WE-Button';
import dates from '../services/dates';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const showPreviousContainer = ({
  items,
  assignMethod,
  showPreviousLabel = 'showPreviousDates',
  showPrevious,
  showPreviousOnClick,
}) => {
  const { t } = useTranslation();
  const today = dates.formatServer(new Date());

  // const futureItems = items.filter(
  //   (item) => showPrevious || item?.date?.localeCompare(today) >= 0
  // );

  const futureItems = items.filter(
    (item) =>
      showPrevious ||
      item?.date?.localeCompare(today) >= 0 ||
      item?.meal?.date?.localeCompare(today) >= 0
  );

  return (
    <>
      {futureItems?.length === 0 ? (
        <h3 style={{ marginTop: '100px' }}>{t('noPastDates')}</h3>
      ) : (
        futureItems
          .sort((item1, item2) => item2?.date?.localeCompare(item1?.date))
          .map((item) =>
            assignMethod(item, item?.date?.localeCompare(today) >= 0)
          )
      )}
      <>
        {items.length > futureItems.length && (
          <ShowPrevious
            showPrevious={showPrevious}
            showPreviousOnClick={showPreviousOnClick}
            label={t(showPreviousLabel)}
            fakeItem={assignMethod({})}
          />
        )}
      </>
    </>
  );
};

const ShowPrevious = ({
  showPrevious,
  showPreviousOnClick,
  label,
  fakeItem,
}) => {
  return (
    !showPrevious && (
      <div className="show-previous">
        <div className={'in-front'}>
          <Button onClick={showPreviousOnClick}>{label}</Button>
        </div>

        {/*<div className={"fake-item"}>*/}
        {/*    {fakeItem}*/}
        {/*</div>*/}
      </div>
    )
  );
};

export default showPreviousContainer;
