import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WEBackButton from '../../components/WE-BackButton';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import cancel from '../../assets/images/close.svg';
import enums from '../../services/enums';
import { Table } from 'react-bootstrap';

const ref = React.createRef();
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
};
const PrintReports = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [company, setCompany] = useState([]);
  const location = useLocation();
  const [val, setVal] = useState();
  const lang = localStorage.getItem('language');

  // useEffect(() => {
  //   if (location.state) {
  //     setCompany(location.state.company);
  //     setData(location.state.data);
  //   }
  // }, [location]);
  useEffect(() => {
    if (location?.state) {
      localStorage.setItem('company', JSON.stringify(location?.state?.company));
      localStorage.setItem('data', JSON.stringify(location?.state?.data));
      setCompany(location.state.company);
      setData(location.state.data);
    } else {
      let company = localStorage.getItem('company');
      let data = localStorage.getItem('data');
      setCompany(JSON.parse(company));
      setData(JSON.parse(data));
    }
  }, [location]);

  useEffect(() => {
    if (company) {
      let ll =
        company &&
        company?.find(
          (el) => el.id === data && data[0]?.dish?.kitchen?.companyId
        );
      setVal(ll);
    }
  }, [company]);
  const printFunc = () => {
    window.print();
  };

  let inventoryTotal = 0;
  let reservationTotal = 0;
  let realizedTotal = 0;
  data?.map((el, i) => {
    inventoryTotal += el.inventory;
    reservationTotal += el.ordered;
    realizedTotal += el.realized;
  });

  const handleBack = () => {
    props.history.goBack();
  };

  return (
    <div className="print-page-popup">
      {/* <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="add-employee-popup print-popup"
      >
        <Box sx={style}>
          <div className="close-btn">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div> */}
      {/* <Typography id="modal-modal-title" variant="h5" component="h2">
          </Typography> */}
      <WEBackButton text={t('returnMenuConstruction')} cb={handleBack} />
      <div
        id={`printpreview`}
        className={`${lang === '"he"' ? 'align-table' : ''}`}
      >
        <Table
          responsive
          align="center"
          style={{
            width: '100%',
            padding: '10px 0px',
            paddingBottom: '0px',
            marginLeft: 'auto',
            marginRight: 'auto',
            // margin: '5px',
          }}
          className="print-table print-first"
        >
          <tbody
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '0px auto',
            }}
          >
            <tr>
              <td
                style={{
                  textAlign: 'center',
                  fontWeight: '700',
                  paddingBottom: '0px',

                  fontSize: '20px',
                  color: 'black',
                }}
                colSpan={2}
                className="mealtype"
              >
                {data &&
                  data.length &&
                  data[0].meal.mealType &&
                  data[0].meal.mealType.display}{' '}
                (
                {data &&
                  data.length &&
                  data[0].meal.mealType &&
                  data[0].meal.mealType.from}{' '}
                {data &&
                  data.length &&
                  data[0].meal.mealType &&
                  data[0].meal.mealType.to}
                )
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'center !important',
                  fontWeight: '600',
                  paddingtop: '0px',

                  fontSize: '18px',
                  paddingBottom: '50px',
                }}
              >
                {data &&
                  data.length &&
                  data[0].meal &&
                  new Date(data[0].meal.date).toLocaleDateString('he-IL')}
              </td>
            </tr>
            <tr className="box-list">
              <td style={{ width: '50%', paddingLeft: '0px' }}>
                <div className="box-section">
                  <p>
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                      {' '}
                      {t('kitchenName')}:
                    </span>{' '}
                    {data &&
                      data.length &&
                      data[0].dish.kitchen &&
                      data[0].dish.kitchen.name}
                  </p>
                  <p>
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                      {t('createddate')}:{' '}
                    </span>{' '}
                    {data &&
                      new Date().toLocaleString('he-IL', {
                        hour: '2-digit',
                        minute: '2-digit',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                  </p>
                  {/*<p>
                          <span style={{ fontSize: '16px', fontWeight: '600' }}>
                            {t('companyName')}:
                          </span>{' '}
                          {val?.name}
                        </p>*/}
                </div>
              </td>
              <td style={{ paddingRight: '0px' }}>
                <div className="box-section">
                  <p>
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                      {t('totalInventory')}:{' '}
                    </span>{' '}
                    {inventoryTotal}
                  </p>

                  <p>
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                      {t('totalReservations')}:
                    </span>{' '}
                    {reservationTotal}&nbsp;(
                    {inventoryTotal !== 0
                      ? ((reservationTotal / inventoryTotal) * 100).toFixed(0)
                      : '0'}
                    %)
                  </p>

                  <p>
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                      {t('totalRealized')}:{' '}
                    </span>{' '}
                    {realizedTotal}&nbsp;(
                    {reservationTotal !== 0
                      ? ((realizedTotal / reservationTotal) * 100).toFixed(0)
                      : 0}
                    %)
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table
          responsive
          style={{
            width: '100%',
            //   border: '1px solid #d7d7d7',
            padding: '20px',
            paddingBottom: '150px',
            marginLeft: 'auto',
            marginRight: 'auto',
            // margin: '50px 5px 5px 5px',
          }}
          className="print-table print-bottom-table table-responsive"
        >
          <tr>
            <th>{t('dishName')}</th>
            <th>{t('tagsname')}</th>
            <th>{t('category')}</th>
            <th>{t('tInventory')}</th>
            <th>{t('reservations')}</th>
            <th>{t('tRealized')}</th>
            <th>{t('chef')}</th>
          </tr>
          {data.map((el, i) => {
            return (
              <tr className="border-for-print-view">
                <td style={{ fontSize: '16px', fontWeight: '600' }}>
                  <span>{el.dish.name}</span>
                  <br />
                  <span style={{ fontSize: '16px' }}>
                    {el.dish.description}
                  </span>
                </td>
                <td style={{ fontSize: '18px', fontWeight: '600' }}>
                  {el.dish.tags.map((ele, i) => (
                    <span>{ele.displayName}, </span>
                  ))}
                </td>
                <td style={{ fontSize: '18px', fontWeight: '600' }}>
                  {enums.display('DishCategory', el.dish.dishCategoryId)}
                </td>
                <td>
                  <span style={{ fontSize: '18px', fontWeight: '600' }}>
                    {el.inventory}
                  </span>{' '}
                  {/* {data && data.dish && data.dish.inventory} */}
                </td>
                <td>
                  <span style={{ fontSize: '18px', fontWeight: '600' }}>
                    {el.ordered}
                  </span>{' '}
                  {/* {data && data.ordered} */}
                </td>
                <td>
                  {' '}
                  <span style={{ fontSize: '18px', fontWeight: '600' }}>
                    {el.realized}
                  </span>{' '}
                  {/* {data && data.realized} */}
                </td>
                <td>
                  {' '}
                  <span style={{ fontSize: '18px', fontWeight: '600' }}>
                    {el.isChefMealDish ? 'V' : ''}
                  </span>{' '}
                  {/* {data && data.isChefMealDish ? 'Yes' : 'No'} */}
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
      <div className="print-btn">
        <button onClick={printFunc}>{t('printReport')}</button>
      </div>

      {/* </Box>
      </Modal> */}
    </div>
  );
};
export default PrintReports;
