import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/InputFields/InputField';
import CheckBoxField from '../../components/InputFields/CheckBox';
// Validators
import {
  isPhoneNumber,
  normalizePhone,
  required,
  isAlpha,
  email,
  passwordValidator,
  isNumber,
  passwordsMatch,
} from '../../services/validators';

import FieldWrapper from '../../components/InputFields/FieldWrapper';
import ArrowIcon from '../../assets/images/left-arrow.svg';

import { ActiveButton } from '../../components//WE-Button';

let ChangePasswordForm = (props) => {
  const { t } = useTranslation();

  const { handleSubmit, isReset, error, invalid } = props;

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="change-password-heading">
        <p className={'form-explanation'}>{t('changePassword')}</p>
      </div>
      <div className="inputs-wrapper">
        <div className="password-info">
          <Field
            name={`oldPassword`}
            containerClass="password"
            type="password"
            component={InputField}
            validate={[required]}
            placeholder={t('oldPassword')}
            icon="padlock"
          />
          <Field
            name={`newPassword`}
            containerClass="password"
            type="password"
            component={InputField}
            validate={[passwordsMatch, required]}
            placeholder={t('newPassword')}
            icon="padlock"
          />
          <h6>{t('passwordSecurity')}</h6>

          <Field
            name={`newPasswordConfirm`}
            containerClass="password"
            type="password"
            component={InputField}
            validate={[required]}
            placeholder={t('repeatPasswordPH')}
            icon="padlock"
          />
        </div>
        <ActiveButton
          type="submit"
          label={t('updatePassword')}
          additionalClassName={'btn'}
        />
      </div>

      {error && <div className="form-error">{error}</div>}
    </form>
  );
};

export default reduxForm({
  form: 'changePassword',
  initialValues: {},
})(ChangePasswordForm);
