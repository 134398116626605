import React from 'react';
// import LanguageSelection from '../../components/Forms/LanguageSelection';
import { connect } from 'react-redux';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import cancel from '../../assets/images/close.svg';
import { useHistory } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};

const GuestScreen = ({ content, dailog, handleClose, t }) => {
  const history = useHistory();
  const handleLogin = () => {
    history.push('/login');
  };
  return (
    <div className={'guest-screen'}>
      {/* <LanguageSelection /> */}
      <div className={'guest-content'}>{content}</div>
      {dailog ? (
        <Modal
          open={dailog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="lg"
          className="add-employee-popup copymeal-popup  check-payment-popup"
        >
          <Box sx={style}>
            <div className="extras-listing-section">
              <h4>{t('resetPassword')}</h4>
              <div className={'extras-meal-management'}>
                <p>{t('resetPasswordExpired')}</p>
              </div>

              <div className="extras-button other-button">
                <button onClick={() => handleLogin()}>{t('confirm')}</button>
                {/* <button onClick={skipAddOrder}>{t('skip')}</button> */}
              </div>
            </div>
          </Box>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GuestScreen);
