import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import http from '../../services/http';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '860px',
  width: '100%',
  height: window.innerWidth < 481 ? '90vh' : '',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const AddFactory = ({
  open,
  handleClose,
  Languages,
  t,
  useFetchingLoader,
  handleFormSubmit,
  payload,
  setPayload,
  editMode,
  kitchens,
  setRefresh,
}) => {
  const resetErrConfig = { name: false, kitchens: false };
  const [errors, setErrors] = useState(resetErrConfig);
  const handleChange = (e, type) => {
    if (type === 'factoryName') {
      setPayload((prev) => ({ ...prev, name: e.target.value }));
    } else {
      setPayload((prev) => ({ ...prev, kitchens: e }));
    }
  };
  const handleAddFactory = async () => {
    try {
      const res = await useFetchingLoader(
        async () =>
          await http.post('factories', {
            name: payload.name,
            kitchenIds: payload.kitchens.map((el) => el.value),
          })
      );
      SwalDialogs.Dialog({
        title: `${t('addedmessage')}`,
        type: 'success',
        buttons: {
          confirmButtonText: t('ok'),
          showCancelButton: false,
        },
        onConfirm: () => {
          handleClose();
          setRefresh((prev) => !prev);
        },
      });
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
    }
  };
  const handleValidate = () => {
    if (!payload?.name || !payload?.kitchens || payload.kitchens.length === 0) {
      setErrors({
        name: !payload.name,
        kitchens: !payload?.kitchens || payload.kitchens.length === 0,
      });
    } else {
      setErrors(resetErrConfig);
      editMode ? handleUpdateFactory() : handleAddFactory();
    }
  };
  const handleUpdateFactory = async () => {
    try {
      const res = await useFetchingLoader(
        async () =>
          await http.put(`factories/${payload.id}`, {
            name: payload.name,
            kitchenIds: payload.kitchens.map((el) => el.value),
          })
      );
      SwalDialogs.Dialog({
        title: `${t('updatedMessage')}`,
        type: 'success',
        buttons: {
          confirmButtonText: t('ok'),
          showCancelButton: false,
        },
        onConfirm: () => {
          handleClose();
          setRefresh((prev) => !prev);
        },
      });
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
    }
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      className="add-employee-popup add-kitchen-popup"
      onClose={() => {
        setErrors(resetErrConfig);
        handleClose();
      }}
    >
      <Box sx={style}>
        <div className="close-btn">
          <h6>{editMode ? t('updateFactory') : t('addFactory')} </h6>
          <img
            src={cancel}
            alt="close"
            onClick={() => {
              setErrors(resetErrConfig);
              handleClose();
            }}
          />
        </div>

        <div className="item">
          <div className="input-field">
            <strong className={'label'}>{t('factoryName')}</strong>
            <input
              name={`factoryName`}
              type="text"
              onChange={(e) => handleChange(e, 'factoryName')}
              value={payload.name}
              placeholder={t('factoryName')}
            />
            {errors.name && <p className="error-msg">{t('fieldIsRequired')}</p>}
          </div>
          <div className="input-field">
            <strong className={'label'}>{t('kitchens')}</strong>
            <Select
              isMulti
              name={'kitchensIds'}
              value={
                payload.kitchens
                  ? payload.kitchens.map((el) => {
                      return { value: el.value, label: el.label };
                    })
                  : []
              }
              onChange={(newData) => handleChange(newData, 'factoryKitchen')}
              options={
                kitchens
                  ? kitchens.map((el) => {
                      return { value: el.id, label: el.name };
                    })
                  : []
              }
              placeholder={t('kitchenname')}
              styles={SelectStyles}
            />
            {errors.kitchens && (
              <p className="error-msg">{t('fieldIsRequired')}</p>
            )}
          </div>
        </div>
        <div className="add-employee-btn">
          <div>
            <button arrow={false} onClick={(e) => handleValidate()}>
              {editMode ? t('update') : t('add')}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default AddFactory;
