import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import Box from '@material-ui/core/Box';
import { Modal, Grid, styled, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ActiveButton, DisabledButton } from '../../components/WE-Button';
import cancel from '../../assets/images/close.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 15px',
  p: 4,
  borderRadius: '5px',
  Width: '550px',
  minHeight: '250px',
};
const SlotPopup = ({
  slotPopup,
  handleSlot,
  t,
  goToDishSelection,
  mealTypeId,
  mealTypes,
  mealType,
  allSlot,
  mealSlot,
  selectMealSlot,
  slotBooked,
  check,
  availableSlot,
  handleClose,
}) => {
  return (
    <Modal
      open={slotPopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup slot-popup"
    >
      <Box sx={style}>
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>

        <Typography id="modal-modal-title" variant="h5" component="h2">
          <div className="slot-section">
            <h6>{t('preferedTime')} </h6>
            {allSlot && allSlot.length > 0 ? '' : t('noSlotsAvailable')}

            {allSlot &&
              allSlot.map((el, i) => (
                <div className="slot-timing" key={i}>
                  <div className="slot-timing-listing">
                    <input
                      type="radio"
                      id="1st"
                      name="slottime"
                      value={el.id}
                      onChange={() => {
                        selectMealSlot(el.id);
                      }}
                      disabled={slotBooked.indexOf(el.id) >= 0 ? true : false}
                    />
                    <label for="1st">
                      {/* {t('firstSeat')}:{' '} */}
                      {el.mealTypesSlot.from && el.mealTypesSlot.to
                        ? el.mealTypesSlot.from + ' - ' + el.mealTypesSlot.to
                        : ''}
                    </label>
                  </div>
                </div>
              ))}

            <div className="bottom">
              <div className="button-wrapper">
                {availableSlot === 1 || (allSlot && allSlot.length === 0) ? (
                  <ActiveButton
                    label={t('dishSelections')}
                    cb={goToDishSelection}
                    arrow={true}
                  />
                ) : (
                  <DisabledButton
                    label={t('dishSelections')}
                    cb={goToDishSelection}
                    arrow={true}
                  />
                )}
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

export default SlotPopup;
