import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import addicon from '../../assets/images/icons/add.svg';
import deleteicon from '../../assets/images/icons/delete.svg';
import editicon from '../../assets/images/icons/edit.svg';
import securityicon from '../../assets/images/icons/security.svg';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import mealIcon from '../../assets/images/icons/fork-plate-knife.svg';
const FactoryDetails = ({
  show,
  handleClose,
  data,
  handleDelete,
  setEditMode,
  setAddEditFactoryPopup,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      size="lg"
      centered
      className="add-mealtypes-popup details-meal-typ-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('factoryDetails')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-more-popup">
          <div className="user-detail-container kitchen-detail-container">
            <div className="user-details-row">
              <div className="user-details-block">
                <h6>{t('factoryName')}</h6>
                <p>{data?.name ? data?.name : '--'}</p>
              </div>
              <div className="user-details-block">
                <h6>{t('kitchens')}</h6>
                <p>
                  {data?.kitchens
                    ? data?.kitchens.map((el) => el.name).join(', ')
                    : '--'}
                </p>
              </div>
              <div className="modal-footer-btn">
                <Button
                  onClick={() => {
                    handleClose(false);
                    setEditMode(true);
                    setAddEditFactoryPopup(true);
                  }}
                >
                  <img src={editicon} />
                  {t('edit')}
                </Button>
                <Button onClick={() => handleDelete(data.id)}>
                  <img src={deleteicon} />
                  {t('delete')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FactoryDetails;
