import React from 'react';

/* Font Awesome icons */
// Link: https://fontawesome.com/icons

/* SVG url */
// https://commons.wikimedia.org/wiki/File:Antu_emblem-encrypted-unlocked.svg

import LogoutIcon from '../assets/icons/icon-logout-green.svg';
import VoucherIcon from '../assets/icons/icon-coupun-dark.svg';
import VoteIcon from '../assets/icons/icon-vote-dark.svg';
import PlusIcon from '../assets/icons/icon-plus-whit.svg';
import MinusIcon from '../assets/icons/icon-minus-whit-copy.svg';
import DeleteIcon from '../assets/icons/icon-delete.svg';
import EditIcon from '../assets/icons/icon-edit.svg';
import InfoIcon from '../assets/icons/icon-info-green.svg';
import DownIcon from '../assets/icons/icon-down.svg';

// import ArrowIcon from '../assets/images/left-arrow.svg';
import ArrowIcon from '../assets/icons/eva_arrow-back-fill.svg';
import SearchIcon from '../assets/images/loupe.svg';
import EditWhiteIcon from '../assets/images/edit.svg';
import CheckIcon from '../assets/images/check.svg';
import CheckGreyIcon from '../assets/images/check_grey.svg';
import CheckBlackIcon from '../assets/images/check_black.svg';
import CheckLightgreyIcon from '../assets/images/check_lightgrey.svg';
import CloseIcon from '../assets/images/close.svg';
import CloseBlackIcon from '../assets/images/close_black.svg';
import LabelIcon from '../assets/images/label.svg';
import CalendarIcon from '../assets/images/calendar.svg';
import DeleteWhiteIcon from '../assets/images/cancel.svg';

import LockIcon from '../assets/icons/lock.png';
import UnlockIcon from '../assets/icons/unlock.png';

import i18n from 'i18next';

const Icons = {
  logout: { src: LogoutIcon, size: { medium: { height: 25, width: 25 } } },
  voucher: {
    src: VoucherIcon,
    size: {
      xSmall: { height: 15, width: 15 },
      small: { height: 20, width: 20 },

      medium: { height: 25, width: 25 },
    },
  },
  edit: {
    src: EditIcon,
    label: 'edit',
    size: {
      xSmall: { height: 15, width: 15 },
      small: { height: 20, width: 20 },
      medium: { height: 25, width: 25 },
    },
  },
  vote: { src: VoteIcon, size: { medium: { height: 25, width: 25 } } },
  down: { src: DownIcon, size: { medium: { height: 25, width: 25 } } },
  plus: {
    src: PlusIcon,
    size: {
      small: { height: 20, width: 20 },
      medium: { height: 25, width: 25 },
    },
  },
  minus: {
    src: MinusIcon,
    size: {
      small: { height: 20, width: 20 },
      medium: { height: 25, width: 25 },
    },
  },
  delete: {
    src: DeleteIcon,
    label: 'delete',
    size: {
      xSmall: { height: 15, width: 15 },
      small: { height: 20, width: 20 },
      medium: { height: 25, width: 25 },
    },
  },
  info: {
    src: InfoIcon,
    label: 'info',
    size: {
      small: { height: 30, width: 30 },
      medium: { height: 30, width: 30 },
    },
  },

  arrow: { src: ArrowIcon, size: { medium: { height: 20, width: 20 } } },
  search: { src: SearchIcon, size: { medium: { height: 20, width: 20 } } },
  editWhite: {
    src: EditWhiteIcon,
    label: 'edit',
    size: { medium: { height: 20, width: 20 } },
  },
  check: { src: CheckIcon, size: { medium: { height: 20, width: 20 } } },
  checkGrey: {
    src: CheckGreyIcon,
    size: { medium: { height: 20, width: 20 } },
  },
  checkLightgrey: {
    src: CheckLightgreyIcon,
    size: { medium: { height: 20, width: 20 } },
  },
  checkBlack: {
    src: CheckBlackIcon,
    size: { medium: { height: 20, width: 20 } },
  },
  close: {
    src: CloseIcon,
    label: 'close',
    size: { medium: { height: 20, width: 20 } },
  },
  closeBlack: {
    src: CloseBlackIcon,
    label: 'close',
    size: { medium: { height: 20, width: 20 } },
  },
  label: {
    src: LabelIcon,
    size: { medium: { height: 20, width: 20 } },
  },
  calendar: { src: CalendarIcon, size: { medium: { height: 30, width: 30 } } },

  // plus: {src: PlusIcon, size:{medium:{height:30,width:30}}},
  // minus: {src: MinusIcon, size:{medium:{height:30,width:30}}},
  deleteWhite: {
    src: DeleteWhiteIcon,
    label: 'delete',
    size: {
      small: { height: 30, width: 30 },
      medium: { height: 40, width: 40 },
    },
  },

  lock: {
    src: LockIcon,
    label: 'lock',
    size: { medium: { height: 30, width: 30 } },
  },
  unlock: {
    src: UnlockIcon,
    label: 'unlock',
    size: { medium: { height: 30, width: 30 } },
  },
};

const WrapperSizes = {
  xSmall: { height: 15, width: 15 },
  small: { height: 30, width: 30 },
  medium: { height: 40, width: 40 },
  large: { height: 50, width: 50 },
};

const WEIcon = ({
  icon,
  text,
  textColor = 'black',
  backgroundColor = 'transparent',
  iconSize = 'medium',
  isDisabled = false,
  additionalClassname = '',
  additionalWrapperClassname = '',
  additionalTextClassname = '',
  cb = () => {},
}) => {
  let label = Icons[icon]?.label ? i18n.t(Icons[icon]?.label) : undefined;
  label = text ?? label;

  return (
    <div
      className={`icon-wrapper ${additionalWrapperClassname} ${
        isDisabled ? 'disabled' : ''
      }`}
      onClick={isDisabled ? () => {} : cb}
    >
      {icon && (
        <div
          className={`we-icon ${icon}Icon ${iconSize} ${backgroundColor} ${additionalClassname}`}
          style={{ ...WrapperSizes[iconSize] }}
        >
          <img
            src={Icons[icon]?.src}
            alt=""
            style={Icons[icon]?.size[iconSize]}
          />
        </div>
      )}
      {label && (
        <p className={`we-icon-text ${additionalTextClassname} ${iconSize}`}>
          {label}
        </p>
      )}
    </div>
  );
};

export default WEIcon;
