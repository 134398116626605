import React, { useState } from 'react';
import { Field } from 'redux-form';

const iconsPath = `${process.env.PUBLIC_URL}/icons/`;

// @ts-ignore
const FieldWrapper = (props) => {
  return (
    <div className="field-wrapper">
      <img className="icon" src={`${iconsPath}${props.icon}.svg`} alt="" />
      <Field {...props} />
    </div>
  );
};

export default FieldWrapper;
