import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { StaticStars } from '../../components/RatingStars';
import WEIcon from '../../components/WE-IconButton';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewCurrentMealDetail = ({
  open,
  handleClose,
  details,
  handleRatingPage,
  MealsCallBacks,
  isFuture,
}) => {
  const { t, i18n } = useTranslation();

  const handleDeleteClick = async () => {
    if (details?.mealOrdered > 0) {
      Alert({ error: { content: t('cantDeleteMealWithOrders') } });
      return;
    }
    Dialog({
      title: t('removeMealTitle'),
      text: `${t('areYouSureYouWantToRemoveMeal')} ${details?.mealDate} - ${
        details?.mealType
      } `,
      buttons: {
        confirmButtonText: t('removeMealBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: async () => await MealsCallBacks.delete(details),
    });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-c-meal-popup view-dish-details"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('futureMeals')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            {/* <div className="back">
              <img src={back} alt="back" onClick={handleClose} />
            </div> */}
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('date')}:</h6>
                  <p>{details?.mealDate ? details?.mealDate : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('time')}:</h6>
                  <p>{details?.mealTime ? details.mealTime : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('mealType')}:</h6>
                  <p>{details?.mealType ? details?.mealType : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('tInventory')}:</h6>
                  <p>
                    {details?.mealInventory ? details?.mealInventory : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('ordered')}:</h6>
                  <p>{details?.mealOrdered ? details?.mealOrdered : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('realizedOrder')}:</h6>
                  <p>{details?.mealRealized ? details?.mealRealized : '--'}</p>
                </div>

                <div
                  className={`user-details-block rating ${
                    details?.mealNumOfRaters > 0 ? '' : 'disable-rating'
                  }`}
                  onClick={() => handleRatingPage(details)}
                >
                  <h6>{t('rating')}:</h6>
                  <StaticStars
                    numOfRaters={details?.mealNumOfRaters || 0}
                    rating={details?.mealRating || -1}
                  />
                </div>
                <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <WEIcon
                        icon="info"
                        text={t('moreInfo')}
                        iconSize={'small'}
                        cb={() => MealsCallBacks.moreInfo(details)}
                      />
                      {isFuture ? (
                        <WEIcon
                          icon="delete"
                          text={t('delete')}
                          iconSize={'small'}
                          cb={handleDeleteClick}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ViewCurrentMealDetail;
