import React from 'react';
import WESpinner from './WE-Spinner';
import LogoWE from '../assets/logo/logo-we.png';

import i18next from 'i18next';

const Loader = () => {
  return (
    <div className="preloader">
      <div className="block_logo">
        <img className="logo animated delay-1 slow" src={LogoWE} alt={''} />
        <h1 className="logo__text animated delay-3 slow">
          work<span>eat</span>
        </h1>
        <p className="logo__description animated delay-5 slow">אוכלים חכם</p>
      </div>
    </div>
  );
};

const FetchingLoader = () => {
  return (
    <div className="preloader fetching">
      <WESpinner size={80} color={'#14cd31'} />
      <h2>{i18next.t('loadingLabel')}</h2>
    </div>
  );
};

export default Loader;
export { FetchingLoader };
