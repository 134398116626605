import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

export default function AlertDialog({
  isOpen,
  title,
  content,
  onClose,
  withoutKitchen = false,
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="dialog-wrapper">
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={
          withoutKitchen
            ? { paper: 'alert-dialog-big' }
            : { paper: 'alert-dialog' }
        }
      >
        <DialogTitle
          id="alert-dialog-title"
          className={withoutKitchen ? 'alert-title' : ''}
        >
          {title || ''}
        </DialogTitle>
        <DialogContent className={withoutKitchen ? 'alert-text' : ''}>
          <DialogContentText id="alert-dialog-description">
            {content || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button
            onClick={handleClose}
            variant={withoutKitchen ? 'contained' : ''}
            color={withoutKitchen ? 'success' : ''}
            className={withoutKitchen ? 'button-alert-green' : ''}
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
