import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { generateMealDishData } from '../../components/DishComponents/Dish';
import { DynamicStars } from '../../components/RatingStars';
import { ActiveButton, DisabledButton } from '../../components/WE-Button';
import { sleep } from '../../services/utils';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import chat from '../../assets/images/chat.png';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import moment from 'moment';
import BackLink from '../../components/BackLink';
import DatesMethods from '../../services/dates.js';

const AddReview = ({
  setLayoutOptions,
  kitchen,
  MealTypes,
  showAlert,
  useLoader,
  user,
  useFetchingLoader,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});

  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);

  const { orderId } = useParams();
  const [details, setDetails] = useState(null);
  const [rating, setRating] = useState(0);
  const [ratingText, setReviewText] = useState('');
  const [reviewDetailsData, setReviewDetailsData] = useState();
  const [hideCommnet, setHideCommnet] = useState('');
  const [commentData, setCommentData] = useState('');
  const [currentUser, setCurrentUser] = useState();
  const [dummy, setDummy] = useState(null);
  const [userOrderId, setUserOrderId] = useState();

  // useEffect(async() => {
  //   let url= window.location.href;
  //   let urlSplit= url.split("/");
  //   let orderId= urlSplit[5];
  //  // console.log("jjjjjjjjjjjjj",orderId)
  //   const order = await useFetchingLoader(
  //     async () => await http.get(`orders/${orderId}`)
  //   );
  //   console.log("jjjjjjjjjjjjjjjjjjjjjj",order,"kkkkkkkkkkkkkkkk",user)
  // },[])

  useEffect(() => {
    const fetchData1 = async () => {
      setLayoutOptions({ title: t('addReviewTitle') });
      if (!kitchen) return;
      const order = await useFetchingLoader(
        async () => await http.get(`orders/${orderId}`)
      );

      if (order?.mealDish?.length) {
        setDetails({ ...order, kitchenName: kitchen.name });
      } else {
        const mealDishDetails = generateMealDishData(
          MealTypesMap_Id,
          order?.mealDish || order?.mealDish[0]
        );
        setDetails({ ...mealDishDetails, kitchenName: kitchen.name });
      }

      setRating(order.rating);
      setUserOrderId(order.userId);
      // setReviewText(order.ratingText);
      let currUser = localStorage.getItem('userId');
      setCurrentUser(currUser);
    };
    fetchData1();
  }, [kitchen]);
  useEffect(() => {
    const fetchData1 = async () => {
      if (!kitchen) return;
      const reviewData = await useFetchingLoader(
        async () => await http.get(`review/${orderId}`)
      );
      setReviewDetailsData(reviewData);
    };
    fetchData1();
  }, [dummy]);
  function containsEmoji(text) {
    const emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;
    return emojiRegex.test(text);
  }
  const handleSubmitReview = async () => {
    try {
      if (containsEmoji(ratingText)) {
        setTimeout(() => Alert({ error: t('textWithEmoji') }), 150);
      } else {
        const rateResponse = await useFetchingLoader(
          async () =>
            await http.post(`orders/${orderId}/rate`, { rating, ratingText })
        );
        history.push('/myMeals');
        setReviewText('');
      }
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  // const handleShowCommnet = () => {
  //   setHideCommnet(true);
  // };

  /******* add New comment */
  const handleSubmit = async () => {
    try {
      let details = {
        userId: currentUser,
        orderId: reviewDetailsData[0]?.order?.id || reviewDetailsData[0].id,
        comment: commentData,
      };
      let comment;
      await useFetchingLoader(async () => {
        comment = await http.post(`review/create`, {
          ...details,
        });
      });
      setTimeout(() => {
        Dialog({
          title: 'Commented Successfully',
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy(true);
            setCommentData('');
          },
        });
      }, 150);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  return (
    <>
      {userOrderId === user.id && (
        <div className="user-review-page">
          {/* <WEBackButton text={backText} redirectHistory={backLink} /> */}
          {/* <div className="back-button-remove">
            <WEBackButton text={backText} redirectHistory={backLink} />
          </div> */}
          <BackLink text={backText} redirectHistory={backLink} />

          {details &&
            (details?.mealDish?.length ? (
              <div className="review ">
                <div className="review-dish-detail">
                  <h4>
                    {details.mealDish.map((el, i) => {
                      return `${el.dish.name}${
                        details.mealDish.length - 1 === i ? '' : ','
                      }`;
                    })}
                  </h4>{' '}
                  <span>|</span>
                  <p>
                    {' '}
                    {t('kitchenPH')}: {details?.mealDish[0].dish.kitchen.name}{' '}
                  </p>{' '}
                  <span>|</span>
                  <p>
                    {DatesMethods.format(details?.mealDish[0]?.meal?.date)} /{' '}
                    {MealTypesMap_Id[details?.mealDish[0]?.meal?.mealTypeId]}
                  </p>
                </div>
                <div
                  className={`review-rating ${
                    reviewDetailsData &&
                    reviewDetailsData[0]?.order?.rating == null
                      ? ''
                      : 'review-rating-disable'
                  }`}
                >
                  <p className="rating-label">{t('rateUsingStars')}:</p>
                  <DynamicStars
                    value={rating}
                    cb={(value) => setRating(value)}
                  />
                </div>
                {reviewDetailsData &&
                reviewDetailsData[0]?.order?.rating == null ? (
                  <>
                    <textarea
                      cols="30"
                      rows="10"
                      placeholder={t('writeDishOpinion')}
                      value={ratingText}
                      onChange={(event) => setReviewText(event.target.value)}
                    />

                    <div className="submit-review-btn">
                      {rating > 0 ? (
                        <ActiveButton
                          label={t('sendReview')}
                          cb={handleSubmitReview}
                        />
                      ) : (
                        <DisabledButton label={t('sendReview')} />
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className="review ">
                <div className="review-dish-detail">
                  <h4>{details?.dishName}</h4> <span>|</span>
                  <p>
                    {' '}
                    {t('kitchenPH')}: {details?.kitchenName}{' '}
                  </p>{' '}
                  <span>|</span>
                  <p>
                    {details?.dateToDisplay} / {details?.mealTypeToDisplay}
                  </p>
                </div>
                <div
                  className={`review-rating ${
                    reviewDetailsData &&
                    reviewDetailsData[0]?.order?.rating == null
                      ? ''
                      : 'review-rating-disable'
                  }`}
                >
                  <p className="rating-label">{t('rateUsingStars')}:</p>
                  <DynamicStars
                    value={rating}
                    cb={(value) => setRating(value)}
                  />
                </div>
                {reviewDetailsData &&
                reviewDetailsData[0]?.order?.rating == null ? (
                  <>
                    <textarea
                      cols="30"
                      rows="10"
                      placeholder={t('writeDishOpinion')}
                      value={ratingText}
                      onChange={(event) => setReviewText(event.target.value)}
                    />

                    <div className="submit-review-btn">
                      {rating > 0 ? (
                        <ActiveButton
                          label={t('sendReview')}
                          cb={handleSubmitReview}
                        />
                      ) : (
                        <DisabledButton label={t('sendReview')} />
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            ))}
          <div className="right-review-section">
            {reviewDetailsData &&
            reviewDetailsData[0]?.order?.rating != null ? (
              <>
                <div className="add-review">
                  <h6>Add a Comment</h6>
                  <textarea
                    cols="30"
                    rows="10"
                    placeholder={t('writeDishOpinion')}
                    onChange={(event) => setCommentData(event.target.value)}
                    value={commentData}
                  />
                  <div className="submit-review-btn">
                    <ActiveButton label={'Comment'} cb={handleSubmit} />
                  </div>
                </div>
                <div className="new-comment review-heading">
                  <h6 className="">Comments</h6>
                </div>
              </>
            ) : (
              ''
            )}
            {reviewDetailsData &&
              reviewDetailsData.map((el, i) => (
                <div className="user-comments-box">
                  {el.comment && (
                    <div className="user-comment">
                      <div className="user-name">
                        <h6>
                          {[el.user.firstName, el.user.lastName].join(' ')}{' '}
                          <span className="time">
                            {new Date(el.createdAt).toISOString().slice(0, 10)}{' '}
                            <span>
                              {moment(el.createdAt).format('hh: mm A')}
                            </span>
                          </span>
                        </h6>
                      </div>
                      <p>{el.comment}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  user: state.user,
});
const mapDispatchToProps = {
  setLayoutOptions,
  showAlert,
  useLoader,
  useFetchingLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddReview);
