import action from '../action.js';
import types from './types';
import Storage from '../../services/storage';
import http from '../../services/http';
import Http from '../../services/http';

export function setEnums(enums) {
  return action(async (dispatch) => {
    dispatch({ type: types.SET_ENUMS, payload: enums });
    return enums;
  });
}

export function toggleModal(visible) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_MODAL_VISIBLE, payload: visible });
  });
}

export function toggleSidebar(opened) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_SIDEBAR, payload: opened });
  });
}

export function toggleLoader(status) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_LOADER, payload: status });
  });
}

export function toggleFetchingLoader(status) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: status });
  });
}

export function toggleAlertMessage(message) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_ALERT_MESSAGE, payload: message });
  });
}

export function showAlert(message) {
  return action(async (dispatch) => {
    dispatch({
      type: types.TOGGLE_ALERT_MESSAGE,
      payload: { show: true, ...message },
    });
  });
}

export function setLayoutOptions(opts) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_LAYOUT_OPTIONS,
      payload: opts,
    });
  });
}

export function useLoader(func) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_LOADER, payload: true });
    let res = await func();
    dispatch({ type: types.TOGGLE_LOADER, payload: false });
    return res;
  });
}
export function useConditionLoader(value = false) {
  return action(async (dispatch) => {
    dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: value });
  });
}
export function useFetchingLoader(func) {
  return action(async (dispatch, getState) => {
    try {
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: true });
      //await new Promise((resolve) => setTimeout(resolve, 200));
      let res = await func();
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: false });
      return res;
    } catch (ex) {
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: false });
      if (ex.redirect) redirect(dispatch, ex.redirect);
      throw ex;
    }
  });
}

const redirect = async (dispatch, path) => {
  dispatch({ type: 'reset' });
  dispatch({ type: types.SET_REDIRECT, payload: path });
};

export function redirectDone() {
  return action(async (dispatch) => {
    dispatch({ type: types.SET_REDIRECT, payload: null });
  });
}

export function getMealTypes() {
  return action(async (dispatch) => {
    let { data: mealTypes } = await http.get('mealTypes');
    dispatch({ type: types.GET_MEAL_TYPES, payload: mealTypes });
  });
}

export function pastMealsData(filter) {
  return action(async (dispatch) => {
    let data = await http.get('meals/pastMeal', filter);
    dispatch({ type: types.GET_PAST_MEAL, payload: data });
  });
}

export function allPastMealsData(filter) {
  return action(async (dispatch) => {
    let data = await http.get('meals/pastMeal', filter);
    dispatch({ type: types.GET_ALL_PAST_MEAL, payload: data });
  });
}

export function getReceipts(userId) {
  return action(async (dispatch) => {
    let data = await http.get(`receipts/getAll`, { userId: userId });
    dispatch({ type: types.GET_RECEIPTS, payload: data });
  });
}
export function dishesData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('dishes', filter);
    dispatch({
      type: types.GET_DISH_DATA,
      payload: data,
    });
  });
}

export function getAllReviews(filter) {
  return action(async (dispatch) => {
    const data = await http.get('orders/getAllRating', filter);
    dispatch({
      type: types.GET_ALL_REVIEWS,
      payload: data,
    });
  });
}

export function currentMealsData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('meals/currentMeal', filter);
    dispatch({
      type: types.GET_CURRENT_MEAL,
      payload: data,
    });
  });
}
export function getFactoriesData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('factories', filter);
    dispatch({
      type: types.GET_FACTORIES,
      payload: data.response,
    });
  });
}
export function getAllLogs(filter) {
  return action(async (dispatch) => {
    const data = await http.get('activityLogs/getAll', filter);
    dispatch({
      type: types.GET_ALL_LOGS,
      payload: data,
    });
  });
}
export function getAllNotifications(filter) {
  return action(async (dispatch) => {
    const data = await http.get('notifications', filter);
    dispatch({
      type: types.GET_ALL_NOTIFICATIONS,
      payload: data,
    });
  });
}
export function getAllAuditMess(filter) {
  return action(async (dispatch) => {
    const data = await http.get('audit/getAll', filter);
    dispatch({
      type: types.GET_ALL_AUDITS,
      payload: data,
    });
  });
}

export function tagsData(filter) {
  return action(async (dispatch) => {
    const data = await http.get('tags', filter);
    dispatch({
      type: types.GET_TAG_DATA,
      payload: data,
    });
  });
}
export function getWalletAmountFromApi(userId) {
  return action(async (dispatch) => {
    const walletData = await http.get(`users/getUserWallet/${userId}`);
    dispatch({
      type: types.SET_WALLET_DATA,
      payload: walletData?.amount,
    });
  });
}
export function setWalletAmount(amount) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_WALLET_DATA,
      payload: amount,
    });
  });
}
export function setOpenCopyMealPopup(value) {
  return action(async (dispatch) => {
    dispatch({
      type: types.OPEN_COPY_MEAL_POPUP,
      payload: value,
    });
  });
}
export function setISuccessCopyMealPopup(value) {
  return action(async (dispatch) => {
    dispatch({
      type: types.IS_SUCCESS_COPY_MEAL_POPUP,
      payload: value,
    });
  });
}
export function setNotificationNumber(notificationNumber) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_NOTIFICATIONS_NUMBER,
      payload: notificationNumber,
    });
  });
}
export function setPushNotification(pushNotification) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_PUSH_NOTIFICATIONS,
      payload: pushNotification,
    });
  });
}
export function getAllInvitations(param) {
  return action(async (dispatch) => {
    const objParam = {
      fromDate: param.fromDate,
      toDate: param.toDate,
      page: param.page,
      limit: param.limit,
      orderField: param?.orderField,
      orderTarget: param?.orderTarget,
      keyword: param?.search,
      companyId: param?.companyId ?? 1,
      contractorId: param?.contractorId,
      userId: param?.userId,
      statusId: param?.statusId,
    };
    for (const key in objParam) {
      if (
        objParam[key] === undefined ||
        objParam[key] === null ||
        objParam[key] === 'null'
      ) {
        delete objParam[key];
      }
    }
    let data = await Http.get('guestInvitations', objParam);
    dispatch({ type: types.GET_ALL_INVITATIONS, payload: data.response });
  });
}
