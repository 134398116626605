import React, { useState, useEffect, useCallback } from 'react';
import { Button, FormSelect, Modal, ModalBody } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Form from 'react-bootstrap/Form';
import arrow from '../../assets/newimages/arrowright.svg';
import '../../style/mealtime.css';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
  getWalletAmountFromApi,
} from '../../store/app/actions';
import http from '../../services/http';
import moment from 'moment';
import enums from '../../services/enums';
import { getMealTimesByDate } from '../../constants/MealTimes';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';

import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MuiButton from '@mui/material/Button';
const MealTimePage = ({
  kitchen,
  user,
  show,
  setLayoutOptions,
  currentLanguage,
  MealTypes,
  showAlert,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getWalletAmountFromApi,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [month, setMonth] = useState([]);
  const [mealData, setMealData] = useState();
  const [allSlot, setAllSlot] = useState(null);
  const [mealType, setMealType] = useState(null);
  const [mealId, setMealId] = useState(null);
  const [mealDate, setMealDate] = useState(null);
  const [slotId, setSlotId] = useState(null);
  const [guestInfo, setGuestInfo] = useState([]);
  const [selectDateErrors, setSelectDateErrors] = useState('');
  const [selectMealTypeErrors, setSelectMealTypeErrors] = useState('');
  const [availableMealTypes, setAvailableMealTypes] = useState(null);
  const [showCollectPoint, setShowCollectPoint] = useState(false);
  const [mealForCollectPoint, setMealForCollectPoint] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [pendingCollectPoint, setPendingCollectPoint] = useState('');
  const [popupProps, setPopupProps] = useState({
    text: '',
    displayed: false,
    isEdit: false,
  });
  const [collectPointsResponse, setCollectPointsResponse] = useState();
  const [dummy, setDummy] = useState(false);

  function getUnique(array) {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  useEffect(() => {
    getWalletAmountFromApi(user?.id);
  }, []);
  function getDatesInRange(startDate, endDate, todayDate) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let getDates = moment(date).format('YYYY-MM-DD');
      if (getDates >= todayDate) {
        dates.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }
  useEffect(() => {
    const fetchData1 = async () => {
      const getGuestMoreInfo = async () => {
        //  GUEST USER INFO
        const guestUserInfo = await useFetchingLoader(
          async () => await http.get(`users/getGuestUserInfo`)
        );
        if (guestUserInfo.data) {
          setGuestInfo(guestUserInfo.data);
        } else {
          setGuestInfo([]);
        }
      };
      if (user?.roleId === 8) {
        getGuestMoreInfo();
      }
      const paymentData = await getPaymentPreferences(kitchen?.id, '');
      setPaymentMethod(paymentData);
      setAvailableMealTypes(null);
    };
    fetchData1();
  }, [kitchen]);

  setLayoutOptions({ showKitchen: true });
  useEffect(() => {
    const getMonth = async () => {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const getMonthlyDataInfo = await http.get(`meals/getMonthly`, {
        kitchenId: kitchen?.id,
      });
      let getMonthlyData = getMonthlyDataInfo;
      // GUEST USER DATES
      let todayDate = moment(date).format('YYYY-MM-DD');

      if (user?.roleId === 8) {
        if (user?.hireFrom && user?.hireTill) {
          if (user?.hireFrom >= todayDate && user?.hireTill >= todayDate) {
            const d1 = new Date(user?.hireFrom);
            const d2 = new Date(user?.hireTill);
            let dateInfo = [];
            let startDate = moment(d1).format('YYYY-MM-DD');
            let endtDate = moment(d2).format('YYYY-MM-DD');

            let dateFilter = getMonthlyData.filter(
              (el) => el.date >= startDate && el.date <= endtDate
            );
            dateFilter.filter((el) => dateInfo.push(el.date));
            dateInfo.sort((a, b) => new Date(a) - new Date(b));
            let dataInfo = getUnique(dateInfo);
            setMonth(dataInfo);
          } else {
            if (user?.hireFrom < todayDate) {
              const d1 = new Date();
              const d2 = new Date(user?.hireTill);
              let dateInfo = [];
              let startDate = moment(d1).format('YYYY-MM-DD');
              let endtDate = moment(d2).format('YYYY-MM-DD');

              let dateFilter = getMonthlyData.filter(
                (el) => el.date >= startDate && el.date <= endtDate
              );
              dateFilter.filter((el) => dateInfo.push(el.date));
              dateInfo.sort((a, b) => new Date(a) - new Date(b));
              let dataInfo = getUnique(dateInfo);
              setMonth(dataInfo);
            } else {
              setMonth([]);
            }
          }
        } else {
          let dateArray = [];
          getMonthlyData.filter((el) => dateArray.push(el.date));
          dateArray.sort((a, b) => new Date(a) - new Date(b));
          let uniqueDate = getUnique(dateArray);
          setMonth([]);
        }
      } else {
        let dateArray = [];
        getMonthlyData.filter((el) => dateArray.push(el.date));
        dateArray.sort((a, b) => new Date(a) - new Date(b));
        let uniqueDate = getUnique(dateArray);
        setMonth(uniqueDate);
      }
    };
    getMonth();
  }, [kitchen]);

  const selectDate = async (e) => {
    let date = e.target.value;
    let dd = '';
    if (
      date === 'Select Date' ||
      date === 'Выберите дату' ||
      date === 'תאריך'
    ) {
      setSelectDateErrors('Please select date');
      setSelectMealTypeErrors('');
      setMealDate(null);
      setMealType(null);
      setMealData(null);
      setAvailableMealTypes(null);
      setAllSlot(null);
      localStorage.removeItem('date');
      localStorage.removeItem('mealTypeId');
    } else {
      setMealDate(date);
      localStorage.setItem('date', e.target.value);
      dd = localStorage.getItem('date');
      setSelectDateErrors('');
      localStorage.removeItem('mealTypeId');
      const { data: mealsInfo } = await useFetchingLoader(
        async () =>
          await http.get(`meals`, {
            filter: {
              //date: date || dd,
              date: date,
              kitchenId: kitchen?.id,
              mealStatusId: [
                enums.getMap('MealStatus').active,
                enums.getMap('MealStatus').blocked,
              ],
            },
          })
      );

      let meals = mealsInfo;
      let futureMeals = getFutureMeals(date, MealTypes);
      const availableMealsTypes = MealTypes.map((mt) => {
        return {
          available:
            !!meals.find(
              (m) => m.mealTypeId === mt.id && m.mealStatusId === 1
            ) && futureMeals.hasOwnProperty(mt.id),
          ...mt,
        };
      });

      let sortedAvailableMealTypes = availableMealsTypes
        .filter((m) => m.available)
        .sort((mt1, mt2) => (mt1.orderIndex > mt2.orderIndex ? 1 : -1));
      setAvailableMealTypes(sortedAvailableMealTypes);
      if (user?.roleId === 8) {
        if (user?.hireFrom && user?.hireTill) {
          const availableMealsTypesInfo = MealTypes.map((mt) => {
            return {
              available: meals.find(
                (m) => m.mealTypeId === mt.id && m.mealStatusId === 1
              ),
              ...mt,
            };
          });

          let getMealTypeList = availableMealsTypesInfo.filter(
            (elInfo) => elInfo?.kitchenId === kitchen?.id && elInfo.available
          );
          let guestMealTypeArray = JSON.parse(guestInfo[0]?.mealTypeId);
          let mealsTypesArray = [];
          getMealTypeList.forEach((el) => {
            if (guestMealTypeArray && guestMealTypeArray.includes(el?.id)) {
              mealsTypesArray.push(el);
            }
          });
          if (mealsTypesArray.length > 0) {
            mealsTypesArray?.sort((a, b) =>
              a?.mealType?.from.localeCompare(b?.mealType?.from)
            );

            const today = new Date();
            const filteredMealsTypesArray = mealsTypesArray.filter((el) => {
              const date2 = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                ...el.to.split(':')
              );
              return today < date2;
            });
            const param = guestMealTypeArray
              ? moment(new Date().toISOString().substring(0, 10)).isSame(
                  moment(e.target.value)
                )
                ? filteredMealsTypesArray
                : mealsTypesArray
              : null;
            setAvailableMealTypes(param);
            setMealType(param[0]?.id ? param[0]?.id : null);
            if (param[0]?.id && (date || dd)) {
              await useFetchingLoader(async () => {
                await request({
                  mealDate: date || dd,
                  mealTypeId: param[0]?.id,
                });
              });
            }
            meals?.sort((a, b) =>
              a?.mealType?.from.localeCompare(b?.mealType?.from)
            );
            setMealData({ data: meals });
          } else {
            setAvailableMealTypes(null);
            setMealType(null);
            setMealData(null);
          }
        } else {
          if (meals.length > 0) {
            meals?.sort((a, b) =>
              a?.mealType?.from.localeCompare(b?.mealType?.from)
            );

            const getMeal = meals.filter(
              (el) => el.mealTypeId == sortedAvailableMealTypes?.[0]?.id
            );
            localStorage.setItem('mealTypeId', getMeal?.[0]?.mealType.id);
            let getAllSlot;
            await useFetchingLoader(async () => {
              getAllSlot = await http.get(
                `mealSlots/getByMealId/${
                  getMeal && getMeal[0] && getMeal?.[0].id
                }`
              );
            });
            if (sortedAvailableMealTypes?.[0]?.id && (date || dd)) {
              await useFetchingLoader(async () => {
                await request({
                  mealDate: date || dd,
                  mealTypeId: sortedAvailableMealTypes?.[0]?.id,
                });
              });
            }
            setAllSlot(getAllSlot);
            setMealType(sortedAvailableMealTypes?.[0]?.id);
            setMealData({ data: meals });
          } else {
            setMealData(null);
          }
        }
      } else {
        if (meals.length > 0) {
          meals?.sort((a, b) =>
            a?.mealType?.from.localeCompare(b?.mealType?.from)
          );

          const getMeal = meals.filter(
            (el) => el.mealTypeId == sortedAvailableMealTypes?.[0]?.id
          );
          localStorage.setItem('mealTypeId', getMeal?.[0]?.mealType.id);
          let getAllSlot;
          await useFetchingLoader(async () => {
            getAllSlot = await http.get(
              `mealSlots/getByMealId/${
                getMeal && getMeal[0] && getMeal?.[0].id
              }`
            );
          });
          if (sortedAvailableMealTypes?.[0]?.id && (date || dd)) {
            await useFetchingLoader(async () => {
              await request({
                mealDate: date || dd,
                mealTypeId: sortedAvailableMealTypes?.[0]?.id,
              });
            });
          }

          setAllSlot(getAllSlot);
          setMealType(sortedAvailableMealTypes?.[0]?.id);
          setMealData({ data: meals });
        } else {
          setMealData(null);
        }
      }
    }
  };

  const getFutureMeals = (date, MealTypes) => {
    let mealTimes = getMealTimesByDate(date, MealTypes);
    let now = moment();
    let futureMeals = MealTypes.filter(
      (mealType) => mealTimes[mealType.id].ends > now
    ).reduce((acc, type) => {
      return { ...acc, [type.id]: type };
    }, {});
    return futureMeals;
  };

  const selectMealType = async (e) => {
    let date = localStorage.getItem('date');
    let mealTypeDataInfo = e.target.value;
    if (mealTypeDataInfo === 'Select MealType') {
      setSelectMealTypeErrors('Please select MealType');
      setMealType(null);
    } else {
      setSelectMealTypeErrors('');
      setMealType(e.target.value);
    }
    //setMealType(e.target.value);
    let { data } = mealData ?? { data: [] };
    const getMeal = data.filter((el) => el.mealTypeId == e.target.value);
    localStorage.setItem('mealTypeId', getMeal[0]?.mealType.id);
    let getAllSlot;
    await useFetchingLoader(async () => {
      getAllSlot = await http.get(
        `mealSlots/getByMealId/${getMeal && getMeal[0] && getMeal[0].id}`
      );
    });
    if (date && e.target.value) {
      await useFetchingLoader(async () => {
        await request({ mealDate: date, mealTypeId: e.target.value });
      });
    }
    setAllSlot(getAllSlot);
  };

  const selectSlot = async (e) => {
    setSlotId(e.target.value);
  };
  const onConfirm = async () => {
    /*state: {
                                date: meal?.date,
                                mealType: meal?.mealTypeId,
                                slotId: order?.[0]?.order?.mealSlotId,
                                orderId: order.id,
                                dishId: dish?.id,
                                dishIds: dishIds,
                                backLink: '/myMeals',
                                backText: t('returnToMyMeals'),
                              }, */
    const checkOrder = await http.get(`orders/checkOrder`, {
      mealDate: mealDate,
      mealTypeId: mealType,
    });
    history.push({
      pathname: '/orderSummary',
      state: {
        date: mealDate && mealDate,
        mealType: mealType ? mealType : localStorage.getItem('mealTypeId'),
        slotId: slotId && slotId,
        orderId:
          checkOrder[checkOrder.length - 1] &&
          checkOrder[checkOrder.length - 1].orderStatusId === 2
            ? undefined
            : checkOrder[checkOrder.length - 1]?.orderId,
        dishId:
          checkOrder[checkOrder.length - 1] &&
          checkOrder[checkOrder.length - 1].orderStatusId === 2
            ? undefined
            : checkOrder[checkOrder.length - 1]?.dishId,
        dishIds:
          checkOrder[checkOrder.length - 1] &&
          checkOrder[checkOrder.length - 1].orderStatusId === 2
            ? undefined
            : Array.isArray(checkOrder[checkOrder.length - 1]?.dishId)
              ? checkOrder[checkOrder.length - 1]?.dishId
              : [checkOrder[checkOrder.length - 1]?.dishId],
      },
    });
  };

  const request = async (data) => {
    const { mealDate, mealTypeId } = data;
    const getMeals = await http.get(`meals/getMeal`, {
      mealDate: mealDate,
      mealType: mealTypeId,
    });

    if (getMeals?.mealType?.creditPoints > 0) {
      setShowCollectPoint(true);
      setMealForCollectPoint(getMeals);
    } else {
      setShowCollectPoint(false);
      setMealForCollectPoint(getMeals);
    }
    const getCollectPointResponse = await http.post(
      `users/getCollectPointRequestByUserId`,
      { mealDate: mealDate, mealTypeId: mealTypeId }
    );
    const checkOrder = await http.get(`orders/checkOrder`, {
      mealDate: mealDate,
      mealTypeId: mealTypeId,
    });
    if (checkOrder.length) {
      if (
        checkOrder[checkOrder.length - 1].orderStatusId === 2 &&
        (!getMeals?.mealType.multipleReservations || !user.isMultiOrders)
      ) {
        setPopupProps((prev) => {
          return { ...prev, text: t('orderedAndRealized'), isEdit: false };
        });
      } else if (checkOrder[checkOrder.length - 1].orderStatusId === 1) {
        setPopupProps((prev) => {
          return { ...prev, text: t('orderedMeal'), isEdit: true };
        });
      } else {
        setPopupProps((prev) => {
          return { ...prev, text: '', isEdit: false };
        });
      }
      setShowCollectPoint(false);
    } else {
      setPopupProps((prev) => {
        return { ...prev, text: '', isEdit: false };
      });
    }
    if (getCollectPointResponse) {
      setPendingCollectPoint(getCollectPointResponse[0]?.status);
      setCollectPointsResponse(getCollectPointResponse[0]);
    }
  };

  const onCollectPoint = async (data) => {
    const { mealDate, mealType, slotId, availableMealTypes } = data;
    const getMealTypeInfo = await http.post(`users/getMealTypeInfoById`, {
      mealTypeId: mealType,
      kitchenId: kitchen?.id,
      date: mealDate,
    });
    let errFlag = false;
    let errMess = '';
    if (getMealTypeInfo?.data[0]?.creditPoints) {
      Dialog({
        title:
          i18n.language === 'he'
            ? `${t('pointMsg')} ${getMealTypeInfo?.data[0]?.creditPoints} ${t(
                'collectPointsMess'
              )} ${MealTypes.find((el) => el?.id == mealType).display}?`
            : `${t('doYouWant')}  ${getMealTypeInfo?.data[0]?.creditPoints} ${t(
                'pointMsg'
              )}  ${MealTypes.find((el) => el?.id == mealType).display}?`,

        type: 'success',
        customFontStyle:
          window.innerWidth > 480 ? 'sw_font_size_24' : 'sw_font_size_20',
        buttons: {
          cancelButtonText: t('back'),
          confirmButtonText: t('ok'),
        },
        onConfirm: async () => {
          const getResponseInfo = await useFetchingLoader(async () => {
            try {
              return await http.post(`users/createCreditPoint`, {
                mealTypeId: mealForCollectPoint?.mealTypeId,
                point: mealForCollectPoint?.mealType?.creditPoints,
                kitchenId: mealForCollectPoint?.kitchenId,
                date: mealForCollectPoint?.date,
                mealId: mealForCollectPoint?.id,
                paymentOptionId: paymentMethod?.selectedPaymentOptions,
              });
            } catch (err) {
              errFlag = true;
              errMess = err;
            }
          });
          if (mealDate && mealType) {
            await useFetchingLoader(async () => {
              await request({ mealDate: mealDate, mealTypeId: mealType });
            });
          }

          if (getResponseInfo?.data[0]['totalPoints'] === 0) {
            getWalletAmountFromApi(user?.id);
            setTimeout(() => {
              Dialog({
                title: `${t('confirmCollectPointMessage1')} ${
                  getMealTypeInfo?.data[0]?.creditPoints
                } ${t('collectPointsMess')}  ${
                  MealTypes.find((el) => el?.id == mealType).display
                }  ${t('pointMsgEnd')}`,
                type: 'success',
                customWidth: 650,
                customFontStyle:
                  window.innerWidth > 480
                    ? 'sw_font_size_24'
                    : 'sw_font_size_20',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: t('ok'),
                },
                onConfirm: async () => {
                  setDummy(true);
                },
              });
            }, 150);
          } else {
            if (errFlag) {
              setTimeout(() => {
                SwalDialogs.Dialog({
                  title: errMess ?? `${t('errorMealTypePointMessage')}`,
                  type: 'error',
                  buttons: {
                    showCancelButton: true,
                    showConfirmButton: false,
                  },
                });
              }, 150);
            }
          }
        },
      });
    } else {
      SwalDialogs.Dialog({
        title: `${t('alertNoMealsAbleMessage')}`,
        type: 'error',
        buttons: {
          showCancelButton: true,
          showConfirmButton: false,
        },
      });
    }
  };

  const revertCollectPoints = async (data) => {
    const { mealDate, mealType, slotId, availableMealTypes } = data;
    const getMealTypeInfo = await http.post(`users/getMealTypeInfoById`, {
      mealTypeId: mealType,
      kitchenId: kitchen?.id,
      date: mealDate,
    });
    let errFlag = false;
    let errMess = '';
    if (getMealTypeInfo?.data[0]?.creditPoints) {
      Dialog({
        title:
          i18n.language === 'he'
            ? `${t('revertCollectPointMessage')} ${
                getMealTypeInfo?.data[0]?.creditPoints
              }  ${t('collectPointsMess')}  ${
                MealTypes.find((el) => el?.id == mealType).display
              }?`
            : `${t('revertCollectPointMessage')}  ${
                getMealTypeInfo?.data[0]?.creditPoints
              } ${t('pointMsg')}  ${
                MealTypes.find((el) => el?.id == mealType).display
              }?`,

        type: 'success',
        customFontStyle:
          window.innerWidth > 480 ? 'sw_font_size_24' : 'sw_font_size_20',
        buttons: {
          cancelButtonText: t('back'),
          confirmButtonText: t('ok'),
        },
        onConfirm: async () => {
          const getResponseInfo = await useFetchingLoader(async () => {
            try {
              return await http.delete(`users/deleteCreditPoint`, {
                mealTypeId: mealForCollectPoint?.mealTypeId,
                point: mealForCollectPoint?.mealType?.creditPoints,
                kitchenId: mealForCollectPoint?.kitchenId,
                date: mealForCollectPoint?.date,
                mealId: mealForCollectPoint?.id,
              });
            } catch (err) {
              errFlag = true;
              errMess = err;
            }
          });
          if (getResponseInfo?.status === 200) {
            setTimeout(() => {
              Dialog({
                title: ` ${t('cancelPointSuccess1')} ${
                  getMealTypeInfo?.data[0]?.creditPoints
                } ${t('cancelPointSuccess2')} ${
                  MealTypes.find((el) => el?.id == mealType).display
                } ${t('cancelPointSuccess3')}`,

                customFontStyle:
                  window.innerWidth > 480
                    ? 'sw_font_size_24'
                    : 'sw_font_size_20',
                type: 'success',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: t('ok'),
                },
                onConfirm: async () => {
                  await useFetchingLoader(async () => {
                    await request({ mealDate: mealDate, mealTypeId: mealType });
                  });
                  await getWalletAmountFromApi(user?.id);
                },
              });
            }, 150);
          } else {
            if (errFlag) {
              setTimeout(() => {
                Dialog({
                  title: errMess,
                  type: 'error',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonText: t('ok'),
                  },
                });
              }, 150);
            }
          }
        },
      });
    } else {
      setTimeout(() => {
        SwalDialogs.Dialog({
          title: `${t('alertNoMealsAbleMessage')}`,
          type: 'error',
          buttons: {
            showCancelButton: true,
            showConfirmButton: false,
          },
        });
      }, 150);
    }
  };
  const handleClosePopup = () => {
    setPopupProps((prev) => {
      return { ...prev, displayed: false };
    });
  };
  return (
    <div className="meal-time-content">
      <div className="container">
        <div className="meal-time-content-box">
          <div className="order-meal-box">
            <h1>{t('orderMealHeading')}</h1>
            <div className="order-meal">
              <div className="order-meal-date">
                <p>{t('reservationMealDate')}</p>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => selectDate(e)}
                  value={mealDate}
                >
                  <option>{t('selectDate')}</option>
                  {month.map((el) => (
                    <>
                      <option value={el}>
                        {moment(new Date()).format('DD/MM/YYYY') ===
                        moment(el).format('DD/MM/YYYY')
                          ? moment(el).format('DD/MM/YYYY') + ' ' + t('today')
                          : moment(el).format('DD/MM/YYYY') +
                            ' ' +
                            moment(el).format('dddd')}
                      </option>
                    </>
                  ))}
                </Form.Select>
                {selectDateErrors && (
                  <p className="error-msg">{selectDateErrors}</p>
                )}
              </div>
              <div className="order-meal-date">
                <p>{t('reservationMealType')}</p>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => selectMealType(e)}
                  value={localStorage.getItem('mealTypeId') ?? ''}
                >
                  {availableMealTypes?.length <= 0 ||
                    (availableMealTypes == null && (
                      <option>{t('selectMealTypeSecond')}</option>
                    ))}
                  {availableMealTypes?.length === 0 ? (
                    <option>{t('noMealsForThisDate')}</option>
                  ) : (
                    availableMealTypes?.map((el) => (
                      <>
                        {' '}
                        <option value={el.id}>{el.display}</option>
                      </>
                    ))
                  )}
                </Form.Select>
                {selectMealTypeErrors && (
                  <p className="error-msg">{selectMealTypeErrors}</p>
                )}
              </div>
              {allSlot?.length ? (
                <div
                  className="order-meal-date"
                  onChange={(e) => selectSlot(e)}
                >
                  <p>{t('slot')}</p>
                  <Form.Select aria-label="Default select example">
                    {allSlot?.map((el) => (
                      <>
                        {' '}
                        <option value={el.mealTypesSlot.id}>
                          {' '}
                          {el.mealTypesSlot.from}-{el.mealTypesSlot.to}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </div>
              ) : (
                ''
              )}
              {pendingCollectPoint === 0 || pendingCollectPoint === 1 ? (
                ''
              ) : (
                <div
                  className={`order-meal-btn ${
                    (mealType && mealDate) ||
                    (allSlot?.length && slotId) ||
                    availableMealTypes?.length > 0
                      ? 'enable-btn'
                      : 'disable-btn'
                  }`}
                >
                  <Button
                    onClick={() => {
                      popupProps.text === ''
                        ? onConfirm()
                        : setPopupProps((prev) => {
                            return { ...prev, displayed: true };
                          });
                    }}
                  >
                    {t('continue')}
                    <img src={arrow} alt="" />
                  </Button>
                </div>
              )}

              {showCollectPoint ? (
                pendingCollectPoint === 0 ? (
                  <div
                    className={`order-meal-btn ${
                      (mealType && mealDate) ||
                      (allSlot?.length && slotId) ||
                      availableMealTypes?.length > 0
                        ? 'enable-btn'
                        : 'disable-btn'
                    }`}
                  >
                    {' '}
                    <Button
                      onClick={(e) =>
                        revertCollectPoints({
                          mealType: mealType,
                          mealDate: mealDate,
                          slotId: slotId,
                          availableMealTypes: availableMealTypes,
                        })
                      }
                    >
                      {t('pending')}
                      <img src={arrow} alt="" />
                    </Button>
                  </div>
                ) : pendingCollectPoint === 1 ? (
                  collectPointsResponse?.status ? (
                    collectPointsResponse?.reported ? (
                      <div className={`order-meal-btn`}>
                        <Button>
                          {t('donePointMsg')}
                          <img src={arrow} alt="" />
                        </Button>
                      </div>
                    ) : (
                      <div className={`order-meal-btn`}>
                        <Button
                          onClick={(e) =>
                            revertCollectPoints({
                              mealType: mealType,
                              mealDate: mealDate,
                              slotId: slotId,
                              availableMealTypes: availableMealTypes,
                            })
                          }
                        >
                          {t('approvedPointMsg')}
                          <img src={arrow} alt="" />
                        </Button>
                      </div>
                    )
                  ) : (
                    user.roleId === 4 && (
                      <div className={`order-meal-btn`}>
                        <Button
                          onClick={(e) =>
                            onCollectPoint({
                              mealType: mealType,
                              mealDate: mealDate,
                              slotId: slotId,
                              availableMealTypes: availableMealTypes,
                              pendingCollectPoint: pendingCollectPoint,
                            })
                          }
                        >
                          {t('collectPoints')}
                          <img src={arrow} alt="" />
                        </Button>
                      </div>
                    )
                  )
                ) : (
                  <div
                    className={`order-meal-btn ${
                      (mealType && mealDate) ||
                      (allSlot?.length && slotId) ||
                      availableMealTypes?.length > 0
                        ? 'enable-btn'
                        : 'disable-btn'
                    }`}
                  >
                    {' '}
                    {user.roleId === 4 && (
                      <Button
                        onClick={(e) =>
                          onCollectPoint({
                            mealType: mealType,
                            mealDate: mealDate,
                            slotId: slotId,
                            availableMealTypes: availableMealTypes,
                            pendingCollectPoint: pendingCollectPoint,
                          })
                        }
                      >
                        {t('collectPoints')}
                        <img src={arrow} alt="" />
                      </Button>
                    )}
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            <Modal
              show={popupProps.displayed}
              onHide={() => handleClosePopup()}
              centered
            >
              <Modal.Header className="m10" closeButton>
                <p className="swal2-title center">{t('orderExistsTitle')}</p>
              </Modal.Header>
              <ModalBody className="m10">
                <p className="swal2-html-container text-center">
                  {t(popupProps.text)}
                </p>
              </ModalBody>
              <Modal.Footer className="flex-center m10">
                {!popupProps.isEdit && (
                  <Button
                    className="back-btn-custom"
                    onClick={() => handleClosePopup()}
                  >
                    {t('close')}
                  </Button>
                )}

                {popupProps.isEdit && (
                  <Button
                    className="continue-btn-custom"
                    onClick={() => onConfirm()}
                  >
                    {t('yes')}
                  </Button>
                )}
                {popupProps.isEdit && (
                  <Button
                    className="back-btn-custom"
                    onClick={() => handleClosePopup()}
                  >
                    {t('no')}
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  MealTypes: state.app.enums.MealType,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getPaymentPreferences,
  getWalletAmountFromApi,
};
export default connect(mapStateToProps, mapDispatchToProps)(MealTimePage);
