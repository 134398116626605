import React from 'react';

const TextArea = ({
  input,
  style,
  placeholder,
  required,
  disabled,
  dataOg,
  containerClass = '',
  meta: { touched, error, submitFailed },
}) => {
  const errorClass = touched && error ? 'error-color' : '';
  const containerClasses = ['input-group', containerClass, errorClass];
  return (
    <div className={containerClasses.join(' ')}>
      <div
        className={`label-input ${
          error && submitFailed ? 'required-field' : ''
        }`}
      >
        <textarea
          className="input-field"
          {...input}
          data-og={dataOg}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {error && submitFailed && <div className="error-message">{error}</div>}
    </div>
  );
};
export default TextArea;
