import http from './http';

class Enums {
  static instance;

  get getInstance() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  constructor() {
    this.map = {};
    return this.getInstance;
  }

  getMap(eName) {
    return this.map[eName];
  }

  get(eName) {
    return this.enums[eName];
  }

  display(eName, value) {
    const entry = this.enums[eName].find((e) => e.id === value);
    return entry ? entry.display : '';
  }

  init = async () => {
    let enums = (this.enums = await http.get('enums'));
    this.map = Object.entries(enums).reduce((res, [eName, values]) => {
      res[eName] = {};
      values.forEach((v) => {
        res[eName][v.value] = v.id;
      });
      return res;
    }, {});
    return enums;
  };
}

export default new Enums().getInstance;
