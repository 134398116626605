import React, { useCallback } from 'react';
import { Field, FieldArray, FormSection, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';
import Select from 'react-select';
import TextArea from '../InputFields/TextArea';
import { ActiveButton } from '../WE-Button';
import DropZone from '../DropZone/DropZone';
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// Validators
import {
  isAlpha,
  required,
  isNotNegativeNumber,
} from '../../services/validators';
const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: '#f0f4f8',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
    color: 'black',
  }),
};
const getFlagUrl = (shortCode) => {
  return `${process.env.PUBLIC_URL}/flags/${shortCode}.svg`;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

let DishForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    onClose,
    error,
    languages,
    DishCategories,
    CurrentLanguage,
    invalid,
    tags,
    initialValues,
    setSelectedTags,
    selectedTags,
    setTags,
  } = props;
  const classes = useStyles();

  const LanguageMap = languages.reduce((acc, lang) => {
    return { ...acc, [lang.id]: lang };
  }, {});
  const isRTL = CurrentLanguage.isRTL;

  const onTagSelect = useCallback((event) => {
    setSelectedTags(event.target.value);
  }, []);

  return (
    <form className="form " onSubmit={handleSubmit}>
      <div className="dish-form">
        <div className={'section1'}>
          <FieldArray
            name="translations"
            languages={LanguageMap}
            isRTL={isRTL}
            component={renderLanguages}
          />
        </div>

        <section className={'section2'}>
          <div className={'price'}>
            <Field
              name={`price`}
              type="number"
              component={InputField}
              placeholder={t('price')}
              validate={[isNotNegativeNumber]}
              parse={(val) => parseFloat(val)}
            />
          </div>

          <div className={'category-select'}>
            <Field
              name={`dishCategoryId`}
              component={'select'}
              containerClass={'item'}
              placeholder={t('chooseType')}
              validate={[required]}
            >
              {DishCategories.map((cat) => (
                <option value={cat.id} key={cat.id}>
                  {cat.display}
                </option>
              ))}
            </Field>
          </div>

          <div className="tags-selection">
            {/* <Field
            classes={classes}
            name="tags"
            component={renderMultiSelectField}
            label={t('selectTags')}
            options={tags}
            optionToValue={(tag) => tag.id}
            optionToDisplayValue={(tag) => tag?.displayName}
            valueToDisplayValue={(value) =>
              tags.find((tag) => tag.id === value)?.displayName
            }
          /> */}
            <label>{t('selectTags')}</label>
            <Select
              isMulti
              name={'tags'}
              value={selectedTags.map((el) => {
                return {
                  value: el,
                  label: tags?.find((ele) => ele.id === el)?.displayName,
                };
              })}
              onChange={(newData) => {
                setSelectedTags(newData.map((k) => k.value));
              }}
              options={tags.map((tag) => {
                return { value: tag.id, label: tag.displayName };
              })}
              placeholder={t('selectTags')}
              styles={SelectStyles}
            />
          </div>

          <div className={'upload-image'}>
            <Field
              name={'image'}
              component={DropZone}
              label={t('addImage')}
              // validate={[required]}
            />
          </div>
        </section>
      </div>

      <div className={'submit-section'}>
        <div className={'cancel'} onClick={onClose}>
          <h4>{t('cancel')}</h4>
        </div>

        <div className={'submit-wrapper'}>
          <ActiveButton
            type={'submit'}
            label={t('saveInfo')}
            additionalClassName={'btn'}
          />
        </div>
      </div>

      {error && <div className="form-error">{error}</div>}
    </form>
  );
};

const CustomTitle = ({ alignContent, flag, displayName }) => {
  return (
    <section className={`lang-title ${alignContent}`}>
      <img src={flag} width={37} height={37} alt={''} />
      <span>{displayName}</span>
    </section>
  );
};

const renderLanguages = ({
  fields,
  languages,
  isRTL,
  meta: { error, submitFailed },
}) => {
  return (
    <>
      {fields.map((translation, index, translations) => {
        let langId = translations.get(index).languageId;
        return (
          <div className={'lang-item'} key={langId}>
            <FormSection name={`${translation}`}>
              <LangForm languages={languages} langId={langId} isRTL={isRTL} />
            </FormSection>
          </div>
        );
      })}
    </>
  );
};

const LangForm = ({ languages, langId, isRTL }) => {
  const shortCode = languages[langId]?.shortCode;
  const { t } = useTranslation();
  const alignTitle =
    isRTL === languages[langId]?.isRTL ? 'align-right' : 'align-left';
  const alignFields = languages[langId]?.isRTL ? 'align-right' : 'align-left';

  return (
    <>
      <CustomTitle
        alignContent={alignTitle}
        displayName={languages[langId]?.displayName}
        flag={getFlagUrl(languages[langId]?.shortCode)}
      />

      <Field
        name={`name`}
        type="text"
        containerClass={`item ${alignFields}`}
        component={InputField}
        placeholder={t('name', { lng: shortCode })}
        validate={[required]}
      />
      <Field
        name={`description`}
        containerClass={`item text-area ${alignFields}`}
        component={TextArea}
        placeholder={t('shortDescription', { lng: shortCode })}
        validate={[required]}
      />
    </>
  );
};

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const renderMultiSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  classes,
  options = [],
  optionToValue,
  optionToDisplayValue,
  valueToDisplayValue,
  ...custom
}) => {
  const [selected, setSelected] = useState(input.value || []);
  const onSelect = useCallback((event) => {
    setSelected(event.target.value);
  }, []);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-label">{label}</InputLabel>
      <Select
        name="tags"
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        value={selected}
        onChange={onSelect}
        input={<Input id="select-multiple-chip" {...input} />}
        renderValue={(selected) => {
          return (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={valueToDisplayValue(value)}
                  className={classes.chip}
                />
              ))}
            </div>
          );
        }}
        MenuProps={MenuProps}
      >
        {options.map((option) => {
          const id = optionToValue(option);
          const displayValue = optionToDisplayValue(option);
          return (
            <MenuItem key={id} value={id} style={{}}>
              {displayValue}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  return (
    <FormControl error={touched && error}>
      <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
      <Select
        native
        {...input}
        {...custom}
        inputProps={{
          name: input.name,
          id: 'color-native-simple',
        }}
      >
        {children}
      </Select>
      {renderFormHelper({ touched, error })}
    </FormControl>
  );
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: input.name,
        id: 'color-native-simple',
      }}
    >
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
);

export default DishForm = reduxForm({
  form: 'dish',
  initialValues: {},
})(DishForm);
