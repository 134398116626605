import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import ProfileLayout from './profileLayout';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import http from '../../services/http';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { useTranslation } from 'react-i18next';
import { updatePasswordValidator } from '../../services/validators';

const Settings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState('');
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const handlePasswordChange = async (form) => {
    let { error, isValid } = updatePasswordValidator(values);
    setErrors(error);
    if (isValid) {
      let res;
      if (values.newPassword !== values.newPasswordConfirm) {
        SwalDialogs.Dialog({
          title: '',
          text: 'New Password and confirm password is not same.',
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
        });
        return;
      }
      try {
        res = await http.put(`users/changePassword`, {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });
        if (res.errorMessage === 'passwordPolicy') {
          SwalDialogs.Dialog({
            title: t('inCorrectPassword'),
            text:
              t('character') +
              ' ' +
              res.errorLetter +
              ' ' +
              t('isNotVaild') +
              ' ' +
              t('passwordSecurity'),
            type: 'error',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('passwordUpdatedConfirmButtonText'),
            },
          });
        } else {
          SwalDialogs.Dialog({
            title: t('passwordUpdatedTitle'),
            text: t('passwordUpdatedText'),
            type: 'success',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('passwordUpdatedConfirmButtonText'),
            },
            onConfirm: () => history.push(`/login`),
          });
        }
      } catch (err) {
        Alert({ error: err });
      }
    }
  };
  return (
    <ProfileLayout>
      <div className="settings-page ">
        <div className="page-heading">
          <h3>{t('passwordSettings')}</h3>
        </div>

        <div className="settings-content ">
          <Form>
            <div className="mb-3 form-group">
              <Form.Label>{t('currentPassword')}</Form.Label>
              <div className="form-input">
                <Form.Control
                  type={'password'}
                  autocomplete="off"
                  name="oldPassword"
                  placeholder={t('enterCurrentPassword')}
                  onChange={(e) => handleChange('oldPassword', e.target.value)}
                />
              </div>
              {errors && <p className="error-msg">{errors.oldPassword}</p>}
            </div>
            <div className="mb-3 form-group">
              <Form.Label>{t('newPassword1')}</Form.Label>
              <div className="form-input">
                <Form.Control
                  type={'password'}
                  autocomplete="off"
                  name="newPassword"
                  placeholder={t('enterNewPassword')}
                  onChange={(e) => handleChange('newPassword', e.target.value)}
                />
              </div>
              {errors && <p className="error-msg">{errors.newPassword}</p>}
            </div>
            <div className="mb-3 form-group">
              <Form.Label>{t('confirmPassword')}</Form.Label>
              <div className="form-input">
                <Form.Control
                  type="password"
                  autoComplete="new-password"
                  name="newPasswordConfirm"
                  placeholder={t('confirmNewPassword')}
                  onChange={(e) =>
                    handleChange('newPasswordConfirm', e.target.value)
                  }
                />
              </div>
              {errors && (
                <p className="error-msg">{errors.newPasswordConfirm}</p>
              )}
            </div>
            <div className="settings-btn">
              <Button className="save-btn" onClick={handlePasswordChange}>
                {t('save')}
              </Button>
              <Button className="save-btn">{t('cancel')}</Button>
            </div>
          </Form>
        </div>
      </div>
    </ProfileLayout>
  );
};

export default Settings;
