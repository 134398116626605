import types from './types';

// Set initial state
const initialState = {
  options: [],
  current: null,
  paymentPreferences: {
    selectedPaymentOptions: {},
    merchantId: '',
    companyNumber: '',
    personalHashKey: '',
    loaded: false,
  },
  reservations: [],
  totalReservations: 0,
  emplobbydata: [],
  empElements: [],
  totalElementsOnEmpPage: 0,
  attendanceData: [],
  totalAttendanceRecords: 0,
};

export function kitchensReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_KITCHENS:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_KITCHEN:
      return {
        ...state,
        current: action.payload,
      };
    case types.GET_PAYMENT_PREFERENCES:
      return {
        ...state,
        paymentPreferences: { ...action.payload, loaded: true },
      };
    case types.LOADING_PAYMENT_PREFERENCES:
      return {
        ...state,
        paymentPreferences: {
          ...state.paymentPreferences,
          loaded: false,
        },
      };
    case types.GET_REPORT_TYPE:
      return {
        ...state,
        reservations: action.payload.data,
        totalReservations: action.payload.total,
      };
    case types.GET_MEAL_TYPE:
      return {
        ...state,
        reservations: action.payload.data,
        totalReservations: action.payload.total,
      };
    case types.GET_RESERVATIONS:
      return {
        ...state,
        reservations: action.payload.data,
        totalReservations: action.payload.total,
      };
    case types.GET_EMPLOYEE_LOBBY_DATA:
      return {
        ...state,
        emplobbydata: action?.payload,
      };
    case types.GET_EMPLOYEE_LOBBY_DATA_NEW:
      return {
        ...state,
        empElements: action?.payload.data,
        totalElementsOnEmpPage: action.payload.total,
      };
    case types.GET_ATTENDANCE:
      return {
        ...state,
        attendanceData: action?.payload.data,
        totalAttendanceRecords: action.payload.total,
      };
    default:
      return state;
  }
}
