import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';
import CheckBoxField from '../InputFields/CheckBox';
import Form from 'react-bootstrap/Form';
// Validators
import {
  isPhoneNumber,
  normalizePhone,
  required,
  isAlpha,
  email,
  passwordValidator,
  isNumber,
  isPositiveNumber,
  validatePhoneNum,
} from '../../services/validators';
import StyleButton from '../StyleButton';
import FieldWrapper from '../InputFields/FieldWrapper';
import { ActiveButton, DisabledButton } from '../WE-Button';
import DropZone from '../DropZone/DropZone';
import WECheckbox from './WE-CheckBox';
import FormCheckBox from '../InputFields/WE-Checkbox';
import { DialogWithChangePassword } from '../../components/Dialogs/SwalDialogs';
import { Button } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

let SignupForm = (props) => {
  const { t, i18n } = useTranslation();
  const {
    handleSubmit,
    error,
    submitFailed,
    valid,
    initialValues,
    handlePassword,
    isSignup = false,
    setReceiveConfirmations,
    setReceivePushNotifications = (param) => {},
    fieldNamesSettings,
    isProfile = false,
  } = props;
  useEffect(() => {
    setReceiveConfirmations(initialValues.receiveConfirmations);
    setReceivePushNotifications(initialValues.getPushNotifications);
  }, []);
  const [getViaMail, setGetViaMail] = useState(
    initialValues.receiveConfirmations
  );
  const [getPushNotif, setGetPushNotif] = useState(
    initialValues.getPushNotifications
  );
  return (
    <form className="form" onSubmit={handleSubmit}>
      <p className={'form-explanation'}>{t('signupFormExplanation')}</p>
      <div className="inputs-wrapper">
        <Field
          name={`firstName`}
          containerClass="one-column first-name"
          type="text"
          component={InputField}
          validate={[required]}
          required={true}
          placeholder={t('firstNamePH')}
          icon="user"
        />

        <Field
          name={`lastName`}
          containerClass="one-column last-name"
          type="text"
          component={InputField}
          validate={[required]}
          required={true}
          placeholder={t('lastNamePH')}
          icon="user"
        />

        <Field
          name={`phone`}
          containerClass="one-column phone"
          type="tel"
          component={InputField}
          placeholder={t('phonePH')}
          validate={[validatePhoneNum]}
          icon="phone-call"
        />

        <Field
          name={`email`}
          containerClass="one-column email"
          type="text"
          component={InputField}
          validate={[email]}
          placeholder={t('emailPH')}
          icon="mail"
        />

        {/* <Field
          name={`currentPassword`}
          containerClass="two-columns password"
          type="password"
          component={InputField}
          placeholder={'Current Password'}
          icon="padlock"
          validate={[required]}
        /> */}
        {/* <Field
          name={`password`}
          containerClass="two-columns password"
          type="password"
          component={InputField}
          placeholder={t('passwordPH')}
          icon="padlock"
        /> */}

        {!isSignup && (
          <Field
            name={`cardId`}
            containerClass="card-id"
            disabled={!isSignup}
            type="number"
            icon="icon-card-number"
            style={{ direction: 'ltr', textAlign: 'left' }}
            component={InputField}
            validate={[isNumber]}
            placeholder={
              fieldNamesSettings?.find((el) => el.value === 'cardId')
                ? fieldNamesSettings
                    ?.find((el) => el.value === 'cardId')
                    ?.translations.find(
                      (el) => el.language.shortCode == i18n.language
                    )?.text
                : t('cardIdPH')
            }
          />
        )}

        <Field
          name={`employeeId`}
          disabled={!isSignup}
          containerClass="employee-id"
          type="number"
          icon="icon-employee-card"
          style={{ direction: 'ltr', textAlign: 'left' }}
          component={InputField}
          validate={[required, isPositiveNumber]}
          required={true}
          placeholder={
            fieldNamesSettings?.find((el) => el.value === 'employeeId')
              ? fieldNamesSettings
                  .find((el) => el.value === 'employeeId')
                  .translations?.find(
                    (el) => el.language.shortCode == i18n.language
                  )?.text
              : t('employeeIdPH')
          }
        />
      </div>

      {error && <div className="form-error">{error}</div>}
      {/* <h6 onClick={handlePassword} className="change-password">
        {t('changePassword')}
      </h6> */}
      <div className="checkboxes-wrapper mb-0 mt-0">
        <FormControlLabel
          className="default-flex-class-for-checkbox"
          control={
            <Checkbox
              checked={getViaMail}
              onChange={() => {
                setGetViaMail(!getViaMail);
                setReceiveConfirmations((prevState) => !prevState);
              }}
              name="receiveConfirmations"
            />
          }
          label={t('iWantToReceiveViaMail')}
        />
      </div>
      {isProfile &&
      ((initialValues && initialValues.roleId == 1) ||
        (initialValues && initialValues.roleId == 2)) ? (
        <div className="checkboxes-wrapper mt-0">
          <FormControlLabel
            className="default-flex-class-for-checkbox"
            control={
              <Checkbox
                checked={getPushNotif}
                onChange={() => {
                  setGetPushNotif(!getPushNotif);
                  setReceivePushNotifications((prevState) => !prevState);
                }}
                name="receiveConfirmations"
              />
            }
            label={t('iWantToReceivePushNotifications')}
          />
        </div>
      ) : (
        ''
      )}
      <div className="settings-btn">
        {submitFailed && !valid ? (
          <DisabledButton
            label={t('saveInfo')}
            additionalClassName={'submit-btn'}
          />
        ) : (
          <>
            <Button type="submit">{t('save')}</Button>
          </>
        )}
      </div>
    </form>
  );
};

export default SignupForm = reduxForm({
  form: 'profile',
  initialValues: {
    phone: '',
  },
})(SignupForm);
