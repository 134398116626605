import React, { useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateLanguage } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  borderRadius: '5px',
  maxWidth: '522px',
  width: '100%',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
  editMode,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        type={type}
        disabled={!editMode}
        autoComplete="new-password"
      />
    </>
  );
};

const AddLangaugePopup = ({
  open,
  handleClose,
  t,
  payload,
  setPayload,
  errors,
  setErrors,
  isUpdate,
  handleUpdateLanguage,
  //handleAddLanguage,
  LanguageStatuses,
  isRTLArray,
}) => {
  const LanguageStatusesMap = LanguageStatuses.reduce((acc, language) => {
    return { ...acc, [language.id]: language.display };
  }, {});
  const handleChange = (name, value) => {
    const { isValid, error } = validateLanguage(value, name);
    setErrors({ ...errors, ...error });
    setPayload({ ...payload, [name]: value });
  };
  const isRTLArrayMap = isRTLArray.reduce((acc, el) => {
    return { ...acc, [el.value]: el.display };
  }, {});

  //   const addLangauge = async (event) => {
  //     event.preventDefault();
  //     let details = {
  //       displayName: payload.displayName,
  //       name: payload.name,
  //       shortCode:payload.shortCode,
  //       isRTL: payload.isRTL,
  //       languageStatusId: payload.languageStatusId,
  //     };
  //     const { isValid, errors } = validateLanguage(details);
  //     setErrors(errors);
  //     if (isValid) {
  //       await handleAddLanguage(details);
  //     }
  //   };

  const updateLanguage = async (event) => {
    event.preventDefault();
    let details = {
      displayName: payload.displayName,
      name: payload.name,
      shortCode: payload.shortCode,
      isRTL: payload.isRTL,
      languageStatusId: payload.languageStatusId,
      languageId: payload.languageId,
    };
    const { isValid, errors } = validateLanguage(details);
    setErrors(errors);
    if (isValid) {
      await handleUpdateLanguage(details);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup update-lang"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{isUpdate ? t('updateLanguage') : t('addLanguage')}</h6>

          <div className="close-button">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <form>
            <div className="item">
              {/* <div className="input-field">
                <GetInputField
                  placeholder={t('displayName')}
                  label={t('displayName')}
                  name="displayName"
                  type="text"
                  value={payload['displayName']}
                  cb={(value) => {
                    handleChange('displayName', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.displayName || errors.displayName}
                  </p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('name')}
                  label={t('name')}
                  name="name"
                  type="text"
                  value={payload['name']}
                  cb={(value) => {
                    handleChange('name', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">{errors.name || errors.name}</p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('shortCode')}
                  label={t('shortCode')}
                  name="shortCode"
                  type="text"
                  value={payload['shortCode']}
                  cb={(value) => {
                    handleChange('shortCode', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.shortCode || errors.shortCode}
                  </p>
                )}
              </div>
              <div className="input-field">
                <label>{t('isRTL')}</label>
                <Select
                  name={'isRTL'}
                  defaultValue={{
                    value: payload['isRTL'],
                    label: isRTLArrayMap[payload['isRTL']],
                  }}
                  onChange={(newData) => {
                    setPayload({ ...payload, isRTL: newData.value });
                  }}
                  options={isRTLArray?.map((el) => {
                    return { value: el.id, label: el.display };
                  })}
                  placeholder={t('isRTL')}
                  styles={SelectStyles}
                  required
                />
                {errors && <p className="error-msg">{errors.isRTL}</p>}
              </div> */}
              <div className="input-field">
                <label>{t('languageStatus')}</label>
                <Select
                  name={'languageStatusId'}
                  defaultValue={{
                    value: payload['languageStatusId'],
                    label: LanguageStatusesMap[payload['languageStatusId']],
                  }}
                  onChange={(newData) => {
                    setPayload({ ...payload, languageStatusId: newData.value });
                  }}
                  options={LanguageStatuses?.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.display };
                  })}
                  placeholder={t('langaugeStatus')}
                  styles={SelectStyles}
                  required
                />
              </div>
              <div className="add-employee-btn">
                <Button onClick={updateLanguage}>{t('update')}</Button>
              </div>
            </div>
          </form>{' '}
          {/* {isUpdate ? ( */}
          {/* ) : (
            <div className="add-employee-btn">
              <Button onClick={addLangauge}>{t('add')}</Button>
            </div>
          )} */}
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddLangaugePopup;
