import { SAVE_SECURITY } from './types';
// Set initial state
const initialState = {};

export function securitySettingReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SECURITY:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
