import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './profile.css';

const ProfileLayout = ({ children }) => {
  const { t } = useTranslation();
  useEffect(() => {});
  return (
    <div className="profile-wrapper">
      <div className="container">
        <div className="profile-grid">
          <div className="profile-sidebar">
            <div className="profile-menu">
              <Nav>
                <NavLink to="/profile">{t('account')}</NavLink>
                <NavLink to="/settings">{t('password1')}</NavLink>
                {/* <NavLink to="/paymentManagement?paymenttype=hyp">
                  {t('payment')}
                </NavLink> */}
                {/* <NavLink to="/security">Security</NavLink> */}
              </Nav>
            </div>
          </div>

          <div className="profile-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;
