import React, { useMemo, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployee,
  validateEmployeenew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { StaticStars } from '../RatingStars';
import image from '../../assets/images/eat-2.png';
import WEIcon from '../WE-IconButton';
import { Dialog } from '../../components/Dialogs/SwalDialogs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  height: '600px',
};

const ExtrasDish = ({
  extrasPopup,
  handleCloseExtras,
  t,
  extrsData,
  addedExtras,
  setAddedExtras,
  setIsSkip,
  AddOrderNew,
  skipAddOrder,
  paymentMethods,
}) => {
  const [value, setValue] = useState();
  const handleaddedExtras = (extrasId) => {
    const checkIfIdExits = addedExtras.find((el) => el === extrasId);
    if (checkIfIdExits) {
      return;
    }
    setValue(extrasId);
    setAddedExtras((prev) => {
      const prevArr = [...prev];
      prevArr.push(extrasId);
      setValue(extrasId);
      return prevArr;
    });
  };

  const removeExtrasId = (extrasId) => {
    if (!addedExtras.length) {
      return;
    }

    const findExtraIdIndex = addedExtras.findIndex((el) => el === extrasId);
    if (findExtraIdIndex === -1) {
      return;
    }

    setAddedExtras((prev) => {
      const prevArr = [...prev];
      prevArr.splice(findExtraIdIndex, 1);
      return prevArr;
    });
  };

  return (
    <Modal
      open={extrasPopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup copymeal-popup show-extra-popup"
    >
      <Box sx={style}>
        <div className="extras-listing-section">
          <div className="close-btn">
            <img src={cancel} alt="close" onClick={handleCloseExtras} />
          </div>
          <h4>{t('availableExtras')}</h4>
          <div className={'extras-meal-management'}>
            {extrsData &&
              extrsData.map((el) => (
                <div className={`item-card extras-list`}>
                  <div className="extras-dishname">
                    <img src={el.imageUrl} alt="img" />
                    {el.text}
                  </div>
                  <div className="price">
                    {t('price')}: {el.price}
                  </div>
                  <div className="extras-button">
                    <ExtraDishIcon
                      arr={addedExtras}
                      id={el.id}
                      handleaddedExtras={handleaddedExtras}
                      removeExtrasId={removeExtrasId}
                    />
                    {/* <div className={'inventory'}>
                      <WEIcon
                        icon={'plus'}
                        iconSize={'small'}
                        backgroundColor={'green'}
                        cb={() => handleaddedExtras(el.id)}
                        isDisabled={addedExtras.length > 0 && value === el.id}
                      />
                      
                      <p>Add</p>
                      <WEIcon
                        icon={'minus'}
                        iconSize={'small'}
                        backgroundColor={'green'}
                        cb={() => removeExtrasId(el.id)}
                        // isDisabled={inventoryValue < 1}
                      />
                     
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
          <div className="extras-button other-button">
            {addedExtras?.length > 0 ? (
              <button onClick={AddOrderNew}>{t('finalizeOrder')}</button>
            ) : (
              ''
            )}
            {addedExtras?.length > 0 ? (
              ''
            ) : (
              <button onClick={skipAddOrder}>{t('skip')}</button>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const ExtraDishIcon = ({ id, handleaddedExtras, arr, removeExtrasId }) => {
  const show = useMemo(() => {
    if (arr.find((el) => el === id)) {
      return true;
    }
    return false;
  }, [arr]);

  return (
    // <div className={`inventory`}>
    //   <WEIcon
    //     icon={'plus'}
    //     iconSize={'small'}
    //     backgroundColor={'green'}
    //     cb={() => handleaddedExtras(id)}
    //     isDisabled={show}
    //   />

    //   <p>{show ? 'Added' : 'Add'}</p>
    //   <WEIcon
    //     icon={'minus'}
    //     iconSize={'small'}
    //     backgroundColor={'red'}
    //     cb={() => removeExtrasId(id)}
    //     isDisabled={!show}
    //   />
    // </div>
    <div className={`inventory`}>
      {!show ? (
        <WEIcon
          icon={'plus'}
          iconSize={'small'}
          backgroundColor={'green'}
          cb={() => handleaddedExtras(id)}
          isDisabled={show}
        />
      ) : (
        <WEIcon
          icon={'minus'}
          iconSize={'small'}
          backgroundColor={'red'}
          cb={() => removeExtrasId(id)}
          isDisabled={!show}
        />
      )}
    </div>
  );
};

export default ExtrasDish;
