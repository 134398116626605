import React from 'react';
import { StaticStars } from '../RatingStars';
import DishImage from '../../screens/client/DishImage';
import Chip from '@material-ui/core/Chip';
import { ActiveButton } from '../WE-Button';
import ExtrasDish from './extrasDish';
import PaymentMethod from './paymentMethod';

export default function OrderSummaryDetails({
  details,
  mealSlotData,
  confirmBtn,
  extrasPopup,
  setExtrasPopup,
  t,
  extrsData,
  addedExtras,
  setAddedExtras,
  setIsSkip,
  AddOrderNew,
  skipAddOrder,
  paymentMethods,
  paymentPopup,
  setPaymentPopup,
  skipAddOrderPayment,
  handlePayment,
  cancellationTime,
}) {
  const handleextrasPopup = () => {
    setExtrasPopup(!extrasPopup);
  };
  const handleCloseExtras = () => {
    setExtrasPopup(false);
  };
  const handleClose = () => {
    setPaymentPopup(false);
  };
  return (
    <>
      <div className="order-card shadow-card">
        <div className="dishImage">
          <DishImage
            fileName={details?.dishImage}
            size={{ height: '100%', width: '100%' }}
          />
        </div>

        <div className="order-details">
          <section className="section1 dash-bottom">
            <h4>{details?.dishName}</h4>
            <StaticStars
              rating={details?.dishRate}
              size={'medium'}
              numOfRaters={details?.numOfRaters}
            />
          </section>

          <section className="section2">
            <p className="order-time">
              {details?.dateToDisplay} / {details?.mealTypeToDisplay}
              {`${
                mealSlotData && mealSlotData?.mealTypesSlot?.from ? '/' : ''
              }`}
              {mealSlotData && mealSlotData?.mealTypesSlot?.from}{' '}
              {`${
                mealSlotData && mealSlotData?.mealTypesSlot?.from ? '-' : ''
              }`}{' '}
              {mealSlotData && mealSlotData?.mealTypesSlot?.to}
            </p>
            <p className="order-description">{details?.dishDescription}</p>
          </section>
          <div style={{ marginBottom: '5px' }}>
            {details?.dishTags
              ?.filter((tag) => tag?.isPublic)
              .map((tag) => (
                <Chip size="small" label={tag?.displayName} />
              ))}
          </div>
          {details?.dishPrice ? (
            <p style={{ fontSize: '22px' }}> ₪{details?.dishPrice}</p>
          ) : null}

          <div className="add-extras-order-details">
            {/* <ActiveButton
              label={'Add Extras'}
              arrow={false}
              cb={handleextrasPopup}
            /> */}
            {confirmBtn}
          </div>
        </div>
      </div>
      {extrasPopup && (
        <ExtrasDish
          extrasPopup={extrasPopup}
          handleCloseExtras={handleCloseExtras}
          t={t}
          extrsData={extrsData}
          setAddedExtras={setAddedExtras}
          addedExtras={addedExtras}
          skipAddOrder={skipAddOrder}
          AddOrderNew={AddOrderNew}
          setIsSkip={setIsSkip}
          paymentMethods={paymentMethods}
        />
      )}
      {paymentPopup && (
        <PaymentMethod
          paymentPopup={paymentPopup}
          t={t}
          handleClose={handleClose}
          skipAddOrderPayment={skipAddOrderPayment}
          handlePayment={handlePayment}
          paymentMethods={paymentMethods}
          cancellationTime={cancellationTime}
        />
      )}
    </>
  );
}
