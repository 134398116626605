import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';

const percentToDisplay = (value, outOfValue) =>
  outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;
const PercentData = ({ value, outOfValue, label }) => {
  value = value ?? 0;
  outOfValue = outOfValue ?? 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>{`${label}:${value}`}</p>
        <p className={'percent'}>{`(${percentToDisplay(
          value,
          outOfValue
        )}%)`}</p>
      </div>
    </>
  );
};

const OrderPercentData = ({ value, outOfValue, label, history }) => {
  value = value ?? 0;
  outOfValue = outOfValue ?? 0;
  const linkReservation = () => {
    let url = window.location.href;
    let mealDate = url?.split('/')[5];
    let mealTypeId = url?.split('/')[6];
    history.push({
      pathname: `/reservationManagement`,
      state: { mealDate: mealDate, mealTypeId: mealTypeId },
    });
    //history.push(`/reservationManagement?mealDate=${mealDate}?mealTypeId=${mealTypeId}`);
  };

  return (
    <>
      <div className={'percent-data order-percent'}>
        <p onClick={linkReservation}>{`${label}:${value}`}</p>
        <p className={'percent'}>{`(${percentToDisplay(
          value,
          outOfValue
        )}%)`}</p>
      </div>
    </>
  );
};

const MealSummary = ({ mealData }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={'meal-summary'}>
      <p className={'inventory'}>{`${t('inventory')}: ${
        mealData.inventory ? mealData.inventory : 0
      }`}</p>

      <OrderPercentData
        label={t('orders')}
        value={mealData?.ordered}
        outOfValue={mealData?.inventory}
        history={history}
      />
      <PercentData
        label={t('realized')}
        value={mealData?.realized}
        outOfValue={mealData?.inventory}
      />
    </div>
  );
};

export default MealSummary;
