import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../store/app/actions';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../services/http';
import { isTabletOrMobile } from '../services/mediaQueries';
import { sleep } from '../services/utils';
import { Alert, Dialog } from '../components/Dialogs/SwalDialogs';
import { ActiveButton } from '../components/WE-Button';
import cancel from '../assets/images/close.svg';
import moment from 'moment';
import JSONFormatter from 'json-formatter-js';
import search from '../assets/icons/search.png';
import file from '../assets/images/file-export.svg';
import { Button } from 'react-bootstrap';
import more from '../assets/images/more.svg';
import next from '../assets/images/next.png';
import ViewCibusErrorDetails from './ViewCibusErrorDetails';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  maxWidth: '500px',
  wordBreak: 'break-all',
  borderRadius: '5px',
  height: 'auto',
};

const CibusError = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [logsToDisplay, setLogsToDisplay] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [viewData, setViewData] = useState();
  const [prefetchData, setPrefetchData] = useState(null);
  const [jsonData, setJsondata] = useState();
  const [fromDate, setFromdate] = useState(
    new Date().setDate(new Date().getDate() - 2)
  );
  const [toDate, setToDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [maxDates, setMaxDate] = useState(false);
  const [columns, setcolumns] = useState();
  const [details, setDetails] = useState();
  const [viewDetails, setViewDetails] = useState(false);

  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});

  /***********fetch log   data */
  useEffect(() => {
    const fetchData = async () => {
      const data = await http.get(`orders/getAllCibusError`);
      setPrefetchData(data);
      let logsData = data.map((u, i) => ({
        number: u.number,
        name: u.name,
        hebrew: u.message.he,
        english: u.message.en,
        russian: u.message.ru,
      }));
      setLogsToDisplay(logsData);
    };
    fetchData();
  }, []);

  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };
  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  // ag grid data
  const columnDefs = [
    {
      field: 'number',
      headerName: t('codeNumber'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'name',
      headerName: t('name'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'hebrew',
      headerName: t('hebrew'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'english',
      headerName: t('english'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'russian',
      headerName: t('russian'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
  ];

  const mobileColumnDefs = [
    {
      field: 'number',
      headerName: t('codeNumber'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'name',
      headerName: t('name'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
      flex: 1,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setcolumns(columnDefs);
    } else if (window.innerWidth <= 480) {
      setcolumns(mobileColumnDefs);
    } else {
      setcolumns(columnDefs);
    }
  }, []);

  /***********Export CSV file */
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const lang = localStorage.getItem('language');
  return (
    <>
      {' '}
      <div className="global-data-table cibus-error-table">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter">
              <div className="search-input">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  onInput={onFilterTextBoxChanged}
                />
                <img src={search} alt="search" />
              </div>
            </div>
            {/* <div className="active-button">
          <div className="actions">
            <img src={file} alt="add" onClick={() => onBtnExport()} />
          </div>
        </div> */}
          </div>

          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={logsToDisplay}
              reactUi={lang === '"he"' ? false : true}
              enableRtl={lang === '"he"' ? true : false}
              columnDefs={columns}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              rowSelection="multiple"
              frameworkComponents={{
                ViewMore: (e) => (
                  <ViewMore e={e} handleViewMore={handleViewMore} />
                ),
              }}
              debug={false}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
                document
                  .querySelector('.ag-pinned-left-cols-container')
                  .remove();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
      <ViewCibusErrorDetails
        open={viewDetails}
        details={details}
        handleClose={handleViewMore}
        setDetails={setDetails}
      />
    </>
  );
};
const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(CibusError);
