import { useState, useEffect } from 'react';
import { Field, reduxForm, FormSection } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';

import { ActiveButton } from '../WE-Button';

// Validators
import { required } from '../../services/validators';
import FormCheckBox from '../InputFields/WE-Checkbox';

const TagForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, onClose, error, languages, initialValues, change } =
    props;

  const [isActive, setIsActive] = useState(initialValues.active);
  const [isPublic, setIsPublic] = useState(initialValues.isPublic);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <form
        className="form "
        onSubmit={handleSubmit}
        style={{
          maxWidth: '100vw',
          justifyContent: 'center',
          width: '650px',
        }}
      >
        <div className="tag-form">
          <FormSection name="translations">
            {languages.map((language, idx) => {
              return (
                <div className="lang-item" key={language.id}>
                  <LangForm
                    language={language}
                    langId={language.id}
                    idx={idx}
                    prefix="translations"
                    change={change}
                  />
                </div>
              );
            })}
          </FormSection>
          <Field
            name={`status`}
            component={FormCheckBox}
            label={t('isActive')}
            type={'checkbox'}
            useFullWidth={false}
            checked={isActive}
            cb={() => {
              setIsActive(!isActive);
            }}
            justifyContent="start"
          />

          <Field
            name={`isPublic`}
            component={FormCheckBox}
            label={t('isPublic')}
            type={'checkbox'}
            useFullWidth={false}
            checked={isPublic}
            cb={() => setIsPublic(!isPublic)}
            justifyContent="start"
          />
        </div>

        <div
          className={'submit-section'}
          style={{
            justifyContent: 'space-between',
          }}
        >
          <div className={'cancel'} onClick={onClose}>
            <h4>{t('cancel')}</h4>
          </div>

          <div
            className={'submit-wrapper'}
            style={{
              flex: 'none',
            }}
          >
            <ActiveButton
              type={'submit'}
              label={t('saveInfo')}
              additionalClassName={'btn'}
            />
          </div>
        </div>

        {error && <div className="form-error">{error}</div>}
      </form>
    </div>
  );
};

const LangForm = ({ language, langId, prefix, idx, change }) => {
  const shortCode = language.shortCode;
  const { t } = useTranslation();
  const alignFields = language.isRTL ? 'align-right' : 'align-left';
  const objPath = `${prefix}[${idx}]`;

  useEffect(() => {
    change(`${objPath}.languageId`, parseInt(langId));
  }, []);

  return (
    <>
      <FormSection name={idx}>
        <Field
          name={`displayName`}
          type="text"
          containerClass={`item ${alignFields}`}
          component={InputField}
          placeholder={t('name', { lng: shortCode })}
          validate={[required]}
        />
      </FormSection>
    </>
  );
};

export default reduxForm({
  form: 'tag',
  initialValues: {
    status: true,
    isPublic: true,
  },
})(TagForm);
