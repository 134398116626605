import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../style/myOrders.scss';
import qrCodeImg from '../../assets/icons/websiteQRCode_noFrame 1.png';

const VoucherPopup = ({ voucherModal, handleVoucherModal }) => {
  return (
    <Modal
      show={voucherModal}
      className="voucher-modal"
      onHide={handleVoucherModal}
      centered
    >
      <Modal.Body>
        <div className="qr-section">
          <img src={qrCodeImg} alt="" />
        </div>
        <div className="voucher-info">
          <h2>Voucher</h2>
          <span>Secondary text...</span>
        </div>
        <div className="meal-details">
          <div className="meal-details-field">
            <h5>Kitchen</h5>
            <p>Modiin Test</p>
          </div>
          <div className="meal-details-field">
            <h5>Meal Type</h5>
            <p>Breakfast</p>
          </div>
          <div className="meal-details-field">
            <h5>Dishes</h5>
            <p>Chicken drumstick in BBQ sauce</p>
          </div>
          <div className="meal-details-field">
            <h5>Quantity</h5>
            <p>5</p>
          </div>
          <div className="meal-details-field">
            <h5>Extras</h5>
            <p>Beverages</p>
          </div>
          <div className="meal-details-field">
            <h5>Total price</h5>
            <p>₪ 0</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VoucherPopup;
