import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import cancel from '../../assets/images/close.svg';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import http from '../../services/http';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import chefDishImg from '../../assets/images/chef_mealDish.png';
import {
  Alert,
  Dialog,
  DialogWithInput,
} from '../../components/Dialogs/SwalDialogs';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '1050px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewDetailsWeekView = ({
  open,
  details,
  handleClose,
  user,
  setDummy,
  DishCategories,
  MealDishStatuses,
  useFetchingLoader,
  setShowEditDishPopup,
  setValuesForEdit,
  kitchen,
  svgObject,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [tooltipVal, setTooltipVal] = useState('');
  const [isTooltipOpenMeal, setIsTooltipOpenMeal] = useState(false);
  const [isTooltipOpenChef, setIsTooltipOpenChef] = useState(false);
  const MealDishStatusMap = MealDishStatuses.reduce((acc, mealDish) => {
    return { ...acc, [mealDish.value]: mealDish.id };
  }, {});
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
      setHeight(event.target.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (!tooltipVal) {
      setTooltipVal(
        svgObject(
          33,
          33,
          DishCategories && details?.dish?.dishCategoryId
            ? DishCategories.find(
                (el) => el.id === details?.dish?.dishCategoryId
              )?.display
            : ''
        )[details?.dish?.dishCategoryId]
      );
    }
  }, [details]);
  const handleDelete = async (mealDishId) => {
    try {
      await useFetchingLoader(async () => {
        let mealDish = await http.get(`mealsDishes/${mealDishId}`);
        if (mealDish.ordered > 0) {
          // Don't allow to delete mealDish with orders
          throw { content: t('thereIsAlreadyAnOrder') };
        } else {
          await http.put(`mealsDishes/${mealDishId}`, {
            mealDishStatusId: MealDishStatusMap.deleted,
          });
          handleClose();
          setDummy((prev) => !prev);
        }
      });
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };
  const handleDeleteClick = async () => {
    if (details.ordered > 0) {
      Alert({ error: { content: t('thereIsAlreadyAnOrder') } });
    } else {
      Dialog({
        title: t('removeDishTitle'),
        text: `${t('areYouSureYouWantToRemoveDish')} ${details?.dish?.name}`,
        buttons: {
          confirmButtonText: t('removeDishBtnLabel'),
          cancelButtonText: t('back'),
        },
        onConfirm: async () => {
          try {
            await handleDelete(details.id);
          } catch (err) {
            setTimeout(() => Alert({ error: err }), 150);
            return err;
          }
        },
      });
    }
  };
  const handleTooltipCloseMeal = () => {
    setIsTooltipOpenMeal(false);
  };
  const handleTooltipCloseChef = () => {
    setIsTooltipOpenChef(false);
  };

  const handleTooltipOpenMeal = () => {
    handleTooltipCloseChef();
    setIsTooltipOpenMeal((prev) => !prev);
  };

  const handleTooltipOpenChef = () => {
    handleTooltipCloseMeal();
    setIsTooltipOpenChef((prev) => !prev);
  };
  return (
    <>
      <Modal open={open} className="view-more-popup">
        <Box sx={style}>
          <div className="view-detail-head view-details-desktop">
            <div className="reservation-title reservation-title-desktop">
              {details?.order ? (
                <p className="meal-details-popup-meal-desc">
                  {moment(details?.meal?.date).format('dddd')} |{' '}
                  {details?.meal?.date} |{' '}
                  {details?.meal?.mealType['mealtype|display|translation'].text}{' '}
                  | {kitchen?.name}
                </p>
              ) : (
                ''
              )}
            </div>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className="more-detail-popup">
              <div className="user-detail-container">
                <div className="week-view-more-details-content-wrapper">
                  <img
                    src={details?.dish?.image}
                    alt="Meal img"
                    className="week-view-card-image"
                  />
                  <div className="week-view-more-details-info-wrapper">
                    <div className="week-view-more-details-text-wrapper">
                      <h5 className="view-details-week-view-header-desktop">
                        {details?.dish
                          ? details?.dish['dish|name|translation']?.text
                          : '--'}
                      </h5>
                      <p className="view-detail-head-desc">
                        {details?.dish
                          ? details?.dish['dish|description|translation']?.text
                          : '--'}
                      </p>
                    </div>

                    <div className="user-details-row-mob">
                      <div className="d-flex gap-2">
                        <ClickAwayListener onClickAway={handleTooltipCloseMeal}>
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipCloseMeal}
                              open={isTooltipOpenMeal}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                DishCategories && details?.dish?.dishCategoryId
                                  ? DishCategories.find(
                                      (el) =>
                                        el.id === details?.dish?.dishCategoryId
                                    )?.display
                                  : ''
                              }
                            >
                              <div onClick={handleTooltipOpenMeal}>
                                {tooltipVal}
                              </div>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>

                        {details?.isChefMealDish ? (
                          <ClickAwayListener
                            onClickAway={handleTooltipCloseChef}
                          >
                            <div>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipCloseChef}
                                open={isTooltipOpenChef}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('chefDish')}
                              >
                                <div onClick={handleTooltipOpenChef}>
                                  <img
                                    src={chefDishImg ? chefDishImg : ''}
                                    alt=""
                                    className="chef-img-week-view"
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </ClickAwayListener>
                        ) : (
                          ''
                        )}
                      </div>
                      <table className="view-detail-table view-detail-table-mob">
                        <thead className="view-detail-table-head">
                          <tr className="view-detail-table-head-tr">
                            <th className="view-detail-table-head-th">
                              {t('inventory')}
                            </th>
                            <th className="view-detail-table-head-th">
                              {t('ordered')}
                            </th>
                            <th className="view-detail-table-head-th">
                              {t('realized')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="view-detail-table-body">
                          <tr className="view-detail-table-body-tr">
                            <td className="view-detail-table-body-td">
                              {'inventory' in details
                                ? details?.inventory
                                : '--'}
                            </td>
                            <td className="view-detail-table-body-td">
                              {'ordered' in details
                                ? `${details?.ordered} (${
                                    details?.inventory === 0
                                      ? 0
                                      : Math.round(
                                          details?.ordered / details?.inventory
                                        )
                                  }%)`
                                : '--'}
                            </td>
                            <td className="view-detail-table-body-td">
                              {'realized' in details
                                ? `${details?.realized} (${
                                    details?.inventory === 0
                                      ? 0
                                      : Math.round(
                                          details?.realized / details?.inventory
                                        )
                                  }%)`
                                : '--'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <table className="view-detail-table">
                      <thead className="view-detail-table-head">
                        <tr className="view-detail-table-head-tr">
                          <th className="view-detail-table-head-th">
                            {t('inventory')}
                          </th>
                          <th className="view-detail-table-head-th">
                            {t('ordered')}
                          </th>
                          <th className="view-detail-table-head-th">
                            {t('realized')}
                          </th>
                          <th className="view-detail-table-head-th">
                            {t('category')}
                          </th>
                          <th className="view-detail-table-head-th">
                            {t('chefDish')}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="view-detail-table-body">
                        <tr className="view-detail-table-body-tr">
                          <td className="view-detail-table-body-td">
                            {'inventory' in details ? details?.inventory : '--'}
                          </td>
                          <td className="view-detail-table-body-td">
                            {'ordered' in details
                              ? `${details?.ordered} (${
                                  details?.inventory === 0
                                    ? 0
                                    : Math.round(
                                        details?.ordered / details?.inventory
                                      )
                                }%)`
                              : '--'}
                          </td>
                          <td className="view-detail-table-body-td">
                            {'realized' in details
                              ? `${details?.realized} (${
                                  details?.inventory === 0
                                    ? 0
                                    : Math.round(
                                        details?.realized / details?.inventory
                                      )
                                }%)`
                              : '--'}
                          </td>
                          <td className="view-detail-table-body-td">
                            {DishCategories && details?.dish?.dishCategoryId
                              ? DishCategories.find(
                                  (el) =>
                                    el.id === details?.dish?.dishCategoryId
                                )?.display
                              : '--'}
                          </td>
                          <td className="view-detail-table-body-td">
                            {'isChefMealDish' in details
                              ? t(details?.isChefMealDish ? 'yes' : 'no')
                              : '--'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="modal-footer-btn mt-0">
                <Button
                  onClick={() => {
                    handleClose();
                    setValuesForEdit(details);
                    setShowEditDishPopup(true);
                  }}
                >
                  <i class="las la-pen"></i>
                  {t('edit')}
                </Button>

                <Button onClick={() => handleDeleteClick()}>
                  <i class="las la-trash"></i>
                  {t('delete')}
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default ViewDetailsWeekView;
