import React, { useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateKitchenDevice } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  borderRadius: '5px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        // disabled={!editMode}
        type={type}
        autoComplete="new-password"
      />
    </>
  );
};

const AddKitchenDevicePopup = ({
  open,
  handleClose,
  t,
  payload,
  setPayload,
  errors,
  setErrors,
  isUpdate,
  Kitchens,
  handleUpdatekitchenDevice,
  handleAddKitchenDevice,
  isDisable,
  setIsDisable,
}) => {
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const handleChange = (name, value) => {
    const { isValid, error } = validateKitchenDevice(value, name);
    setErrors({ ...errors, ...error });
    setPayload({ ...payload, [name]: value });
    setIsDisable(false);
  };

  const addKitchenDevice = async (event) => {
    event.preventDefault();
    setIsDisable(true);
    let details = {
      displayName: payload.displayName,
      deviceType: payload.deviceType,
      location: payload.location,
      kitchenId: payload.kitchenId,
    };
    const { isValid, errors } = validateKitchenDevice(details);
    setErrors(errors);
    if (isValid) {
      await handleAddKitchenDevice(details);
    }
  };
  const deviceTypes = [
    { id: 1, name: 'Dispenser' },
    { id: 2, name: 'Kiosk' },
  ];
  const devicesIds = { Dispenser: 1, Kiosk: 2 };
  const updatekitchenDevice = async (event) => {
    event.preventDefault();
    setIsDisable(true);
    let details = {
      displayName: payload.displayName,
      deviceType: payload.deviceType,
      location: payload.location,
      kitchenId: payload.kitchenId,
      kitchenDeviceId: payload.kitchenDeviceId,
    };
    const { isValid, errors } = validateKitchenDevice(details);
    setErrors(errors);
    if (isValid) {
      await handleUpdatekitchenDevice(details);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{isUpdate ? t('updateKitchenDevice') : t('addKitchenDevice')}</h6>

          <div className="close-button">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <form>
            <div className="item">
              <div className="input-field">
                <GetInputField
                  placeholder={t('displayName')}
                  label={t('displayName')}
                  name="displayName"
                  type="text"
                  value={payload['displayName']}
                  cb={(value) => {
                    handleChange('displayName', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.displayName || errors.displayName}
                  </p>
                )}
              </div>
              <div className="input-field">
                <label>{t('deviceType')}</label>
                <Select
                  name={'deviceType'}
                  defaultValue={{
                    value: devicesIds[payload['deviceType']],
                    label:
                      payload['deviceType'] == 1
                        ? 'Dispenser'
                        : payload['deviceType'] == 2
                          ? 'Kiosk'
                          : '',
                  }}
                  options={deviceTypes?.map((deviceType) => {
                    return { value: deviceType.id, label: deviceType.name };
                  })}
                  placeholder={t('deviceType')}
                  styles={SelectStyles}
                  onChange={(deviceType) => {
                    handleChange('deviceType', deviceType.value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.deviceType || errors.deviceType}
                  </p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('location')}
                  label={t('location')}
                  name="location"
                  type="text"
                  value={payload['location']}
                  cb={(value) => {
                    handleChange('location', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.location || errors.location}
                  </p>
                )}
              </div>
              <div className="input-field">
                <label>{t('kitchenname')}</label>
                <Select
                  name={'kitchenId'}
                  defaultValue={{
                    value: payload['kitchenId'],
                    label: KitchensMap[payload['kitchenId']],
                  }}
                  onChange={(newData) => {
                    setPayload({ ...payload, kitchenId: newData.value });
                  }}
                  options={Kitchens?.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.name };
                  })}
                  placeholder={t('kitchens')}
                  styles={SelectStyles}
                  required
                />
                {errors && <p className="error-msg">{errors.kitchenId}</p>}
              </div>
            </div>
          </form>{' '}
          {isUpdate ? (
            <div className={`add-employee-btn`}>
              <Button onClick={updatekitchenDevice} disabled={isDisable}>
                {t('update')}
              </Button>
            </div>
          ) : (
            <div className={`add-employee-btn`}>
              <Button onClick={addKitchenDevice} disabled={isDisable}>
                {t('add')}
              </Button>
            </div>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddKitchenDevicePopup;
