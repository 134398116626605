import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import DishForm from '../../components/Forms/DishForm';
import { connect } from 'react-redux';
import http from '../../services/http';
import axios from 'axios';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import AppConfig from '../../constants/Config';
import { getAssetUrl } from '../../services/aws';
import { sleep } from '../../services/utils';
import WEIcon from '../../components/WE-IconButton';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import defaultImage from '../../assets/images/defaultImage.png';
import { getCsrfToken } from '../../services/csrfToken';
import BackLink from '../../components/BackLink';
import '../../style/addeditPopup.scss';
import Dropzone from 'react-dropzone';
import { validateAddDish, validateEditDish } from '../../services/validators';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@material-ui/core';
import { styled } from '@mui/system';

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: '#f0f4f8',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
    color: 'black',
  }),
};
const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: '#14cd30 !important', // Цвет индикатора
  },
  '& .Mui-selected': {
    color: '#14cd30 !important', // Цвет выбранной метки вкладки
  },
}));

const EditDishPopupForWeekView = ({
  handleClose,
  show,
  DishStatuses,
  languages,
  DishCategories,
  kitchen,
  values,
  setValuesForEdit,
  useFetchingLoader,
  setDummy,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dishId } = useParams();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const [pastFlag, setPastFlag] = useState(false);
  const [errors, setErrors] = useState({});
  const [tags, setTags] = useState([]);
  const [tabIndex, setTabIndex] = useState(
    languages && languages.length > 0 ? languages[0].id : 0
  );
  const [switchCondition, setSwitchCondition] = useState(false);
  const [editedData, setEditedData] = useState({});
  useEffect(() => {
    const fetchData1 = async () => {
      const filterPayload = {
        page: 1,
        limit: 1000,
        keyword: '',
      };
      const { data: _tags } = await http.get('tags', filterPayload);
      setTags(_tags?.filter((tag) => tag.status));
      let title = t('editDish');

      const valuesCopy = JSON.parse(JSON.stringify(values));
      setEditedData({
        id: valuesCopy?.id,
        dishId: valuesCopy?.dishId,
        mealId: valuesCopy?.mealId,
        names: valuesCopy?.dish
          ? valuesCopy?.dish['dish|name|translations']
              .sort((a, b) => a.languageId - b.languageId)
              .slice()
          : [],
        descriptions: valuesCopy?.dish
          ? valuesCopy?.dish['dish|description|translations']
              .sort((a, b) => a.languageId - b.languageId)
              .slice()
          : [],
        tags: valuesCopy?.dish?.tags
          ? [...valuesCopy?.dish?.tags.map((el) => el?.id)]
          : [],
        price: valuesCopy?.dish?.price,
        inventory: valuesCopy?.inventory,
        isChefMealDish: valuesCopy?.isChefMealDish,
        dishCategoryId: valuesCopy?.dish?.dishCategoryId,
        image: valuesCopy?.dish?.image
          ? makeImage(valuesCopy?.dish?.image)
          : '',
      });
      setSwitchCondition(valuesCopy?.isChefMealDish);
      setLayoutOptions({ title });
      const now = moment(new Date()).format('YYYY-MM-DD');
      const selectedDate = moment().format('YYYY-MM-DD');
      setPastFlag(moment(selectedDate).isBefore(moment(now)));
    };
    if (Object.keys(values) !== 0) {
      fetchData1();
    }
  }, [values]);
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);
  const makeImage = (imgText) =>
    imgText?.startsWith('http') ? imgText : getAssetUrl(imgText);

  const handleSubmit = async () => {
    let res;
    try {
      const descTranslations = editedData?.descriptions.sort(
        (a, b) => a?.languageId - b?.languageId
      );
      const namesTranslations = editedData?.names.sort(
        (a, b) => a?.languageId - b?.languageId
      );
      const translations = namesTranslations.reduce((acc, curr, i) => {
        acc.push({
          description: descTranslations[i].text,
          name: curr.text,
          languageId: curr.languageId,
        });
        return acc;
      }, []);
      const payloadForNameUpdate = {
        dishCategoryId: Number(editedData?.dishCategoryId),
        image: editedData?.image,
        price: editedData?.price,
        tags: editedData?.tags ?? [],

        translations: translations,
      };
      var formData = new FormData();
      Object.keys(payloadForNameUpdate).forEach((item) => {
        if (item === 'translations') {
          formData.append(
            item.toString(),
            JSON.stringify(payloadForNameUpdate[item])
          );
        } else if (item === 'tags') {
          formData.append(
            item.toString(),
            JSON.stringify(
              payloadForNameUpdate[item].length === 0
                ? []
                : payloadForNameUpdate[item]
            )
          );
        } else if (item === 'image') {
          formData.set(
            'image',
            payloadForNameUpdate[item] && payloadForNameUpdate[item][0].path
              ? payloadForNameUpdate[item][0]
              : payloadForNameUpdate[item]
          );
        } else formData.append(item.toString(), payloadForNameUpdate[item]);
      });
      await useFetchingLoader(async () => {
        await axios.put(
          `${AppConfig.server.url}/dishes/${editedData?.dishId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem('token')
              )}`,
              'X-Site-Url': window.location.href,
              'X-Org-Id': localStorage.getItem('companyId') ?? null,
            },
          }
        );
        await http.put(`mealsDishes/${editedData?.id}`, {
          inventory: parseInt(editedData?.inventory),
          isChefMealDish: editedData?.isChefMealDish,
        });
      });
      await SwalDialogs.Dialog({
        title: t('dishUpdatedMessage'),
        type: 'success',
        onClose: () => {
          if (window.location.hash.includes('menuConstruction')) {
            setDummy((prev) => !prev);
          }
          setValuesForEdit({});
          setEditedData({});
          setDummy((prev) => !prev);
          handleClose();
        },
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('replaceOrderBtnLabel'),
        },
      });
    } catch (err) {
      Alert({ error: res?.message ? res?.message : err });
    }
  };
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleFields = (e, index = -1) => {
    if (Array.isArray(e)) {
      setEditedData({ ...editedData, tags: e.map((el) => el.value) });
    } else {
      const { name, value } = e?.target;
      if (name === 'dishCategoryId') {
        setEditedData({ ...editedData, [name]: value });
      } else if (name === 'price') {
        e.target.value = Number(value.replace(/\D/g, ''));
        setEditedData({ ...editedData, [name]: Math.abs(value) });
      } else if (index !== -1) {
        if (name === 'name') {
          const prevTransalations = editedData.names.slice();
          prevTransalations[index].text = value;
          setEditedData({ ...editedData, names: prevTransalations });
        } else {
          const prevTransalations = editedData.descriptions.slice();
          prevTransalations[index].text = value;
          setEditedData({
            ...editedData,
            descriptions: prevTransalations,
          });
        }
      } else {
        setEditedData({ ...editedData, [name]: value });
      }
    }
  };
  const handleChangeSwitch = () => {
    setSwitchCondition((prev) => {
      setEditedData({ ...editedData, isChefMealDish: !prev });
      return !prev;
    });
  };
  const handleSubmitImage = (name, value) => {
    setEditedData((prev) => ({ ...prev, [name]: value }));
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setValuesForEdit({});
        setEditedData({});
        setErrors({});
        handleClose(false);
      }}
      onClose={() => {
        setValuesForEdit({});
        setEditedData({});
        setErrors({});
        handleClose(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-edit-popup add-edit-popup-week-view"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('updateDish')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-edit-dish d-flex flex-column gap-4">
          <div className="d-flex flex-column">
            <CustomTabs
              value={tabIndex}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="primary"
            >
              {languages.length > 0 &&
                languages?.map((lang, index) => (
                  <Tab
                    label={lang?.displayName}
                    {...a11yProps(lang.id)}
                    value={lang.id}
                  />
                ))}
            </CustomTabs>
            {languages.length > 0 &&
              languages?.map((lang, index) => {
                return (
                  <CustomTabPanel value={tabIndex} index={lang.id}>
                    <Form.Group
                      className=" form-group mb-0"
                      controlId="exampleForm.ControlInput1"
                      key={lang + index}
                    >
                      <Form.Label>{lang?.displayName}</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={pastFlag}
                        placeholder="Enter Name"
                        as="textarea"
                        name="name"
                        defaultValue={
                          editedData?.names
                            ? editedData?.names.find(
                                (el) => el?.languageId === lang.id
                              )?.text
                            : ''
                        }
                        onBlur={(e) => handleFields(e, index)}
                      />
                      {errors && errors[`translations[${index}].name`] && (
                        <p className="error-msg">
                          {errors[`translations[${index}].name`]}
                        </p>
                      )}
                      <Form.Control
                        disabled={pastFlag}
                        name="description"
                        type="text"
                        as="textarea"
                        placeholder="Short Description"
                        defaultValue={
                          editedData?.descriptions
                            ? editedData?.descriptions.find(
                                (el) => el?.languageId === lang.id
                              )?.text
                            : ''
                        }
                        onBlur={(e) => handleFields(e, index)}
                      />
                      {errors &&
                        errors[`translations[${index}].description`] && (
                          <p className="error-msg">
                            {errors[`translations[${index}].description`]}
                          </p>
                        )}
                    </Form.Group>
                  </CustomTabPanel>
                );
              })}
          </div>
          <div className="d-flex flex-column gap-3 m-0">
            <div className="line"></div>
            <p className="user-create-title text-left">{t('Settings')}</p>
          </div>
          <div className="add-edit-dish-grid-2">
            <Form.Group
              className=" form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>{t('inventory')}</Form.Label>
              <Form.Control
                disabled={pastFlag}
                type="number"
                name="inventory"
                placeholder="inventory"
                onChange={handleFields}
                value={editedData?.inventory}
              />
            </Form.Group>

            <Form.Group
              className=" form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>{t('price')}</Form.Label>
              <Form.Control
                disabled={pastFlag}
                type="number"
                name="price"
                placeholder="price"
                onChange={handleFields}
                value={editedData?.price}
              />
            </Form.Group>
            <Form.Group
              className=" form-group"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>{t('category')} </Form.Label>
              <Form.Select
                disabled={pastFlag}
                aria-label="Default select example"
                name="dishCategoryId"
                onChange={handleFields}
                value={
                  Array.isArray(editedData?.dishCategoryId) &&
                  editedData?.dishCategoryId.length
                    ? editedData?.dishCategoryId[0]
                    : editedData?.dishCategoryId
                }
              >
                {DishCategories.map((el, i) => (
                  <option value={el.id} key={i}>
                    {el.display}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className=" form-group"
              controlId="exampleForm.ControlInput1"
            >
              <label>{t('selectTags')}</label>
              <Select
                isMulti
                disabled={pastFlag}
                name={'tags'}
                value={
                  editedData?.tags
                    ? editedData?.tags.map((el) => {
                        return {
                          value: el,
                          label: tags?.find((ele) => ele.id === el)
                            ?.displayName,
                        };
                      })
                    : []
                }
                onChange={handleFields}
                options={tags.map((tag) => {
                  return { value: tag.id, label: tag.displayName };
                })}
                placeholder={t('selectTags')}
                styles={SelectStyles}
              />
            </Form.Group>
            <div className=" form-group-week-view">
              <Form.Label>{t('chefDish')} </Form.Label>
              <Form.Check
                type="switch"
                aria-label="Default select example"
                name="isChefMealDish"
                checked={editedData?.isChefMealDish}
                onChange={handleChangeSwitch}
              />
            </div>
            <div className="upload-image">
              <label>
                <UploadfileSection
                  fileValue={editedData?.image}
                  disabled={pastFlag}
                  setFileValue={(val) => handleSubmitImage('image', val)}
                  t={t}
                />
              </label>
            </div>
          </div>
        </div>
        {!pastFlag && (
          <div className="add-btn">
            <Button type="submit" onClick={handleSubmit}>
              {t('update')}
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default EditDishPopupForWeekView;

const UploadfileSection = ({ fileValue, setFileValue, t }) => {
  const [preview, setPreview] = useState(null);
  const handleuploadImage = (value) => {
    setFileValue(value);

    const ImageUrl = window.URL.createObjectURL(value[0]);
    setPreview(ImageUrl);
  };

  const removeFile = (index, e) => {
    e.preventDefault();
    let dd = null;
    setFileValue(dd);
  };

  return (
    <div className={`dropzone dropzone--single`}>
      <Dropzone
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
        name={name}
        multiple={false}
        onDrop={(fileToUpload) => {
          handleuploadImage(fileToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone__input">
            {(!fileValue || fileValue == 'null' || fileValue.length === 0) && (
              <div className="dropzone__drop-here">
                <span>{t('dropImage')}</span>
                <span className="lnr lnr-upload" />
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      {fileValue === null || fileValue === undefined ? (
        <>
          {fileValue && fileValue.length ? (
            <aside className="dropzone__img">
              <img src={defaultImage} alt="drop-img" />
              <p className="dropzone__img-name">{fileValue[0]?.name}</p>
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(0, e)}
              >
                {t('remove')}
              </button>
            </aside>
          ) : (
            <aside className="dropzone__img">
              <img src={defaultImage} alt="drop-img" />
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(0, e)}
              >
                {t('remove')}
              </button>
            </aside>
          )}
        </>
      ) : (
        <>
          {fileValue && fileValue.length ? (
            <aside className="dropzone__img">
              <img
                src={
                  preview
                    ? preview
                    : fileValue == 'null'
                      ? defaultImage
                      : fileValue
                }
                alt="drop-img"
              />
              <p className="dropzone__img-name">{fileValue[0].name}</p>
              <button
                className="dropzone__img-delete"
                type="button"
                onClick={(e) => removeFile(0, e)}
              >
                {t('remove')}
              </button>
            </aside>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};
