import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewEmployeeDetails = ({ open, handleClose, details }) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('employeeManagement')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            {/* <div className="back">
              <img src={back} alt="back" onClick={handleClose} />
            </div> */}
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('employeeName')}:</h6>
                  <p>
                    {details?.['Employee name']
                      ? details?.['Employee name']
                      : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('employeeNo')}:</h6>
                  <p>{details?.Email ? details.Email : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('cardNo')}:</h6>
                  <p>
                    {details?.['Card Number'] ? details?.['Card Number'] : '--'}
                  </p>
                </div>

                <div className="user-details-block">
                  <h6>{t('status')}:</h6>
                  <p>{details?.Status ? details.Status : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('registrationDate')}:</h6>
                  <p>
                    {details?.['Registration Date']
                      ? details?.['Registration Date']
                      : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('kitchensPH')}:</h6>
                  <p>
                    {details?.Kitchens?.length > 0 ? (
                      <>
                        {details?.Kitchens.map((el, i) => (
                          <>{el}</>
                        ))}
                      </>
                    ) : (
                      '--'
                    )}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('phone')}:</h6>
                  <p>{details?.phone ? details.phone : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6> {t('Credit Card')}:</h6>
                  <p>
                    <input type="checkbox" checked={details?.creditCard} />
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('paymentMethod')}:</h6>
                  <p>{details?.paymentMethod ? details.paymentMethod : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('reservations')}:</h6>
                  <p>{details?.totalReservation}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <i class="las la-pen" title="edit"></i>
                      <i class="las la-trash" title="delete"></i>
                      <i class="las la-share-square" title="Resend"></i>
                      <i class="las la-link" title="copy url"></i>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ViewEmployeeDetails;
