import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';
import CheckBoxField from '../InputFields/CheckBox';
// Validators
import {
  isPhoneNumber,
  normalizePhone,
  required,
  isAlpha,
  email,
  passwordValidator,
  isNumber,
  passwordsMatch,
} from '../../services/validators';
import StyleButton from '../StyleButton';
import FieldWrapper from '../InputFields/FieldWrapper';
import ArrowIcon from '../../assets/images/left-arrow.svg';

import WECheckbox from '../Forms/WE-CheckBox.jsx';
import { ActiveButton } from '../WE-Button';

let ResetPasswordForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, isReset, error, invalid, type } = props;

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="logo">
        <h6>{t('welcomeText')}</h6>
      </div>
      {/* <div className="reset-description">
              <Description t={t} type={type} />
            </div> */}

      <div className="login-heading">
        <h2>{t('resetPassword')}</h2>
        <Description t={t} type={type} />
      </div>
      <div className="inputs-wrapper">
        <div className="password-info">
          <Field
            name={`newPassword`}
            containerClass="password"
            type="password"
            component={InputField}
            validate={[passwordsMatch, required]}
            placeholder={t('passwordPH')}
            icon="padlock"
          />
          <h6>{t('passwordSecurity')}</h6>
        </div>

        <Field
          name={`newPasswordConfirm`}
          containerClass="password"
          type="password"
          component={InputField}
          validate={[required]}
          placeholder={t('repeatPasswordPH')}
          icon="padlock"
        />
        <div className="new-design-btn">
          <ActiveButton
            type={'submit'}
            label={isReset ? t('updatePassword') : t('confirm')}
            additionalClassName={'btn'}
          />
        </div>
      </div>

      {error && <div className="form-error">{error}</div>}
    </form>
  );
};
const Description = ({ t, type }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
      fontSize: '20px',
    }}
    dangerouslySetInnerHTML={{ __html: t(`${type}PasswordDescription`) }}
  />
);

export default ResetPasswordForm = reduxForm({
  form: 'resetPassword',
  initialValues: {},
})(ResetPasswordForm);
