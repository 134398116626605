import React, { useEffect, useState } from 'react';
import { Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { StaticStars } from '../../components/RatingStars';
import DishImage from '../../screens/client/DishImage';
import TableBtn from '../Newfolder/TableBtn';
import http from '../../services/http';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ViewDishDetail = ({
  open,
  handleClose,
  details,
  handleEditdish,
  handleDeleteClick,
  user,
  DishCategories,
  onlyHistory,
}) => {
  const { t, i18n } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [historyData, setHistoryData] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await http.get('audit/getByParams', {
        filter: {
          entity: 'dish',
          actionEntityId: details.dishId,
        },
      });
      setHistoryData(data.data);
    };
    if (open) {
      fetchData();
    }
  }, [open]);

  return (
    <Modal
      show={open}
      onHide={() => {
        handleClose();
        setTabIndex(0);
      }}
      centered
      className="view-more-popup view-employee-popup view-dish-details mw-75vw"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('dishManagement')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          {onlyHistory ? (
            <>
              {' '}
              {historyData && historyData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className="p-2"
                          width={125}
                        >
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className="p-2"
                          width={125}
                        >
                          <strong>{t('action')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2">
                          <div className="d-flex m-0 w-100">
                            <strong className="history-column-fieldname">{`${t(
                              'fieldName'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'oldValue'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'newValue'
                            )}`}</strong>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyData.map((row) => (
                        <StyledTableRow
                          key={row.name}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('HH:mm:ss')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{row?.madeBy}</p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {t(row.typeAction)}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            {Object.keys(row.diff).length > 0
                              ? Object.entries(row.diff).map((el) => (
                                  <div>
                                    <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                      <p className="audit-details-text pr-3">{`${
                                        t(el[0]) || '--'
                                      }:`}</p>
                                    </StyledTableCell>
                                    <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                      {el[0] !== 'image' ? (
                                        <p className="audit-details-text pr-3">
                                          {el[1] &&
                                          el[1]?.old &&
                                          el[0] === 'tags'
                                            ? el[1]?.old.length === 0
                                              ? '--'
                                              : el[1]?.old.join(', ')
                                            : el[1] &&
                                                el[1]?.old &&
                                                el[0] === 'dishCategoryId'
                                              ? DishCategories.find(
                                                  (elem) =>
                                                    elem.id === el[1]?.old
                                                )?.display
                                              : el[1]?.old ||
                                                  typeof el[1]?.old ===
                                                    'boolean'
                                                ? typeof el[1]?.old ===
                                                  'boolean'
                                                  ? el[1]?.old
                                                    ? t('yes')
                                                    : t('no')
                                                  : el[1]?.old
                                                : '--'}
                                        </p>
                                      ) : row.diff?.image?.old ? (
                                        <img
                                          width="166"
                                          height="150"
                                          src={row.diff?.image?.old ?? ''}
                                          className="pr-3"
                                          alt={'test'}
                                        />
                                      ) : (
                                        <p className="audit-details-text pr-3">
                                          {'--'}
                                        </p>
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                      {el[0] !== 'image' ? (
                                        <p className="audit-details-text">
                                          {el[1] &&
                                          el[1]?.new &&
                                          el[0] === 'tags'
                                            ? el[1]?.new.length === 0
                                              ? '--'
                                              : el[1]?.new.join(', ')
                                            : el[1] &&
                                                el[1]?.new &&
                                                el[0] === 'dishCategoryId'
                                              ? DishCategories.find(
                                                  (elem) =>
                                                    elem.id === el[1]?.new
                                                )?.display
                                              : el[1]?.new ||
                                                  typeof el[1]?.new ===
                                                    'boolean'
                                                ? el[1]?.new
                                                : '--'}
                                        </p>
                                      ) : row.diff?.image?.new ? (
                                        <img
                                          width="166"
                                          height="150"
                                          src={row.diff?.image?.new ?? ''}
                                          className="pr-3"
                                          alt={'test'}
                                        />
                                      ) : (
                                        <p className="audit-details-text pr-3">
                                          {'--'}
                                        </p>
                                      )}
                                    </StyledTableCell>
                                  </div>
                                ))
                              : '--'}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </>
          ) : (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label={t('mainInfo')} {...a11yProps(0)} />

                  <Tab label={t('history')} {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={tabIndex} index={0}>
                <div className="view-more-popup">
                  {/* <div className="back">
              <img src={back} alt="back" onClick={handleClose} />
            </div> */}
                  <div className="user-detail-container">
                    <div className="user-details-row">
                      <div className="user-details-block">
                        <h6>{t('image')}:</h6>
                        <DishImage fileName={details?.dishImage} />
                      </div>
                      <div className="user-details-block">
                        <h6>{t('name')}:</h6>
                        <p>{details?.dishName ? details?.dishName : '--'}</p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('description')}:</h6>
                        <p>
                          {details?.dishDescription
                            ? details?.dishDescription
                            : '--'}
                        </p>
                      </div>
                      <div className="user-details-block">
                        <h6>{t('category')}:</h6>
                        <p>
                          {details?.dishCategory ? details.dishCategory : '--'}
                        </p>
                      </div>

                      <div className="user-details-block">
                        <h6>{t('price')}:</h6>
                        <p>{details?.dishPrice}</p>
                      </div>

                      <div className="user-details-block">
                        <h6>{t('rating')}:</h6>
                        <p>
                          <StaticStars
                            rating={details?.dishRating || -1}
                            numOfRaters={details?.numberOfRaters}
                          />
                        </p>
                      </div>
                      {/* <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <i
                        class="las la-pen"
                        title="edit"
                        onClick={() => handleEditdish(details?.dishId, true)}
                      ></i>
                      <i
                        class="las la-trash"
                        title="delete"
                        onClick={() =>
                          handleDeleteClick(details?.dishId, details?.dishName)
                        }
                      ></i>
                    </div>
                  </p>
                </div> */}
                      {user.roleId !== 9 && user.roleId !== 10 && (
                        <div className="modal-footer-btn">
                          <Button
                            onClick={() =>
                              handleEditdish(details?.dishId, true)
                            }
                          >
                            <i class="las la-pen" title="edit"></i>
                            {t('edit')}
                          </Button>
                          <Button
                            onClick={() =>
                              handleDeleteClick(
                                details?.dishId,
                                details?.dishName
                              )
                            }
                          >
                            <i class="las la-trash" title="delete"></i>
                            {t('delete')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel
                value={tabIndex}
                index={1}
                sx={{ maxWidth: '75vw' }}
              >
                {historyData && historyData.length > 0 ? (
                  <TableContainer
                    component={Paper}
                    className="audit-table-container"
                  >
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className="p-2" width={100}>
                            <strong>{`${t('date')} ${t('time')}`}</strong>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className="p-2"
                            width={100}
                          >
                            <strong>{t('user')}</strong>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className="p-2"
                            width={100}
                          >
                            <strong>{t('action')}</strong>
                          </StyledTableCell>
                          <StyledTableCell className="p-2">
                            <div className="d-flex m-0 w-100">
                              <strong className="history-column-fieldname">{`${t(
                                'fieldName'
                              )}`}</strong>
                              <strong className="history-column-values">{`${t(
                                'oldValue'
                              )}`}</strong>
                              <strong className="history-column-values">{`${t(
                                'newValue'
                              )}`}</strong>
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historyData.map((row) => (
                          <StyledTableRow
                            key={row.name}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell className="p-2">
                              <p className="audit-details-text">
                                {moment(row?.createdAt).format('YYYY-MM-DD')}
                              </p>
                              <p className="audit-details-text">
                                {moment(row?.createdAt).format('HH:mm:ss')}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell align="center" className="p-2">
                              <p className="audit-details-text">
                                {row?.madeBy}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell align="center" className="p-2">
                              <p className="audit-details-text">
                                {t(row.typeAction)}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell align="center" className="p-2">
                              {Object.keys(row.diff).length > 0
                                ? Object.entries(row.diff).map((el) => (
                                    <div>
                                      <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                        <p className="audit-details-text pr-3">{`${
                                          t(el[0]) || '--'
                                        }:`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 ">
                                        {el[0] !== 'image' ? (
                                          <p className="audit-details-text pr-3">
                                            {el[1] &&
                                            el[1]?.old &&
                                            el[0] === 'tags'
                                              ? el[1]?.old.length === 0
                                                ? '--'
                                                : el[1]?.old.join(', ')
                                              : el[1] &&
                                                  el[1]?.old &&
                                                  el[0] === 'dishCategoryId'
                                                ? DishCategories.find(
                                                    (elem) =>
                                                      elem.id === el[1]?.old
                                                  )?.display
                                                : el[1]?.old ||
                                                    typeof el[1]?.old ===
                                                      'boolean'
                                                  ? el[1]?.old
                                                  : '--'}
                                          </p>
                                        ) : row.diff?.image?.old ? (
                                          <img
                                            width="166"
                                            height="150"
                                            src={row.diff?.image?.old ?? ''}
                                            className="pr-3"
                                            alt={'test'}
                                          />
                                        ) : (
                                          <p className="audit-details-text pr-3">
                                            {'--'}
                                          </p>
                                        )}
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0">
                                        {el[0] !== 'image' ? (
                                          <p className="audit-details-text">
                                            {el[1] &&
                                            el[1]?.new &&
                                            el[0] === 'tags'
                                              ? el[1]?.new.length === 0
                                                ? '--'
                                                : el[1]?.new.join(', ')
                                              : el[1] &&
                                                  el[1]?.new &&
                                                  el[0] === 'dishCategoryId'
                                                ? DishCategories.find(
                                                    (elem) =>
                                                      elem.id === el[1]?.new
                                                  )?.display
                                                : el[1]?.new ||
                                                    typeof el[1]?.new ===
                                                      'boolean'
                                                  ? el[1]?.new
                                                  : '--'}
                                          </p>
                                        ) : row.diff?.image?.new ? (
                                          <img
                                            width="166"
                                            height="150"
                                            src={row.diff?.image?.new ?? ''}
                                            className="pr-3"
                                          />
                                        ) : (
                                          <p className="audit-details-text pr-3">
                                            {'--'}
                                          </p>
                                        )}
                                      </StyledTableCell>
                                    </div>
                                  ))
                                : '--'}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                    <Typography className="history-text-styles fs-5">
                      {t('emptyHistoryMess')}
                    </Typography>
                  </div>
                )}
              </CustomTabPanel>
            </>
          )}
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ViewDishDetail;
