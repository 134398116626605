import { connect } from 'react-redux';

// Actions
import { setEnums, toggleLoader, getMealTypes } from '../store/app/actions';
import { getUser } from '../store/user/actions';
import { initLanguages } from '../store/languages/actions';
import { getKitchens } from '../store/kitchens/actions';

// The component we're mapping to
import AppBoot from './AppBoot';

// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  data: state.app.data,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setEnums,
  getUser,
  toggleLoader,
  initLanguages,
  getKitchens,
  getMealTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBoot);
