import action from '../action.js';
import types from './types';
import http from '../../services/http';
// import Storage from '../../services/storage';

export function getKitchens(companyId) {
  return action(async (dispatch) => {
    const kitchenId = localStorage.getItem('kitchen');
    let { data: kitchens } = await http.get('kitchens', {
      filter: { companyId, kitchenStatusId: 1 },
    });
    let storageKitchen = kitchens.find(
      (kitchen) => kitchen?.id === Number(kitchenId)
    );
    const kitchen = storageKitchen ? storageKitchen : kitchens[0];
    dispatch({
      type: types.GET_KITCHENS,
      payload: {
        current: kitchen,
        options: kitchens,
      },
    });
  });
}

export function setKitchen(kitchen, withReload = true) {
  let localStorageKitchen = localStorage.getItem('kitchen');

  let kitchenId = kitchen?.id;
  // const noop = () => ({ type: 'NOOP' });

  if (localStorageKitchen && localStorageKitchen != kitchenId) {
    return action(async (dispatch) => {
      const updateKitchen = await http.put('users/updateLastUsedKitchen', {
        kitchenId: kitchen?.id,
      });
      if (updateKitchen) {
        localStorage.setItem('kitchen', kitchen?.id);
      }
      if (withReload) {
        window.location.reload();
      }
      dispatch({
        type: types.SET_KITCHEN,
        payload: kitchen,
      });
    });
  }
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_KITCHEN,
      payload: kitchen,
    });
  });
}

export function setPaymentPreferences(
  {
    merchantId,
    companyNumber,
    personalHashKey,
    paymentOptionId,
    paymentType,
    terminalNo,
    userName,
    currencyId,
    referenceId,
    posId,
    applicationId,
    authorization,
    companyCard,
    companyId,
    vatFreePrice,
    addOrderUrl,
    paymentUrl,
    cibusRedirectUrl,
    host,
    chargeTerminalId,
  }: {
    merchantId: string;
    companyNumber: string;
    personalHashKey: string;
    paymentOptionId: number;
    paymentType: string;
    terminalNo: string;
    userName: string;
    currencyId: string;
    referenceId: string;
    posId: string;
    applicationId: string;
    authorization: string;
    companyCard: string;
    companyId: string;
    vatFreePrice: string;
    addOrderUrl: string;
    cibusRedirectUrl: string;
    paymentUrl: string;
    host: string;
    webAuth: string;
    oAuth: string;
    chargeTerminalId: string;
  },
  kitchenId
) {
  return action(async () => {
    const { data } = await http.put(`kitchens/${kitchenId}/payments`, {
      merchantId: paymentOptionId === 3 ? merchantId : '',
      chargeTerminalId: paymentOptionId === 3 ? chargeTerminalId : '',
      companyNumber: paymentOptionId === 3 ? companyNumber : '',
      personalHashKey: paymentOptionId === 3 ? personalHashKey : '',
      paymentOptionId: paymentOptionId,
      terminalNo: paymentOptionId === 3 ? terminalNo : '',
      userName: paymentOptionId === 3 ? userName : '',
      paymentType: paymentOptionId === 3 ? paymentType : '',
      currencyId: paymentOptionId === 5 ? currencyId : '',
      referenceId: paymentOptionId === 6 ? referenceId : '',
      posId: paymentOptionId === 6 ? posId : '',
      applicationId: paymentOptionId === 6 ? applicationId : '',
      authorization: paymentOptionId === 6 ? authorization : '',
      companyCard: paymentOptionId === 6 ? companyCard : '',
      companyId: paymentOptionId === 6 ? companyId : '',
      vatFreePrice: paymentOptionId === 6 ? vatFreePrice : '',
      addOrderUrl: paymentOptionId === 6 ? addOrderUrl : '',
      paymentUrl: paymentOptionId === 6 ? paymentUrl : '',
      host: paymentOptionId === 6 ? host : '',
      cibusRedirectUrl: paymentOptionId === 6 ? cibusRedirectUrl : '',
    });
    return data;
  });
}

export function getPaymentPreferences(kitchenId) {
  return action(async (dispatch) => {
    dispatch({ type: types.LOADING_PAYMENT_PREFERENCES });
    const data = await http.get(`kitchens/${kitchenId}/payments`);
    let paymentPreferences = {};
    // if (data?.paymentType === 'hyp') {
    paymentPreferences = {
      selectedPaymentOptions: data?.paymentOptionId || '',
      merchantId: data?.merchantId || '',
      companyNumber: data?.companyNumber || '',
      personalHashKey: data?.personalHashKey || '',
      terminalNo: data?.terminalNo || '',
      userName: data?.userName || '',
      paymentType: data?.paymentType || '',
      currencyId: data?.currencyId || '',
      referenceId: data?.referenceId || '',
      posId: data?.posId || '',
      applicationId: data?.applicationId || '',
      authorization: data?.authorization || '',
      companyCard: data?.companyCard || '',
      companyId: data?.companyId || '',
      vatFreePrice: data?.vatFreePrice || '',
      addOrderUrl: data?.addOrderUrl || '',
      paymentUrl: data?.paymentUrl || '',
      cibusRedirectUrl: data?.cibusRedirectUrl || '',
      host: data?.host || '',
      chargeTerminalId: data.chargeTerminalId || '',
    };
    // } else {
    //   paymentPreferences = {
    //     selectedPaymentOptions: data?.paymentOptionId || '',
    //     merchantId: '',
    //     companyNumber: '',
    //     personalHashKey: '',
    //     terminalNo: '',
    //     userName: '',
    //     paymentType: data?.paymentType || '',
    //     currencyId: data?.currencyId || '',
    //     referenceId: data?.referenceId || '',
    //     posId: data?.posId || '',
    //     applicationId : data?.applicationId || '',
    //     authorization :data?.authorization || '',
    //     companyCard : data?.companyCard||'',
    //     companyId : data?.companyId||''
    //   };
    // }

    dispatch({
      type: types.GET_PAYMENT_PREFERENCES,
      payload: paymentPreferences,
    });
    return paymentPreferences;
  }, true);
}

export function getReservations({
  kitchenId,
  fromDate,
  toDate,
  orderId,
  contractorId,
  userId,
  mealTypeId,
  filterDate,
  fromFile,
  orderStatusId,
  paymentTypeId,
  reservationType,
  currentPageAllLogs,
  newPageSizeLogs,
  searchText,
  field,
  order,
  selectedDishes,
  selectedRoles,
}) {
  let obj = {};
  if (!field || !order) {
    obj = {
      fromDate,
      toDate,
      orderId: orderId ? Number(orderId) : undefined,
      contractorId: contractorId ? Number(contractorId) : undefined,
      userId: userId ? Number(userId) : undefined,
      mealTypeId: mealTypeId ? Number(mealTypeId) : undefined,
      filterDate: filterDate ? filterDate : undefined,
      fromFile: fromFile ? fromFile : undefined,
      orderStatusId: orderStatusId ? orderStatusId : undefined,
      paymentTypeId: paymentTypeId ? paymentTypeId : undefined,
      reservationType: reservationType ? reservationType : undefined,
      page: currentPageAllLogs ? currentPageAllLogs : 1,
      limit: newPageSizeLogs ? newPageSizeLogs : 50,
      findStr: searchText,
      selectedDishes: selectedDishes ? selectedDishes : [],
      selectedRoles: selectedRoles ? selectedRoles : [],
    };
  } else {
    obj = {
      fromDate,
      toDate,
      orderId: orderId ? Number(orderId) : undefined,
      contractorId: contractorId ? Number(contractorId) : undefined,
      userId: userId ? Number(userId) : undefined,
      mealTypeId: mealTypeId ? Number(mealTypeId) : undefined,
      filterDate: filterDate ? filterDate : undefined,
      fromFile: fromFile ? fromFile : undefined,
      orderStatusId: orderStatusId ? orderStatusId : undefined,
      paymentTypeId: paymentTypeId ? paymentTypeId : undefined,
      reservationType: reservationType ? reservationType : undefined,
      page: currentPageAllLogs ? currentPageAllLogs : 1,
      limit: newPageSizeLogs ? newPageSizeLogs : 50,
      findStr: searchText,
      orderField: field,
      orderTarget: order,
      selectedDishes: selectedDishes,
      selectedRoles: selectedRoles,
    };
  }
  return action(async (dispatch) => {
    const data = await http.get(`kitchens/${kitchenId}/reservations`, obj);
    dispatch({
      type: types.GET_RESERVATIONS,
      payload: data,
    });
  }, true);
}

export function getReportTypeReservations(
  kitchenId: any,
  fromDate: any,
  toDate: any,
  page: any,
  limit: any,
  roleId: string,
  findStr: string,
  orderField: string,
  order: string
) {
  const obj: {
    fromDate: any;
    toDate: any;
    page: any;
    limit: any;
    roleId?: string;
    findStr?: string;
    orderField: string;
    orderTarget: string;
    kitchenId: any;
  } = {
    fromDate,
    toDate,
    page: page ? page : 1,
    limit: limit ? limit : 50,
    roleId,
    findStr,
    orderField,
    orderTarget: order,
    kitchenId: kitchenId,
  };
  obj.findStr ? obj : delete obj['findStr'];
  obj.roleId ? obj : delete obj['roleId'];
  obj.kitchenId ? obj : delete obj['kitchenID'];

  return action(async (dispatch) => {
    const data = await http.get(
      `kitchens/${kitchenId}/reportTypeReservations`,
      obj
    );
    dispatch({
      type: types.GET_REPORT_TYPE,
      payload: data,
    });
  }, true);
}

export function getMealTypeReservations(
  kitchenId: any,
  fromDate: any,
  toDate: any,
  currentPageAllLogs: any,
  newPageSizeLogs: any,
  orderField: any,
  order: any
) {
  return action(async (dispatch) => {
    const data = await http.get(
      `kitchens/${kitchenId}/getReportMealTypeReservations`,
      {
        fromDate,
        toDate,
        page: currentPageAllLogs,
        limit: newPageSizeLogs,
        orderField: orderField,
        orderTarget: order,
      }
    );
    dispatch({
      type: types.GET_MEAL_TYPE,
      payload: data,
    });
  }, true);
}

export function getEmployeeLobbyData(kitchenId: any) {
  return action(async (dispatch) => {
    const data = await http.get(`kitchens/${kitchenId}/employeeLobby2`);
    dispatch({
      type: types.GET_EMPLOYEE_LOBBY_DATA,
      payload: data,
    });
  }, true);
}

export function getEmployeeLobbyDataNew(
  kitchenId: any,
  fromDate: any,
  toDate: any,
  page: any,
  limit: any,
  mealTypeId: any,
  orderStatus: any
) {
  const obj: {
    fromDate: any;
    toDate: any;
    page: any;
    limit: any;
    mealTypeId: any;
    orderStatus: any;
  } = {
    fromDate: fromDate ?? new Date(),
    toDate,
    page: page ? page : 1,
    limit: limit ? limit : 5,
    mealTypeId: mealTypeId,
    orderStatus,
  };
  obj.toDate ? obj : delete obj['toDate'];
  return action(async (dispatch) => {
    const data = await http.get(`kitchens/${kitchenId}/getEmployeeLobby`, obj);
    dispatch({
      type: types.GET_EMPLOYEE_LOBBY_DATA_NEW,
      payload: data.response,
    });
  }, true);
}

export function getAttendanceData(
  fromDate: any,
  toDate: any,
  timeShift: any,
  page: any,
  limit: any,
  search: string,
  orderField: string,
  order: string,
  userId: string
) {
  const obj: {
    fromDate: any;
    toDate: any;
    timeShift?: string;
    page: any;
    limit: any;
    search?: string;
    orderField: string;
    orderTarget: string;
    userId: string;
  } = {
    fromDate,
    toDate,
    page: page ? page : 1,
    limit: limit ? limit : 50,
    timeShift,
    search,
    orderField,
    orderTarget: order,
    userId,
  };

  return action(async (dispatch) => {
    const data = await http.get(`users/attendanceReport`, obj);
    dispatch({
      type: types.GET_ATTENDANCE,
      payload: data,
    });
  }, true);
}
