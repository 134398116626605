import React from 'react';
import back from '../assets/icons/backBtn.svg';
import i18n from 'i18next';

const WrapperSizes = {
  xSmall: { height: 15, width: 15 },
  small: { height: 30, width: 30 },
  medium: { height: 40, width: 40 },
  large: { height: 50, width: 50 },
};

const BackButton = ({
  icon,
  text,
  textColor = 'black',
  backgroundColor = 'transparent',
  iconSize = 'medium',
  isDisabled = false,
  additionalClassname = '',
  additionalWrapperClassname = '',
  additionalTextClassname = '',
  cb = () => {},
}) => {
  return (
    <div
      className={`back-link-section ${additionalWrapperClassname} ${
        isDisabled ? 'disabled' : ''
      }`}
      onClick={isDisabled ? () => {} : cb}
    >
      {/* <i className="las la-arrow-left" /> */}
      <img src={back} alt="back btn" />
      <p className={`back-link-text`}>{text}</p>
    </div>
  );
};

export default BackButton;
