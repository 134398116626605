import React, { useState, useEffect } from 'react';
import { getAssetUrl } from '../../services/aws';
import defaultImage from '../../assets/images/defaultImage.png';
import ChefMealDish from '../../assets/icons/chef_mealDish.png';

export default function DishImage({
  fileName,
  isChefMealDish = false,
  size = { height: '100px', width: '150px' },
}) {
  const [dishImg, setDishImg] = useState('');

  useEffect(() => {
    const fetchData1 = async () => {
      fileName = fileName?.split(' ').join('%20');
      fileName?.startsWith('http')
        ? setDishImg(fileName)
        : setDishImg(await getAssetUrl(fileName));
    };
    fetchData1();
  }, []);

  return (
    <div
      className="dish-img"
      style={{
        backgroundImage: `url(${
          dishImg.includes(null)
            ? defaultImage
            : // : dishImg.includes('/h')
              // ? defaultImage
              dishImg
              ? dishImg.includes('undefined')
                ? defaultImage
                : // : dishImg.includes('/h')
                  // ? defaultImage
                  dishImg
              : defaultImage
        })`,
        ...size,
      }}
    >
      {isChefMealDish && (
        <img className={'chef-mealDish'} alt="" src={ChefMealDish} />
      )}
    </div>
  );
}
