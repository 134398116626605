import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Layout from './Layout';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const { lang: language } = useParams();

  return (
    <Layout>
      <Container>
        {/* Hebrew text */}
        {language === 'he' ? (
          <div className="privacy-policy hebrew-text">
            <h2>עודכן לאחרונה: 11 בינואר, 2023</h2>
            <h6>אודות וורק איט</h6>
            <ul>
              <li>
                וורק איט היא פלטפורמה אינטרנטית, המאפשרת למשתמשים הזמנת פריטי
                מזון במטבח ו/או חדר אוכל ו/או מתקן אחר של החברה המארחת, מימוש
                ההזמנה במטבח והוספת חוות דעת על המנה ועל המטבח.
              </li>
            </ul>
            <h6>מי אנחנו?</h6>
            <ul>
              <li>
                וורק איט בע"מ (חברה בהקמה, להלן: "החברה") היא המפתחת והמפעילה של
                פלטפורמת וורק איט (workeat.co.il), לרבות האתר ושאר השירותים
                המסופקים על-ידה (כולם ביחד – "הפלטפורמה").
              </li>
            </ul>
            <h6>מה עניינה של מדיניות הפרטיות הזו?</h6>
            <ul>
              <li>
                יצרנו את מדיניות הפרטיות הזו כי אנחנו מעריכים מאוד את המידע
                האישי שלך; אנא קרא אותה בזהירות, שכן היא כוללת מידע חשוב בנוגע
                לכך.
              </li>
              <li>
                אנו פועלים כמיטב יכולתו להגנת פרטיותם של המשתמשים, בהתאם לחוקי
                ותקנות הגנת הפרטיות. מוזמנים לפנות אלינו בכל שאלה בנוגע למדיניות
                הפרטיות, לכתובת הדואר האלקטרוני
                <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
              </li>
            </ul>
            <h6>הסכמה</h6>
            <h6>
              כל שימוש שאעשה (או שארשה לאחרים לעשות בשמי) בפלטפורמה, מהווה את
              הסכמתי לתנאי מדיניות הפרטיות הזו, לרבות – איסוף, שימוש, גילוי,
              העברה או אחסון – של כל מידע אישי או פרטי המתקבל על ידנו בכפוף
              לתנאי מדיניות פרטיות זו.
            </h6>
            <h6>איזה מידע אנחנו אוספים?</h6>
            <ul>
              <li>
                <span>1.</span> מידע אישי:
              </li>
              <ul>
                <li>
                  <span>a.</span>שם פרטי ושם משפחה
                </li>
                <li>
                  <span>b.</span>מספר עובד פנימי (לעובדי החברה בלבד)
                </li>
                <li>
                  <span>c.</span>כתובת דואר אלקטרוני (אופציונלי)
                </li>
                <li>
                  <span>d.</span>מספר טלפון (אופציונלי)
                </li>
              </ul>
              <li>
                <span>2.</span> מידע לא-אישי:{' '}
              </li>
              <ul>
                <li>
                  <span>a.</span>אופן השימוש בפלטפורמה{' '}
                </li>
              </ul>
            </ul>
            <p>
              יתכן ונימנע מאיסוף אחד או יותר מהפריטים שלעיל (הן מידע אישי והן
              מידע לא-אישי) בארגונך. אם ברצונך לדעת במדויק אילו פרטים נאספו, הנך
              מוזמן לפנות ל-
              <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
            </p>
            <h6>מה נעשה עם המידע שאנחנו אוספים?</h6>
            <ul>
              <li>
                <span>1.</span> מידע אישי - כל מידע אישי שנאסוף, מטופל בהתאם
                להוראות מדיניות הפרטיות, ויכול לשמש ל:
              </li>
              <ul>
                <li>
                  <span>a.</span>גישה ושימוש – כל מידע אישי שתמסור לנו יכול לשמש
                  אותנו בכדי לספק לך גישה לפלטפורמה או שימושיות בה, ולניטור
                  השימוש שלך באותם השירותים.
                </li>
                <li>
                  <span>b.</span>מטרות עסקיות של החברה – כל מידע אישי שתמסור
                  לנו, יכול לשמש אותנו בכדי לשפר את השימושיות של השירותים שאנחנו
                  מספקים, להבנה טובה יותר של המשתמשים, לזיהוי ומניעה של שימושים
                  לא נאותים ולהגנה מפניהם, לאכיפת תנאי השימוש שלנו, ולניהול
                  הכללי והשוטף של הפלטפורמה.
                </li>
                <li>
                  <span>c.</span>מטרות מסוימות – כל מידע אישי שתמסור לנו, למטרה
                  מסוימת, יכול לשמש אותנו בקשר למימוש אותה מטרה.
                </li>
                <li>
                  <span>d.</span>סטטיסטיקה – כל מידע אישי שתמסור לנו להכנת דוחות
                  המכילים מידע וניתוחים סטטיסטיים.
                </li>
              </ul>
              <li>
                {' '}
                <span>2.</span>מידע לא-אישי - היות ומידע לא-אישי לא יכול לזהות
                אותך באופן ישיר, אנחנו שומרים את הזכות לעשות כל שימוש אפשרי על
                פי חוק, במידע לא-אישי.
              </li>
            </ul>
            <h6>איזה מידע נחלוק עם צדדים שלישיים?</h6>
            <ul>
              <li>
                <span>1.</span>
                מחויבות על-פי חוק – בכל מקרה בו נאמין, בתום לב, שגילוי מידע יהיה
                הכרחי על-פי חוק, לרבות אך לא רק, במקרה של צווים של גופים
                ממשלתיים, בתי משפט או רשויות רשמיות אחרות, בכל סמכות שיפוט שהיא.
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  מחויבות על-פי חוק – בכל מקרה בו נאמין, בתום לב, שגילוי מידע
                  יהיה הכרחי על-פי חוק, לרבות אך לא רק, במקרה של צווים של גופים
                  ממשלתיים, בתי משפט או רשויות רשמיות אחרות, בכל סמכות שיפוט
                  שהיא.
                </li>
                <li>
                  <span>b.</span>
                  מניעת עוולה או עבירה – בכל בו נאמין, בתום לב, שגילוי מידע יהיה
                  הכרחי לשם מניעת עבירה פלילית, נזק או הונאה.
                </li>
                <li>
                  <span>c.</span>
                  מטרות עסקיות – ככל שנפתח את עסקינו, ייתכן שנרכוש או נמכור
                  עסקים ונכסים. במקרה של מכירה, מיזוג, ארגון מחדש, פירוק או כל
                  אירוע דומה, מידע אישי יכול להיות כלול במסגרת הנכסים המועברים.
                  אתה מודע ומסכים כי כל מי שירכוש את החברה או הפלטפורמה, או
                  ייכנס בנעלי החברה (או נכסיה), ימשיך להחזיק באותן זכויות בקשר
                  למידע אישי ופרטיות, בהתאם לתנאי מדיניות הפרטיות.
                </li>
                <li>
                  <span>d.</span>
                  סטטיסטיקה – אנו נהיה רשאים להשתמש במידע אישי שתמסור לנו, לשם
                  הכנת דוחות המכילים מידע וניתוחים סטטיסטיים, אותם נוכל למסור
                  לצורך קידום הפלטפורמה.
                </li>
              </ul>
              <li>
                <span>2.</span>
                מידע לא-אישי - היות ומידע לא-אישי לא יכול לזהות אותך באופן ישיר,
                אנחנו שומרים את הזכות לעשות כל שימוש אפשרי על פי חוק, במידע
                לא-אישי.
              </li>
            </ul>
            <h6>קישורים לאתרים אחרים</h6>
            <p>
              הפלטפורמה עשויה להכיל קישורים לאתרי אינטרנט ו/או שירותים של צדדים
              שלישיים. אנחנו לא נהיה אחראים על מדיניות הפרטיות באותם אתרים,
              וממליצים לך לבדוק בעצמך את אותה מדיניות פרטיות, בכל מקרה ובכל אתר
              או שירות.
            </p>
            <h6>אחסון ואבטחת מידע</h6>
            <p>
              אנחנו מחויבים מאוד להגנה על המידע האישי והפרטיות שלך, ועושים כל
              פעולה טכנית סבירה, שמקובלת בתעשייה, לשמירה על בטחון המידע האישי
              והפרטיות שלך, ולהגנה עליהם מפני אובדן, שינוי או שימוש לא ראוי. עם
              זאת, אם תבחין בפירצה כלשהי באבטחת המידע, אנא הודע לנו מידית לכתובת
              הדואר האלקטרוני info@workeat.co.il על מנת שנוכל לבחון את העניין
              ולטפל בו, מוקדם ככל שניתן.
            </p>
            <h6>תנאים אחרים</h6>
            <ul>
              <li>
                <span>1.</span>שינוי תנאים
              </li>
              <ul>
                <li>
                  <span>a.</span>הפלטפורמה ועסקינו עשויים להשתנות מעת לעת.
                  לפיכך, מפעם לפעם יהיה צורך לשנות את מדיניות הפרטיות הזו. אנו
                  שומרים על הזכות, על פי שיקול דעתנו, לעדכן או לשנות את מדיניות
                  פרטיות זו, בכל עת (להלן: "השינויים"). אנו נודיע על השינויים
                  למדיניות הפרטיות באמצעות הכותרת "עודכן לאחרונה" המופיעה בראש
                  מדיניות פרטיות זו. באחריותך להיכנס לדף זה, מעת לעת, בכדי לברר
                  האם בוצעו שינויים במדיניות הפרטיות, בייחוד לפני מתן מידע אישי.
                  מועד העדכון האחרון של מדיניות הפרטיות מופיע בראש העמוד. המשך
                  השימוש בשירותים מהווה הסכמה מתמשכת לכל שינוי במדיניות פרטיות,
                  כפי שיתבצע מעת לעת. אם שינוי כלשהו במדיניות הפרטיות לא יהיה
                  לרוחך, הסעד היחיד העומד לרשותך יהיה הפסקת השימוש בשירותים או
                  הגלישה באתר
                </li>
              </ul>
              <li>
                <span>2.</span>דיוור ישיר
              </li>
              <ul>
                <li>
                  <span>a.</span>ייתכן והחברה תהיה מעוניינת לשלוח אליך מדי פעם
                  באמצעות מסרונים או דואר אלקטרוני, מידע בדבר שירותיה וכן מידע
                  שיווקי ופרסומי.
                </li>
                <li>
                  <span>b.</span>השימוש בשירותים או בחלקם, לרבות מסירת מספר
                  הטלפון או כתובת הדואר האלקטרוני שלך, מהווים הסכמה מפורשת
                  למשלוח הודעות כאמור. עם זאת, בכל עת ניתן לבטל את הסכמתך על-ידי
                  הודעה מתאימה לכתובת הדואר האלקטרוני
                  <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
                </li>
                <li>
                  <span>c.</span>על אף האמור, אנו נהיה רשאים לשלוח הודעות ישירות
                  אשר למיטב אמונתנו הנן קריטיות לצורך שימושך בפלטפורמה, גם אחרי
                  בחירתך להסיר את הסכמתך.
                </li>
              </ul>
              <li>
                <span>3.</span>עוגיות (Cookies)
              </li>
              <ul>
                <li>
                  <span>a.</span>אנו עשויים להשתמש בקבצי Cookie לשם הבטחת חוויית
                  משתמש טובה יותר, ושימוש מתמשך ונאות של הפלטפורמה או אתר
                  האינטרנט שלנו, לרבות איסוף סטטיסטיקות, אימות, עדכונים וכו'.
                </li>
                <li>
                  <span>b.</span>דפדפני אינטרנט מודרניים מאפשרים לך להימנע
                  משימוש בקבצי עוגיות. אם אינך יודע איך לעשות זאת, בדוק את תפריט
                  ה"עזרה" של הדפדפן שלך.
                </li>
              </ul>
              <li>
                <span>4.</span>זכות למחיקה, תיקון והעברה - עומדת לרשותך הזכות
                לדרוש כי המידע האישי שלך יוסר, יתוקן או יועבר אליך, ולפנות
                בבקשות נוספות בקשר עם המידע האישי שלך, באמצעות משלוח דואר
                אלקטרוני מפורט ל: info@workeat.co.il. יחד עם זאת, במטרה לשמור על
                תקינות המידע המצוי בידי החברה המארחת, ישמר אופן השימוש במערכת,
                אך ללא פרטים מזהים שלך.
              </li>
              <li>
                <span>5.</span>ברירת דין וסמכות שיפוט - הדין שיחול על מדיניות
                הפרטיות יהיה הדין הישראלי. סמכות השיפוט הייחודית והבלעדית בכל
                הקשור לנושאים ולעניינים הנובעים או הקשורים למדיניות הפרטיות, תהא
                אך ורק לבית המשפט המוסמך שמקום מושבו בתל-אביב, ישראל.
              </li>
            </ul>
          </div>
        ) : language === 'en' || language === 'ru' ? (
          <div className="privacy-policy">
            <h2>Recently Updated: January 11th, 2023</h2>
            <h6>About WorkEat </h6>
            <p>
              <span>1.1</span>
              WorkEat is an internet platform, which allows its Users to order
              food items in a kitchen and/or dining room and/or in another
              facility of a Hosting Company, following preparation and service
              to the user, the user will be able to add, using the platform, a
              review about the dish that was served and the kitchen in which it
              was prepared.
            </p>
            <h6>About WorkEat Ltd.</h6>
            <p>
              WorkEat Ltd. (a company to be incorporated, hereinafter: "the
              Company") is the developer and operator of the WorkEat platform
              (workeat.co.il), including the website and the rest of the
              services that are provided by it (which will all be called
              collectively- "the Platform").
            </p>
            <h6>What is the Purpose of this Privacy Policy?</h6>
            <p>
              We created this privacy policy because we highly value your
              personal information; please read this policy carefully because it
              includes important information regarding your private information
            </p>
            <p>
              We act to the best of our ability to protect the Users privacy in
              accordance with the laws and regulations of the protection of
              privacy.
            </p>
            <p>
              We invite you to address us with any questions regarding the
              privacy policy at the e-mail address: info@workeat.co.il
            </p>
            <h6>Consent</h6>
            <h6>
              By using the WorkEat Platform or allowing someone else to use it
              on your behalf, you give your consent to our collection, use,
              disclosure, transfer and storage of any personal data and
              information received by us as a result of such use, in accordance
              with this Privacy Policy. Which Information do we collect?
            </h6>
            <ul>
              <li>
                <span>1.</span> Personal Data
              </li>
              <ul>
                <li>
                  <span>a.</span> Full Name
                </li>
                <li>
                  <span>b.</span> Internal Number of an Employee (only for
                  employees of the Company)
                </li>
                <li>
                  <span>c.</span> Email Address (optional)
                </li>
                <li>
                  <span>d.</span> Phone Number (optional)
                </li>
              </ul>
              <li>
                <span>2.</span> Non-personal Data
              </li>
              <ul>
                <li>
                  {' '}
                  <span>a.</span>
                  How you use the Platform
                </li>
                <li>
                  We may avoid collecting one or more of the items above (both
                  personal and non-personal data) in your organization. If you
                  wish to know which items were exactly collected, you may
                  address info@workeat.co.il
                </li>
              </ul>
            </ul>
            <h6>What will we do with the Information we gather?</h6>
            <ul>
              <li>
                <span>1.</span>
                Personal Data - All the personal data we collect is treated in
                accordance with the instructions of this Privacy Policy, and it
                can serve for:
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  Access and Use – all the personal data you will provide us can
                  serve us for providing you with access to the platform or to
                  its usability, and for monitoring your use of those services.
                </li>
                <li>
                  <span>b.</span>
                  The Companys Business Purposes - all the personal data you
                  will give to us can serve us to improve the usability of the
                  services we provide, to better understand our Users, to
                  identify and prevent improper usages and protect from them, to
                  enforce our terms of use, and for the general and regular
                  management of the Platform.
                </li>
                <li>
                  <span>c.</span>
                  Certain Objectives - every piece of information you will give
                  us for a certain objective can serve us with respect to
                  exercising that objective.
                </li>
                <li>
                  <span>d.</span>
                  Statistics – every personal information you will give us may
                  serve for the preparation of non-identifiable reports that
                  contain statistical data and statistical analyses
                </li>
              </ul>
              <li>
                <span>2.</span>
                Non-personal Data <br />
              </li>
              <li>
                Since non-personal data cannot directly identify you, we
                maintain the right to use it for any usage that is permitted by
                law.
              </li>
            </ul>
            <h6>Which Information do we share with Third Parties?</h6>
            <ul>
              <li>
                <span>1.</span>
                Personal Data - However, naturally there are circumstances in
                which we may disclose, share or transfer your Personal Data,
                without a further notice, as follows:
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  Required by Law – If we believe in good faith that disclosure
                  is required by law, including but not limited to, orders by
                  any governmental entity, court or any other judicial entity,
                  in any jurisdiction.
                </li>
                <li>
                  <span>b.</span>
                  To Prevent Wrongdoing – If we believe in good faith that
                  disclosure is required in order to prevent any kind of illegal
                  activity, fraud or civil wrong.
                </li>
                <li>
                  <span>c.</span>
                  Business Purposes - As we develop our business, we might sell
                  or buy businesses or assets. In the event of a corporate sale,
                  merger, reorganization, dissolution or similar event, Personal
                  Data may be part of the transferred assets. You acknowledge
                  and agree that any successor to or acquirer of WorkEat (or its
                  assets) will continue to have the right to use your Personal
                  Data and information in accordance with the terms of this
                  Privacy Policy.
                </li>
                <li>
                  <span>d.</span>
                  Statistics- We may disclose Personal Data for purpose of
                  production of statistic reports containing aggregated
                  information, which would be transferred to third parties in
                  order to promote the Platform..
                </li>
              </ul>

              <li>
                <span>2.</span>
                Non-Personal Data - Since Non-Personal Data cannot be used to
                identify you in person, we may disclose such data in any way
                permitted by law.
              </li>
            </ul>
            <h6>Links to Other Sites</h6>
            <p>
              The Platform may contain links to other websites and/or
              third-party services. We are not responsible for the privacy
              policies of such websites and/or services, and we advise you to
              review their privacy policies.
            </p>
            <h6>Storage and Security</h6>
            <p>
              We are strongly committed to the protection of your Personal Data
              and information, and we will take reasonable technical steps,
              which are accepted in our industry, to keep your Information
              secured and protect it from loss, misuse or alteration. However,
              if you notice a risk or any security violations, we advise you to
              report to us on
              <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>, so we
              can resolve it as soon as possible.
            </p>
            <h6>Other Terms</h6>

            <ul>
              <li>
                <span>1.</span>
                Changes to This Privacy Policy
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  The Platform and our business may change from time to time. As
                  a result, at times it may be necessary for us to make changes
                  to this Privacy Policy. We reserve the right, in our sole
                  discretion, to update or modify this Privacy Policy at any
                  time (collectively, "Modifications"). Modifications to this
                  Privacy Policy will be displayed by the "Last Updated" date at
                  the top of this Privacy Policy. Please review this Privacy
                  Policy periodically, and especially before you provide any
                  Personal Data or information. This Privacy Policy was last
                  updated on the date indicated above. Your continued use of the
                  Services following the effectiveness of any Modifications to
                  this Privacy Policy constitutes acceptance of those
                  Modifications. If any Modification to this Privacy Policy is
                  not acceptable to you, your sole remedy is to cease accessing,
                  browsing and otherwise using the Platform.
                </li>
              </ul>
              <li>
                <span>2.</span>
                Direct Messages
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  The Company may send you, from time to time, information
                  regarding its services, including advertisements, via short
                  messages or emails.
                </li>
                <li>
                  <span>b.</span>
                  By using the Platform, including providing your phone number
                  or email address, you explicitly consent to receive such
                  messages. However, at any time you may inform us of your
                  removing such consent, by sending a proper email to
                  <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
                </li>
                <li>
                  <span>c.</span>
                  We may, however, send direct messages we believe are critical
                  for your use of the platform, even following your choice to
                  unsubscribe.
                </li>
              </ul>
              <li>
                <span>3.</span>
                Cookies
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  We may use Cookies for the proper and continuous operation of
                  the Platform or website, including a collection of statistics,
                  verifications, modifications etc.
                </li>
                <li>
                  <span>b.</span>
                  Modern browsers allow you to avoid such use of Cookies. If you
                  don't know how to do that, check the help files of your
                  browser.
                </li>
              </ul>
              <li>
                <span>4.</span>
                Rights of Affected Person
              </li>
              <ul>
                <li>
                  You shall have the right to demand that your Personal Data be
                  removed, corrected, transferred to you and deliver further
                  requests to us regarding your Personal Data, by sending proper
                  email to
                  <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>.
                  However, in order to maintain that the information the Hosting
                  Company has will be in a proper condition, documentation
                  regarding your use the system will be preserved without your
                  identifying details.
                </li>
              </ul>
              <li>
                <span>5.</span>
                Dispute Resolution
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  The laws of the State of Israel shall govern this Privacy
                  Policy. Any dispute which may arise in connection with this
                  Privacy Policy shall be brought to the competent court in the
                  Tel-Aviv district, Israel, which shall be granted exclusive
                  jurisdiction.
                </li>
              </ul>
            </ul>
          </div>
        ) : (
          ''
        )}

        {/* russian content */}
        {/* { && (
          <div className="privacy-policy">
            <h2>Recently Updated: January 11th, 2023</h2>
            <h6>About WorkEat </h6>
            <p>
              <span>1.1</span>
              WorkEat is an internet platform, which allows its Users to order
              food items in a kitchen and/or dining room and/or in another
              facility of a Hosting Company, following preparation and service
              to the user, the user will be able to add, using the platform, a
              review about the dish that was served and the kitchen in which it
              was prepared.
            </p>
            <h6>About WorkEat Ltd.</h6>
            <p>
              WorkEat Ltd. (a company to be incorporated, hereinafter: "the
              Company") is the developer and operator of the WorkEat platform
              (workeat.co.il), including the website and the rest of the
              services that are provided by it (which will all be called
              collectively- "the Platform").
            </p>
            <h6>What is the Purpose of this Privacy Policy?</h6>
            <p>
              We created this privacy policy because we highly value your
              personal information; please read this policy carefully because it
              includes important information regarding your private information
            </p>
            <p>
              We act to the best of our ability to protect the Users privacy in
              accordance with the laws and regulations of the protection of
              privacy.
            </p>
            <p>
              We invite you to address us with any questions regarding the
              privacy policy at the e-mail address: info@workeat.co.il
            </p>
            <h6>Consent</h6>
            <h6>
              By using the WorkEat Platform or allowing someone else to use it
              on your behalf, you give your consent to our collection, use,
              disclosure, transfer and storage of any personal data and
              information received by us as a result of such use, in accordance
              with this Privacy Policy. Which Information do we collect?
            </h6>
            <ul>
              <li>
                <span>1.</span> Personal Data
              </li>
              <ul>
                <li>
                  <span>a.</span> Full Name
                </li>
                <li>
                  <span>b.</span> Internal Number of an Employee (only for
                  employees of the Company)
                </li>
                <li>
                  <span>c.</span> Email Address (optional)
                </li>
                <li>
                  <span>d.</span> Phone Number (optional)
                </li>
              </ul>
              <li>
                <span>2.</span> Non-personal Data
              </li>
              <ul>
                <li>
                  {' '}
                  <span>a.</span>
                  How you use the Platform
                </li>
                <li>
                  We may avoid collecting one or more of the items above (both
                  personal and non-personal data) in your organization. If you
                  wish to know which items were exactly collected, you may
                  address info@workeat.co.il
                </li>
              </ul>
            </ul>
            <h6>What will we do with the Information we gather?</h6>
            <ul>
              <li>
                <span>1.</span>
                Personal Data - All the personal data we collect is treated in
                accordance with the instructions of this Privacy Policy, and it
                can serve for:
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  Access and Use – all the personal data you will provide us can
                  serve us for providing you with access to the platform or to
                  its usability, and for monitoring your use of those services.
                </li>
                <li>
                  <span>b.</span>
                  The Companys Business Purposes - all the personal data you
                  will give to us can serve us to improve the usability of the
                  services we provide, to better understand our Users, to
                  identify and prevent improper usages and protect from them, to
                  enforce our terms of use, and for the general and regular
                  management of the Platform.
                </li>
                <li>
                  <span>c.</span>
                  Certain Objectives - every piece of information you will give
                  us for a certain objective can serve us with respect to
                  exercising that objective.
                </li>
                <li>
                  <span>d.</span>
                  Statistics – every personal information you will give us may
                  serve for the preparation of non-identifiable reports that
                  contain statistical data and statistical analyses
                </li>
              </ul>
              <li>
                <span>2.</span>
                Non-personal Data <br />
              </li>
              <li>
                Since non-personal data cannot directly identify you, we
                maintain the right to use it for any usage that is permitted by
                law.
              </li>
            </ul>
            <h6>Which Information do we share with Third Parties?</h6>
            <ul>
              <li>
                <span>1.</span>
                Personal Data - However, naturally there are circumstances in
                which we may disclose, share or transfer your Personal Data,
                without a further notice, as follows:
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  Required by Law – If we believe in good faith that disclosure
                  is required by law, including but not limited to, orders by
                  any governmental entity, court or any other judicial entity,
                  in any jurisdiction.
                </li>
                <li>
                  <span>b.</span>
                  To Prevent Wrongdoing – If we believe in good faith that
                  disclosure is required in order to prevent any kind of illegal
                  activity, fraud or civil wrong.
                </li>
                <li>
                  <span>c.</span>
                  Business Purposes - As we develop our business, we might sell
                  or buy businesses or assets. In the event of a corporate sale,
                  merger, reorganization, dissolution or similar event, Personal
                  Data may be part of the transferred assets. You acknowledge
                  and agree that any successor to or acquirer of WorkEat (or its
                  assets) will continue to have the right to use your Personal
                  Data and information in accordance with the terms of this
                  Privacy Policy.
                </li>
                <li>
                  <span>d.</span>
                  Statistics- We may disclose Personal Data for purpose of
                  production of statistic reports containing aggregated
                  information, which would be transferred to third parties in
                  order to promote the Platform..
                </li>
              </ul>

              <li>
                <span>2.</span>
                Non-Personal Data - Since Non-Personal Data cannot be used to
                identify you in person, we may disclose such data in any way
                permitted by law.
              </li>
            </ul>
            <h6>Links to Other Sites</h6>
            <p>
              The Platform may contain links to other websites and/or
              third-party services. We are not responsible for the privacy
              policies of such websites and/or services, and we advise you to
              review their privacy policies.
            </p>
            <h6>Storage and Security</h6>
            <p>
              We are strongly committed to the protection of your Personal Data
              and information, and we will take reasonable technical steps,
              which are accepted in our industry, to keep your Information
              secured and protect it from loss, misuse or alteration. However,
              if you notice a risk or any security violations, we advise you to
              report to us on
              <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>, so we
              can resolve it as soon as possible.
            </p>
            <h6>Other Terms</h6>

            <ul>
              <li>
                <span>1.</span>
                Changes to This Privacy Policy
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  The Platform and our business may change from time to time. As
                  a result, at times it may be necessary for us to make changes
                  to this Privacy Policy. We reserve the right, in our sole
                  discretion, to update or modify this Privacy Policy at any
                  time (collectively, "Modifications"). Modifications to this
                  Privacy Policy will be displayed by the "Last Updated" date at
                  the top of this Privacy Policy. Please review this Privacy
                  Policy periodically, and especially before you provide any
                  Personal Data or information. This Privacy Policy was last
                  updated on the date indicated above. Your continued use of the
                  Services following the effectiveness of any Modifications to
                  this Privacy Policy constitutes acceptance of those
                  Modifications. If any Modification to this Privacy Policy is
                  not acceptable to you, your sole remedy is to cease accessing,
                  browsing and otherwise using the Platform.
                </li>
              </ul>
              <li>
                <span>2.</span>
                Direct Messages
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  The Company may send you, from time to time, information
                  regarding its services, including advertisements, via short
                  messages or emails.
                </li>
                <li>
                  <span>b.</span>
                  By using the Platform, including providing your phone number
                  or email address, you explicitly consent to receive such
                  messages. However, at any time you may inform us of your
                  removing such consent, by sending a proper email to
                  <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
                </li>
                <li>
                  <span>c.</span>
                  We may, however, send direct messages we believe are critical
                  for your use of the platform, even following your choice to
                  unsubscribe.
                </li>
              </ul>
              <li>
                <span>3.</span>
                Cookies
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  We may use Cookies for the proper and continuous operation of
                  the Platform or website, including a collection of statistics,
                  verifications, modifications etc.
                </li>
                <li>
                  <span>b.</span>
                  Modern browsers allow you to avoid such use of Cookies. If you
                  don't know how to do that, check the help files of your
                  browser.
                </li>
              </ul>
              <li>
                <span>4.</span>
                Rights of Affected Person
              </li>
              <ul>
                <li>
                  You shall have the right to demand that your Personal Data be
                  removed, corrected, transferred to you and deliver further
                  requests to us regarding your Personal Data, by sending proper
                  email to
                  <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>.
                  However, in order to maintain that the information the Hosting
                  Company has will be in a proper condition, documentation
                  regarding your use the system will be preserved without your
                  identifying details.
                </li>
              </ul>
              <li>
                <span>5.</span>
                Dispute Resolution
              </li>
              <ul>
                <li>
                  <span>a.</span>
                  The laws of the State of Israel shall govern this Privacy
                  Policy. Any dispute which may arise in connection with this
                  Privacy Policy shall be brought to the competent court in the
                  Tel-Aviv district, Israel, which shall be granted exclusive
                  jurisdiction.
                </li>
              </ul>
            </ul>
          </div>
        )} */}
      </Container>
    </Layout>
  );
};
export default PrivacyPolicy;
