import action from '../action.js';
import types from './types';

export function setMealTime(mealTime) {
  return action(async (dispatch) => {
    dispatch({
      type: types.SET_MEALTIME,
      payload: mealTime,
    });
  });
}
