//const env = 'production' || process.env.TECHNOTEST_ENV || 'develop';
const env = process.env.NODE_ENV || 'local';
let config = {};
// App Details
config.appName = 'Work Eat';
config.server = {
  restPrefix: 'rest',
  assetPrefix: 'public/images',
};

const full = window.location.host;
//window.location.host is subdomain.domain.com
const parts = full.split('.');
const subDomain = parts[0];
const domain = parts[1];
const type = parts[2];

/***** password regex */
config.passwordRegExp =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/;

config.companyName = 'develop';
config.clientUrl = full?.includes('localhost')
  ? 'http://localhost:3000/#/login'
  : 'https://develop-app.workeat.co.il/#/login';
config.magneticStripe = false;
config.walletCreation = false;
config.makeCardNumber = false;

config.isProduction = process.env.REACT_APP_IS_PRODUCTION;
config.serverUrl = process.env.REACT_APP_SERVER_URL || 'localhost:4000';
config.assetsUrl =
  process.env.REACT_APP_DEPLOYMENT_ENV === 'master'
    ? 'https://workeat-prod.s3-eu-west-1.amazonaws.com/assets/'
    : 'https://workeat.s3-eu-west-1.amazonaws.com/assets/';

config.server.url =
  window.location.protocol === 'https:'
    ? `https://${config.serverUrl}`
    : `http://${config.serverUrl}`;
//console.log(env);
//console.log(config);

export default config;
