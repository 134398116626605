import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../services/validators';
import cancel from '../assets/images/close.svg';
import Storage from '../services/storage';
import back from '../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { DynamicStars } from '../components/RatingStars';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewReviewDetails = ({ open, handleClose, details }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-review-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('reviewManagement')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup">
            {/* <div className="back">
              <img src={back} alt="back" onClick={handleClose} />
            </div> */}
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('user')}:</h6>
                  <p>{details?.Name ? details?.Name : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('dishName')}:</h6>
                  <p className="user-details-block-para">
                    {' '}
                    {details?.dishName?.mealDish?.dish?.name
                      ? details?.dishName?.mealDish?.dish?.name
                      : ''}
                    {details?.dishName?.mealDish.length > 0 &&
                      details?.dishName?.mealDish?.map((el, i) => (
                        <p key={i}>{el?.mealDish?.dish?.name}</p>
                      ))}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('rating')}:</h6>
                  <p>
                    <div
                      className={`rating-star`}
                      style={{ pointerEvents: 'none' }}
                    >
                      <DynamicStars value={details?.Rating} />
                    </div>
                  </p>
                </div>

                <div className="user-details-block">
                  <h6>{t('description')}:</h6>
                  <p>{details?.description ? details.description : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('date')}:</h6>
                  <p>{details?.date ? details?.date : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('action')}:</h6>
                  <p>
                    <div className="actions-btns">
                      <Button
                        className="edit-btn"
                        onClick={() => {
                          history.push({
                            pathname: `/reviewDetails/${details?.orderId}/${
                              details?.dishId ||
                              details?.dishName?.mealDish[0]?.mealDish?.dishId
                            }`,
                            // state: { userdata: user },
                          });
                        }}
                      >
                        {t('viewMore')}
                      </Button>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Typography>
        {/* <div className="view-popup-btn">
          <button className="close-modal-btn" onClick={handleClose}>
            {t('close')}
          </button>
        </div> */}
      </Box>
    </Modal>
  );
};
export default ViewReviewDetails;
