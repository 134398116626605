import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import http from '../../services/http';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '35vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ViewGuestDetails = ({
  open,
  details,
  handleClose,
  UserStatuses,
  setKitchensIds,
  setPayload,
  Kitchens,
  Roles,
  handleOpen,
  handleConfirmation,
  ResendVerification,
  CopyDataUrl,
  handleWallet,
  allUsers,
  handleOrderLink,
  setMealTypesIds,
  setGetMealTypes,
  getMealTypes,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
  countryOptions,
  visitPurposeValues,
  allUsersForInvite,
  contractorList,
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await http.get('audit/getByParams', {
        filter: {
          entity: 'user',
          actionEntityId: details.userId,
        },
      });
      setHistoryData(data.data);
    };
    if (details?.userId) {
      fetchData();
    }
  }, [details]);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="view-more-popup"
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <div className="reservation-title">
              <Typography id="modal-modal-title" variant="h5" component="h2">
                {t('guestUserManagement')}
              </Typography>
            </div>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Box id="modal-modal-description">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label={t('mainInfo')} {...a11yProps(0)} />

                <Tab label={t('history')} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabIndex} index={0}>
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="view-detail-content">
                    <div className="user-details-block">
                      <h6>{t('name')}:</h6>
                      <p>{details?.firstName ? details?.firstName : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('email')}:</h6>
                      <p>{details?.Email ? details?.Email : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('startDate')}:</h6>
                      <p>{details?.hireFrom ? details?.hireFrom : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('endDate')}:</h6>
                      <p>{details?.hireTill ? details?.hireTill : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('userStatus')}:</h6>
                      <p>{details?.Status ? details?.Status : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('kitchenname')}:</h6>
                      <p>{details?.Kitchens ? details?.Kitchens : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('contractors')}:</h6>
                      <p>
                        {details?.Contractors
                          ? details?.Contractors.map((el) => el.name).join(', ')
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('phone')}:</h6>
                      <p>{details?.phone ? details?.phone : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('guestLink')}:</h6>
                      <p>
                        {details?.generatedLink ? details?.generatedLink : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('guestId')}:</h6>
                      <p>{details?.guestId ? details?.guestId : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('company')}:</h6>
                      <p>{details?.company ? details?.company : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('visitorCountry')}:</h6>
                      <p>
                        {details?.visitorCountry
                          ? countryOptions.find(
                              (el) =>
                                el.value == details?.visitorCountry ||
                                el.label == details?.visitorCountry
                            )?.label
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('visitPurpose')}:</h6>
                      <p>
                        {details?.visitPurpose
                          ? visitPurposeValues.find(
                              (el) =>
                                el.value == details?.visitPurpose ||
                                el.label == details?.visitPurpose
                            )?.label
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('isMultiOrders')}:</h6>
                      <p>
                        <input
                          type="checkbox"
                          checked={details?.isMultiOrders}
                        />
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('invitedBy')}:</h6>
                      <p>
                        {details?.invitedBy
                          ? allUsersForInvite.find(
                              (el) => el.value == details?.invitedBy
                            ).label
                          : '--'}
                      </p>
                    </div>
                  </div>
                  <div className="modal-footer-btn">
                    <Button
                      onClick={() => {
                        let ids = details.kitchensIds;
                        let newMealTypeDateArray = [];
                        if (details.kitchensIds?.length) {
                          details.kitchensIds?.forEach((el) => {
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          });
                        }
                        setMealTypesKitchenWise(newMealTypeDateArray);
                        const kitchen =
                          allUsers
                            ?.find((el) => el.id === details['userId'])
                            ?.kitchens?.map((el) => el.id) || ids;
                        setKitchensIds(kitchen);

                        if (details['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          userId: details['userId'],
                          card: details['Card Number'],
                          email: details['Email'],
                          fName: details['firstName'],
                          lName: details['lastName'],
                          phone: details['phone']
                            ? details['phone'].split('-').join('')
                            : details['phone'],
                          kitchensIds: kitchen,
                          employeeId: details['employeeId'],
                          allContractors: details['allContractors'],
                          contractors: details['Contractors'],
                          status: details?.Status,
                          hireFrom: details['hireFrom'],
                          hireTill: details['hireTill'],
                          mealTypeId: details.mealTypeId
                            ? details['mealTypeId']
                            : [],
                          mealTypesIds: details.mealTypeId
                            ? details['mealTypeId']
                            : [],
                          contractorsIds: [],
                          company: details['company'],
                          visitorCountry: details['visitorCountry'],
                          visitPurpose: details['visitPurpose'],
                          guestId: details['guestId'],
                          isMultiOrders: details['isMultiOrders'],
                          invitedBy: details['invitedBy'],
                        });

                        let mealTypeIdList = details.mealTypeId
                          ? JSON.parse(details['mealTypeId'])
                          : [];
                        let array = [];
                        if (mealTypeIdList && mealTypeIdList.length) {
                          let aa = mealTypeIdList.map((el) => array.push(el));
                        }
                        setMealTypesIds(array);
                        handleOpen();
                      }}
                    >
                      <i class="las la-pen" title="edit"></i>
                      {t('edit')}
                    </Button>
                    <Button
                      onClick={() => {
                        let ids = details.kitchensIds;
                        let newMealTypeDateArray = [];
                        if (details.kitchensIds?.length) {
                          details.kitchensIds?.forEach((el) => {
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          });
                        }
                        setMealTypesKitchenWise(newMealTypeDateArray);
                        const kitchen =
                          allUsers
                            ?.find((el) => el.id === details['userId'])
                            ?.kitchens?.map((el) => el.id) || ids;
                        setKitchensIds(kitchen);
                        if (details['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          kitchensIds: ids,
                          contractors: details['contractors'],
                          status: details?.Status,
                          hireFrom: details['hireFrom'],
                          hireTill: details['hireTill'],
                          mealTypeId: details['mealTypeId'] || [],
                          isPasswordTemporary: details?.isPasswordTemporary,
                          mealTypesIds: details['mealTypeId'] || [],
                          company: details['company'],
                          visitorCountry: details['visitorCountry'],
                          visitPurpose: details['visitPurpose'],
                          guestId: details['guestId'],
                          isMultiOrders: details['isMultiOrders'],
                          invitedBy: details['invitedBy'],
                        });
                        let mealTypeIdList = details.mealTypeId
                          ? JSON.parse(details['mealTypeId'])
                          : [];
                        let array = [];

                        if (mealTypeIdList && mealTypeIdList.length) {
                          let aa = mealTypeIdList?.map((el) => array.push(el));
                        }

                        setMealTypesIds(array);
                        handleOpen('recreate');
                      }}
                    >
                      <i class="las la-user-circle"></i>
                      {t('createAnotherUser')}
                    </Button>
                    <Button
                      onClick={() => handleConfirmation(details['userId'])}
                    >
                      <i class="las la-trash"></i>
                      {t('delete')}
                    </Button>

                    <Button>
                      <i
                        class="las la-copy"
                        title="Copy"
                        onClick={() => CopyDataUrl(details)}
                      ></i>
                      {t('copyUrl')}
                    </Button>
                  </div>
                </div>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1} class="overflow-hidden">
              {historyData && historyData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('action')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2">
                          <div className="d-flex m-0 w-100">
                            <strong className="history-column-fieldname">{`${t(
                              'fieldName'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'oldValue'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'newValue'
                            )}`}</strong>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyData.map((row) => (
                        <StyledTableRow
                          key={row.name}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('HH:mm:ss')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{row?.madeBy}</p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {t(row.typeAction)}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <div className="d-flex m-0 flex-column gap-2">
                              {Object.keys(row.diff).length > 0
                                ? Object.entries(row.diff).map((el) => (
                                    <div>
                                      <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                        <p className="audit-details-text">{`${
                                          t(el[0]) || '--'
                                        }:`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                        <p className="pl-1 pr-1 audit-details-text">{`${
                                          el[1] &&
                                          el[1]?.old &&
                                          el[0] === 'contractorsIds'
                                            ? el[1]?.old.length === 0
                                              ? '--'
                                              : contractorList
                                                  .filter((obj1) =>
                                                    el[1]?.old.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                            : el[1] &&
                                                el[1]?.old &&
                                                el[0] === 'kitchensIds'
                                              ? el[1]?.old.length === 0
                                                ? '--'
                                                : Kitchens.filter((obj1) =>
                                                    el[1]?.old.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                    .map((el) => el.name)
                                                    .join(', ')
                                              : el[1] &&
                                                  el[1]?.old &&
                                                  el[0] === 'roleId'
                                                ? Roles.filter(
                                                    (obj1) =>
                                                      el[1]?.old === obj1.id
                                                  ).map((el) => el.display)[0]
                                                : el[1] &&
                                                    el[1]?.old &&
                                                    el[0] === 'userStatusId'
                                                  ? UserStatuses.filter(
                                                      (obj1) =>
                                                        el[1]?.old === obj1.id
                                                    ).map((el) => el.display)[0]
                                                  : el[1] &&
                                                      el[1]?.old &&
                                                      el[0] === 'mealTypeId'
                                                    ? getMealTypes
                                                        .filter((obj1) =>
                                                          el[1]?.old.includes(
                                                            obj1.id
                                                          )
                                                        )
                                                        .map((el) => el.display)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.old &&
                                                        el[0] === 'invitedBy'
                                                      ? allUsers.find((elem) =>
                                                          elem.value ==
                                                          Array.isArray(
                                                            el[1]?.old
                                                          )
                                                            ? el[1]?.old[0]
                                                            : el[1]?.old
                                                        )?.label
                                                      : el[1]?.old ||
                                                          typeof el[1]?.old ===
                                                            'boolean'
                                                        ? el[1]?.old
                                                        : '--'
                                        }`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                        <p className="pl-1 pr-1 audit-details-text">{`${
                                          el[1] &&
                                          el[1]?.new &&
                                          el[0] === 'contractorsIds'
                                            ? el[1]?.new.length === 0
                                              ? '--'
                                              : contractorList
                                                  .filter((obj1) =>
                                                    el[1]?.new.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                            : el[1] &&
                                                el[1]?.new &&
                                                el[0] === 'kitchensIds'
                                              ? Kitchens.filter((obj1) =>
                                                  el[1]?.new.find(
                                                    (obj2) => obj2 === obj1.id
                                                  )
                                                )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                              : el[1] &&
                                                  el[1]?.new &&
                                                  el[0] === 'roleId'
                                                ? Roles.filter(
                                                    (obj1) =>
                                                      (el[1]?.new[0] ??
                                                        el[1]?.new) === obj1.id
                                                  ).map((el) => el.display)[0]
                                                : el[1] &&
                                                    el[1]?.new &&
                                                    el[0] === 'userStatusId'
                                                  ? UserStatuses.filter(
                                                      (obj1) =>
                                                        el[1]?.new === obj1.id
                                                    ).map((el) => el.display)[0]
                                                  : el[1] &&
                                                      el[1]?.new &&
                                                      el[0] === 'mealTypeId'
                                                    ? getMealTypes
                                                        .filter((obj1) =>
                                                          el[1]?.new.includes(
                                                            obj1.id
                                                          )
                                                        )
                                                        .map((el) => el.display)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.new &&
                                                        el[0] === 'invitedBy'
                                                      ? allUsers.find((elem) =>
                                                          elem.value ===
                                                          Array.isArray(
                                                            el[1]?.new
                                                          )
                                                            ? el[1]?.new[0]
                                                            : el[1]?.new
                                                        )?.label
                                                      : el[1]?.new ||
                                                          typeof el[1]?.new ===
                                                            'boolean'
                                                        ? el[1]?.new
                                                        : '--'
                                        }`}</p>
                                      </StyledTableCell>
                                    </div>
                                  ))
                                : '--'}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
          {/* <div className="view-popup-btn">
            <button className="close-modal-btn" onClick={handleClose}>
              {t('close')}
            </button>
          </div> */}
        </Box>
      </Modal>
    </>
  );
};
export default ViewGuestDetails;
