import React, { useState, useEffect, useCallback } from 'react';
import { Button, FormSelect, Modal, ModalBody } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Form from 'react-bootstrap/Form';
import arrow from '../../assets/newimages/arrowright.svg';
import '../../style/mealtime.css';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
  getWalletAmountFromApi,
} from '../../store/app/actions';
import http from '../../services/http';
import moment from 'moment';
import enums from '../../services/enums';
import { getMealTimesByDate } from '../../constants/MealTimes';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import Select from 'react-select';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MuiButton from '@mui/material/Button';

const SelectStylesMulti = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: '45px',
    color: '#656565',
    backgroundColor: 'transparent',
    border: '1px solid #9dc79d',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#656565',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    visibility: 'hidden',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: 75,
    overflow: 'auto',
    padding: '0 10px',
    color: '#656565',
    '::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '::-webkit-scrollbar-track': {
      background: '#cacaca',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#00D100',
      borderRadius: '10px',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#656565',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    color: '#00D100',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
  noOptionsMessage: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
};
const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '45px',
    minHeight: '45px',
    color: '#656565',
    backgroundColor: 'transparent',
    border: '1px solid #9dc79d',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    visibility: 'hidden',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
    padding: '0 10px',
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    color: '#00D100',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
  noOptionsMessage: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
};
const SelectStylesMultiSmall = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: '45px',
    color: '#656565',
    backgroundColor: 'transparent',
    border: '1px solid #9dc79d',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#656565',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: 100,
    overflow: 'auto',
    padding: '0 7px',
    color: '#656565',
    '::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '::-webkit-scrollbar-track': {
      background: '#cacaca',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#00D100',
      borderRadius: '10px',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    visibility: 'hidden',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#656565',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    color: '#00D100',
    padding: '3px',
    ':active': {
      padding: '3px',
    },
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
  noOptionsMessage: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
};
const SelectStylesSmall = {
  control: (provided, state) => ({
    ...provided,
    height: '45px',
    minHeight: '45px',
    color: '#656565',
    backgroundColor: 'transparent',
    border: '1px solid #9dc79d',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
    padding: '0 7px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    visibility: 'hidden',
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    color: '#00D100',
    padding: '3px',
    ':active': {
      padding: '3px',
    },
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
  noOptionsMessage: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
};
const SelectStylesMob = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 60,
    color: '#656565',
    backgroundColor: 'transparent',
    border: '1px solid #9dc79d',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    color: '#00D100',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
    padding: '0 20px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
  noOptionsMessage: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
};
const SelectStylesMultiMob = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: '60px',
    color: '#4b4b4b',
    backgroundColor: 'transparent',
    border: '1px solid #9dc79d',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  menu: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#4b4b4b',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: 60,
    overflow: 'auto',
    padding: '0 20px',
    color: '#4b4b4b',
    '::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '::-webkit-scrollbar-track': {
      background: '#cacaca',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#00D100',
      borderRadius: '10px',
    },
  }),
  dropdownIndicator: (styles, { data }) => ({
    ...styles,
    color: '#00D100',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
  noOptionsMessage: (styles, { data }) => ({
    ...styles,
    color: '#4b4b4b',
  }),
};

const ReservationForAnotherUser = ({
  kitchen,
  user,
  show,
  setLayoutOptions,
  currentLanguage,
  MealTypes,
  showAlert,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getWalletAmountFromApi,
  kitchens,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [month, setMonth] = useState([]);
  const [mealData, setMealData] = useState();
  const [mealType, setMealType] = useState(null);
  const [mealId, setMealId] = useState(null);
  const [mealDate, setMealDate] = useState(null);
  const [guestInfo, setGuestInfo] = useState([]);
  const [selectDateErrors, setSelectDateErrors] = useState('');
  const [selectMealTypeErrors, setSelectMealTypeErrors] = useState('');
  const [availableMealTypes, setAvailableMealTypes] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedKitchen, setSelectedKitchen] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [popupProps, setPopupProps] = useState({
    text: '',
    displayed: false,
    isEdit: false,
  });
  const [allKitchens, setAllKitchens] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [dummy, setDummy] = useState(false);

  function getUnique(array) {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  useEffect(() => {
    getWalletAmountFromApi(user?.id);
    setAllKitchens(
      kitchens.length !== 0 &&
        user.kitchens &&
        user.kitchens.length !== 0 &&
        user.roleId !== 1
        ? kitchens.filter(
            (el) =>
              user.kitchens.find((elem) => elem.id === el.id) !== undefined
          )
        : kitchens
    );
    setLayoutOptions({ showKitchen: false });
    return () => {
      setLayoutOptions({ showKitchen: true });
    };
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      const allUserData = await http.get(
        `users/getAllUsers/${selectedKitchen}`
      );
      console.log('allUserData', allUserData);
      setAllUsers(
        allUserData.response.data.map((elem) => ({
          value: elem.id,
          label: `${elem.firstName} ${elem.lastName}`,
        }))
      );
    };
    if (selectedKitchen) {
      fetchData1();
    }
  }, [selectedKitchen]);

  function getDatesInRange(startDate, endDate, todayDate) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      let getDates = moment(date).format('YYYY-MM-DD');
      if (getDates >= todayDate) {
        dates.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  useEffect(() => {
    const fetchData1 = async () => {
      /*const getGuestMoreInfo = async () => {
        //  GUEST USER INFO
        const guestUserInfo = await useFetchingLoader(
          async () => await http.get(`users/getGuestUserInfo`)
        );
        if (guestUserInfo.data) {
          setGuestInfo(guestUserInfo.data);
        } else {
          setGuestInfo([]);
        }
      };
      if (user?.roleId === 8) {
        getGuestMoreInfo();
      }*/
      const paymentData = await getPaymentPreferences(selectedKitchen, '');
      setPaymentMethod(paymentData);
      setAvailableMealTypes(null);
    };
    fetchData1();
  }, [selectedKitchen]);

  useEffect(() => {
    const getMonth = async () => {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const getMonthlyDataInfo = await http.get(`meals/getMonthly`, {
        kitchenId: selectedKitchen,
      });
      let getMonthlyData = getMonthlyDataInfo;
      // GUEST USER DATES
      /*let todayDate = moment(date).format('YYYY-MM-DD');

      if (user?.roleId === 8) {
        if (user?.hireFrom && user?.hireTill) {
          if (user?.hireFrom >= todayDate && user?.hireTill >= todayDate) {
            const d1 = new Date(user?.hireFrom);
            const d2 = new Date(user?.hireTill);
            let dateInfo = [];
            let startDate = moment(d1).format('YYYY-MM-DD');
            let endtDate = moment(d2).format('YYYY-MM-DD');

            let dateFilter = getMonthlyData.filter(
              (el) => el.date >= startDate && el.date <= endtDate
            );
            dateFilter.filter((el) => dateInfo.push(el.date));
            dateInfo.sort((a, b) => new Date(a) - new Date(b));
            let dataInfo = getUnique(dateInfo);
            setMonth(dataInfo);
          } else {
            if (user?.hireFrom < todayDate) {
              const d1 = new Date();
              const d2 = new Date(user?.hireTill);
              let dateInfo = [];
              let startDate = moment(d1).format('YYYY-MM-DD');
              let endtDate = moment(d2).format('YYYY-MM-DD');

              let dateFilter = getMonthlyData.filter(
                (el) => el.date >= startDate && el.date <= endtDate
              );
              dateFilter.filter((el) => dateInfo.push(el.date));
              dateInfo.sort((a, b) => new Date(a) - new Date(b));
              let dataInfo = getUnique(dateInfo);
              setMonth(dataInfo);
            } else {
              setMonth([]);
            }
          }
        } else {
          let dateArray = [];
          getMonthlyData.filter((el) => dateArray.push(el.date));
          dateArray.sort((a, b) => new Date(a) - new Date(b));
          let uniqueDate = getUnique(dateArray);
          setMonth([]);
        }
      } else {*/
      let dateArray = [];
      getMonthlyData.filter((el) => dateArray.push(el.date));
      dateArray.sort((a, b) => new Date(a) - new Date(b));
      let uniqueDate = getUnique(dateArray);
      setMonth(uniqueDate);
      // }
    };
    getMonth();
  }, [selectedKitchen]);

  const selectDate = async (e) => {
    let date = e.value;
    let dd = '';
    if (
      date === 'Select Date' ||
      date === 'Выберите дату' ||
      date === 'תאריך'
    ) {
      setSelectDateErrors('Please select date');
      setSelectMealTypeErrors('');
      setMealDate(null);
      setMealType(null);
      setMealData(null);
      setAvailableMealTypes(null);
      localStorage.removeItem('date');
      localStorage.removeItem('mealTypeId');
    } else {
      setMealType(null);
      setMealDate(date);
      localStorage.setItem('date', e.value);
      dd = localStorage.getItem('date');
      setSelectDateErrors('');
      localStorage.removeItem('mealTypeId');
      const { data: mealsInfo } = await useFetchingLoader(
        async () =>
          await http.get(`meals`, {
            filter: {
              //date: date || dd,
              date: date,
              kitchenId: selectedKitchen,
              mealStatusId: [
                enums.getMap('MealStatus').active,
                enums.getMap('MealStatus').blocked,
              ],
            },
          })
      );

      let meals = mealsInfo;
      let futureMeals = getFutureMeals(date, MealTypes);
      const availableMealsTypes = MealTypes.map((mt) => {
        return {
          available:
            !!meals.find(
              (m) => m.mealTypeId === mt.id && m.mealStatusId === 1
            ) && futureMeals.hasOwnProperty(mt.id),
          ...mt,
        };
      });

      let sortedAvailableMealTypes = availableMealsTypes
        .filter((m) => m.available)
        .sort((mt1, mt2) => (mt1.orderIndex > mt2.orderIndex ? 1 : -1));
      setAvailableMealTypes(sortedAvailableMealTypes);
      /*if (user?.roleId === 8) {
        if (user?.hireFrom && user?.hireTill) {
          const availableMealsTypesInfo = MealTypes.map((mt) => {
            return {
              available: meals.find(
                (m) => m.mealTypeId === mt.id && m.mealStatusId === 1
              ),
              ...mt,
            };
          });

          let getMealTypeList = availableMealsTypesInfo.filter(
            (elInfo) => elInfo?.kitchenId === selectedKitchen && elInfo.available
          );
          let guestMealTypeArray = JSON.parse(guestInfo[0]?.mealTypeId);
          let mealsTypesArray = [];
          getMealTypeList.forEach((el) => {
            if (guestMealTypeArray && guestMealTypeArray.includes(el?.id)) {
              mealsTypesArray.push(el);
            }
          });
          if (mealsTypesArray.length > 0) {
            mealsTypesArray?.sort((a, b) =>
              a?.mealType?.from.localeCompare(b?.mealType?.from)
            );

            const today = new Date();
            const filteredMealsTypesArray = mealsTypesArray.filter((el) => {
              const date2 = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                ...el.to.split(':')
              );
              return today < date2;
            });
            const param = guestMealTypeArray
              ? moment(new Date().toISOString().substring(0, 10)).isSame(
                  moment(e.target.value)
                )
                ? filteredMealsTypesArray
                : mealsTypesArray
              : null;
            setAvailableMealTypes(param);
            setMealType(param[0]?.id ? param[0]?.id : null);
            if (param[0]?.id && (date || dd)) {
              await useFetchingLoader(async () => {
                await request({
                  mealDate: date || dd,
                  mealTypeId: param[0]?.id,
                });
              });
            }
            meals?.sort((a, b) =>
              a?.mealType?.from.localeCompare(b?.mealType?.from)
            );
            setMealData({ data: meals });
          } else {
            setAvailableMealTypes(null);
            setMealType(null);
            setMealData(null);
          }
        } else {
          if (meals.length > 0) {
            meals?.sort((a, b) =>
              a?.mealType?.from.localeCompare(b?.mealType?.from)
            );

            const getMeal = meals.filter(
              (el) => el.mealTypeId == sortedAvailableMealTypes?.[0]?.id
            );
            localStorage.setItem('mealTypeId', getMeal?.[0]?.mealType.id);
            if (sortedAvailableMealTypes?.[0]?.id && (date || dd)) {
              await useFetchingLoader(async () => {
                await request({
                  mealDate: date || dd,
                  mealTypeId: sortedAvailableMealTypes?.[0]?.id,
                });
              });
            }
            setMealType(sortedAvailableMealTypes?.[0]?.id);
            setMealData({ data: meals });
          } else {
            setMealData(null);
          }
        }
      } else {*/
      if (meals.length > 0) {
        meals?.sort((a, b) =>
          a?.mealType?.from.localeCompare(b?.mealType?.from)
        );

        const getMeal = meals.filter(
          (el) => el.mealTypeId == sortedAvailableMealTypes?.[0]?.id
        );
        localStorage.setItem('mealTypeId', getMeal?.[0]?.mealType.id);
        /*let getAllSlot;
          await useFetchingLoader(async () => {
            getAllSlot = await http.get(
              `mealSlots/getByMealId/${
                getMeal && getMeal[0] && getMeal?.[0].id
              }`
            );
          });*/
        if (sortedAvailableMealTypes?.[0]?.id && (date || dd)) {
          await useFetchingLoader(async () => {
            await request({
              mealDate: date || dd,
              mealTypeId: sortedAvailableMealTypes?.[0]?.id,
            });
          });
        }

        // setAllSlot(getAllSlot);
        setMealData({ data: meals });
      } else {
        setMealData(null);
      }
      //}
    }
  };

  const getFutureMeals = (date, MealTypes) => {
    let mealTimes = getMealTimesByDate(date, MealTypes);
    let now = moment();
    let futureMeals = MealTypes.filter(
      (mealType) => mealTimes[mealType.id].ends > now
    ).reduce((acc, type) => {
      return { ...acc, [type.id]: type };
    }, {});
    return futureMeals;
  };

  const selectMealType = async (e) => {
    let date = localStorage.getItem('date');
    let mealTypeDataInfo = e.value;
    if (mealTypeDataInfo === 'Select MealType') {
      setSelectMealTypeErrors('Please select MealType');
      setMealType(null);
    } else {
      setSelectMealTypeErrors('');
      setMealType(e.value);
    }
    let { data } = mealData ?? { data: [] };
    const getMeal = data.filter((el) => el.mealTypeId == e.value);
    localStorage.setItem('mealTypeId', getMeal[0]?.mealType.id);
    if (date && e.value) {
      await useFetchingLoader(async () => {
        await request({ mealDate: date, mealTypeId: e.value });
      });
    }
  };
  const onConfirm = async () => {
    const checkOrder = await http.get(`orders/checkOrder`, {
      mealDate: mealDate,
      mealTypeId: mealType,
    });
    history.push({
      pathname: '/orderSummary',
      state: {
        date: mealDate && mealDate,
        mealType: mealType ? mealType : localStorage.getItem('mealTypeId'),
        // slotId: slotId && slotId,
        orderId:
          checkOrder[checkOrder.length - 1] &&
          checkOrder[checkOrder.length - 1].orderStatusId === 2
            ? undefined
            : checkOrder[checkOrder.length - 1]?.orderId,
        dishId:
          checkOrder[checkOrder.length - 1] &&
          checkOrder[checkOrder.length - 1].orderStatusId === 2
            ? undefined
            : checkOrder[checkOrder.length - 1]?.dishId,
        dishIds:
          checkOrder[checkOrder.length - 1] &&
          checkOrder[checkOrder.length - 1].orderStatusId === 2
            ? undefined
            : Array.isArray(checkOrder[checkOrder.length - 1]?.dishId)
              ? checkOrder[checkOrder.length - 1]?.dishId
              : [checkOrder[checkOrder.length - 1]?.dishId],
      },
    });
  };

  const request = async (data) => {
    const { mealDate, mealTypeId } = data;
    const getMeals = await http.get(`meals/getMeal`, {
      mealDate: mealDate,
      mealType: mealTypeId,
    });
    /*const getCollectPointResponse = await http.post(
      `users/getCollectPointRequestByUserId`,
      { mealDate: mealDate, mealTypeId: mealTypeId }
    );*/
    const checkOrder = await http.get(`orders/checkOrder`, {
      mealDate: mealDate,
      mealTypeId: mealTypeId,
    });
    if (checkOrder.length) {
      if (
        checkOrder[checkOrder.length - 1].orderStatusId === 2 &&
        (!getMeals?.mealType.multipleReservations || !user.isMultiOrders)
      ) {
        setPopupProps((prev) => {
          return { ...prev, text: t('orderedAndRealized'), isEdit: false };
        });
      } else if (checkOrder[checkOrder.length - 1].orderStatusId === 1) {
        setPopupProps((prev) => {
          return { ...prev, text: t('orderedMeal'), isEdit: true };
        });
      } else {
        setPopupProps((prev) => {
          return { ...prev, text: '', isEdit: false };
        });
      }
    } else {
      setPopupProps((prev) => {
        return { ...prev, text: '', isEdit: false };
      });
    }
  };
  const handleClosePopup = () => {
    setPopupProps((prev) => {
      return { ...prev, displayed: false };
    });
  };
  return (
    <div className="meal-time-content">
      <div className="container">
        <div className="meal-time-content-box">
          <div className="order-meal-box">
            <h1>{t('orderMealHeading')}</h1>
            <div className="order-meal">
              <div className="order-meal-date">
                <p>{t('selectKitchen')}</p>
                <Select
                  name={'selectKitchen'}
                  placeholder={t('selectKitchen')}
                  isClearable={true}
                  className="client-lobby-filter-block-dropdown green-background"
                  styles={SelectStyles}
                  onChange={(newData) => {
                    setSelectedKitchen(newData?.value ?? newData);
                  }}
                  value={allKitchens
                    .map((el) => ({
                      value: el?.id,
                      label: el?.name,
                    }))
                    .filter((el) => el.value === selectedKitchen)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(157, 199, 157, 0.3)',
                      primary: '#00D100',
                      neutral20: '#00D100',
                      neutral30: '#656565',
                      neutral40: '#00D100',
                      neutral60: '#00D100',
                      neutral80: '#656565',
                      neutral90: '#656565',
                    },
                  })}
                  options={allKitchens.map((el) => ({
                    value: el?.id,
                    label: el?.name,
                  }))}
                />
                {selectDateErrors && (
                  <p className="error-msg">{selectDateErrors}</p>
                )}
              </div>
              <div className="order-meal-date">
                <p>{t('selectUsers')}</p>
                <Select
                  isMulti={true}
                  name={'selectUsers'}
                  placeholder={t('selectUsers')}
                  isClearable={true}
                  className="client-lobby-filter-block-dropdown green-background"
                  styles={SelectStylesMulti}
                  onChange={(newData) => {
                    setSelectedUser(newData?.value ?? newData);
                  }}
                  value={allUsers.find((el) => el.value === selectedUser)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(157, 199, 157, 0.3)',
                      primary: '#00D100',
                      neutral20: '#00D100',
                      neutral30: '#656565',
                      neutral40: '#00D100',
                      neutral60: '#00D100',
                      neutral80: '#656565',
                      neutral90: '#656565',
                    },
                  })}
                  options={allUsers}
                />
                {selectDateErrors && (
                  <p className="error-msg">{selectDateErrors}</p>
                )}
              </div>
              <div className="order-meal-date">
                <p>{t('reservationMealDate')}</p>
                <Select
                  name={'reservationMealDate'}
                  placeholder={t('reservationMealDate')}
                  isClearable={true}
                  className="client-lobby-filter-block-dropdown green-background"
                  styles={SelectStyles}
                  onChange={(newData) => selectDate(newData)}
                  value={month
                    .map((el) => ({
                      value: el,
                      label:
                        moment(new Date()).format('DD/MM/YYYY') ===
                        moment(el).format('DD/MM/YYYY')
                          ? moment(el).format('DD/MM/YYYY') + ' ' + t('today')
                          : moment(el).format('DD/MM/YYYY') +
                            ' ' +
                            moment(el).format('dddd'),
                    }))
                    .find((el) => el.value === mealDate)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(157, 199, 157, 0.3)',
                      primary: '#00D100',
                      neutral20: '#00D100',
                      neutral30: '#656565',
                      neutral40: '#00D100',
                      neutral60: '#00D100',
                      neutral80: '#656565',
                      neutral90: '#656565',
                    },
                  })}
                  options={month.map((el) => ({
                    value: el,
                    label:
                      moment(new Date()).format('DD/MM/YYYY') ===
                      moment(el).format('DD/MM/YYYY')
                        ? moment(el).format('DD/MM/YYYY') + ' ' + t('today')
                        : moment(el).format('DD/MM/YYYY') +
                          ' ' +
                          moment(el).format('dddd'),
                  }))}
                />
                {selectDateErrors && (
                  <p className="error-msg">{selectDateErrors}</p>
                )}
              </div>
              <div className="order-meal-date">
                <p>{t('reservationMealType')}</p>
                <Select
                  name={'reservationMealType'}
                  placeholder={
                    availableMealTypes?.length === 0
                      ? t('noMealsForThisDate')
                      : t('reservationMealType')
                  }
                  isClearable={true}
                  className="client-lobby-filter-block-dropdown green-background"
                  styles={SelectStyles}
                  onChange={(newData) => selectMealType(newData)}
                  value={availableMealTypes
                    ?.map((el) => ({ value: el.id, label: el.display }))
                    .find((el) => el.value === mealType)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(157, 199, 157, 0.3)',
                      primary: '#00D100',
                      neutral20: '#00D100',
                      neutral30: '#656565',
                      neutral40: '#00D100',
                      neutral60: '#00D100',
                      neutral80: '#656565',
                      neutral90: '#656565',
                    },
                  })}
                  options={availableMealTypes?.map((el) => ({
                    value: el.id,
                    label: el.display,
                  }))}
                />
                {/*<Form.Select
                  aria-label="Default select example"
                  onChange={(e) => selectMealType(e)}
                  value={localStorage.getItem('mealTypeId') ?? ''}
                >
                  {availableMealTypes?.length <= 0 ||
                    (availableMealTypes == null && (
                      <option>{t('selectMealTypeSecond')}</option>
                    ))}
                  {availableMealTypes?.length === 0 ? (
                    <option>{t('noMealsForThisDate')}</option>
                  ) : (
                    availableMealTypes?.map((el) => (
                      <>
                        {' '}
                        <option value={el.id}>{el.display}</option>
                      </>
                    ))
                  )}
                </Form.Select>*/}
                {selectMealTypeErrors && (
                  <p className="error-msg">{selectMealTypeErrors}</p>
                )}
              </div>

              <div
                className={`order-meal-btn ${
                  (mealType && mealDate) || availableMealTypes?.length > 0
                    ? 'enable-btn'
                    : 'disable-btn'
                }`}
              >
                <Button
                  onClick={() => {
                    popupProps.text === ''
                      ? onConfirm()
                      : setPopupProps((prev) => {
                          return { ...prev, displayed: true };
                        });
                  }}
                >
                  {t('continue')}
                  <img src={arrow} alt="" />
                </Button>
              </div>
            </div>
            <Modal
              show={popupProps.displayed}
              onHide={() => handleClosePopup()}
              centered
            >
              <Modal.Header className="m10" closeButton>
                <p className="swal2-title center">{t('orderExistsTitle')}</p>
              </Modal.Header>
              <ModalBody className="m10">
                <p className="swal2-html-container text-center">
                  {t(popupProps.text)}
                </p>
              </ModalBody>
              <Modal.Footer className="flex-center m10">
                {!popupProps.isEdit && (
                  <Button
                    className="back-btn-custom"
                    onClick={() => handleClosePopup()}
                  >
                    {t('close')}
                  </Button>
                )}

                {popupProps.isEdit && (
                  <Button
                    className="continue-btn-custom"
                    onClick={() => onConfirm()}
                  >
                    {t('yes')}
                  </Button>
                )}
                {popupProps.isEdit && (
                  <Button
                    className="back-btn-custom"
                    onClick={() => handleClosePopup()}
                  >
                    {t('no')}
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  MealTypes: state.app.enums.MealType,
  kitchens: state.kitchens.options,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getPaymentPreferences,
  getWalletAmountFromApi,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationForAnotherUser);
