import React from 'react';
import WEIcon from './WE-IconButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import BackButton from './BackButton';

const BackLink = ({
  text = null,
  redirectHistory = null,
  showOnMobile = true,
  additionalWrapperClassname = '',
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <BackButton
      icon={'arrow'}
      backgroundColor={'green'}
      text={text ?? t('back')}
      additionalWrapperClassname={additionalWrapperClassname}
      cb={
        redirectHistory
          ? () => history.push(redirectHistory)
          : () => history.goBack()
      }
    />
  );
};

export default BackLink;
