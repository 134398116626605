import React from 'react';
import WEIcon from './WE-IconButton';

/*
 * className - indicates the styling of the button
 * Label - the text on the button
 * Icon - an optional image/icon to display inside the button
 * cb - the callback function when clicking the button
 * disableBtn - a boolean that indicates the button's disable mode
 * */
export default function WEButton({
  wrapperAdditionalClassname = '',
  additionalClassName = '',
  justifyBtn = 'center',
  label,
  icon = null,
  cb = () => {},
  disableBtn = false,
  type = 'button',
}) {
  return (
    <div
      className={`button-wrapper ${wrapperAdditionalClassname}`}
      style={{ justifyContent: justifyBtn }}
    >
      <button
        className={`we-button ${additionalClassName}`}
        onClick={cb}
        disabled={disableBtn}
        type={type}
      >
        {label} {icon}
      </button>
    </div>
  );
}

const Arrow = <WEIcon icon={'arrow'} iconSize={'small'} />;

const ActiveButton = ({
  wrapperAdditionalClassname = '',
  additionalClassName = '',
  justifyBtn = 'center',
  label,
  arrow = false,
  disableBtn = false,
  ...rest
}) => {
  return WEButton({
    wrapperAdditionalClassname,
    additionalClassName: `active ${additionalClassName}`,
    justifyBtn,
    label,
    disableBtn,
    icon: arrow ? Arrow : null,
    ...rest,
  });
};

const DisabledButton = ({ additionalClassName = '', label }) => {
  return WEButton({
    additionalClassName: `disabled ${additionalClassName}`,
    label,
    disableBtn: true,
  });
};

export { ActiveButton, DisabledButton };
