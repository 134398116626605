import React, { useEffect, useState, useRef } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  getFactoriesData,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import deleteicon from '../../assets/images/icons/delete.svg';
import editicon from '../../assets/images/icons/edit.svg';
import { useHistory } from 'react-router';
import { sleep } from '../../services/utils';
import moment from 'moment';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import MealSummary from '../../components/Kitchen/MealSummary';
import search from '../../assets/icons/search.png';
import { Button } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import next from '../../assets/images/next.png';
import cancel from '../../assets/images/close.svg';
import agGridLocales from '../../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
import AddFactory from './AddFactory';
import FactoryDetails from './FactoryDetails';
import http from '../../services/http';

const FactoryManagement = ({
  setLayoutOptions,
  user,
  kitchen,
  useLoader,
  useFetchingLoader,
  mealsToDisplay,
  factoryToDisplay,
  factoryTotal,
  getFactoriesData,
  kitchens,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);

  const [allFactoriesForDisplay, setAllFactoriesForDisplay] = useState(null);

  const [tableColumn, setTableColumn] = useState();
  const [newPageSizeFactories, setPageSizeFactories] = useState(20); // Records per page
  const [gridApi, setGridApi] = useState();
  const [pageCountsFactories, setpageCountsFactories] = useState(1); // Total pages count
  const [currentPageFactories, setCurrentPageFactories] = useState(1); // Current page
  const [searchKeyword, setSearchKeywordFactory] = useState(''); // Search text
  const [addEditFactoryPopup, setAddEditFactoryPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [payload, setPayload] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const lang = localStorage.getItem('language');

  const fetchDataFromAPI = async () => {
    const filterPayload = {
      keyword: searchKeyword,
      orderTarget:
        'order' in columnAndOrder ? columnAndOrder?.order : undefined,
      orderField: 'field' in columnAndOrder ? columnAndOrder?.field : undefined,
      limit: newPageSizeFactories,
      page: currentPageFactories,
    };
    const filteredObject = Object.fromEntries(
      Object.entries(filterPayload).filter(
        ([key, value]) => value !== undefined
      )
    );
    await useFetchingLoader(async () => await getFactoriesData(filteredObject));
  };
  useEffect(() => {
    const fetchData1 = async () => {
      if (!kitchen) return;
      setLayoutOptions({ title: `${t('factoryManagement')}` });
      await fetchDataFromAPI();
    };
    fetchData1();
  }, [
    refresh,
    columnAndOrder,
    currentPageFactories,
    newPageSizeFactories,
    pageCountsFactories,
  ]);

  useEffect(() => {
    if (factoryToDisplay) {
      setAllFactoriesForDisplay(factoryToDisplay);
      setpageCountsFactories(
        factoryTotal === 0 ? 0 : Math.ceil(factoryTotal / newPageSizeFactories)
      );
    }
  }, [factoryToDisplay]);
  const handleDeleteClick = async (id) => {
    Dialog({
      title: t('deleteFactory'),
      text: `${t('deleteFactoryConfMess')}`,
      buttons: {
        confirmButtonText: t('removeMealBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: async () => handleDeleteAction(id),
    });
  };
  const handleDeleteAction = async (id) => {
    try {
      setShow(false);
      await useFetchingLoader(async () => await http.delete(`factories/${id}`));
      SwalDialogs.Dialog({
        title: `${t('deleteMessage')}`,
        type: 'success',
        buttons: {
          confirmButtonText: t('removeMealBtnLabel'),
          showCancelButton: false,
        },
        onClose: () => {
          fetchDataFromAPI();
        },
      });
    } catch (err) {
      Alert({ error: err });
    }
  };
  const columnDefs = [
    {
      field: 'name',
      headerName: t('factoryName'),
      sortable: true,
      comparator: () => {},
      headerClass: '<resizable>-header',
      cellRenderer: (params) => {
        return params.data.name;
      },
    },
    {
      field: 'kitchens',
      headerName: t('kitchens'),
      cellRenderer: (params) => {
        return params.data.kitchens.map((el) => el.name).join(', ');
      },
      headerClass: '<resizable>-header',
    },
    {
      field: 'Action',
      headerName: t('action'),
      cellRenderer: 'ActionButton',
      maxWidth: 100,
      minWidth: 80,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const handleClose = () => {
    setPayload({});

    setAddEditFactoryPopup(false);
    setEditMode(false);
  };

  useEffect(() => {
    setTableColumn(columnDefs);
  }, []);
  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();

    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const handleReset = () => {
    setColumnAndOrder({});
    setSearchKeywordFactory('');
  };
  const paperStyle = {
    overflow: 'visible',
  };
  const handleCloseDetails = (isCleanPayload = true) => {
    setShow(false);
    if (isCleanPayload) {
      setPayload({});
    }
  };
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper">
            <div className="search-block">
              <div className="search-input">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  value={searchKeyword}
                  onInput={(e) => {
                    setSearchKeywordFactory(e.target.value);
                  }}
                />
                <img src={search} alt="search" />
              </div>
              <button
                onClick={() => fetchDataFromAPI()}
                className="search-button m-1 mt-0 mb-0 h-100"
              >
                <img src={search} alt="search" />
              </button>
              <button
                onClick={() => handleReset()}
                className="reset-button m-1 mt-0 mb-0 h-100"
              >
                <img src={cancel} alt="search" />
              </button>
            </div>
          </div>
          <div className={'top flex gap-4'}>
            <Button
              onClick={() => setAddEditFactoryPopup(true)}
              className={`add-factory-btn ${
                localStorage.languageId == 3 ? 'ru-min-width' : ''
              }`}
            >
              <i class="las la-plus" />
              {t('addFactory')}
            </Button>
          </div>
        </div>

        <div className="add-dishes-table">
          <AgGridReact
            className="ag-theme-alpine"
            rowData={allFactoriesForDisplay}
            columnDefs={tableColumn}
            localeText={agGridLocales[i18n.language]}
            reactUi={true}
            enableRtl={lang === '"he"' ? true : false}
            frameworkComponents={{
              ActionButton: (e) => (
                <ActionButton
                  e={e}
                  t={t}
                  setEditMode={setEditMode}
                  setAddEditFactoryPopup={setAddEditFactoryPopup}
                  setPayload={setPayload}
                  handleDeleteClick={handleDeleteClick}
                  setData={setData}
                  setShow={setShow}
                />
              ),
            }}
            onSortChanged={() => printSortStateToConsole()}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            onGridReady={(params) => {
              setGridApi(params.api);
            }}
            onGridColumnsChanged={(params) => {
              setGridApi(params.api);
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeFactories * (currentPageFactories - 1) + 1} to{' '}
                {newPageSizeFactories * currentPageFactories} of {factoryTotal}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsFactories <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageFactories(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsFactories <= 1 ? true : false}
                onClick={() => {
                  currentPageFactories > 1
                    ? setCurrentPageFactories(currentPageFactories - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageFactories} of{' '}
                {Math.ceil(factoryTotal / newPageSizeFactories)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsFactories <= 1 ? true : false}
                onClick={() => {
                  currentPageFactories <
                  Math.ceil(factoryTotal / newPageSizeFactories)
                    ? setCurrentPageFactories(currentPageFactories + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsFactories <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageFactories(
                    Math.ceil(factoryTotal / newPageSizeFactories)
                  );
                }}
              />
            </Pagination>
          </div>
        </div>
      </div>
      <AddFactory
        open={addEditFactoryPopup}
        handleClose={handleClose}
        t={t}
        editMode={editMode}
        payload={payload}
        setPayload={setPayload}
        setRefresh={setRefresh}
        kitchens={kitchens}
        useFetchingLoader={useFetchingLoader}
      />
      <FactoryDetails
        show={show}
        handleClose={handleCloseDetails}
        handleDelete={handleDeleteClick}
        setEditMode={setEditMode}
        setAddEditFactoryPopup={setAddEditFactoryPopup}
        data={data}
      />
    </div>
  );
};

const ActionButton = ({
  e,
  t,
  setAddEditFactoryPopup,
  setEditMode,
  setPayload,
  handleDeleteClick,
  setData,
  setShow,
}) => {
  return (
    <div className="right-listing w-100">
      <section className="actions d-flex justify-content-around">
        <img
          src={editicon}
          onClick={() => {
            setPayload({
              id: e.data.id,
              name: e.data.name,
              kitchens: e.data.kitchens.map((el) => ({
                label: el.name,
                value: el.id,
              })),
            });
            setAddEditFactoryPopup(true);
            setEditMode(true);
          }}
          className="actions-factory-desktop pointer"
        />
        <img
          src={deleteicon}
          onClick={() => handleDeleteClick(e.data.id)}
          className="actions-factory-desktop pointer"
        />

        <section className="actions-factory-mob">
          {' '}
          <div className="view-more-btn">
            <img
              src={next}
              alt="next"
              className="pointer"
              onClick={() => {
                setData(e.data);
                setPayload({
                  id: e.data.id,
                  name: e.data.name,
                  kitchens: e.data.kitchens.map((el) => ({
                    label: el.name,
                    value: el.id,
                  })),
                });
                setShow(true);
              }}
            />
          </div>
        </section>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  dataTableData: state.app.dataTableData,
  kitchens: state.kitchens.options,
  factoryToDisplay: state.app.factoryToDisplay,
  factoryTotal: state.app.factoryTotal,
});
const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getFactoriesData,
};
export default connect(mapStateToProps, mapDispatchToProps)(FactoryManagement);
