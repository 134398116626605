import { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';

import { ActiveButton, DisabledButton } from '../WE-Button';
import { Button } from 'react-bootstrap';

const ScurityForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    error,
    valid,
    submitFailed,
    securityData,
    initialValues,
  } = props;
  const [value, setValue] = useState(securityData?.whitelistIPs);
  return (
    <Form
      className="form"
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <div className="inputs-wrapper">
        <Field
          name="whitelistIPs"
          containerClass="employee-id"
          type="text"
          component={InputField}
          validate={[]}
          value={value}
          // cb={(v) => setValue(v)}
          placeholder={t('securityFormBlacklistPlaceholder')}
          icon="icon-employee-card"
        />

        <div className="settings-btn">
          {submitFailed && !valid ? (
            <DisabledButton
              label={t('securitySubmitButton')}
              additionalClassName={'btn'}
            />
          ) : (
            <>
              <Button type="submit">Save</Button>
              <Button>Cancel</Button>
            </>
          )}
        </div>
      </div>

      {error && <div className="form-error">{error}</div>}
    </Form>
  );
};

export default reduxForm({
  form: 'scurityForm',
  initialValues: {
    whitelistIPs: 'dfdfasdfsd',
  },
  enableReinitialize: true,
})(ScurityForm);
