import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Login.module.scss';
import banner from '../../assets/images/login-banner.png';

const LoginBanner = () => {
  let { t } = useTranslation();
  return (
    <div className="login-banner-wrapper">
      {/* <div className="login-contact-info">
        <a href="tel:+91 023 456 789" target="_blank">
          <img src={telephone} alt="telephone" /> +91 023 456 789
        </a>
      </div> */}
      <div className="login-banner">
        <img src={banner} alt="banner" />
      </div>
      <div className="login-welcome-box">
        <h2>{t('welcomeToWorkeat')}</h2>
        <p>{t('theWayTo')}</p>
      </div>
    </div>
  );
};

export default LoginBanner;
