import appTypes from './app/types';

export default function action(_action, isHttp) {
  return async (dispatch, getState) => {
    try {
      if (isHttp) {
        //dispatch({ type: appTypes.TOGGLE_LOADER, payload: true });
        const response = await _action(dispatch, getState);
        //dispatch({ type: appTypes.TOGGLE_LOADER, payload: false });
        return response;
      } else {
        return await _action(dispatch, getState);
      }
    } catch (error) {
      dispatch({ type: appTypes.TOGGLE_LOADER, payload: false });
      dispatch({
        type: appTypes.TOGGLE_ALERT_MESSAGE,
        payload: { type: 'error', message: error },
      });
      throw error;
    }
  };
}

export function useFetchingLoader(func) {
  return action(async (dispatch) => {
    try {
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: true });
      //await new Promise((resolve) => setTimeout(resolve, 200));
      let res = await func();
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: false });

      return res;
    } catch (ex) {
      dispatch({ type: types.TOGGLE_FETCHING_LOADER, payload: false });
      if (ex.redirect) redirect(dispatch, ex.redirect);

      throw ex;
    }
  });
}
