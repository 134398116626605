import types from './types';

// Set initial state
const initialState = {
  data: null,
  enums: {},
  modalVisible: false,
  sidebarOpened: false,
  showLoader: true,
  showCopyMealPopup: false,
  isSuccessCopyMealProcess: true,
  showFetchingLoader: false,
  message: {
    show: false,
    content: '',
    title: '',
  },
  pastmealsToDisplay: [],
  receipts: [],
  allpastmealsToDisplay: [],
  factoryToDisplay: [],
  invitationToDisplay: [],
  dishesToDisplay: [],
  dataTableData: [],
  tagsToDisplay: [],
  pastMealsTotal: 0,
  factoryTotal: 0,
  invitationTotal: 0,
  dishesTotal: 0,
  allpastMealsTotal: 0,
  mealsTotal: 0,
  tagsTotal: 0,
  walletAmount: 0,
  notificationsCount: 0,
  pushNotification: null,
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_ENUMS:
      return {
        ...state,
        enums: { ...state.enums, ...action.payload },
      };

    case types.TOGGLE_MODAL_VISIBLE:
      return {
        ...state,
        loginModalVisible: action.payload,
      };
    case types.GET_RECEIPTS:
      return {
        ...state,
        receipts: action.payload,
      };
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpened: action.payload,
      };

    case types.TOGGLE_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      };

    case types.TOGGLE_FETCHING_LOADER:
      return {
        ...state,
        showFetchingLoader: action.payload,
      };

    case types.TOGGLE_ALERT_MESSAGE:
      return {
        ...state,
        message: { ...action.payload },
      };
    case types.SET_LAYOUT_OPTIONS:
      return {
        ...state,
        layoutOptions: action.payload,
      };
    case types.GET_MEAL_TYPES:
      let newEnums = { ...state.enums, MealType: action.payload };
      return {
        ...state,
        enums: newEnums,
      };

    case types.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    case types.GET_PAST_MEAL:
      return {
        ...state,
        pastmealsToDisplay: action?.payload?.data,
        pastMealsTotal: action?.payload?.total,
      };
    case types.GET_FACTORIES:
      return {
        ...state,
        factoryToDisplay: action?.payload?.data,
        factoryTotal: action?.payload?.total,
      };
    case types.GET_ALL_PAST_MEAL:
      return {
        ...state,
        allpastmealsToDisplay: action?.payload?.data,
        allpastMealsTotal: action?.payload?.total,
      };
    case types.GET_DISH_DATA:
      return {
        ...state,
        dishesToDisplay: action?.payload?.data,
        dishesTotal: action?.payload?.total,
      };
    case types.GET_ALL_INVITATIONS:
      return {
        ...state,
        invitationToDisplay: action?.payload?.data,
        invitationTotal: action?.payload?.total,
      };
    case types.GET_ALL_REVIEWS:
      return {
        ...state,
        reviewsToDisplay: action?.payload?.data,
        reviewsTotal: action?.payload?.total,
      };
    case types.GET_CURRENT_MEAL:
      return {
        ...state,
        meals: action?.payload?.data?.filterMeals,
        dataTableData: action?.payload?.data?.dataTableData,
        mealsTotal: action?.payload?.total,
      };
    case types.GET_ALL_LOGS:
      return {
        ...state,
        alllogsToDisplay: action?.payload?.data,
        logsTotal: action?.payload?.total,
      };
    case types.SET_PUSH_NOTIFICATIONS:
      return {
        ...state,
        pushNotification: action?.payload,
      };
    case types.SET_NOTIFICATIONS_NUMBER:
      return {
        ...state,
        notificationsCount: action?.payload,
      };
    case types.GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotificationsToDisplay: action?.payload?.response?.data,
        notificationsTotal: action?.payload?.response?.total,
      };
    case types.GET_ALL_AUDITS:
      return {
        ...state,
        allAuditsToDisplay: action?.payload?.data,
        auditsTotal: action?.payload?.total,
      };
    case types.GET_TAG_DATA:
      return {
        ...state,
        tagsToDisplay: action?.payload?.data,
        tagsTotal: action?.payload?.total,
      };
    case types.SET_WALLET_DATA:
      return {
        ...state,
        walletAmount: action?.payload,
      };
    case types.OPEN_COPY_MEAL_POPUP:
      return {
        ...state,
        showCopyMealPopup: action?.payload,
      };
    case types.IS_SUCCESS_COPY_MEAL_POPUP:
      return {
        ...state,
        isSuccessCopyMealProcess: action?.payload,
      };

    default:
      return state;
  }
}
