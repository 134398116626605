export default {
  GET_DATA: 'get_data',
  GET_MEAL_TYPES: 'get_mealTypes',
  SET_ENUMS: 'get_enums',
  TOGGLE_MODAL_VISIBLE: 'toggle_modal',
  TOGGLE_SIDEBAR: 'toggle_sidebar',
  TOGGLE_LOADER: 'toggle_loader',
  TOGGLE_FETCHING_LOADER: 'toggle_fetching_loader',
  SET_REDIRECT: 'redirect',
  TOGGLE_ALERT_MESSAGE: 'toggle_alert_message',
  SET_LAYOUT_OPTIONS: 'set_layout_options',
  GET_PAST_MEAL: 'get_past_meal',
  GET_ALL_PAST_MEAL: 'get_all_past_meal',
  GET_DISH_DATA: 'get_dish_data',
  GET_ALL_REVIEWS: 'get_all_reviews',
  GET_CURRENT_MEAL: 'get_current_meals',
  GET_ALL_LOGS: 'get_all_logs',
  GET_ALL_NOTIFICATIONS: 'get_all_notifications',
  GET_ALL_AUDITS: 'get_all_audits',
  GET_TAG_DATA: 'get_tag_data',
  SET_WALLET_DATA: 'set_wallet_data',
  OPEN_COPY_MEAL_POPUP: 'open_copy_meal_popup',
  IS_SUCCESS_COPY_MEAL_POPUP: 'is_success_copy_meal_popup',
  GET_FACTORIES: 'get_factories',
  GET_ALL_INVITATIONS: 'get_all_invitations',
  SET_NOTIFICATIONS_NUMBER: 'set_notifications_number',
  SET_PUSH_NOTIFICATIONS: 'set_push_notifications',
  GET_RECEIPTS: 'get_receipts',
};
