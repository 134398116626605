import React from 'react';
import { Button } from 'react-bootstrap';
import '../../style/myOrders.scss';
import Tooltip from '@mui/material/Tooltip';

const TableBtn = ({
  img,
  btnName,
  func,
  tooltip = '',
  customClass = '',
  imgSize = '',
}) => {
  return tooltip ? (
    <Tooltip title={tooltip} placement="bottom">
      <Button className={`table-btn ${customClass}`} onClick={func}>
        <img src={img} alt="icon" className={imgSize} />
        {btnName}
      </Button>
    </Tooltip>
  ) : (
    <Button className={`table-btn ${customClass}`} onClick={func}>
      <img src={img} alt="icon" className={imgSize} />
      {btnName}
    </Button>
  );
};

export default TableBtn;
