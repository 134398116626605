import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { frontendUrl } from '../../services/utils';
import http from '../../services/http';

import ArrowIcon from '../../assets/images/left-arrow.svg';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import OrderSummaryDetails from '../../components/OrderComponents/OrderSummaryDetails.jsx';
import OrderConfirmationPopup from '../../components/OrderComponents/OrderConfirmationPopup.jsx';
import { generateMealDishData } from '../../components/DishComponents/Dish';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import { sleep } from '../../services/utils';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import ExtrasDish from '../../components/OrderComponents/extrasDish';
import CafeteriaDetails from '../../components/OrderComponents/cafeteriaDetails';
import CafeteriaOrderConfirmationPopup from '../../components/OrderComponents/CafeteriaOrderConfirmationPopup.jsx';
import DatesMethods from '../../services/dates.js';

const CafeteriaOrderSummary = ({
  setLayoutOptions,
  showAlert,
  OrderStatuses,
  MealTypes,
  useLoader,
  getPaymentPreferences,
  useFetchingLoader,
  user,
  kitchen,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const [mealSlotId, setMealSlotId] = useState(null);
  const [mealSlotData, setMealSlotData] = useState(null);
  const [extrasPopup, setExtrasPopup] = useState(false);
  const [extrsData, setExtrasData] = useState();
  const [addedExtras, setAddedExtras] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(0);
  const [details, setDetails] = useState(null);
  const [togglePopup, setTogglePopup] = useState(false);
  const [mealId, setMealId] = useState();
  const [isSkip, setIsSkip] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [mealExtra, setMealExtra] = useState();
  const [paymenttypeData, setPaymentTypeData] = useState();
  const [csrfTokenState, setCsrfTokenState] = useState('');
  const [totalPrice, setTotalPrice] = useState();
  const [totalQuantity, setTotalQuantity] = useState();

  const [haveWeReceivedPostResponseState, setHaveWeReceivedPostResponseState] =
    useState('not yet');
  const [cafeteriaiMealDish, setCafeteriaiMealDish] = useState([]);
  const { state } = useLocation();
  const mealDishData = state?.mealDishArray;
  const skip = {
    status: false,
  };
  let extraItemTotal = 0;
  useEffect(() => {
    const getData = async () => {
      const data = await getPaymentPreferences(kitchen.id, '');
      setPaymentType(data.paymentType);
      // setPaymentMethod(data.selectedPaymentOptions);
      setPaymentTypeData(data);
    };
    getData();
  }, []);

  setLayoutOptions({ title: t('orderSummaryTitle') });
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);
  const OrderStatusesMap = OrderStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  const refreshPaymentMethods = useCallback(() => {
    useLoader(async () => {
      setPaymentMethods(await http.get('users/paymentMethods'));
      setDefaultPaymentMethod(
        (await http.get('users/defaultPaymentMethod'))?.id
      );
    });
  }, []);
  useEffect(() => {
    const fetchData1 = async () => {
      refreshPaymentMethods();
      let url = window.location.href;
      let split = url.split('+')[1];
      let split1 = split?.split('&')[0];
      if (split1) {
        const order = await http.get(`orders/${split1}`);

        if (order.id == split1) {
          const data = await http.get(
            `mealExtras/getMealExtra/${split1}/${user.id}`,
            {
              filter: {
                orderStatusId: 1,
                date: details?.date,
                mealTypeId: mealTypeId,
              },
            }
          );
          setMealExtra(data);
          setTogglePopup(true);
        }
      }
    };
    fetchData1();
  }, []);

  const { date, mealTypeId, slotId, orderId } = useParams();
  const skipAddOrder = async () => {
    setIsSkip(false);
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: details.date,
            mealTypeId: details.mealTypeId,
            // orderId: orderId,
          },
        });

        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            // text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
            //   'cancelOrderTextin'
            // )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
            //   order.mealDish.dish.kitchen.name
            // } ${t('changeOrderTextto')}  ${details.dishName}`,
            // onConfirm: () => replaceOrderNew(order.id),
            text: `Click on "OK" to change your order dishes`,
            onConfirm: () => replaceOrderNew(order.orderId),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          // handleURLABc();
          setPaymentPopup(true);
        }
      } catch (err) {
        Alert({ error: err });
      }
      // setPaymentPopup(true);
    } else {
      setExtrasPopup(false);
      makeOrder();
    }
  };
  const AddOrderNew = async () => {
    setIsSkip(true);
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: details.date,
            mealTypeId: details.mealTypeId,
            //  orderId: orderId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `Click on "OK" to change your order dishes`,
            onConfirm: () => replaceOrderNew(order.orderId),
            // text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
            //   'cancelOrderTextin'
            // )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
            //   order.mealDish.dish.kitchen.name
            // } ${t('changeOrderTextto')}  ${details.dishName}`,
            // onConfirm: () => replaceOrderNew(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          // handleURLABc();
          setPaymentPopup(true);
        }
      } catch (err) {
        Alert({ error: err });
      }
      // setPaymentPopup(true);
    } else {
      setExtrasPopup(false);
      makeOrder();
    }
  };
  const skipAddOrderPayment = () => {
    makeOrder();
    setPaymentPopup(false);
  };

  //   const MakeOrderwithoutExtras = async () => {
  //     if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
  //       try {
  //         const { data } = await http.get('orders/getAllOrder', {
  //           filter: {
  //             orderStatusId: 1,
  //             date: details.date,
  //             mealTypeId: details.mealTypeId,
  //           },
  //         });
  //         if (data.length > 0) {
  //           let order = data[0];
  //           SwalDialogs.Dialog({
  //             title: t('orderExistsTitle'),
  //             text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
  //               'cancelOrderTextin'
  //             )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
  //               order.mealDish.dish.kitchen.name
  //             } ${t('changeOrderTextto')}  ${details.dishName}`,
  //             onConfirm: () => replaceOrderNew(order.id),
  //             buttons: {
  //               showCancelButton: true,
  //               confirmButtonText: t('replaceOrderBtnLabel'),
  //               cancelButtonText: t('back'),
  //             },
  //           });
  //         } else {
  //           // handleURLABc();
  //           setPaymentPopup(true);
  //         }
  //       } catch (err) {
  //         Alert({ error: err });
  //       }
  //       // setPaymentPopup(true);
  //     } else {
  //       makeOrder();
  //     }
  //   };

  const MakeCafeteriaOrder = async () => {
    if (paymenttypeData?.selectedPaymentOptions === 3 && user.roleId !== 6) {
      try {
        const { data } = await http.get('orders/getAllOrder', {
          filter: {
            orderStatusId: 1,
            date: date,
            mealTypeId: mealTypeId,
            // orderId: orderId,
          },
        });
        if (data.length > 0) {
          let order = data[0];
          SwalDialogs.Dialog({
            title: t('orderExistsTitle'),
            text: `Click on "OK" to change your order dishes`,
            onConfirm: () => replaceOrderNew(order.orderId),
            // text: `${t('changeOrderText')} ${order.mealDish.dish.name} ${t(
            //   'cancelOrderTextin'
            // )} ${order.mealDish.meal.date} ${t('cancelOrderTextin')} ${
            //   order.mealDish.dish.kitchen.name
            // } ${t('changeOrderTextto')}  ${details.dishName}`,
            // onConfirm: () => replaceOrderNew(order.id),
            buttons: {
              showCancelButton: true,
              confirmButtonText: t('replaceOrderBtnLabel'),
              cancelButtonText: t('back'),
            },
          });
        } else {
          // handleURLABc();
          setPaymentPopup(true);
        }
      } catch (err) {
        Alert({ error: err });
      }
      // setPaymentPopup(true);
    } else {
      makeOrder();
    }
  };
  /* Fetches the MealDishes and updates 'mealDishes' */
  useEffect(() => {
    const fetchData1 = async () => {
      let url = window.location.href;
      let splitData = url.split('&')[1];

      let b = [];
      let arr = splitData?.split('?');
      arr?.forEach((el) =>
        b.push({ mealDishId: el.split('-')[0], quantity: el.split('-')[1] })
      );

      // const split_string = mealDishId.split('-');
      let array = [];
      (state?.mealDishArray ? state?.mealDishArray : b).map(async (el, i) => {
        const mealDishData = await useFetchingLoader(
          async () => await http.get(`mealsDishes/${el.mealDishId}`)
        );
        mealDishData['quantity'] = el.quantity;

        array.push(mealDishData);
        setCafeteriaiMealDish(array);
        const getMealId = mealDishData ? mealDishData.meal.id : '';
        setMealId(getMealId);
        const mealDishDetails = generateMealDishData(
          MealTypesMap_Id,
          mealDishData
        );
        if (array.length > 0) {
          let sum = array.reduce((x, y) => x + y?.dish?.price * y?.quantity, 0);

          setTotalPrice(sum);
        }
        setDetails(mealDishDetails);
      });
      let sumquantity = state?.mealDishArray.reduce(
        (x, y) => x + y?.quantity,
        0
      );
      setTotalQuantity(sumquantity);
    };
    fetchData1();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      if (slotId) {
        const mealSlot = await useFetchingLoader(
          async () => await http.get(`mealSlots/getById/${slotId}`)
        );
        setMealSlotData(mealSlot);
      }
      //}
    };
    fetchData1();
  }, [slotId]);

  // useEffect(async () => {}, []);

  /********* get extras data  */
  useEffect(() => {
    const fetchData1 = async () => {
      if (mealId) {
        const data = await useFetchingLoader(async () => {
          return await http.get(`mealExtras/getMealExtrasMeal/${mealId}`);
        });
        setExtrasData(data);
      }
    };
    fetchData1();
  }, [mealId]);

  const addOrder = async () => {
    if (isSkip === true) {
      extraItemTotal =
        addedExtras.length > 0
          ? addedExtras
              .map((el) => {
                const findExtraDish = extrsData.find((elem) => elem.id === el);
                if (findExtraDish) {
                  return findExtraDish?.price;
                }
              })
              .reduce((prev, cur) => prev + cur, 0)
          : 0;
    } else {
      extraItemTotal = 0;
    }
    const res = await http.get(`users/getUserKitchen/${user.id}`);
    if (res.KitchenId && res.roleId === 6) {
      try {
        const mealDishData = await useFetchingLoader(
          async () =>
            await http.post(`orders/create`, {
              orderMealDishData: state?.mealDishArray,
              // orderPrice: totalPrice + extraItemTotal,
              slotId: slotId,
              mealExtraId: addedExtras,
            })
        );
        const data = await http.get(
          `mealExtras/getMealExtra/${mealDishData?.id}/${user.id}`,
          {
            filter: {
              orderStatusId: 1,
              date: details.date,
              mealTypeId: details.mealTypeId,
            },
          }
        );

        setMealExtra(data);
        setExtrasPopup(false);
        setTogglePopup(true);
      } catch (err) {
        setTimeout(() => {
          Alert({ error: err });
        }, 150);
      }
    } else if (res.roleId !== 6) {
      try {
        const mealDishData = await useFetchingLoader(
          async () =>
            await http.post(`orders/create`, {
              orderMealDishData: state?.mealDishArray,
              // orderPrice: totalPrice + extraItemTotal,
              slotId: slotId,
              mealExtraId: addedExtras,
            })
        );
        const data = await http.get(
          `mealExtras/getMealExtra/${mealDishData?.id}/${user.id}`,
          {
            filter: {
              orderStatusId: 1,
              date: details.date,
              mealTypeId: details.mealTypeId,
            },
          }
        );

        setMealExtra(data);
        setExtrasPopup(false);
        setTogglePopup(true);
      } catch (err) {
        setTimeout(() => Alert({ error: err }), 150);
        return err;
      }
    } else {
      Alert({ error: t('alertForContractor') });
    }
  };

  const replaceOrder = async (orderIdToCancel) => {
    await useFetchingLoader(
      async () => await http.put(`orders/${orderIdToCancel}/cancel`)
    );
    const data = await http.get(
      `mealExtras/getMealExtra/${details.id}/${user.id}`,
      {
        filter: {
          orderStatusId: 1,
          date: details.date,
          mealTypeId: details.mealTypeId,
        },
      }
    );

    setMealExtra(data);
    await addOrder();
  };

  const replaceOrderNew = async (orderIdToCancel) => {
    await useFetchingLoader(
      async () => await http.put(`orders/${orderIdToCancel}/cancel`)
    );
    const data = await http.get(
      `mealExtras/getMealExtra/${details.id}/${user.id}`,
      {
        filter: {
          orderStatusId: 1,
          date: details.date,
          mealTypeId: details.mealTypeId,
        },
      }
    );

    setMealExtra(data);
    setPaymentPopup(true);
    // handleURLABc();
  };

  const handleextrasPopup = () => {
    setExtrasPopup(!extrasPopup);
  };

  const makeOrder = async () => {
    try {
      const { data } = await http.get('orders/getAllOrder', {
        filter: {
          orderStatusId: 1,
          date: date,
          mealTypeId: mealTypeId,
          // orderId: orderId,
        },
      });

      if (data.length > 0) {
        //let order = data[0];
        let order = data[0];
        SwalDialogs.Dialog({
          title: t('orderExistsTitle'),
          text: `Click on "OK" to change your order dishes`,
          onConfirm: () => replaceOrder(order.orderId),
          buttons: {
            showCancelButton: true,
            confirmButtonText: t('replaceOrderBtnLabel'),
            cancelButtonText: t('back'),
          },
        });
      } else {
        await addOrder();
      }
    } catch (err) {
      Alert({ error: err });
    }
  };

  const handlePayment = async () => {
    handleURLABc();
  };

  const handleURLABc = async () => {
    let url = window.location.href;
    let orders = url.split('#');
    const res = await http.post('users/paymentUrl', {
      url_redirect: frontendUrl('successfullySavedPaymentMethod'),
      paymentType: paymentType,
      currentUrl: orders[1],
      mealDishId: state?.mealDishArray,
      orderPrice: parseFloat(totalPrice) + parseFloat(extraItemTotal),
      slotId: slotId,
      mealExtraId: addedExtras,
      mealId: details.mealId,
      dishId: details.dishId,
    });
    window.location.href = res.url;
  };

  return (
    <>
      <div className="order-wrapper cafeteria-order-wrapper">
        <div className="cafeteria-title">
          <p className="order-time">
            Kitchen: {cafeteriaiMealDish[0]?.dish?.kitchen?.name}
          </p>
          <p className="order-time">
            {DatesMethods.format(cafeteriaiMealDish[0]?.meal?.date)} /{' '}
            {cafeteriaiMealDish[0]?.meal?.mealType?.display}
            {`${mealSlotData && mealSlotData?.mealTypesSlot?.from ? '/' : ''}`}
            {mealSlotData && mealSlotData?.mealTypesSlot?.from}{' '}
            {`${mealSlotData && mealSlotData?.mealTypesSlot?.from ? '-' : ''}`}{' '}
            {mealSlotData && mealSlotData?.mealTypesSlot?.to}
          </p>
        </div>
        <div className="cafeteria-ordered-list">
          {cafeteriaiMealDish.length > 0 &&
            cafeteriaiMealDish?.map((el, i) => (
              <>
                <CafeteriaDetails
                  details={el}
                  mealSlotData={mealSlotData}
                  setExtrasPopup={setExtrasPopup}
                  extrasPopup={extrasPopup}
                  extrsData={extrsData}
                  setAddedExtras={setAddedExtras}
                  addedExtras={addedExtras}
                  skipAddOrder={skipAddOrder}
                  AddOrderNew={AddOrderNew}
                  setIsSkip={setIsSkip}
                  paymentPopup={paymentPopup}
                  setPaymentPopup={setPaymentPopup}
                  paymentMethods={paymentMethods}
                  skipAddOrderPayment={skipAddOrderPayment}
                  handlePayment={handlePayment}
                  MealTypes={MealTypes}
                  t={t}
                />

                <CafeteriaOrderConfirmationPopup
                  mealExtra={mealExtra}
                  toggle={togglePopup}
                  details={cafeteriaiMealDish}
                  MealTypes={MealTypes}
                />
              </>
            ))}
        </div>
      </div>
      {cafeteriaiMealDish.length > 0 && (
        <div className="cafeteria-total-section">
          <p>
            <span>Total Quantity:</span>
            {totalQuantity && totalQuantity}
          </p>
          <p>
            <span>Total Amount:</span>
            {totalPrice && totalPrice}
          </p>
          <ActiveButton
            justifyBtn={''}
            cb={extrsData?.length > 0 ? handleextrasPopup : MakeCafeteriaOrder}
            label={t('orderConfirmation')}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  OrderStatuses: state.app.enums.OrderStatus,
  MealTypes: state.app.enums.MealType,
  user: state.user,
  kitchen: state.kitchens.current,
});
const mapDispatchToProps = {
  setLayoutOptions,
  showAlert,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CafeteriaOrderSummary);
