// https://www.ag-grid.com/react-data-grid/localisation/

const AG_GRID_LOCALE_EN = {
  // Set Filter
  selectAll: '(Select All)',
  selectAllSearchResults: '(Select All Search Results)',
  searchOoo: 'Search...',
  blanks: '(Blanks)',
  noMatches: 'No matches',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'Equals',
  notEqual: 'Not equal',
  empty: 'Choose One',

  // Number Filter
  lessThan: 'Less than',
  greaterThan: 'Greater than',
  lessThanOrEqual: 'Less than or equal',
  greaterThanOrEqual: 'Greater than or equal',
  inRange: 'In range',
  inRangeStart: 'from',
  inRangeEnd: 'to',

  // Text Filter
  contains: 'Contains',
  notContains: 'Not contains',
  startsWith: 'Starts with',
  endsWith: 'Ends with',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: 'Apply',
  resetFilter: 'Reset',
  clearFilter: 'Clear',
  cancelFilter: 'Cancel',

  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',

  // Side Bar
  columns: 'Columns',
  filters: 'Filters',

  // columns tool panel
  pivotMode: 'Pivot Mode',
  groups: 'Row Groups',
  rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
  values: 'Values',
  valueColumnsEmptyMessage: 'Drag here to aggregate',
  pivots: 'Column Labels',
  pivotColumnsEmptyMessage: 'Drag here to set column labels',

  // Header of the Default Group Column
  group: 'Group',

  // Other
  loadingOoo: 'Loading...',
  noRowsToShow: 'No Rows To Show',
  enabled: 'Enabled',

  // Menu
  pinColumn: 'Pin Column',
  pinLeft: 'Pin Left',
  pinRight: 'Pin Right',
  noPin: 'No Pin',
  valueAggregation: 'Value Aggregation',
  autosizeThiscolumn: 'Autosize This Column',
  autosizeAllColumns: 'Autosize All Columns',
  groupBy: 'Group by',
  ungroupBy: 'Un-Group by',
  resetColumns: 'Reset Columns',
  expandAll: 'Expand All',
  collapseAll: 'Close All',
  copy: 'Copy',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copy With Headers',
  paste: 'Paste',
  ctrlV: 'Ctrl+V',
  export: 'Export',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'None',
  count: 'Count',
  avg: 'Average',
  filteredRows: 'Filtered',
  selectedRows: 'Selected',
  totalRows: 'Total Rows',
  totalAndFilteredRows: 'Rows',
  more: 'More',
  to: 'to',
  of: 'of',
  page: 'Page',
  nextPage: 'Next Page',
  lastPage: 'Last Page',
  firstPage: 'First Page',
  previousPage: 'Previous Page',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Column',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',

  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',

  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',

  line: 'Line',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',

  histogramChart: 'Histogram',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',

  // ARIA
  ariaHidden: 'hidden',
  ariaVisible: 'visible',
  ariaChecked: 'checked',
  ariaUnchecked: 'unchecked',
  ariaIndeterminate: 'indeterminate',
  ariaColumnSelectAll: 'Toggle Select All Columns',
  ariaInputEditor: 'Input Editor',
  ariaDateFilterInput: 'Date Filter Input',
  ariaFilterInput: 'Filter Input',
  ariaFilterColumnsInput: 'Filter Columns Input',
  ariaFilterValue: 'Filter Value',
  ariaFilterFromValue: 'Filter from value',
  ariaFilterToValue: 'Filter to value',
  ariaFilteringOperator: 'Filtering Operator',
  ariaColumn: 'Column',
  ariaColumnGroup: 'Column Group',
  ariaRowSelect: 'Press SPACE to select this row',
  ariaRowDeselect: 'Press SPACE to deselect this row',
  ariaRowToggleSelection: 'Press Space to toggle row selection',
  ariaRowSelectAll: 'Press Space to toggle all rows selection',
  ariaToggleVisibility: 'Press SPACE to toggle visibility',
  ariaSearch: 'Search',
  ariaSearchFilterValues: 'Search filter values',

  // ARIA LABEL FOR DIALOGS
  ariaLabelColumnMenu: 'Column Menu',
  ariaLabelCellEditor: 'Cell Editor',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Select Field',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Context Menu',
  ariaLabelSubMenu: 'SubMenu',
  ariaLabelAggregationFunction: 'Aggregation Function',
};

// FIXME / TODO
const AG_GRID_LOCALE_RU = {
  // Set Filter
  selectAll: '(Select All)',
  selectAllSearchResults: '(Select All Search Results)',
  searchOoo: 'Search...',
  blanks: '(Blanks)',
  noMatches: 'No matches',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'Equals',
  notEqual: 'Not equal',
  empty: 'Choose One',

  // Number Filter
  lessThan: 'Less than',
  greaterThan: 'Greater than',
  lessThanOrEqual: 'Less than or equal',
  greaterThanOrEqual: 'Greater than or equal',
  inRange: 'In range',
  inRangeStart: 'from',
  inRangeEnd: 'to',

  // Text Filter
  contains: 'Contains',
  notContains: 'Not contains',
  startsWith: 'Starts with',
  endsWith: 'Ends with',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: 'Apply',
  resetFilter: 'Reset',
  clearFilter: 'Clear',
  cancelFilter: 'Cancel',

  // Filter Titles
  textFilter: 'Text Filter',
  numberFilter: 'Number Filter',
  dateFilter: 'Date Filter',
  setFilter: 'Set Filter',

  // Side Bar
  columns: 'Columns',
  filters: 'Filters',

  // columns tool panel
  pivotMode: 'Pivot Mode',
  groups: 'Row Groups',
  rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
  values: 'Values',
  valueColumnsEmptyMessage: 'Drag here to aggregate',
  pivots: 'Column Labels',
  pivotColumnsEmptyMessage: 'Drag here to set column labels',

  // Header of the Default Group Column
  group: 'Group',

  // Other
  loadingOoo: 'Загрузка...',
  noRowsToShow: 'Нет строк для отображения',
  enabled: 'Enabled',

  // Menu
  pinColumn: 'Pin Column',
  pinLeft: 'Pin Left',
  pinRight: 'Pin Right',
  noPin: 'No Pin',
  valueAggregation: 'Value Aggregation',
  autosizeThiscolumn: 'Autosize This Column',
  autosizeAllColumns: 'Autosize All Columns',
  groupBy: 'Group by',
  ungroupBy: 'Un-Group by',
  resetColumns: 'Reset Columns',
  expandAll: 'Expand All',
  collapseAll: 'Close All',
  copy: 'Copy',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copy With Headers',
  paste: 'Paste',
  ctrlV: 'Ctrl+V',
  export: 'Export',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'None',
  count: 'Count',
  avg: 'Average',
  filteredRows: 'Filtered',
  selectedRows: 'Selected',
  totalRows: 'Total Rows',
  totalAndFilteredRows: 'Rows',
  more: 'More',
  to: 'to',
  of: 'of',
  page: 'Page',
  nextPage: 'Next Page',
  lastPage: 'Last Page',
  firstPage: 'First Page',
  previousPage: 'Previous Page',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Column',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',

  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',

  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',

  line: 'Line',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',

  histogramChart: 'Histogram',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',

  // ARIA
  ariaHidden: 'hidden',
  ariaVisible: 'visible',
  ariaChecked: 'checked',
  ariaUnchecked: 'unchecked',
  ariaIndeterminate: 'indeterminate',
  ariaColumnSelectAll: 'Toggle Select All Columns',
  ariaInputEditor: 'Input Editor',
  ariaDateFilterInput: 'Date Filter Input',
  ariaFilterInput: 'Filter Input',
  ariaFilterColumnsInput: 'Filter Columns Input',
  ariaFilterValue: 'Filter Value',
  ariaFilterFromValue: 'Filter from value',
  ariaFilterToValue: 'Filter to value',
  ariaFilteringOperator: 'Filtering Operator',
  ariaColumn: 'Column',
  ariaColumnGroup: 'Column Group',
  ariaRowSelect: 'Press SPACE to select this row',
  ariaRowDeselect: 'Press SPACE to deselect this row',
  ariaRowToggleSelection: 'Press Space to toggle row selection',
  ariaRowSelectAll: 'Press Space to toggle all rows selection',
  ariaToggleVisibility: 'Press SPACE to toggle visibility',
  ariaSearch: 'Search',
  ariaSearchFilterValues: 'Search filter values',

  // ARIA LABEL FOR DIALOGS
  ariaLabelColumnMenu: 'Column Menu',
  ariaLabelCellEditor: 'Cell Editor',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Select Field',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Context Menu',
  ariaLabelSubMenu: 'SubMenu',
  ariaLabelAggregationFunction: 'Aggregation Function',
};

const AG_GRID_LOCALE_HE = {
  // Set Filter
  selectAll: '(בחר הכל)',
  selectAllSearchResults: '(בחר את כל תוצאות החיפוש)',
  searchOoo: 'לחפש...',
  blanks: '(ריקים)',
  noMatches: 'אין התאמה',

  // Number Filter & Text Filter
  filterOoo: 'לְסַנֵן...',
  equals: 'שווים',
  notEqual: 'לא שווה',
  empty: 'תבחר אחד',

  // Number Filter
  lessThan: 'פחות מ',
  greaterThan: 'גדול מ',
  lessThanOrEqual: 'פחות מ או שווה',
  greaterThanOrEqual: 'גדול או שווה',
  inRange: 'בטווח',
  inRangeStart: 'מ',
  inRangeEnd: 'ל',

  // Text Filter
  contains: 'מכיל',
  notContains: 'לא מכיל',
  startsWith: 'מתחיל עם',
  endsWith: 'נגמר עם',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: 'החל',
  resetFilter: 'איפוס',
  clearFilter: 'נקה',
  cancelFilter: 'ביטול',

  // Filter Titles
  textFilter: 'סינון טקסט',
  numberFilter: 'סינון מספר',
  dateFilter: 'סינון תאריכים',
  setFilter: 'החל סינון',

  // Side Bar
  columns: 'עמודות',
  filters: 'מסננים',

  // columns tool panel
  pivotMode: 'מצב Pivot',
  groups: 'קבוצות',
  rowGroupColumnsEmptyMessage: 'גרור לכאן כדי להגדיר קבוצות',
  values: 'ערכים',
  valueColumnsEmptyMessage: 'גרור לכאן כדי לסכום',
  pivots: 'תוויות עמודות',
  pivotColumnsEmptyMessage: 'גרור לכאן כדי להגדיר תוויות עמודות',

  // Header of the Default Group Column
  group: 'קבוצה',

  // Other
  loadingOoo: 'טוען...',
  noRowsToShow: 'אין שורות להצגה',
  enabled: 'מופעל',

  // Menu
  pinColumn: 'קבע עמודה',
  pinLeft: 'קבע שמאלה',
  pinRight: 'קבע ימינה',
  noPin: 'אין קיבוע',
  valueAggregation: 'סכום',
  autosizeThiscolumn: 'גודל אוטומטי של עמודה זו',
  autosizeAllColumns: 'גודל אוטומטי של כל העמודות',
  groupBy: 'קבץ לפי',
  ungroupBy: 'בטל קבוצה לפי',
  resetColumns: 'אפס עמודות',
  expandAll: 'הרחב הכל',
  collapseAll: 'סגור הכל',
  copy: 'העתקה',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'העתק עם כותרות',
  paste: 'הדבק',
  ctrlV: 'Ctrl+V',
  export: 'ייצוא',
  csvExport: 'ייצוא CSV',
  excelExport: 'ייצוא לאקסל',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'סכום',
  min: 'מינימום',
  max: 'מקסימום',
  none: 'ללא',
  count: 'כמות',
  avg: 'ממוצע',
  filteredRows: 'מסונן',
  selectedRows: 'נבחר',
  totalRows: 'סה"כ שורות',
  totalAndFilteredRows: 'שורות',
  more: 'יותר',
  to: 'עד',
  of: 'מתוך',
  page: 'עמוד',
  nextPage: 'עמוד הבא',
  lastPage: 'עמוד אחרון',
  firstPage: 'עמוד ראשון',
  previousPage: 'עמוד קודם',

  // Pivoting
  pivotColumnGroupTotals: 'סה"כ',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'תרשים ציר ומצב ציר',
  pivotChart: 'תרשים ציר',
  chartRange: 'טווח תרשים',

  columnChart: 'טור',
  groupedColumn: 'מקובצים',
  stackedColumn: 'מְגוּבָּב',
  normalizedColumn: '100% מוערמים',

  barChart: 'בָּר',
  groupedBar: 'מקובצים',
  stackedBar: 'מְגוּבָּב',
  normalizedBar: '100% מוערמים',

  pieChart: 'פַּאִי',
  pie: 'פַּאִי',
  doughnut: 'סופגניה',

  line: 'קַו',

  xyChart: 'X Y (פיזור)',
  scatter: 'לְפַזֵר',
  bubble: 'בּוּעָה',

  areaChart: 'אֵזוֹר',
  area: 'אֵזוֹר',
  stackedArea: 'מְגוּבָּב',
  normalizedArea: '100% מוערמים',

  histogramChart: 'היסטוגרמה',

  // Charts
  pivotChartTitle: 'תרשים ציר',
  rangeChartTitle: 'תרשים טווח',
  settings: 'הגדרות',
  data: 'נתונים',
  format: 'פוּרמָט',
  categories: 'קטגוריות',
  defaultCategory: '(אף אחד)',
  series: 'סִדרָה',
  xyValues: 'X Y ערכים',
  paired: 'מצב מצב מזווג',
  axis: 'צִיר',
  navigator: 'נווט',
  color: 'צֶבַע',
  thickness: 'עוֹבִי',
  xType: 'סוג X',
  automatic: 'אוֹטוֹמָטִי',
  category: 'קטגוריה',
  number: 'מספר',
  time: 'זְמַן',
  xRotation: 'x סיבוב',
  yRotation: 'סיבוב y',
  ticks: 'קרציות',
  width: 'רוֹחַב',
  height: 'גוֹבַה',
  length: 'אורך',
  padding: 'ריפוד',
  spacing: 'מרווחים',
  chart: 'תרשים',
  title: 'כותרת',
  titlePlaceholder: 'כותרת התרשים - לחץ פעמיים כדי לערוך',
  background: 'רקע כללי',
  font: 'גוֹפָן',
  top: 'חלק עליון',
  right: 'ימין',
  bottom: 'תַחתִית',
  left: 'שמאלה',
  labels: 'תוויות',
  size: 'גודל',
  minSize: 'גודל מינימלי',
  maxSize: 'גודל מקסימלי',
  legend: 'אגדה',
  position: 'עמדה',
  markerSize: 'גודל מרקר',
  markerStroke: 'מרקר שבץ',
  markerPadding: 'ריפוד מרקר',
  itemSpacing: 'מרווח פריטים',
  itemPaddingX: 'ריפוד פריט X',
  itemPaddingY: 'ריפוד פריט Y',
  layoutHorizontalSpacing: 'מרווח אופקי',
  layoutVerticalSpacing: 'מרווח אנכי',
  strokeWidth: 'רוחב שבץ',
  offset: 'לְקַזֵז',
  offsets: 'קיזוזים',
  tooltips: 'עצות כלים',
  callout: 'לקרוא',
  markers: 'סמנים',
  shadow: 'צֵל',
  blur: 'לְטַשׁטֵשׁ',
  xOffset: 'X היסט',
  yOffset: 'Y Offset',
  lineWidth: 'רוחב קו',
  normal: 'נוֹרמָלִי',
  bold: 'נוֹעָז',
  italic: 'נטוי',
  boldItalic: 'מודגש נטוי',
  predefined: 'מוגדר מראש',
  fillOpacity: 'מילוי אטימות',
  strokeOpacity: 'אטימות קו',
  histogramBinCount: 'ספירת פחים',
  columnGroup: 'טור',
  barGroup: 'בָּר',
  pieGroup: 'פַּאִי',
  lineGroup: 'קַו',
  scatterGroup: 'X Y (פיזור)',
  areaGroup: 'אֵזוֹר',
  histogramGroup: 'היסטוגרמה',
  groupedColumnTooltip: 'מקובצים',
  stackedColumnTooltip: 'מְגוּבָּב',
  normalizedColumnTooltip: '100% מוערמים',
  groupedBarTooltip: 'מקובצים',
  stackedBarTooltip: 'מְגוּבָּב',
  normalizedBarTooltip: '100% מוערמים',
  pieTooltip: 'פַּאִי',
  doughnutTooltip: 'סופגניה',
  lineTooltip: 'קַו',
  groupedAreaTooltip: 'אֵזוֹר',
  stackedAreaTooltip: 'מְגוּבָּב',
  normalizedAreaTooltip: '100% מוערמים',
  scatterTooltip: 'לְפַזֵר',
  bubbleTooltip: 'בּוּעָה',
  histogramTooltip: 'היסטוגרמה',
  noDataToChart: 'אין נתונים זמינים לתרשים',
  pivotChartRequiresPivotMode: 'תרשים ציר מחייב הפעלת מצב ציר.',
  chartSettingsToolbarTooltip: 'תַפרִיט',
  chartLinkToolbarTooltip: 'מקושר ל-Grid',
  chartUnlinkToolbarTooltip: 'לא מקושר לרשת',
  chartDownloadToolbarTooltip: 'הורד תרשים',

  // ARIA
  ariaHidden: 'מוּסתָר',
  ariaVisible: 'גלוי',
  ariaChecked: 'בָּדוּק',
  ariaUnchecked: 'לא בדוק',
  ariaIndeterminate: 'לֹא קָבוּעַ',
  ariaColumnSelectAll: 'החלף בחר את כל העמודות',
  ariaInputEditor: 'עורך קלט',
  ariaDateFilterInput: 'קלט מסנן תאריך',
  ariaFilterInput: 'קלט מסנן',
  ariaFilterColumnsInput: 'קלט עמודות סינון',
  ariaFilterValue: 'ערך סינון',
  ariaFilterFromValue: 'סינון מתוך ערך',
  ariaFilterToValue: 'סינון לפי ערך',
  ariaFilteringOperator: 'מפעיל סינון',
  ariaColumn: 'טור',
  ariaColumnGroup: 'קבוצת עמודות',
  ariaRowSelect: 'הקש על SPACE כדי לבחור בשורה זו',
  ariaRowDeselect: 'הקש על רווח כדי לבטל את הבחירה בשורה זו',
  ariaRowToggleSelection: 'הקש על הרווח כדי לשנות את בחירת השורה',
  ariaRowSelectAll: 'הקש על הרווח כדי להחליף את כל בחירת השורות',
  ariaToggleVisibility: 'הקש על SPACE כדי לשנות את הראות',
  ariaSearch: 'לחפש',
  ariaSearchFilterValues: 'חיפוש ערכי מסנן',

  // ARIA LABEL FOR DIALOGS
  ariaLabelColumnMenu: 'תפריט עמודות',
  ariaLabelCellEditor: 'עורך תאים',
  ariaLabelDialog: 'דיאלוג',
  ariaLabelSelectField: 'בחר שדה',
  ariaLabelTooltip: 'טיפים לכלי עבודה',
  ariaLabelContextMenu: 'תפריט הקשר',
  ariaLabelSubMenu: 'תפריט משנה',
  ariaLabelAggregationFunction: 'פונקציית צבירה',
};

export default {
  en: AG_GRID_LOCALE_EN,
  he: AG_GRID_LOCALE_HE,
  ru: AG_GRID_LOCALE_RU,
};
