import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

const percentToDisplay = (value, outOfValue) =>
  outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;

const PercentData = ({ value, outOfValue, label }) => {
  value = value ?? 0;
  outOfValue = outOfValue ?? 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>{`${label}:${value}`}</p>
        <p className={'percent'}>{`(${percentToDisplay(
          value,
          outOfValue
        )}%)`}</p>
      </div>
    </>
  );
};

const MealDishSummary = ({ mealData, currentLanguage }) => {
  const { t } = useTranslation();

  const d = { ...mealData.mealDish };

  const propertyValues = Object.values(d);
  let filter = propertyValues.filter((ele) => ele.isChefMealDish === true);
  return (
    <div className={'meal-summary'}>
      <p className={'inventory'}>
        {`${t('chefDish')}:${
          filter.length > 0 ? filter.map((el) => el.name[1]) : 'none'
        }`}
      </p>
      <Tippy
        content={
          <>
            {propertyValues && (
              <div className="hover-popup">
                {propertyValues &&
                  propertyValues.map((el) => (
                    <p>{el.name[currentLanguage.id]}</p>
                  ))}
              </div>
            )}
          </>
        }
      >
        <p className={'inventory'} id="myButton">
          {`${t('dishes')}: ${propertyValues ? propertyValues.length : 0}`}
        </p>
      </Tippy>
      <p className={'inventory'}>{`${t('inventory')}: ${
        mealData.inventory ? mealData.inventory : 0
      }`}</p>

      <PercentData
        label={t('orders')}
        value={mealData?.ordered}
        outOfValue={mealData?.inventory}
      />
      <PercentData
        label={t('realized')}
        value={mealData?.realized}
        outOfValue={mealData?.inventory}
      />
    </div>
  );
};

export default MealDishSummary;
