import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLoader } from '../../store/app/actions';
import http from '../../services/http';
import { ActiveButton } from '../../components/WE-Button';
import { useHistory } from 'react-router';

const SuccessfullySavedPaymentMethod = ({ useLoader }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const history = useHistory();

  useEffect(() => {
    useLoader(async () => {
      const searchParams = new URLSearchParams(
        window.location.hash.split('?')[1]
      );
      const params = {};
      searchParams.forEach((value, key) => (params[key] = value));
      try {
        await http.post('users/paymentMethod', params);
        setStatus(t('successfullySavedPaymentMethod'));
      } catch (err) {
        setStatus(t('errorSavingPaymentMethod'));
      }
    });

    return () => {};
  }, []);

  return (
    <>
      {status}{' '}
      <ActiveButton
        arrow={true}
        label={t('viewStoredPaymentMethods')}
        cb={() => {
          history.push('/paymentManagement');
        }}
      />
    </>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = { useLoader };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessfullySavedPaymentMethod);
