import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import http from '../../services/http';
import moment from 'moment';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '35vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ViewContractorDetails = ({
  open,
  details,
  handleClose,
  UserStatuses,
  setKitchensIds,
  setPayload,
  Kitchens,
  Roles,
  handleOpen,
  handleConfirmation,
  ResendVerification,
  CopyDataUrl,
  handleWallet,
  handleOrderLink,
  setMealTypesIds,
  cibusEnabled,
  fieldNamesSettings,
  contractorList,
  allUsers,
}) => {
  const { t, i18n } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [getMealTypes, setGetMealTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const getMealType = await http.get(`mealTypes`);
      setGetMealTypes(getMealType?.data);
      const data = await http.get('audit/getByParams', {
        filter: {
          entity: 'user',
          actionEntityId: details.userId,
        },
      });
      setHistoryData(data.data);
    };
    if (details?.userId) {
      fetchData();
    }
  }, [details]);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="view-more-popup"
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('userManagement')}
            </Typography>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Box id="modal-modal-description">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label={t('mainInfo')} {...a11yProps(0)} />

                <Tab label={t('history')} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabIndex} index={0}>
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="view-detail-content">
                    <div className="user-details-block">
                      <h6>{t('name')}:</h6>
                      <p>
                        {details?.firstName ? details?.firstName : '--'}{' '}
                        {details?.lastName ? details?.lastName : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('email')}:</h6>
                      <p>{details?.email ? details?.email : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>
                        {fieldNamesSettings?.find(
                          (el) => el.value === 'employeeId'
                        )
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'employeeId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('employeeNo')}
                        :
                      </h6>
                      <p>{details?.employeeId ? details?.employeeId : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>
                        {fieldNamesSettings?.find((el) => el.value === 'cardId')
                          ? fieldNamesSettings
                              ?.find((el) => el.value === 'cardId')
                              ?.translations.find(
                                (el) => el.language.shortCode == i18n.language
                              )?.text
                          : t('cardId')}
                        :
                      </h6>
                      <p>{details?.cardId ? details?.cardId : '--'}</p>
                    </div>

                    <div className="user-details-block">
                      <h6>{t('userStatus')}:</h6>
                      <p>{details?.Status ? details?.Status : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('kitchenname')}:</h6>
                      <p>{details?.Kitchens ? details?.Kitchens : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('contractors')}:</h6>
                      <p>
                        {details?.Contractors
                          ? details?.Contractors.map((el) => el.name).join(', ')
                          : '--'}
                      </p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('registrationDate')}:</h6>
                      <p>{details?.createdAt ? details?.createdAt : '--'}</p>
                    </div>
                    <div className="user-details-block">
                      <h6>{t('phone')}:</h6>
                      <p>{details?.phone ? details?.phone : '--'}</p>
                    </div>
                    {cibusEnabled && (
                      <div className="user-details-block">
                        <h6>
                          {fieldNamesSettings?.find(
                            (el) => el.value === 'magneticStripe'
                          )
                            ? fieldNamesSettings
                                ?.find((el) => el.value === 'magneticStripe')
                                ?.translations.find(
                                  (el) => el.language.shortCode == i18n.language
                                )?.text
                            : t('magneticStripe')}
                          :
                        </h6>
                        <p>
                          {details?.magneticStripe
                            ? details?.magneticStripe
                            : '--'}
                        </p>
                      </div>
                    )}
                    <div className="user-details-block">
                      <h6>{t('action')}:</h6>
                      <p>
                        <div className="actions-btns">
                          <i
                            class="las la-pen"
                            title="edit"
                            onClick={() => {
                              let ids = details.kitchensIds;

                              setKitchensIds(ids);
                              if (details['RoleId'] === 6) {
                                setShowContractor(true);
                              }
                              setPayload({
                                userId: details['userId'],
                                card: details['cardId'],
                                email: details['email'],
                                fName: details['firstName'],
                                lName: details['lastName'],
                                phone: details['phone']
                                  ? details['phone'].split('-').join('')
                                  : details['phone'],
                                kitchensIds: ids,
                                employeeId: details['employeeId'],
                                allContractors: details['allContractors'],
                                contractors: details['Contractors'],
                                role: [
                                  Roles.find(
                                    (el) => el.display === details['Role']
                                  )?.id,
                                ],
                                status: details?.Status,
                                contractorsIds: [],
                                magneticStripe: details?.magneticStripe,
                              });
                              handleOpen();
                            }}
                          ></i>
                          <i
                            class="las la-user-circle"
                            onClick={() => {
                              let ids = details.kitchensIds;
                              setKitchensIds(ids);
                              if (details['RoleId'] === 6) {
                                setShowContractor(true);
                              }
                              setPayload({
                                userId: details['userId'],
                                card: details['cardId'],
                                kitchensIds: ids,
                                employeeId: details['employeeId'],
                                allContractors: details['allContractors'],
                                contractors: details['Contractors'],
                                role: [
                                  Roles.find(
                                    (el) => el.display === details['Role']
                                  )?.id,
                                ],
                                status: details?.Status,
                                contractorsIds: [],
                                magneticStripe: details?.magneticStripe,
                              });
                              handleOpen('recreate');
                            }}
                          ></i>
                          <i
                            class="las la-trash"
                            title="delete"
                            onClick={() =>
                              handleConfirmation(details['userId'])
                            }
                          ></i>
                          {UserStatuses?.find(
                            (el) => el.display === details?.Status
                          )?.value === 'created' ||
                          UserStatuses?.find(
                            (el) => el.display === details?.Status
                          )?.value === 'pending' ? (
                            <i
                              class="las la-copy"
                              title="Copy"
                              onClick={() => ResendVerification(details)}
                            ></i>
                          ) : (
                            ''
                          )}
                          {UserStatuses?.find(
                            (el) => el.display === details?.Status
                          )?.value === 'created' ||
                          UserStatuses?.find(
                            (el) => el.display === details?.Status
                          )?.value === 'pending' ? (
                            <i
                              class="las la-share-square"
                              title="Resend"
                              onClick={() => CopyDataUrl(details)}
                            ></i>
                          ) : (
                            ''
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1} class="overflow-hidden">
              {historyData && historyData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('action')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2">
                          <div className="d-flex m-0 w-100">
                            <strong className="history-column-fieldname">{`${t(
                              'fieldName'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'oldValue'
                            )}`}</strong>
                            <strong className="history-column-values">{`${t(
                              'newValue'
                            )}`}</strong>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyData.map((row) => (
                        <StyledTableRow
                          key={row.name}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(row?.createdAt).format('HH:mm:ss')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{row?.madeBy}</p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {t(row.typeAction)}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <div className="d-flex m-0 flex-column gap-2">
                              {Object.keys(row.diff).length > 0
                                ? Object.entries(row.diff).map((el) => (
                                    <div>
                                      <StyledTableCell className="p-1 history-column-fieldname border-bottom-0">
                                        <p className="audit-details-text">{`${
                                          t(el[0]) || '--'
                                        }:`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                        <p className="pl-1 pr-1 audit-details-text">{`${
                                          el[1] &&
                                          el[1]?.old &&
                                          el[0] === 'contractorsIds'
                                            ? el[1]?.old.length === 0
                                              ? '--'
                                              : contractorList
                                                  .filter((obj1) =>
                                                    el[1]?.old.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                            : el[1] &&
                                                el[1]?.old &&
                                                el[0] === 'kitchensIds'
                                              ? el[1]?.old.length === 0
                                                ? '--'
                                                : Kitchens.filter((obj1) =>
                                                    el[1]?.old.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                    .map((el) => el.name)
                                                    .join(', ')
                                              : el[1] &&
                                                  el[1]?.old &&
                                                  el[0] === 'roleId'
                                                ? Roles.filter(
                                                    (obj1) =>
                                                      el[1]?.old === obj1.id
                                                  ).map((el) => el.display)[0]
                                                : el[1] &&
                                                    el[1]?.old &&
                                                    el[0] === 'userStatusId'
                                                  ? UserStatuses.filter(
                                                      (obj1) =>
                                                        el[1]?.old === obj1.id
                                                    ).map((el) => el.display)[0]
                                                  : el[1] &&
                                                      el[1]?.old &&
                                                      el[0] === 'mealTypeId'
                                                    ? getMealTypes
                                                        .filter((obj1) =>
                                                          el[1]?.old.includes(
                                                            obj1.id
                                                          )
                                                        )
                                                        .map(
                                                          (el) => el?.display
                                                        )
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.old &&
                                                        el[0] === 'invitedBy'
                                                      ? allUsers.find((elem) =>
                                                          elem.value ==
                                                          Array.isArray(
                                                            el[1]?.old
                                                          )
                                                            ? el[1]?.old[0]
                                                            : el[1]?.old
                                                        )?.label
                                                      : el[1]?.old ||
                                                          typeof el[1]?.old ===
                                                            'boolean'
                                                        ? el[1]?.old
                                                        : '--'
                                        }`}</p>
                                      </StyledTableCell>
                                      <StyledTableCell className="p-1 history-column-values border-bottom-0 text-break">
                                        <p className="pl-1 pr-1 audit-details-text">{`${
                                          el[1] &&
                                          el[1]?.new &&
                                          el[0] === 'contractorsIds'
                                            ? el[1]?.new.length === 0
                                              ? '--'
                                              : contractorList
                                                  .filter((obj1) =>
                                                    el[1]?.new.find(
                                                      (obj2) => obj2 === obj1.id
                                                    )
                                                  )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                            : el[1] &&
                                                el[1]?.new &&
                                                el[0] === 'kitchensIds'
                                              ? Kitchens.filter((obj1) =>
                                                  el[1]?.new.find(
                                                    (obj2) => obj2 === obj1.id
                                                  )
                                                )
                                                  .map((el) => el.name)
                                                  .join(', ')
                                              : el[1] &&
                                                  el[1]?.new &&
                                                  el[0] === 'roleId'
                                                ? Roles.filter(
                                                    (obj1) =>
                                                      (el[1]?.new[0] ??
                                                        el[1]?.new) === obj1.id
                                                  ).map((el) => el.display)[0]
                                                : el[1] &&
                                                    el[1]?.new &&
                                                    el[0] === 'userStatusId'
                                                  ? UserStatuses.filter(
                                                      (obj1) =>
                                                        el[1]?.new === obj1.id
                                                    ).map((el) => el.display)[0]
                                                  : el[1] &&
                                                      el[1]?.new &&
                                                      el[0] === 'mealTypeId'
                                                    ? getMealTypes
                                                        .filter((obj1) =>
                                                          el[1]?.new.includes(
                                                            obj1.id
                                                          )
                                                        )
                                                        .map((el) => el.display)
                                                        .join(', ')
                                                    : el[1] &&
                                                        el[1]?.new &&
                                                        el[0] === 'invitedBy'
                                                      ? allUsers.find((elem) =>
                                                          elem.value ===
                                                          Array.isArray(
                                                            el[1]?.new
                                                          )
                                                            ? el[1]?.new[0]
                                                            : el[1]?.new
                                                        )?.label
                                                      : el[1]?.new ||
                                                          typeof el[1]?.new ===
                                                            'boolean'
                                                        ? el[1]?.new
                                                        : '--'
                                        }`}</p>
                                      </StyledTableCell>
                                    </div>
                                  ))
                                : '--'}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
          {/* <div className="view-popup-btn">
            <button className="close-modal-btn" onClick={handleClose}>
              {t('close')}
            </button>
          </div> */}
        </Box>
      </Modal>
    </>
  );
};
export default ViewContractorDetails;
