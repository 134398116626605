import React from 'react';
import CardBackground from '../assets/images/food_bg.png';

const LobbyCard = ({ title, subTitle, mainContent, actionBtn }) => {
  return (
    <div
      className="item kitchen-lobby-card shadow-card"
      style={{ backgroundImage: `url(${CardBackground})` }}
    >
      <h4 className="title">{title}</h4>
      <p className="sub-title">{subTitle}</p>
      <section className={'content'}>{mainContent}</section>
      {actionBtn}
    </div>
  );
};

export { LobbyCard };
