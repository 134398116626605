import React from 'react';
import { useHistory } from 'react-router-dom';
import DatesMethods from '../../services/dates.js';
import enums from '../../services/enums';
import { useTranslation } from 'react-i18next';

import { StaticStars } from '../RatingStars.jsx';
import { ActiveButton, DisabledButton } from '../WE-Button.jsx';
import DishImage from '../../screens/client/DishImage';
import Chip from '@material-ui/core/Chip';

/* Mapping mealDishData to relevant values */
const generateMealDishData = (MealTypesMap_Id, mealDishData, mealSlotId) => {
  return {
    id: mealDishData.id,
    dishName: mealDishData.dish.name,
    mealTypeId: mealDishData.meal.mealTypeId,
    mealTypeToDisplay: MealTypesMap_Id[mealDishData.meal.mealTypeId],
    dateToDisplay: DatesMethods.format(mealDishData.meal.date),
    date: mealDishData.meal.date,
    dishDescription: mealDishData.dish.description,
    dishImage: mealDishData.dish.image,
    dishRate: mealDishData.dish.rating || -1,
    numOfRaters: mealDishData.dish.numOfRaters,
    mealDishStatus: enums.display(
      'MealDishStatus',
      mealDishData.mealDishStatusId
    ),
    mealId: mealDishData.mealId,
    dishId: mealDishData.dishId,
    dishTags: mealDishData.dish.tags,
    dishPrice: mealDishData.dish.price,
    dishCategory: enums.display(
      'DishCategory',
      mealDishData.dish.dishCategoryId
    ),
    mealSlotId: mealSlotId,
    // kitchenName:mealDishData.meal.dish.kitchen.name
  };
};

function Dish({ MealTypesMap_Id, mealDishData, mealSlotId, selected = false }) {
  const history = useHistory();
  const { t } = useTranslation();
  const data = generateMealDishData(MealTypesMap_Id, mealDishData, mealSlotId);

  // Navigates to the 'Order Summary' page
  const goToOrderSummaryHandler = () => {
    if (mealDishData.ordered < mealDishData.inventory && !selected) {
      history.push(
        `/orderSummary/${data.id}${
          data?.mealSlotId != undefined ? '/' + data?.mealSlotId : ''
        }`
      );
    }
  };

  return (
    <div
      className={`dish-single-card shadow-card ${
        mealDishData.ordered < mealDishData.inventory && !selected
          ? 'pointer'
          : ''
      } ${selected ? 'current-selected' : ''}`}
      onClick={goToOrderSummaryHandler}
    >
      <DishImage
        fileName={data.dishImage}
        isChefMealDish={mealDishData.isChefMealDish}
        size={{ height: '200px' }}
      />

      {/* Type, Name and Rating*/}
      <div className="dish-details">
        <div className={'section1'}>
          <p className={'dish-name dish-text'}>{data.dishName}</p>
          <p className={'dish-category'}>{data.dishCategory}</p>
        </div>
        <div className="reservation-dish-rating">
          <StaticStars
            rating={data.dishRate || -1}
            numOfRaters={data.numOfRaters}
          />
          {mealDishData.dish.price ? (
            <p style={{ fontSize: '22px' }}> ₪{mealDishData.dish.price}</p>
          ) : null}
        </div>
        <div className={'top'}>
          {/*<p className='dish-mealType dish-text'>{data.mealTypeToDisplay}</p>*/}
        </div>
      </div>
      <div style={{ marginBottom: '5px' }}>
        {mealDishData.dish.tags
          .filter((tag) => tag.isPublic)
          .map((tag) => (
            <Chip size="small" label={tag.displayName} />
          ))}
      </div>

      {/*<div className='dish-description'>*/}
      {/*    <p className="dish-text">{data.dishDescription}</p>*/}
      {/*</div>*/}

      {/*Dish button*/}
      {selected ? (
        <DisabledButton label={t('dishIsOrdered')} />
      ) : mealDishData.ordered < mealDishData.inventory ? (
        <ActiveButton
          label={t('additionalInfo')}
          additionalClassName={'text'}
          cb={goToOrderSummaryHandler}
          arrow={true}
        />
      ) : (
        <DisabledButton
          label={t('mealRanOut')}
          additionalClassName={'finished'}
        />
      )}
    </div>
  );
}

export default Dish;
export { generateMealDishData };
