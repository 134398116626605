import action from '../action.js';
import { SAVE_SECURITY } from './types';
import Http from '../../services/http';
import Storage from '../../services/storage';

export function saveSecuritySetting({ whiteLists, kitchenId }) {
  return action(async (dispatch) => {
    const token = await Storage.get('token');
    if (token) {
      Http.setAuthorizationHeader(token);
      let securitySetting = await Http.post('securitySettings/create', {
        whiteLists,
        token,
        kitchenId,
      });

      dispatch({
        type: SAVE_SECURITY,
        payload: { strWhiteLists: securitySetting.whitelistIPs },
      });
    } else {
      return null;
    }

    return;
  });
}
