import React, { useMemo, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../WE-Button';
import {
  validateEmployee,
  validateEmployeenew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { StaticStars } from '../RatingStars';
import image from '../../assets/images/eat-2.png';
import WEIcon from '../WE-IconButton';
import { Dialog } from '../Dialogs/SwalDialogs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};

const PaymentMethod = ({
  paymentMethods,
  paymentPopup,
  setPaymentPopup,
  t,
  handleClose,
  skipAddOrderPayment,
  handlePayment,
  cancellationTime,
}) => {
  return (
    <Modal
      open={paymentPopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup copymeal-popup  check-payment-popup"
    >
      <Box sx={style}>
        {paymentMethods.length > 0 ? (
          <div className="extras-listing-section">
            <div className="close-btn">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
            <h4>{t('paymentInfo')}</h4>
            <div className={'extras-meal-management'}>
              <p>
                {t('paymentConfirm')} {paymentMethods[0].last4Digits}
                <br />
                <br />
                {cancellationTime && (
                  <>
                    {t('cancellationIsAvaiableUntill')} {cancellationTime}{' '}
                    {t('ofTheSame')}
                  </>
                )}
              </p>
            </div>

            <div className="extras-button other-button">
              <button onClick={skipAddOrderPayment}>{t('confirm')}</button>
              {/* <button onClick={skipAddOrder}>{t('skip')}</button> */}
            </div>
          </div>
        ) : (
          <div className="extras-listing-section">
            <div className="close-btn">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
            <h4>{t('paymentInfo')}</h4>
            <div className={'extras-meal-management'}>
              <p>{t('paymentAlert')}</p>
            </div>
            <div className="extras-button other-button">
              <button onClick={handlePayment}>{t('addMethod')}</button>
              {/* <button onClick={skipAddOrder}>{t('skip')}</button> */}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default PaymentMethod;
