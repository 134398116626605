import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployee } from '../../services/validators';
import cancel from '../../assets/images/close.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  p: 4,
  borderRadius: '5px',
};

const CopyUrlPopup = ({
  open,
  handleClose,
  resendData,
  handleCloseUrl,
  copied,
  setCopied,
}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(resendData);
  });
  const handleCopied = () => {
    setCopied(true);
  };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>Copy URL</h6>
          <div className="close-button">
            <img src={cancel} alt="close" onClick={handleCloseUrl} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="copy-url">
            <p>{resendData && resendData.resetPasswordUrl}</p>
            <CopyToClipboard text={value && value.resetPasswordUrl}>
              <button className="copy-btn" onClick={handleCopied}>
                {copied ? 'Copied!' : 'Copy'}
              </button>
            </CopyToClipboard>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default CopyUrlPopup;
