import React, { useMemo, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import {
  validateEmployee,
  validateEmployeenew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import image from '../../assets/images/eat-2.png';
import successIcon from '../../assets/images/icons/success.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};

const CibusPaymentSuccessPopup = ({
  showPaymentPopup,
  t,
  handlePaymentSuccessPopupClose,
}) => {
  return (
    <Modal
      open={showPaymentPopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="payment-info-popup "
    >
      <Box sx={style}>
        <div className="extras-listing-section">
          <div className="close-btn">
            <img
              src={cancel}
              alt="close"
              onClick={handlePaymentSuccessPopupClose}
            />
          </div>
          <div className="payment-info-body">
            <img src={successIcon} alt="success-icon" />
            <p>{t('paymentSuccessMessage')}</p>
          </div>

          <div className="view-more-popup">
            <div className="view-popup-btn">
              {' '}
              <button
                className="close-modal-btn"
                onClick={handlePaymentSuccessPopupClose}
              >
                {t('ok')}{' '}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CibusPaymentSuccessPopup;
