import types from './types';

// Set initial state
const initialState = {
  options: null,
  current: null,
};

export function languagesReducer(state = initialState, action) {
  switch (action.type) {
    case types.INIT_LANGUAGES:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
}
