import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles/index';
import Rating from '@material-ui/lab/Rating/index';
// From link: https://material-ui.com/components/rating/

/*  Dynamic Stars   */

const useStylesDynamic = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '150px',
    justifyContent: 'center',
    fontSize: '14px',
    padding: '0 5px',
  },

  greenStars: {
    color: '#14cd31',
    direction: 'ltr',
  },
}));

const DynamicStars = ({ cb, value }) => {
  const classes = useStylesDynamic();

  const handleChange = (event, value) => {
    cb(value);
  };

  return (
    <div className={classes.root}>
      <Rating
        name="ratingDynamic"
        className={classes.greenStars}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

/*  Static Stars  */

const useStylesStatic = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minWidth: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
  },

  greenStars: {
    color: '#14cd31',
    direction: 'rtl',
    transform: 'scaleX(-1)',
  },
}));

// Todo - Fix half stars
function StaticStars({
  numOfRaters,
  rating,
  size = 'small',
  showNumOfRaters = true,
}) {
  const { t } = useTranslation();
  const classes = useStylesStatic();

  const mapSizeToFontSize = {
    small: '0.7em',
    medium: '1em',
    large: '1.2em',
  };

  return (
    <div className={'rating-wrapper'}>
      <div className={`${classes.root} makeStylesRating`}>
        {/* If there is no rating don't show stars */}
        <>
          {/* <Rating
            name="rating"
            className={classes.greenStars}
            size={size}
            value={rating}
            precision={1}
            readOnly
          /> */}
          {showNumOfRaters && (
            <>
              <p
                style={{ fontSize: mapSizeToFontSize[size] }}
                className="p-mb-0"
              >{`(${numOfRaters ?? 0}) ${t('raters')}`}</p>
              {/* <p style={{ fontSize: mapSizeToFontSize[size] }}>{`(${
                numOfRaters ?? 0
              })`}</p> */}
            </>
          )}
        </>
      </div>
    </div>
  );
}

export { StaticStars, DynamicStars };
