import React, { useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployee,
  validateEmployeenew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  p: 4,
  borderRadius: '5px',
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        // disabled={!editMode}
        type={type}
      />
    </>
  );
};

const AddEmployeePopup = ({
  open,
  handleClose,
  handleAddNewUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  setIsEdit,
  isUpdate,
  userData,
  setKitchensIds,
  kitchensIds,
  t,
  errors,
  setErrors,
  user,
}) => {
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const RolesMap_ValueId = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});

  const [role] = useState(4);
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });

  const handleChange = (name, value) => {
    const { isValid, error } = validateEmployee(value, name);
    setErrors({ ...errors, ...error });
    setPayload({ ...payload, [name]: value });
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      firstName: payload.fName,
      lastName: payload.lName,
      email: payload.email,
      phone: payload.phone,
      // userStatusId:userData.find(el => el.id === payload.userId)?.userStatusId,
      userStatusId: status,
      // kitchensIds: userData
      //   .find((el) => el.id === payload.userId)
      //   ?.kitchens.map((ele) => ele.id),
      password: payload.password,
      cardId: payload.card,
      employeeId: payload.employeeId,
      roleId: RolesMap_ValueId.employee,
      kitchensIds,
      connectTeam: 0,
      // value: status,
    };
    const { isValid, errors } = validateEmployeenew(
      details,
      cardNumberLengthForUser,
      employeeNumberLengthForUser
    );
    setErrors(errors);
    if (isValid) {
      await handleAddNewUser({ ...details });
      // handleClose();
    }
  };

  const CheckPassword = (value) => {
    if (
      value.label === 'Active' &&
      (payload.password === '' || !payload.password)
    ) {
      setPError(true);
      setErrors({ ...errors, password: 'Password is required' });
      setPayload({ ...payload, status: 'Active' });
    } else {
      setPError(false);
      setStatus(value);
      setPayload({ ...payload, status: value.label });
      setErrors({ ...errors, password: '' });
    }
  };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{isUpdate ? t('Update Employee') : t('addEmployee')}</h6>
          <div className="close-button">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <form>
            <div className="item">
              <div className="input-field">
                <GetInputField
                  placeholder={t('firstName')}
                  label={t('firstName')}
                  name="fName"
                  type="text"
                  value={payload['fName']}
                  cb={(value) => {
                    handleChange('fName', value);
                  }}
                />
                {errors && (
                  <p className="error-msg">
                    {errors.fName || errors.firstName}
                  </p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('lastName')}
                  label={t('lastName')}
                  name="lName"
                  type="text"
                  value={payload['lName']}
                  cb={(value) => {
                    handleChange('lName', value);
                  }}
                  required
                />
                {errors && (
                  <p className="error-msg">{errors.lName || errors.lastName}</p>
                )}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('phone')}
                  label={t('phone')}
                  name="phone"
                  type="number"
                  value={payload['phone']?.replace('+', '')}
                  cb={(value) => {
                    handleChange('phone', value);
                  }}
                  required
                />
                {errors && (
                  <p className="error-msg">{errors.phone || errors.phone}</p>
                )}
              </div>

              <div className="input-field">
                <GetInputField
                  placeholder={t('email')}
                  label={t('email')}
                  name="email"
                  type="email"
                  value={payload['email']}
                  cb={(value) => {
                    handleChange('email', value);
                  }}
                  required
                />
                {errors && <p className="error-msg">{errors.email}</p>}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('password')}
                  label={t('password')}
                  name="password"
                  type="password"
                  value={payload['password']}
                  cb={(value) => {
                    handleChange('password', value);
                  }}
                  required
                />
                {/* {pError && <p className="error-msg">Password is required</p>} */}
                {(errors || pError) && (
                  <p className="error-msg">{errors.password}</p>
                )}
              </div>
              <div className="input-field">
                <label>{t('kitchenname')}</label>
                <Select
                  isMulti
                  name={'kitchensIds'}
                  value={kitchensIds?.map((kitchenId) => {
                    return { value: kitchenId, label: KitchensMap[kitchenId] };
                  })}
                  onChange={(newData) => {
                    setKitchensIds(newData.map((k) => k.value));
                    setPayload({
                      ...payload,
                      kitchensIds: newData.map((k) => k.value),
                    });
                  }}
                  options={Kitchens.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.name };
                  })}
                  placeholder={t('kitchenname')}
                  styles={SelectStyles}
                  required
                />
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('employeeNo')}
                  label={t('employeeNo')}
                  name="employee"
                  type="number"
                  value={payload['employeeId']}
                  cb={(value) => {
                    handleChange('employeeId', value);
                  }}
                  required
                />
                {errors && <p className="error-msg">{errors.employee}</p>}
              </div>
              <div className="input-field">
                <GetInputField
                  placeholder={t('cardNo')}
                  label={t('cardNo')}
                  name="card"
                  type="number"
                  value={payload['card']}
                  cb={(value) => {
                    handleChange('card', value);
                  }}
                  required
                />
              </div>
              {user.roleId === 1 || isUpdate ? (
                <div className="input-field">
                  <label>{t('status')}</label>
                  {/* <Select
                name={'status'}
                value={ { value: 1, label: StatusMap[1] }}
                placeholder={t('status')}
                styles={SelectStyles}
                isMulti
                required
              /> */}
                  <Select
                    name={'status'}
                    defaultValue={
                      UserStatuses.find((el) => el.display === payload.status)
                        ? {
                            value: UserStatuses.find(
                              (el) => el.display === payload.status
                            )?.id,
                            label: UserStatuses.find(
                              (el) => el.display === payload.status
                            )?.display,
                          }
                        : { value: 1, label: StatusMap[1] }
                    }
                    onChange={(newData) => {
                      CheckPassword(newData);
                    }}
                    options={UserStatuses.map((kitchen) => {
                      return { value: kitchen.id, label: kitchen.display };
                    })}
                    placeholder={t('status')}
                    styles={SelectStyles}
                    // isMulti
                    required
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </form>
          {isUpdate ? (
            <div className="add-employee-btn">
              {/* <div onClick={handleUpdateUser}>
                <ActiveButton label={t('update')} arrow={true} />
              </div> */}
              <Button onClick={handleUpdateUser}>{t('update')}</Button>
            </div>
          ) : (
            <div className="add-employee-btn">
              {/* <div onClick={handleFormSubmit}>
                <ActiveButton label={t('add')} arrow={true} />
              </div> */}
              <Button onClick={handleFormSubmit}>{t('add')}</Button>
            </div>
          )}
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddEmployeePopup;
