import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActiveButton } from '../WE-Button';
import CheckGreen from '../../assets/images/check_green.svg';

const Popup = ({ toggle, details, mealExtra }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push('/myMeals');
  };
  return (
    <>
      {toggle && (
        <div className="order-popup fadeInClass">
          <div className="popup-block">
            <div className="popup-title">
              <img src={CheckGreen} alt="" />
              <h4>{t('orderReceivedSuccessfully')}</h4>
            </div>

            <div className="order-details">
              <h4>{details.dishName}</h4>
              {mealExtra && mealExtra.length > 0 ? (
                <h6>Extra: {mealExtra && mealExtra[0].text} </h6>
              ) : (
                ''
              )}
              <p>
                {details.dateToDisplay} / {details.mealTypeToDisplay}
              </p>
            </div>
          </div>

          <ActiveButton cb={handleClick} label={t('confirm')} />
        </div>
      )}
    </>
  );
};

export default Popup;
