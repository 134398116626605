import types from './types';

// Set initial state
const initialState = {
  companyLogo: '',
  companyName: '',
  companyId: '',
  defaultLanguage: '',
  fieldNamesSettings: [],
  paymentsSettings: {
    id: null,
    cibusEnabled: false,
    hypEnabled: false,
    salaryEnabled: false,
    walletEnabled: true,
  },
  menuSettings: {
    id: 0,
    attendanceEnabled: false,
    salaryEnabled: false,
    factoriesEnabled: false,
    invitationsEnabled: false,
  },
  company: {
    id: 1,
    name: '',
    logo: null,
    url: '',
    urlTitle: '',
    createdAt: '',
    updatedAt: '',
    companyStatusId: 0,
    registrationFlowId: 0,
  },
  isManualEnabledForSalary: false,
  fileTypeForSalary: null,
  codeUrlForSalary: '',
  employeeNumberLengthForUser: null,
  cardNumberLengthForUser: null,
  cardGenerationUrlForUser: null,
  isStrippedCard: false,
};

export function companyConfigReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PARAMETER_BY_DOMAIN:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_PARAMETER_BY_COMPANY_ID:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_SALARY_CONFIG_BY_COMPANY_ID:
      return {
        ...state,
        isManualEnabledForSalary: Boolean(action.payload.isManualEnabled),
        fileTypeForSalary: action.payload.fileType,
        codeUrlForSalary: action.payload.codeUrl,
      };
    case types.GET_USER_CONFIG_BY_COMPANY_ID:
      return {
        ...state,
        employeeNumberLengthForUser: Number(
          action.payload.employeeNumberLength
        ),
        cardNumberLengthForUser: Number(action.payload.cardNumberLength),
        cardGenerationUrlForUser: action.payload.cardGenerationUrl,
        isStrippedCard: action.payload.isStrippedCard,
      };
    default:
      return state;
  }
}
