import React, { useEffect } from 'react';
import Arrow from '../../assets/images/arrow-down__green.svg';
import Dropdown from 'react-bootstrap/Dropdown';

const KitchenDropdown = ({
  curKitchen,
  kitchens,
  menuActive,
  setKitchenCB,
  user,
  currentLanguage,
  userKitchen,
  isSidebar,
}) => {
  let filterKitchen = user?.Kitchen?.filter(
    (el) => el.languageId === currentLanguage.id
  );
  return (
    <>
      {user?.roleId === 4 ||
      user?.roleId === 6 ||
      user?.roleId === 7 ||
      user?.roleId === 8 ? (
        <>
          {userKitchen && (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={`green-border green-border-and-green-background ${
                    isSidebar ? 'custom-width-for-kitchen-dropdown' : ''
                  }`}
                >
                  {curKitchen?.name}
                  {userKitchen.length > 1 && (
                    <i
                      class="fa fa-caret-down green-dropdown"
                      aria-hidden="true"
                    ></i>
                  )}
                </Dropdown.Toggle>
                {userKitchen.length > 1 && (
                  <Dropdown.Menu
                    className={`green-border green-border-and-green-background ${
                      isSidebar ? 'custom-width-for-kitchen-dropdown' : ''
                    }`}
                  >
                    {userKitchen.map((k) => (
                      <Dropdown.Item
                        className="list-item text-decoration-none"
                        key={k.id}
                        onClick={() => setKitchenCB(k)}
                      >
                        {k.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </>
          )}
        </>
      ) : (
        <>
          {curKitchen && (
            <>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {curKitchen.name}
                  {kitchens.length > 1 && (
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  )}
                </Dropdown.Toggle>
                {kitchens.length > 1 && (
                  <Dropdown.Menu>
                    {kitchens.map((k) => (
                      <Dropdown.Item
                        className="list-item"
                        key={k.id}
                        onClick={() => setKitchenCB(k)}
                      >
                        {k.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </>
          )}
        </>
      )}
    </>
  );
};

export default KitchenDropdown;

// import React from 'react';
// import Arrow from '../../assets/images/arrow-down__green.svg';

// const KitchenDropdown = ({
//   curKitchen,
//   kitchens,
//   menuActive,
//   setKitchenCB,
//   user,
//   currentLanguage,
// }) => {
//   let filterKitchen = user?.Kitchen?.filter(
//     (el) => el.languageId === currentLanguage.id
//   );
//   return (
//     <>
//       {user.roleId === 4 ? (
//         <>
//           {curKitchen && (
//             <>
//               <div className={`list-item`}>
//                 {curKitchen.name}
//                 {filterKitchen?.length > 1 && (
//                   <img className="kitchen-arrow" src={Arrow} alt="" />
//                 )}
//               </div>
//               <div
//                 className={`content ${
//                   kitchens.length > 1 && menuActive ? 'active' : ''
//                 }`}
//               >
//                 {filterKitchen?.map((k) => (
//                   <div
//                     className="list-item"
//                     key={k.id}
//                     onClick={() => setKitchenCB(k)}
//                   >
//                     {k.name}

//                     <img
//                       className="kitchen-arrow"
//                       src={Arrow}
//                       alt=""
//                       style={{}}
//                     />
//                   </div>
//                 ))}
//                 {/* {kitchens.map((k) => (
//                   <div
//                     className="list-item"
//                     key={k.id}
//                     onClick={() => setKitchenCB(k)}
//                   >
//                     {k.name}
//                     <img
//                       className="kitchen-arrow"
//                       src={Arrow}
//                       alt=""
//                       style={{}}
//                     />
//                   </div>
//                 ))} */}
//               </div>
//             </>
//           )}
//         </>
//       ) : (
//         <>
//           {curKitchen && (
//             <>
//               <div className={`list-item`}>
//                 {curKitchen.name}
//                 {kitchens.length > 1 && (
//                   <img className="kitchen-arrow" src={Arrow} alt="" />
//                 )}
//               </div>
//               <div
//                 className={`content ${
//                   kitchens.length > 1 && menuActive ? 'active' : ''
//                 }`}
//               >
//                 {kitchens.map((k) => (
//                   <div
//                     className="list-item"
//                     key={k.id}
//                     onClick={() => setKitchenCB(k)}
//                   >
//                     {k.name}
//                     <img
//                       className="kitchen-arrow"
//                       src={Arrow}
//                       alt=""
//                       style={{}}
//                     />
//                   </div>
//                 ))}
//               </div>
//             </>
//           )}
//         </>
//       )}
//     </>
//   );
// };

// export default KitchenDropdown;
