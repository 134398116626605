import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Grid,
  styled,
  Typography,
  Paper,
  Button,
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployee } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Swal from 'sweetalert2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  maxWidth: '450px',
  borderRadius: '5px',
  width: '100%',
};
const EmailValidator = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
const ResetPasswordForm = ({
  value,
  setValue,
  open,
  handleClose,
  handleResetPassword,
  t,
  error,
  setError,
  handlereCaptcha,
  captcha,
  setCaptchaRef,
}) => {
  const handleChange = (e) => {
    if (!EmailValidator(e.target.value)) {
      setError(true);
    }
    setValue(e.target.value);
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup delete-popup forget-popup"
    >
      <Box sx={style}>
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <Typography id="modal-modal-description">
          <div className="reset-form">
            <div className="input-field">
              <label>{t('email')}</label>
              <input
                name="email"
                placeholder={t('email')}
                defaultValue={value}
                onBlur={(e) => handleChange(e)}
                required={true}
                type="text"
              />
              {error && <p className="error-msg">{t('invalidEmail')}</p>}
            </div>
            <ReCAPTCHA
              ref={(r) => setCaptchaRef(r)}
              theme="light"
              sitekey="6LeDMxkfAAAAAEhBNa8zcj4DuuPbyfIWuwG2uAqO"
              // sitekey="6LdBOAkfAAAAAGVoi62X3sn0tpOInlOzoIsSTfU2"
              onChange={handlereCaptcha}
              className="recaptcha"
            />
            <div
              className={`add-employee-btn ${!captcha ? 'disabled-btn' : ''}`}
            >
              <div onClick={handleResetPassword}>
                <ActiveButton label={t('send')} arrow={true} />
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default ResetPasswordForm;
