import React from 'react';
import WEIcon from './WE-IconButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import back from '../assets/images/back-arrow.png';

const WEBackButton = ({
  text = null,
  redirectHistory = null,
  showOnMobile = true,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    // <WEIcon
    //   icon={'arrow'}
    //   backgroundColor={'green'}
    //   text={text ?? t('back')}
    //   additionalTextClassname={`back-button ${
    //     showOnMobile ? 'show-on-mobile' : ''
    //   }`}
    //   additionalClassname={'back-button'}
    //   additionalWrapperClassname={'back-button'}
    //   cb={
    //     redirectHistory
    //       ? () => history.push(redirectHistory)
    //       : () => history.goBack()
    //   }
    // />
    <div
      className="gloal-back-btn"
      onClick={
        redirectHistory
          ? () => history.push(redirectHistory)
          : () => history.goBack()
      }
    >
      {/* <i class="las la-long-arrow-alt-left"></i> */}
      <img src={back} alt="back" />
      <p>{t('back')}</p>
    </div>
  );
};

export default WEBackButton;
