import { useEffect, useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';

// Validators
import { required, isPositiveNumberOrEmail } from '../../services/validators';
// import LogoWE from '../../assets/logo/logo-we.png';
import { ActiveButton, DisabledButton } from '../WE-Button';
import FormCheckBox from '../InputFields/WE-Checkbox';
import logo from '../../assets/logo/workeat-logo.svg';
//import CompanySecondaryLogo from '../../assets/logo/WD-SecondoryLogo.svg';

let LoginForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleForgetPasswordClick,
    error,
    history,
    valid,
    submitFailed,
    companyLogo,
    connectTeamLogin,
    useFetchingLoader,
    // onOtpFrom,
  } = props;
  useEffect(() => {
    const token = window.location.search.split('=');

    if (token[0] && token[0] === '?token') {
      history.push('/login');
      useFetchingLoader(async () => await connectTeamLogin(token[1]));
    }
  }, []);
  const [checked, setChecked] = useState(true);
  return (
    <>
      {window.location.search.split('=')[0] &&
      window.location.search.split('=')[0] === '?token' ? (
        <></>
      ) : (
        <Form className="form login-form" onSubmit={handleSubmit}>
          {/* <div className="logo">
          <img src={logo} alt="logo" />
        {/* <div className="reset-page-header mb-header">
          <div className="inner-header-flex">
            <img src={LogoWE} alt="" style={{ width: '30px' }} />
            <img src={CompanyLogo} alt="" style={{ width: '40px' }} />
          </div>
        </div> */}

          <div className="logo">
            <img src={logo} alt="logo" />
            <img
              src={companyLogo}
              alt=""
              style={{ width: '50px', margin: '0 15px' }}
            />
            {/* <img src={CompanyLogo} alt="" className="comapany-secondary-logo" /> */}
          </div>
          {/* 
        <div className="login-heading">
          {isGuestUserError ?? <p>{isGuestUserError}</p>}

          <h2>{t('loginText')}</h2>
          <p>{t('loginDesc')}</p>
        </div> */}
          <div className="login-heading">
            {/* {isGuestUserError ?? <p>{isGuestUserError}</p>} */}

            <h2>{t('loginText')}</h2>
            <p>{t('loginFormText')}</p>
          </div>
          <div className="inputs-wrapper">
            <Field
              name="employeeId"
              containerClass="employee-id"
              type="text"
              component={InputField}
              validate={[isPositiveNumberOrEmail, required]}
              placeholder={t('emailPH')}
              icon="icon-employee-card"
            />

            <div className="flex-column">
              <Field
                name={`password`}
                containerClass="password"
                type="password"
                component={InputField}
                validate={[required]}
                placeholder={t('passwordPH')}
                icon="padlock"
              />
            </div>
            <div className="rememmber-me">
              <Field
                name={`keepLoggedIn`}
                component={FormCheckBox}
                label={t('remember')}
                type={'checkbox'}
                checked={checked}
                cb={() => setChecked(!checked)}
              />
              <p
                className="login-action forgot-password"
                onClick={handleForgetPasswordClick}
              >
                {t('forgotPassword')}
              </p>
            </div>

            <div className="flex-column new-design-btn">
              {submitFailed && !valid ? (
                <DisabledButton
                  label={t('connectLabel')}
                  additionalClassName={'btn'}
                />
              ) : (
                <ActiveButton
                  type="submit"
                  additionalClassName={'btn'}
                  label={t('connectLabel')}
                />
              )}

              {/*<ActiveButton
          type="button"
          additionalClassName={'btn'}
          label={t('loginWithOtp')}
          arrow={true}
          cb={onOtpFrom}
        />*/}
            </div>
          </div>

          {error && <div className="form-error">{error}</div>}
        </Form>
      )}
    </>
  );
};

export default LoginForm = reduxForm({
  form: 'login',
  initialValues: {},
})(LoginForm);
