import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import http from '../../services/http';
import {
  setLayoutOptions,
  useFetchingLoader,
  setPushNotification,
  setNotificationNumber,
} from '../../store/app/actions';
import { getCompanyParametersByCompanyId } from '../../store/companyConfig/actions';
import LoginForm from '../../components/Forms/LoginForm';
import {
  login,
  logout,
  sendOtp,
  verifyOtp,
  connectTeamLoginAction,
  loginByRefreshToken,
  getUser,
} from '../../store/user/actions';
import { getKitchens, setKitchen } from '../../store/kitchens/actions';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import Swal from 'sweetalert2';
import {
  Alert,
  SuccessDailog,
  Dialog,
  DialogLogin,
} from '../../components/Dialogs/SwalDialogs';
import GuestScreen from '../Guests/GuestScreen';
import OtpFrom from '../../components/Forms/OtpFrom';
import OtpEmailForm from '../../components/Forms/OtpEmailForm';
import { ReactComponent as BackArrowIcon } from '../../assets/images/left-arrow__blue.svg';
// import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import styles from './Login.module.scss';
import ResetPassword from '../ResetPassword';
import ResetPasswordForm from './resetPassword';
import LoginBanner from './loginBanner';
import { socket } from '../../services/socket';

const EmailValidator = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

// @ts-ignore
const Login = ({
  user,
  setLayoutOptions,
  getKitchens,
  login,
  connectTeamLoginAction,
  sendOtp,
  verifyOtp,
  companyId,
  currentLanguage,
  getCompanyParametersByCompanyId,
  companyLogo,
  kitchen,
  allKitchens,
  setKitchen,
  useFetchingLoader,
  loginByRefreshToken,
  getUser,
  setNotificationNumber,
  setPushNotification,
}) => {
  const { t } = useTranslation();
  const { email } = useParams();
  const history = useHistory();
  setLayoutOptions({ title: t('loginTitle'), showKitchen: false });

  const [loginType, setLoginType] = useState(0);
  const [identifier, setIdentifier] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState();
  const [captcha, setReCaptcha] = useState(false);
  const [isGuestUserError, setisGuestUserError] = useState();
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const token = window.location.search.split('=');
  let languageId = currentLanguage?.id;
  let captchaa;
  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData1 = async () => {
      let url = window.location.href;
      let password = null;
      let keepLoggedIn = true;

      if (window.location.hash.includes('loginByToken')) {
        try {
          const arrayWithValues = window.location.hash.split('?')[1].split('&');

          const objectFromStrings = arrayWithValues.reduce((acc, current) => {
            const [key, value] = current.split('=');
            acc[key] = value;
            return acc;
          }, {});

          let user = await useFetchingLoader(
            async () =>
              await loginByRefreshToken(
                objectFromStrings?.authToken,
                languageId,
                keepLoggedIn
              )
          );
          await useFetchingLoader(async () => {
            const userMarker = await getUser();
            if (userMarker) {
              await getKitchens(user.companyId);
            }
          });
          if (user) {
            history.push(
              `/invite?action=${objectFromStrings.action}&id=${objectFromStrings.id}&userId=${objectFromStrings.userId}`
            );
          }
        } catch (error) {
          setisGuestUserError(error);
          const err = new Error(error);
          err.content = error;
          DialogLogin({
            title: error instanceof Object ? error.message : error,
            type: 'error',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('ok'),
            },
            onConfirm: () => {
              //  window.location.reload();
            },
          });
          //Alert({ error: err });
        }
      } else {
        if (email) {
          try {
            let user = await login(email, password, languageId, keepLoggedIn);
            if (user.isPasswordTemporary) {
              history.push(
                `/update-password/forceSet/${user.resetPasswordToken}`
              );
            } else {
              await getKitchens(user.companyId);

              if (user.roleId === 8) {
                history.push(`/mealTime`);
              } else {
                history.push(`/lobby`);
              }
            }
          } catch (error) {
            setisGuestUserError(error);
            const err = new Error(error);
            err.content = error;
            DialogLogin({
              title: error instanceof Object ? error.message : error,
              type: 'error',
              buttons: {
                showCancelButton: false,
                confirmButtonText: t('ok'),
              },
              onConfirm: () => {
                //  window.location.reload();
              },
            });
            //Alert({ error: err });
          }
        }
      }
    };
    fetchData1();
  }, []);

  const connectTeamLogin = async (token) => {
    try {
      const isLocalStorageNotEmpty = Boolean('language' in localStorage);
      localStorage.setItem('loginByConnecteam', true);
      let data = await http.get('users/ConnectTeamAuth', {
        token: token,
      });
      await connectTeamLoginAction(data, languageId);

      if (data?.token) {
        window.gtag('event', 'Login', {
          title: 'Login-success',
          client: window.location.host.split('.')[0],
        });
        await getCompanyParametersByCompanyId(
          companyId ?? localStorage.getItem('companyId')
        );
        let dataKitchen = await http.get('kitchens', {
          filter: { companyId: data.companyId, kitchenStatusId: 1 },
        });
        if (data.KitchenIds && data.KitchenIds.length !== 0) {
          const kitchen =
            data.KitchenIds.find(
              (el) => el == localStorage.getItem('kitchen')
            ) ?? data.KitchenIds[0];
          await setKitchen(
            dataKitchen.data.find((el) => el?.id == kitchen),
            false
          );
        }
        await getKitchens(data.companyId);

        const newUrl = window.location.href.split('?')[0];
        window.history.replaceState({}, document.title, newUrl);
        if (!data?.KitchenIds || data?.KitchenIds?.length === 0) {
          localStorage.setItem('showEmptyKitchenMess', '1');
        }
        localStorage.setItem('loginByConnecteam', false);

        history.push(`/lobby`);
      }
    } catch (error) {
      window.gtag('event', 'Login', {
        title: 'Login-error',
        client: window.location.host.split('.')[0],
      });
      const err = error;
      setTimeout(() => {
        DialogLogin({
          title:
            error.message === 'Request failed with status code 401'
              ? t('unauthorized')
              : '',
          text:
            error.message === 'Request failed with status code 401'
              ? `${t('tokenExpired')} ${t('contactYourAdmin')}`
              : error instanceof Object
                ? error?.message instanceof Object
                  ? `${error?.message?.errors[0].message}. ${t(
                      'contactYourAdmin'
                    )}`
                  : `${error?.message}. ${t('contactYourAdmin')}`
                : `${error}. ${t('contactYourAdmin')}`,
          type: 'error',
          showConfirmButton:
            !error?.message === 'Request failed with status code 401',
          allowOutsideClick:
            !error?.message === 'Request failed with status code 401',
          allowEscapeKey:
            !error?.message === 'Request failed with status code 401',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('ok'),
          },
          onConfirm: () => {},
        });
      }, 500);
    }
  };

  const handleSubmit = async (form) => {
    let { password, keepLoggedIn } = form;
    let identifier = form.employeeId.trim();
    try {
      let user = await login(identifier, password, languageId, keepLoggedIn);

      if (user.isPasswordTemporary) {
        history.push(`/update-password/forceSet/${user.resetPasswordToken}`);
      } else {
        await getKitchens(user.companyId);
        window.gtag('event', 'Login', {
          title: 'Login-success',
          client: window.location.host.split('.')[0],
        });
        getCompanyParametersByCompanyId(
          companyId ?? localStorage.getItem('companyId')
        );
        localStorage.setItem('showEmptyKitchenMess', '1');
        if (localStorage.getItem('token')) {
          socket.on('notification', (params) => {
            setNotificationNumber(params.count);
            setPushNotification(params.notification);
          });
          localStorage.setItem('notificationsSocket', true);
          socket.emit('user_connected', user.id);
        }
        await history.push(`/lobby`);
      }
    } catch (error) {
      window.gtag('event', 'Login', {
        title: 'Login-error',
        client: window.location.host.split('.')[0],
      });
      const err = error;

      //err.content = error;
      setTimeout(() => {
        DialogLogin({
          title: error instanceof Object ? error.message : error,
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('ok'),
          },
          onConfirm: () => {
            // window.location.reload();
          },
        });
      }, 500);

      // Alert({ error: err });
    }
  };

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captchaa = ref);
    }
  };

  const handlereCaptcha = (value) => {
    if (value) {
      setReCaptcha(true);
      setRecaptchaResponse(value);
    }
  };

  const handleForgetPasswordClick = async () => {
    history.push('/forget');
    // setOpen(true);
    // SwalDialogs.DialogWithInput({
    //   title: t('enterEmailTitle'),
    //   text: t('enterEmailText'),
    //   buttons: {
    //     confirmButtonText: t('send'),
    //     cancelButtonText: t('back'),
    //   },
    //   onPreConfirm: async (email) => {
    //     if (!EmailValidator(email)) {
    //       Swal.showValidationMessage(t('invalidEmail'));
    //       return;
    //     }

    //     return await http
    //       .post(`users/resetPassword`, { email })
    //       .then((response) => {
    //         return response;
    //       })
    //       .catch((error) => {
    //         Swal.showValidationMessage(`${error}`);
    //       });
    //   },
    //   onConfirm: (result) => {
    //     let isSent = result.value.sent;

    //     if (isSent) {
    //       SwalDialogs.Dialog({
    //         title: t('resetPasswordTitle'),
    //         text: t('resetPasswordText'),
    //         buttons: {
    //           showCancelButton: false,
    //         },
    //       });
    //     }
    //   },
    // });
  };

  const handleResetPassword = async () => {
    if (!EmailValidator(value)) {
      setError(true);
    }
    let res = await http
      .post(`users/resetPassword`, {
        email: value,
        recaptchaResponse,
        companyId: localStorage.getItem('companyId'),
      })
      .then((response) => {
        return Dialog({
          title: t('resetPasswordTitle'),
          text: t('resetPasswordText'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setOpen(false);
          },
        });
      })
      .catch((error) => {
        Dialog({
          title: error instanceof Object ? error.message : error,
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            captchaa.reset();
            // setReCaptcha(false)

            //  setOpen(false);
          },
        });
      });

    // try {

    //   let response= await http.post(`users/resetPassword`, {value});

    //   Dialog({
    //     title: t('addedmessage'),
    //     type: 'success',
    //     buttons: {
    //       showCancelButton: false,
    //       confirmButtonText: t('confirm'),
    //     },
    //     onConfirm: () => {
    //       // setDummy(true);
    //     },
    //   });
    //   // setDummy(false);
    //   // handleClose();
    // } catch (error) {
    //   Alert({ error: error });
    // }
  };

  const onSendOtp = async (form) => {
    const { employeeId: identifier, phone } = form;

    if (!identifier && !phone) {
      const err = new Error();
      err.content = t('otpAddAtleastOneField');
      Alert({ error: err });

      return;
    }

    try {
      await sendOtp(identifier, phone);
      setLoginType(2);

      if (identifier) {
        setIdentifier(identifier);
        SuccessDailog({
          title: t('otpEmailSuccess'),
          type: 'success',
        });
      } else if (phone) {
        setPhoneNumber(phone);
        SuccessDailog({
          title: t('otpPhoneSuccess'),
          type: 'success',
        });
      }
    } catch (error) {
      const err = new Error(error);
      err.content = error && error.message;
      Alert({ error: err });
    }
  };

  const onVerifyOtp = async (form) => {
    const { otpCode } = form;

    try {
      await verifyOtp(identifier, phoneNumber, otpCode);
      await getKitchens(user?.companyId);
      history.push(`/lobby`);
    } catch (error) {
      const err = new Error(error);
      err.content = error && error.message;
      Alert({ error: err });
    }
  };

  const back = () => {
    setLoginType(loginType - 1);
    setIdentifier(null);
    setPhoneNumber(null);
  };

  return (
    <>
      {token[0] && token[0] === '?token' ? (
        <LoginForm
          useFetchingLoader={useFetchingLoader}
          onSubmit={handleSubmit}
          history={history}
          initialValues={{ ...user }}
          handleForgetPasswordClick={handleForgetPasswordClick}
          onOtpFrom={() => setLoginType(1)}
          isGuestUserError={isGuestUserError}
          user={user}
          companyLogo={companyLogo}
          connectTeamLogin={connectTeamLogin}
        />
      ) : (
        <>
          <Helmet>
            <body className="login-page-padding"></body>
          </Helmet>
          <GuestScreen
            content={
              <>
                <div className="login">
                  {loginType > 0 && (
                    <BackArrowIcon
                      className={styles.backArrow}
                      onClick={back}
                    />
                  )}
                  {loginType === 0 ? (
                    <LoginForm
                      useFetchingLoader={useFetchingLoader}
                      onSubmit={handleSubmit}
                      history={history}
                      initialValues={{ ...user }}
                      handleForgetPasswordClick={handleForgetPasswordClick}
                      onOtpFrom={() => setLoginType(1)}
                      isGuestUserError={isGuestUserError}
                      user={user}
                      companyLogo={companyLogo}
                      connectTeamLogin={connectTeamLogin}
                    />
                  ) : loginType === 1 ? (
                    <OtpEmailForm
                      onSubmit={onSendOtp}
                      initialValues={{ ...user }}
                    />
                  ) : (
                    <OtpFrom
                      onSubmit={onVerifyOtp}
                      initialValues={{ ...user }}
                    />
                  )}
                  <div className="align-right privacy-text mob-view-privacy">
                    {!localStorage.getItem('token') ? (
                      <>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-condition"> Terms & Conditions</Link>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <LoginBanner />
                </div>
                <ResetPasswordForm
                  value={value}
                  setValue={setValue}
                  open={open}
                  handleClose={handleClose}
                  handleResetPassword={handleResetPassword}
                  t={t}
                  error={error}
                  setError={setError}
                  handlereCaptcha={handlereCaptcha}
                  captcha={captcha}
                  setCaptchaRef={setCaptchaRef}
                />
              </>
            }
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentLanguage: state.languages.current,
  companyId: state.companyConfig.companyId,
  companyLogo: state.companyConfig.companyLogo,
  kitchen: state.kitchens.current,
  allKitchens: state.kitchens.options,
});

const mapDispatchToProps = {
  setLayoutOptions,
  login,
  connectTeamLoginAction,
  logout,
  getKitchens,
  sendOtp,
  verifyOtp,
  getCompanyParametersByCompanyId,
  setKitchen,
  useFetchingLoader,
  loginByRefreshToken,
  getUser,
  setNotificationNumber,
  setPushNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
