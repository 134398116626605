import types from './types';
// Set initial state
const initialState = {
  allUsers: [],
  allUserData: [],
  usersTotal: 0,
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_USER:
      if (action.payload) {
        return {
          ...state,
          ...action.payload,
        };
      }
      break;
    case types.RESET:
      return {
        ...initialState,
        user: null,
      };
    case types.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action?.payload?.data,
        usersTotal: action?.payload?.total,
      };
    case types.GET_ALL_USER_DATA:
      return {
        ...state,
        allUserData: action?.payload?.data,
      };
    default:
      return state;
  }
}
