import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { DisabledButton } from '../../components/WE-Button';
import JSONFormatter from 'json-formatter-js';
import moment from 'moment';
import http from '../../services/http';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewSalaryReportdetails = ({
  kitchen,
  reservations,
  MealTypes,
  Languages,
  open,
  details,
  handleClose,
  reportType,
  handleOpen,
  reserData,
  kitchensForDisplay,
  setDetails,
  companyName,
  fileTypeForSalary,
  setFileTypeForSalaryState,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [viewData, setViewData] = useState(false);
  const [jsonData, setJsondata] = useState();
  const [kitchenForCard, setKitchenForCard] = useState('');
  useEffect(() => {
    if (details && details?.kitchenId) {
      const findedElem = kitchensForDisplay.find(
        (el) => el.id === details?.kitchenId
      );
      setKitchenForCard(findedElem?.name || '--');
    }
  }, [details]);
  const compareDates = (dateStr1, dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    return date1 - date2;
  };
  const goToReservationsPage = async ({ e }) => {
    setFileTypeForSalaryState((prev) => {
      if (Number(prev) === 1) {
        history.push({
          pathname: `/reservationByUser`,
          state: {
            kitchenId: details.kitchenId,
            fromDate: details.getReservationDate[0],
            toDate: details.getReservationDate[1],
            userType: '4',
          },
        });
      } else {
        let fromURL = details?.fileName;
        const filename = fromURL?.split('com/').reverse()[0];
        const sortedDatesArray = [...details?.getReservationDate].sort(
          compareDates
        );
        history.push({
          pathname: `/reservationManagement`,
          state: {
            backText: t('salaryReportFromDate'),
            FromDate: sortedDatesArray.map((el) => String(el)),
            FromFile: filename,
          },
        });
      }
      return prev;
    });
  };

  const downloadSalaryReport = async ({ details }) => {
    let e = { data: details };
    let fromURL = e?.data?.fileName;
    const filename = fromURL.split('com/').reverse()[0];

    let postData = {
      id: e?.data?.id,
    };
    const response = await http.post(`kitchens/downloadSalary`, postData);
    const file = new Blob([response.join('\n')], { type: 'text/plain' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('salaryFileReport')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup view-reservation-page">
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('date')}:</h6>
                  <p>{moment(details?.createdAt).format('YYYY/MM/DD')}</p>
                </div>
              </div>
            </div>
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('isfile')}:</h6>
                  <p
                    onClick={() => downloadSalaryReport({ details })}
                    className="link-css"
                  >
                    {details?.fileName?.split('com/').reverse()[0]}
                  </p>
                </div>
              </div>
            </div>

            {(fileTypeForSalary == 1 ||
              window.location.href.includes('vpg')) && (
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="user-details-block">
                    <h6>{t('kitchen')}:</h6>
                    <p>{kitchenForCard}</p>
                  </div>
                </div>
              </div>
            )}
            {(fileTypeForSalary == 1 ||
              window.location.href.includes('vpg')) && (
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="user-details-block">
                    <h6>{t('dateRange')}:</h6>
                    <p>
                      {details?.getReservationDate
                        ? details?.getReservationDate.join(' - ')
                        : '--'}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {(fileTypeForSalary == 1 ||
              window.location.href.includes('vpg')) && (
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="user-details-block">
                    <h6>{t('generatedBy')}:</h6>
                    <p>
                      {details?.userParam
                        ? `${details?.userParam.firstName} ${details?.userParam.lastName}`
                        : t('system')}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>#{t('isEntries')}:</h6>
                  <p
                    onClick={() => goToReservationsPage({ details })}
                    className="link-css"
                  >
                    {/* {t('getReservation')} */}
                    {details?.totalReservations}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
});

// export default ReservationManagement;
export default connect(mapStateToProps)(ViewSalaryReportdetails);
