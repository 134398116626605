import React, { useEffect, useState } from 'react';
import '../../style/yourdish.css';
import { Button, Modal } from 'react-bootstrap';
import plusIcon from '../../assets/newimages/plus-icon.svg';
import minusIcon from '../../assets/newimages/minus-icon.svg';
import arrowRight from '../../assets/newimages/arrow-right-white.svg';
import defaultImage from '../../assets/images/defaultImage.png';
import http from '../../services/http';
import { DialogWithInput } from '../../components/Dialogs/SwalDialogs';

const MyOrderItem = ({
  img,
  title,
  subText,
  price,
  multipleDish,
  data,
  mealDishArray,
  index,
  setMealDishArray,
  quant,
  category,
  setUpdate,
  orderId,
  removeOrder,
  extraDish,
  paymentOption,
  setCafeteriaiMealDish,
  setMyOrdersPopup,
  update,
  t,
  user,
}) => {
  const [quantity, setQuantity] = useState(quant ? quant : 1);
  const [pricese, setPrice] = useState();
  const [openQuantityModel, setOpenQuantityModel] = useState(false);
  useEffect(() => {
    setQuantity(mealDishArray[index]?.quantity);
  }, [update]);
  const handleIncrease = () => {
    const prev = [...mealDishArray];
    prev[index].quantity = prev[index]?.quantity
      ? prev[index]?.quantity + 1
      : 2;

    prev[index].price = price;

    let array = [];
    mealDishArray &&
      mealDishArray.map(async (el, i) => {
        const mealDishData = await http.get(`mealsDishes/${el.mealDishId}`);
        mealDishData['quantity'] = el.quantity;

        array.push(mealDishData);
        setCafeteriaiMealDish(array);
      });
    setQuantity(quantity + 1);
    setMealDishArray(mealDishArray);
    setUpdate(true);
  };
  const handleDecrease = () => {
    const prev = [...mealDishArray];
    prev[index].quantity = prev[index]?.quantity
      ? prev[index]?.quantity - 1
      : 2;
    prev[index].price = price;
    let array = [];
    mealDishArray &&
      mealDishArray.map(async (el, i) => {
        const mealDishData = await http.get(`mealsDishes/${el.mealDishId}`);
        mealDishData['quantity'] = el.quantity;

        array.push(mealDishData);
        setCafeteriaiMealDish(array);
      });
    setQuantity(quantity - 1);
    setMealDishArray(mealDishArray);
    setUpdate(true);
  };

  const openInventoryModal = (quantity, title = '') => {
    setMyOrdersPopup(true);
    //setOpenQuantityModel(true)
    DialogWithInput({
      title: `${t('enterQuantityFor')} ${title}`,
      type: 'number',
      onConfirm: async (event) => {
        const prev = [...mealDishArray];
        prev[index].quantity = Number(
          event.value ? (event.value > 0 ? event.value : quantity) : quantity
        );
        prev[index].price = price;
        let array = [];
        mealDishArray &&
          mealDishArray.map(async (el, i) => {
            const mealDishData = await http.get(`mealsDishes/${el.mealDishId}`);
            mealDishData['quantity'] = el.quantity;

            array.push(mealDishData);
            setCafeteriaiMealDish(array);
          });
        setQuantity(
          Number(
            event.value ? (event.value > 0 ? event.value : quantity) : quantity
          )
        );
        setMealDishArray(mealDishArray);
        setUpdate(true);
        setMyOrdersPopup(false);
      },
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };

  return (
    <>
      <div className="myorder-item" key={data?.id}>
        <img
          src={
            img
              ? img == 'h' || img === 'null'
                ? defaultImage
                : img
              : defaultImage
          }
          alt=""
          className="myorder-img"
        />
        <div className="myorder-info-section">
          <div className="myorder-info">
            <div className="order-dish-name">
              <h4>{title}</h4>
              <i class="las la-times" onClick={() => removeOrder(data?.id)}></i>
            </div>
            <span>{subText}</span>
            <div class="dishInfo-inner">
              <div class="meat-meat">
                <span>{category}</span>
              </div>
              {(user.roleId === 4 || user.roleId === 6 || user.roleId === 8) &&
                price !== 0 &&
                (paymentOption == 4 ? (
                  ''
                ) : (
                  <div class="meat-prices">
                    <span>
                      {paymentOption == 5 ? '' : price ? '₪' : ''}
                      {price}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="myorder-price-section">
            <div className="myorder-quantity">
              {multipleDish ? (
                <>
                  <Button
                    onClick={handleDecrease}
                    className={`remove-quantity ${
                      quantity === 1 ? 'disable-value' : ''
                    }`}
                  >
                    <img src={minusIcon} alt="" />
                  </Button>
                  <span onClick={() => openInventoryModal(quantity, title)}>
                    {quantity}
                  </span>
                  <Button onClick={handleIncrease}>
                    <img src={plusIcon} alt="" />
                  </Button>
                </>
              ) : (
                ''
              )}
            </div>
            {(user.roleId === 4 || user.roleId === 6 || user.roleId === 8) &&
              price !== 0 &&
              paymentOption !== 4 &&
              (paymentOption == 4 ? (
                ''
              ) : (
                <h6>
                  {paymentOption == 5
                    ? ''
                    : price !== 0 || price !== 0
                      ? '₪'
                      : ''}
                  {price * quantity}
                </h6>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrderItem;
