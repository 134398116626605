import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
  getAllInvitations,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import DatePicker from 'react-datepicker';
import WESpinner from '../../../src/components/WE-Spinner';
import cancel from '../../assets/images/close.svg';
import AddInvitation from './AddInvitation';
import { validateGuestUsersNew } from '../../services/validators';
//import CopyUrlPopup from './copyUrl';
import ConfirmationPopup from './confirmation';
import ViewInvitationDetails from './ViewInvitationDetails';
import more from '../../assets/images/more.svg';
import next from '../../assets/images/next.png';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/icons/search.png';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import countryList from 'react-select-country-list';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { VariableSizeList as List } from 'react-window';
import Toast from 'react-bootstrap/Toast';
import Excel from 'exceljs';

const initialState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 8,
  hireFrom: '',
  hireTill: '',
  mealTypeId: [],
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'created',
  contractor: '',
  contractorsIds: [],
  company: '',
  visitorCountry: {},
  visitPurpose: {},
  guestId: '',
  isMultiOrders: false,
};
const height = 35;
const InvitationManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  UserStatuses,
  Kitchens,
  Roles,
  useLoader,
  useFetchingLoader,
  invitationToDisplay,
  invitationTotal,
  getAllInvitations,
  cibusEnabled,
  companyName,
  fieldNamesSettings,
  Enums,
}) => {
  const { t } = useTranslation();
  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [newPageSizeUsers, setPageSizeUsers] = useState(25); // Records per page
  const [totalUserRecords, setTotalUserRecords] = useState(0); // Total records
  const [pageCountsUsers, setpageCountsUsers] = useState(1); // Total pages count
  const [currentPageUsers, setCurrentPageUsers] = useState(1); // Current page
  const [searchKeywordUsers, setSearchKeywordUsers] = useState(''); // Search text
  const [disableButton, setDisableButton] = useState(false);
  const [payload, setPayload] = useState(initialState);
  const [openDownload, setOpenDownload] = useState(false);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();

  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);

  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [contractorList, setContractorList] = useState();
  const [getMealTypes, setGetMealTypes] = useState();
  const [getMealTypesKitchenWise, setMealTypesKitchenWise] = useState();

  const [errors, setErrors] = useState({});
  const [mealTypesIds, setMealTypesIds] = useState([]);
  const [currentUserKitchens, setCurrentUserKitchesList] = useState([]);

  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [invitationStatusId, setInvitationStatusId] = useState();
  const [userContractorId, setUserContractorId] = useState();
  const [invitedBy, setInvitedBy] = useState();
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [allContractors, setAllContractors] = useState([]);
  const [isMultiOrders, setIsMultiOrders] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [fromDate, setFromdate] = useState(new Date());
  const [fromDateFormat, setfromDateFormat] = useState();
  const [toDateFormat, settoDateFormat] = useState();
  const [toDate, setToDate] = useState(new Date());
  const [visitPurposeValues, setVisitPurposeValues] = useState([
    { label: t('event'), value: 'event' },
    { label: t('meeting'), value: 'meet' },
    { label: t('conference'), value: 'conf' },
    { label: t('technician'), value: 'tech' },
  ]);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload({ ...initialState, allContractors: contractorList });
      setIsUpdate(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setMealTypesKitchenWise([]);
    setPayload(initialState);
    setErrors({});
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      width: 200,
      padding: '0',
      backgroundColor: '#eff0f7',
      border: '1px solid #eff0f7',
      boxShadow: 'none',
      borderRadius: '5px',
      fontSize: '12px',
      fontFamily: 'OpenSans',
      textAlign: 'left',
      color: '#6e6893',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#6e6893',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 200,
      fontSize: '12px',
      padding: '3px 10px',
      fontFamily: 'OpenSans',
      color: '#6e6893',
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      padding: '10px',
      width: '100%',
      fontSize: '12px',
      fontFamily: 'OpenSans',
      backgroundColor:
        state.isFocused && !state.isSelected
          ? '#cce9ff'
          : state.isSelected
            ? '#2589f5'
            : 'transparent',
      color: state.isSelected ? '#ffffff' : '#000000',
      cursor: state.isFocused ? 'pointer' : 'auto',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 200,
      fontSize: '12px',
      fontFamily: 'Open Sans',
      color: '#6e6893',
      textAlign: 'left',
    }),
  };
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  useEffect(() => {
    const fetchData1 = async () => {
      const allUserData = await http.get(`users/getAllUsers`);
      setAllUsers(
        allUserData.response.data.map((elem) => ({
          value: elem.id,
          label: `${elem.firstName} ${elem.lastName}`,
        }))
      );
    };
    if (user.roleId !== 4) {
      fetchData1();
    }
  }, []);
  useEffect(() => {
    const fetchData1 = async () => {
      const arrayWithValues = window.location.hash.split('?')[1].split('&');
      const objectFromStrings = arrayWithValues.reduce((acc, current) => {
        const [key, value] = current.split('=');
        acc[key] = value;
        return acc;
      }, {});
      switch (objectFromStrings.action) {
        case 'approve':
          try {
            const response = await useFetchingLoader(
              async () =>
                await http.get(
                  `guestInvitations/approve/${objectFromStrings.id}`
                )
            );
            if (response) {
              setTimeout(() => {
                Dialog({
                  title: t('approveSuccess'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    const newUrl = window.location.href.split('?')[0];
                    window.history.replaceState({}, document.title, newUrl);
                    setDummy((prev) => !prev);
                  },
                  onClose: () => {
                    const newUrl = window.location.href.split('?')[0];
                    window.history.replaceState({}, document.title, newUrl);
                    setDummy((prev) => !prev);
                  },
                });
              }, 500);
            }
          } catch (err) {
            if (err?.name === 'invitationApproved') {
              setTimeout(
                () =>
                  Alert({
                    error: `${t('invitation')} ${t('numberSymbol')}${
                      objectFromStrings.id
                    } ${t('alreadyApproved')}`,
                  }),
                500
              );
              return err.message;
            } else {
              setTimeout(
                () =>
                  Alert({
                    error: `${t('invitation')} ${t('numberSymbol')}${
                      objectFromStrings.id
                    } ${t('alreadyRejected')}`,
                  }),
                500
              );
              return err.message;
            }
          } finally {
            const newUrl = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, newUrl);
          }
          break;
        case 'reject':
          try {
            const response = await useFetchingLoader(
              async () =>
                await http.get(
                  `guestInvitations/reject/${objectFromStrings.id}`
                )
            );
            if (response) {
              setTimeout(() => {
                Dialog({
                  title: t('rejectSuccess'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    const newUrl = window.location.href.split('?')[0];
                    window.history.replaceState({}, document.title, newUrl);

                    setDummy((prev) => !prev);
                  },
                  onClose: () => {
                    const newUrl = window.location.href.split('?')[0];
                    window.history.replaceState({}, document.title, newUrl);
                    setDummy((prev) => !prev);
                  },
                });
              }, 500);
            }
          } catch (err) {
            if (err?.name === 'invitationApproved') {
              setTimeout(
                () =>
                  Alert({
                    error: `${t('invitation')} ${t('numberSymbol')}${
                      objectFromStrings.id
                    } ${t('alreadyApproved')}`,
                  }),
                500
              );
              return err.message;
            } else {
              setTimeout(
                () =>
                  Alert({
                    error: `${t('invitation')} ${t('numberSymbol')}${
                      objectFromStrings.id
                    } ${t('alreadyRejected')}`,
                  }),
                500
              );
              return err.message;
            }
          } finally {
            const newUrl = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, newUrl);
          }
          break;
        case 'details':
          try {
            let guestInvitations, contractorData, getMealType;
            await useFetchingLoader(async () => {
              guestInvitations = await http.get(
                `guestInvitations/${objectFromStrings.id}`
              );
              contractorData = await http.get(`contractors/getAllContractors`);
              getMealType = await http.get(`mealTypes`);
            });
            const userData = JSON.parse(guestInvitations.response.data?.form);
            let ids = userData.kitchensIds;
            let newMealTypeDateArray = [];
            if (userData.kitchensIds?.length) {
              userData.kitchensIds?.forEach((el) => {
                let newMealTypeInfo = getMealType?.data?.filter(
                  (elInfo) => elInfo?.kitchenId === el
                );
                newMealTypeDateArray = [
                  ...newMealTypeDateArray,
                  ...newMealTypeInfo,
                ];
              });
            }
            const date = {
              invitationId: guestInvitations.response.data?.id,
              Name: [userData.firstName, userData.lastName].join(' '),
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              employeeId: userData?.employeeId,
              kitchensIds: userData?.kitchensIds,
              InvitationStatus:
                guestInvitations.response.data?.guestInvitationStatusId,
              Kitchens: ids ? ids : '--',
              Contractors: userData?.contractorsIds
                ? contractorData.data
                    .filter((el) => userData?.contractorsIds.includes(el.id))
                    .map((el) => ({ id: el.id, name: el.name }))
                : '--',
              Email: userData?.email,
              phone: userData?.phone
                ? String(userData['phone']).split('-').join('')
                : userData['phone'],
              hireFrom: userData?.hireFrom,
              hireTill: userData?.hireTill,
              mealTypeId: userData.mealTypeId ? userData['mealTypeId'] : [],
              company: userData?.company,
              visitorCountry: userData?.visitorCountry,
              visitPurpose: userData?.visitPurpose,
              guestId: userData?.guestId,
              isMultiOrders: userData?.isMultiOrders,
              approvedAt: guestInvitations.response.data?.approvedAt,
              approvedBy: guestInvitations.response.data?.approvedBy,
              approvedByUser: guestInvitations.response.data?.approvedByUser,
              createdAt:
                guestInvitations.response.data?.createdAt.split('T')[0],
              rejectedAt: guestInvitations.response.data?.rejectedAt,
              rejectedBy: guestInvitations.response.data?.rejectedBy,
              rejectedByUser: guestInvitations.response.data?.rejectedByUser,
              createdByUser: guestInvitations.response.data?.createdByUser,
              guestUser: guestInvitations.response.data?.guestUser,
            };
            let mealTypeIdList = userData.mealTypeId
              ? userData['mealTypeId']
              : [];
            let array = [];
            if (mealTypeIdList && mealTypeIdList.length) {
              let aa = mealTypeIdList.map((el) => array.push(el));
            }
            setMealTypesIds(array);
            handleViewMore(date);
          } catch (err) {
            setTimeout(() => Alert({ error: err }), 500);
            return err;
          } finally {
            const newUrl = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, newUrl);
          }
          break;
        case 'edit':
          try {
            let guestInvitations, contractorData, getMealType;
            await useFetchingLoader(async () => {
              guestInvitations = await http.get(
                `guestInvitations/${objectFromStrings.id}`
              );
              contractorData = await http.get(`contractors/getAllContractors`);
              getMealType = await http.get(`mealTypes`);
            });
            const userData = JSON.parse(guestInvitations.response.data?.form);
            let ids = userData.kitchensIds;
            let newMealTypeDateArray = [];
            if (userData.kitchensIds?.length) {
              userData.kitchensIds?.forEach((el) => {
                let newMealTypeInfo = getMealType?.data?.filter(
                  (elInfo) => elInfo?.kitchenId === el
                );
                newMealTypeDateArray = [
                  ...newMealTypeDateArray,
                  ...newMealTypeInfo,
                ];
              });
            }
            setMealTypesKitchenWise(newMealTypeDateArray);
            setKitchensIds(ids);
            setPayload({
              card: userData['cardId'],
              email: userData['email'],
              fName: userData['firstName'],
              lName: userData['lastName'],
              phone: userData?.phone
                ? String(userData['phone']).split('-').join('')
                : userData['phone'],
              kitchensIds: ids,
              employeeId: userData['employeeId'],
              allContractors: user?.contractors,
              contractors: userData?.contractorsIds
                ? (user.roleId == 1 ? contractorData.data : user.contractors)
                    .filter((el) => userData?.contractorsIds.includes(el.id))
                    .map((el) => ({ id: el.id, name: el.name }))
                : [],
              mealTypesIds: userData.mealTypeId ? userData['mealTypeId'] : [],
              status: guestInvitations.response.data?.guestInvitationStatusId,
              hireFrom: userData['hireFrom'],
              hireTill: userData['hireTill'],
              invitationId: guestInvitations.response.data?.id,
              contractorsIds: [],
              company: userData['company'],
              isMultiOrders: userData['isMultiOrders'],
              visitorCountry: userData['visitorCountry'],
              visitPurpose: userData['visitPurpose'],
              guestId: userData['guestId'],
            });
            let mealTypeIdList = userData.mealTypeId
              ? userData['mealTypeId']
              : [];
            let array = [];
            if (mealTypeIdList && mealTypeIdList.length) {
              let aa = mealTypeIdList.map((el) => array.push(el));
            }
            setMealTypesIds(array);
            handleOpen();
          } catch (err) {
            setTimeout(() => Alert({ error: err }), 500);
            return err;
          } finally {
            const newUrl = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, newUrl);
          }
          break;
        default:
          break;
      }
    };
    if (window.location.hash.split('?')[1]) {
      fetchData1();
    }
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      let userKitchen = Kitchens.filter((el) =>
        user?.KitchenId?.includes(el.id)
      );
      setCurrentUserKitchesList(userKitchen);
    };
    fetchData1();
  }, []);

  const getInvitations = async () => {
    let filter = {
      companyId: user.companyId,
      limit: newPageSizeUsers,
      page: currentPageUsers,
      roleId: 8,
      statusId: invitationStatusId,
      contractorId: userContractorId,
      userId: user.roleId === 4 ? user.id : invitedBy?.value,
      orderField:
        'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
      search: searchKeywordUsers
        ? searchKeywordUsers.trim().replace(/\+/g, '%2B')
        : '',
      fromDate: moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00',
      toDate: moment(toDate).format('YYYY-MM-DD') + 'T23:59:59',
    };
    await useFetchingLoader(async () => {
      const getMealType = await http.get(`mealTypes`);
      setGetMealTypes(getMealType?.data);
      const contractorData = await http.get(`contractors/getAllContractors`);
      setAllContractors(contractorData.data);
      setContractorList(
        user.roleId == 1 || user.roleId == 2 || user.roleId == 10
          ? contractorData.data
          : user.contractors
      );

      await getAllInvitations(filter);
    });
  };

  const onBtnExport = async (fileName) => {
    try {
      if (disableButton || usersToDisplay.length === 0) {
        console.log('Disabled or empty table');
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'invitationId',
          'name',
          'email',
          'phone',
          'kitchenname',
          'mealtype',
          'startDate',
          'endDate',
          'contractors',
          'guestId',
          'company',
          'visitorCountry',
          'visitPurpose',
          'isMultiOrders',
          'invitedBy',
          'invitedDate',
          'approvedBy',
          'approvedAt',
          'rejectedBy',
          'rejectedAt',
          'guestLink',
          'invitationStatus',
        ].filter((el) => el !== undefined);
        const resArr = arr.map((el) => t(el));
        let filter = {
          statusId: invitationStatusId,
          contractorId: userContractorId,
          userId: user.roleId === 4 ? user.id : invitedBy?.value,
          orderField:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          orderTarget:
            'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
          keyword: searchKeywordUsers
            ? searchKeywordUsers.trim().replace(/\+/g, '%2B')
            : '',
          fromDate: moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00',
          toDate: moment(toDate).format('YYYY-MM-DD') + 'T23:59:59',
        };
        for (const key in filter) {
          if (
            filter[key] === undefined ||
            filter[key] === null ||
            filter[key] === 'null'
          ) {
            delete filter[key];
          }
        }
        const res = await http.post(
          `guestInvitations/download`,
          {
            isReport: true,
            headers: resArr,
          },
          { ...filter }
        );
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.addRows(res.data);
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {
          type: 'application/octet-binary',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        a.remove();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      console.log('e', e);
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  useEffect(() => {
    const fetchData1 = async () => {
      await getInvitations();
    };
    fetchData1();
  }, [
    dummy,
    kitchen,
    newPageSizeUsers,
    currentPageUsers,
    columnAndOrder,
    userContractorId,
    invitationStatusId,
    invitedBy,
  ]);

  useEffect(() => {
    const fetchData1 = async () => {
      setPrefetchData(invitationToDisplay);
      let serverUsers = invitationToDisplay.map((u, i) => {
        const userData = JSON.parse(u?.form);
        return {
          invitationId: u?.id,
          Name: [userData.firstName, userData.lastName].join(' '),
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          employeeId: userData?.employeeId,
          kitchensIds: userData?.kitchensIds,
          InvitationStatus: u?.guestInvitationStatusId,
          Kitchens: userData?.kitchensIds
            ? Kitchens.filter((el) =>
                userData?.kitchensIds.includes(el.id)
              ).map((el) => el.name)
            : '--',
          Contractors: userData?.contractorsIds
            ? allContractors
                .filter((el) => userData?.contractorsIds.includes(el.id))
                .map((el) => ({ id: el.id, name: el.name }))
            : '--',
          Email: userData?.email,
          phone: userData?.phone,
          hireFrom: userData?.hireFrom,
          hireTill: userData?.hireTill,
          mealTypeId: userData?.mealTypeId,
          company: userData?.company,
          visitorCountry: userData?.visitorCountry,
          visitPurpose: userData?.visitPurpose,
          guestId: userData?.guestId,
          magneticStripe: userData?.magneticStripe,
          isMultiOrders: userData?.isMultiOrders,
          approvedAt: u?.approvedAt,
          approvedBy: u?.approvedBy,
          approvedByUser: u?.approvedByUser,
          createdAt: u?.createdAt.split('T')[0],
          rejectedAt: u?.rejectedAt,
          rejectedBy: u?.rejectedBy,
          rejectedByUser: u?.rejectedByUser,
          createdByUser: u?.createdByUser,
          guestUser: u?.guestUser,
        };
      });
      setUsersToDisplay(serverUsers);
      setTotalUserRecords(invitationTotal);
      setpageCountsUsers(Math.ceil(invitationTotal / newPageSizeUsers));
      setLoading(true);
    };
    fetchData1();
  }, [dummy, kitchen, invitationToDisplay]);

  useEffect(() => {}, []);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  useEffect(() => {
    if (window.innerWidth <= 1280 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  // ag grid data
  const columnDefs = [
    {
      field: 'id',
      headerName: t('invitationId'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.invitationId ? params?.data?.invitationId : '--';
      },
    },
    {
      field: 'firstName',
      headerName: t('employeeName'),
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
    },
    {
      field: 'email',
      headerName: t('email'),
      cellRenderer: (params) => {
        return params?.data?.Email ? params?.data?.Email : '--';
      },
      suppressMovable: true,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      cellRenderer: 'Phone',
      suppressMovable: true,
    },
    {
      field: 'Kitchens',
      headerName: t('kitchenname'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.Kitchens && params?.data?.Kitchens.length !== 0
          ? params?.data?.Kitchens.join(', ')
          : '--';
      },
    },

    {
      field: 'hireFrom',
      headerName: t('startDate'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.hireFrom ? params?.data?.hireFrom : '--';
      },
    },
    {
      field: 'hireTill',
      headerName: t('endDate'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.hireTill ? params?.data?.hireTill : '--';
      },
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      cellRenderer: (params) => {
        return params?.data?.Contractors
          ? params?.data?.Contractors.map((el) => el.name).join(', ')
          : '--';
      },
      suppressMovable: true,
    },

    {
      field: 'createdAt',
      headerName: t('invitedDate'),
      comparator: () => {},
      sortable: true,
      suppressMovable: true,
    },
    {
      field: 'guestInvitationStatusId',
      headerName: t('status'),
      suppressMovable: true,
      comparator: () => {},
      sortable: true,
      cellRenderer: (params) => {
        return params?.data?.InvitationStatus
          ? Enums.GuestInvitationStatus.find(
              (el) => el.id == params?.data?.InvitationStatus
            ).display
          : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  // Tab view
  const columnDefsTab = [
    {
      field: 'id',
      headerName: t('invitationId'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.invitationId ? params?.data?.invitationId : '--';
      },
    },
    {
      field: 'firstName',
      headerName: t('employeeName'),

      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
    },
    {
      field: 'Contractors',
      headerName: t('contractors'),
      cellRenderer: (params) => {
        return params?.data?.Contractors
          ? params?.data?.Contractors.map((el) => el.name).join(', ')
          : '--';
      },
      suppressMovable: true,
    },
    {
      field: 'guestInvitationStatusId',
      headerName: t('status'),
      suppressMovable: true,
      comparator: () => {},
      sortable: true,
      cellRenderer: (params) => {
        return params?.data?.InvitationStatus
          ? Enums.GuestInvitationStatus.find(
              (el) => el.id == params?.data?.InvitationStatus
            ).display
          : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'id',
      headerName: t('invitationId'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.invitationId ? params?.data?.invitationId : '--';
      },
    },
    {
      field: 'firstName',
      headerName: t('employeeName'),

      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'NameCellRender',
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };
  const handleFilterChanged = (e) => {
    setCurrentPageUsers(1);
    const value = e.target.value;
    setSearchKeywordUsers(value);
  };
  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 80,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };
  /******* add New employee */
  const handleAddNewInvitation = async (userData) => {
    //setDummy(false);
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      //  let userToAdd = userData;
      let userToAdd;
      userData.employeeId =
        userData.employeeId && userData.employeeId !== ''
          ? userData.employeeId
          : null;
      userData.cardId =
        userData.cardId && userData.cardId !== '' ? userData.cardId : null;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`guestInvitations`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      setTimeout(() => {
        handleClose();
        Dialog({
          title: t('invitedMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy((prev) => !prev);
          },
          onClose: () => {
            setDummy((prev) => !prev);
          },
        });
      }, 150);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  /******** update employee details */
  const handleUpdateInvitation = async (event) => {
    event.preventDefault();
    try {
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload?.email ? payload?.email : null,
        phone: payload?.phone,
        userStatusId: { value: 3 },
        roleId: [RolesMap?.guestUser],
        kitchensIds: kitchensIds,
        magneticStripe: payload.magneticStripe,
        contractorsIds: payload.contractorsIds,
        hireFrom: payload.hireFrom,
        hireTill: payload.hireTill,
        isMultiOrders: Boolean(payload.isMultiOrders),
        mealTypeId: payload?.mealTypesIds,
        company: payload?.company,
        connectTeam: 0,
        companyId: user.companyId,
        visitorCountry: payload?.visitorCountry
          ? Array.isArray(payload?.visitorCountry)
            ? payload?.visitorCountry[0]
            : payload?.visitorCountry
          : '',
        visitPurpose: payload?.visitPurpose
          ? Array.isArray(payload?.visitPurpose)
            ? payload?.visitPurpose[0]
            : payload?.visitPurpose
          : '',
        guestId: payload?.guestId,
      };
      const { isValid, errors } = validateGuestUsersNew(details, true);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }
      const invitationId = payload.invitationId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`guestInvitations/updateAndApprove/${invitationId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      if (response) {
        setTimeout(() => {
          handleClose();
          Dialog({
            title: t('updateAndInvite'),
            type: 'success',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('confirm'),
            },
            onConfirm: () => {
              const newUrl = window.location.href.split('?')[0];
              window.history.replaceState({}, document.title, newUrl);
              setDummy((prev) => !prev);
              setViewDetails(false);
              setMealTypesKitchenWise([]);
              setPayload(initialState);
              setKitchensIds([]);
            },
            onClose: () => {
              const newUrl = window.location.href.split('?')[0];
              window.history.replaceState({}, document.title, newUrl);
              setDummy((prev) => !prev);
              setViewDetails(false);
              setMealTypesKitchenWise([]);
              setPayload(initialState);
              setKitchensIds([]);
            },
          });
        }, 150);
        setKitchensIds([]);
      }
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      setTimeout(() => {
        Dialog({
          title: t('resendMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            //setDummy(true);
          },
        });
      }, 150);
    }
  };

  const CopyDataUrl = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/guestLinkCopy`, {
          userData: { userId: el?.userId },
        })
    );
    setResendData(res);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };

  const handleConfirmation = (userId) => {
    setConfirmation(!confirmation);
    setDeleteId(userId);
  };
  const handleReset = () => {
    setColumnAndOrder({});
    setSearchKeywordUsers('');
    setInvitationStatusId(null);
    setUserContractorId(null);
    setInvitedBy(null);
    gridApi.setSortModel([]);
    setFromdate(moment().format('YYYY-MM-DD'));
    setToDate(moment().format('YYYY-MM-DD'));
    setfromDateFormat(new Date());
    settoDateFormat(new Date());
    setCurrentPageUsers(1);
  };
  const getUserData = () => {
    getInvitations();
  };
  const handleChangeInvitedBy = async (userTypeId) => {
    setCurrentPageUsers(1);
    setInvitedBy(userTypeId ?? null);
  };
  const handleChangeContractorFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserContractorId(userTypeId ?? null);
  };
  const handleChangeInvitationStatusFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setInvitationStatusId(userTypeId ?? null);
  };

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const handleChange = async (name, value) => {
    setCurrentPageUsers(1);
    if (name === 'fromdate') {
      if (!value) {
        setFromdate(null);
      }
      setfromDateFormat(value);
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        setToDate(null);
      }
      const tempToDate = new Date(value);
      settoDateFormat(value);
      setToDate(tempToDate);
    }
  };
  const lang = localStorage.getItem('language');
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper search-user-wrapper-custom-styles invitation-management-custom-wrapper">
            <div className="invitation-management-datepicker-wrapper">
              <div className="invitation-management-datepicker-elem">
                <label>{t('fromDate')}</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={fromDateFormat ? fromDateFormat : new Date()}
                  name="fromDate"
                  onChange={(event) => handleChange('fromdate', event)}
                  placeholder="DD/MM/YYYY"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className="invitation-management-datepicker-elem">
                <label>{t('toDate')}</label>

                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={toDateFormat ? toDateFormat : new Date()}
                  name="toDate"
                  onChange={(event) => handleChange('todate', event)}
                  placeholder="DD/MM/YYYY"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className="search-block invitation-management-search-block">
              <div className="search-input">
                <canvas id="canvas" width="0" height="0"></canvas>
                <img src={search} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={`${t('freeTextSearch')}`}
                  onInput={handleFilterChanged}
                  value={searchKeywordUsers}
                />
              </div>
              <button
                onClick={getUserData}
                className="search-button resize inv-manage-size"
              >
                <img src={search} alt="search" />
              </button>
              <button
                onClick={() => handleReset()}
                className="reset-button-user-management"
              >
                <img src={cancel} alt="reset" />
              </button>
            </div>
          </div>

          <div className="active-button active-button-styles mt-3">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{totalUserRecords}</span>
              </p>
              {usersToDisplay?.length !== undefined &&
                usersToDisplay?.length !== null && (
                  <>
                    {loading == 0 ? (
                      <div className="loading_excel" title="Loading">
                        <WESpinner size={15} color={'#14cd31'} />
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      className={`disp-flex-ai-center ${
                        loading == 1 ? '' : 'hidden'
                      }`}
                    >
                      {
                        <img
                          src={file}
                          alt="add"
                          onClick={() =>
                            onBtnExport(
                              `${companyName}-invitation-${moment().format(
                                'DD-MMMM-YYYY'
                              )}`
                            )
                          }
                        />
                      }
                    </div>
                  </>
                )}
            </div>
            {usersToDisplay?.length !== undefined &&
              usersToDisplay?.length !== null && (
                <>
                  <div className={`actions ${loading == 1 ? '' : 'hidden'}`}>
                    {user.roleId == 4 && (
                      <Button
                        onClick={() => handleOpen('add')}
                        className={`add-factory-btn ${
                          localStorage.languageId == 3 ? 'ru-min-width' : ''
                        }`}
                      >
                        <i class="las la-plus" />
                        {t('inviteUser')}
                      </Button>
                    )}
                  </div>
                </>
              )}
          </div>
        </div>

        <div className="filter-block invitation-management-filter-block">
          <div className="from-date w-30">
            <Select
              name="invitationStatuses"
              id="invitation-management-select"
              className="invitation-management-select"
              isClearable
              components={{ MenuList }}
              value={
                invitationStatusId && Enums.GuestInvitationStatus
                  ? {
                      value: Enums.GuestInvitationStatus.find(
                        (el) => el.id == invitationStatusId
                      )?.id,
                      label: Enums.GuestInvitationStatus.find(
                        (el) => el.id == invitationStatusId
                      )?.display,
                    }
                  : null
              }
              onChange={(newData) =>
                handleChangeInvitationStatusFilter(newData?.value)
              }
              options={
                Enums.GuestInvitationStatus
                  ? Enums.GuestInvitationStatus.map((el) => {
                      return { value: el.id, label: el.display };
                    })
                  : []
              }
              placeholder={t('selectInviteStatus')}
              isSearchable={true}
              styles={SelectStyles}
            />
          </div>
          {user.roleId != 4 && (
            <>
              <div className="from-date w-30">
                <Select
                  name="contractors"
                  className="invitation-management-select"
                  components={{ MenuList }}
                  value={
                    userContractorId && contractorList
                      ? {
                          value: contractorList.find(
                            (el) => el.id == userContractorId
                          )?.id,
                          label: contractorList.find(
                            (el) => el.id == userContractorId
                          )?.name,
                        }
                      : null
                  }
                  isClearable
                  onChange={(newData) =>
                    handleChangeContractorFilter(newData?.value)
                  }
                  options={
                    contractorList
                      ? contractorList.map((el) => {
                          return { value: el.id, label: el.name };
                        })
                      : []
                  }
                  placeholder={t('selectContractor')}
                  isSearchable={true}
                  styles={SelectStyles}
                />
              </div>
              <div className="from-date w-30">
                <Select
                  isClearable
                  name="invitedBy"
                  components={{ MenuList }}
                  className="invitation-management-select"
                  value={invitedBy}
                  onChange={(newData) => handleChangeInvitedBy(newData)}
                  options={
                    allUsers
                      ? allUsers.map((el) => ({
                          value: el.value,
                          label: capitalizeEachWord(el.label),
                        }))
                      : []
                  }
                  placeholder={t('invitedBy')}
                  isSearchable={true}
                  styles={SelectStyles}
                />
              </div>
            </>
          )}
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={tableColumn}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            onSortChanged={() => printSortStateToConsole()}
            frameworkComponents={{
              Phone: (e) => <Phone e={e} />,
              ViewMore: (e) => (
                <ViewMore
                  e={e}
                  allContractors={user?.contractors}
                  handleViewMore={handleViewMore}
                />
              ),
              NameCellRender: (e) => <NameCellRender e={e} />,
            }}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            rowSelection="multiple"
            onGridReady={(params) => {
              setGridApi(params.api);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={10}
            paginationNumberFormatter={function (params) {
              return '[' + params.value.toLocaleString() + ']';
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeUsers * (currentPageUsers - 1) + 1} to{' '}
                {newPageSizeUsers * currentPageUsers} of {totalUserRecords}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers - 1 > 0
                    ? setCurrentPageUsers(currentPageUsers - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageUsers} of{' '}
                {Math.ceil(invitationTotal / newPageSizeUsers)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers <
                  Math.ceil(invitationTotal / newPageSizeUsers)
                    ? setCurrentPageUsers(currentPageUsers + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(
                    Math.ceil(invitationTotal / newPageSizeUsers)
                  );
                }}
              />
            </Pagination>
          </div>
        </div>

        <AddInvitation
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewInvitation={handleAddNewInvitation}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          countryOptions={countryOptions}
          visitPurposeValues={visitPurposeValues}
          handleUpdateInvitation={handleUpdateInvitation}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          t={t}
          errors={errors}
          setErrors={setErrors}
          setIsMultiOrders={setIsMultiOrders}
          isMultiOrders={isMultiOrders}
          user={user}
          contractorList={contractorList}
          kitchen={kitchen}
          getMealTypes={getMealTypes}
          mealTypesIds={mealTypesIds}
          setMealTypesIds={setMealTypesIds}
          setGetMealTypes={setGetMealTypes}
          currentUserKitchens={currentUserKitchens}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
        />
        <ViewInvitationDetails
          open={viewDetails}
          details={details}
          Enums={Enums}
          setDummy={setDummy}
          handleClose={handleViewMore}
          CopyDataUrl={CopyDataUrl}
          ResendVerification={ResendVerification}
          handleConfirmation={handleConfirmation}
          handleOpen={handleOpen}
          setPayload={setPayload}
          countryOptions={countryOptions}
          visitPurposeValues={visitPurposeValues}
          invitationToDisplay={invitationToDisplay}
          UserStatuses={UserStatuses}
          setKitchensIds={setKitchensIds}
          Kitchens={Kitchens}
          Roles={Roles}
          user={user}
          setMealTypesIds={setMealTypesIds}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
          setGetMealTypes={setGetMealTypes}
          getMealTypes={getMealTypes}
          contractorList={contractorList}
          useFetchingLoader={useFetchingLoader}
          allUsers={allUsers}
          //handleWallet={handleWallet}
          //handleOrderLink={handleOrderLink}
        />
        <Toast
          onClose={() => setOpenDownload(false)}
          show={openDownload}
          style={{
            position: 'sticky',
            right: '20px',
            bottom: '70px',
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              onClick={() => setOpenDownload(false)}
            />
          </Toast.Header>
          <Toast.Body>{t('downloadFile')}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <h6>
        <p>{e?.data?.Name ? e?.data?.Name : '--'}</p>
      </h6>
    </div>
  );
};

const Phone = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e?.data?.phone ? e?.data?.phone : '--'}</p>
    </div>
  );
};
const ViewMore = ({ e, handleViewMore, allContractors, contractors }) => {
  return (
    <div className="view-more-btn">
      <img
        src={next}
        alt="next"
        onClick={() =>
          handleViewMore({ ...e.data, allContractors, contractors })
        }
      />
    </div>
  );
};
function capitalizeEachWord(string) {
  if (string) {
    const words = string.toLowerCase().split(' ');
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
  } else {
    return '';
  }
}
function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
const MenuList = ({
  options,
  children,
  maxHeight,
  width,
  getValue,
  selectProps,
}) => {
  const [value] = getValue();
  const object = document.getElementById('invitation-management-select');
  const canvas = document.getElementById('canvas');
  const elementWidth = object.offsetWidth - 26;
  const ctx = canvas.getContext('2d');
  ctx.font = '12px OpenSans';
  const getItemSize = (index) => {
    let colRows = children[index]?.props
      ? Math.ceil(
          Math.round(
            ctx.measureText(children[index].props.data.label.trim()).width
          ) / elementWidth
        )
      : 1;
    colRows = colRows > 1 ? colRows * 0.75 : colRows;
    return 35 * colRows;
  };
  const gridRef = useResetCache(children.length);
  return (
    <List
      height={
        children.length
          ? children.length < 5
            ? children.reduce((acc, curr) => {
                let colRows = curr?.props
                  ? Math.ceil(
                      Math.round(
                        ctx.measureText(curr.props.data.label.trim()).width
                      ) / 150
                    )
                  : 1;
                colRows = colRows > 1 ? colRows * 0.75 : colRows;
                return acc + 38 * colRows;
              }, 0)
            : 180
          : 0
      }
      ref={gridRef}
      itemCount={children.length}
      itemSize={getItemSize}
      className="customList"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const ContractorCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {Array.isArray(e?.data.Contractors) && e?.data.Contractors?.length > 0
          ? e?.data?.Contractors.map((el) => el.name).join(', ')
          : '--'}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Enums: state.app.enums,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
  invitationToDisplay: state.app.invitationToDisplay,
  invitationTotal: state.app.invitationTotal,
  companyName: state.companyConfig.companyName,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllInvitations,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationManagement);
