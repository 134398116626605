import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import http from '../../services/http';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import { validateSlotType } from '../../services/validators';
import cancel from '../../assets/images/close.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};
const initialValues = {
  from: '',
  to: '',
  maxReservations: '',
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onChange={(event) => cb(event.target.value)}
        required
        type={type}
      />
    </>
  );
};

const UpdateReservations = ({
  open,
  handleClose,
  mealId,
  mealSlot,
  t,
  useFetchingLoader,
}) => {
  const [payload, setPayload] = useState(initialValues);
  const [data, setData] = useState(null);

  const handleChange = (name, value) => {
    setPayload({ ...payload, [name]: value });
  };

  const handleUpdateReservation = async (slotId) => {
    // try {
    //   await http.put(`mealSlots/update/${slotId}`, {
    //     reservations: payload.reservations,
    //   });

    //   // Update the meal inventory with the response from the db
    // } catch (err) {
    //   Alert({ error: err });
    // }
    try {
      await useFetchingLoader(async () => {
        await http.put(`mealSlots/update/${slotId}`, {
          reservations: payload.reservations,
        });
      });
      setTimeout(() => {
        Dialog({
          title: 'Updated Successfully',
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {},
        });
      }, 150);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup add-slot-popup"
    >
      <Box sx={style}>
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {t('mealtypeSlot')}
        </Typography>
        <Typography id="modal-modal-description">
          <div className="update-reservations">
            {mealSlot?.length > 0 ? (
              mealSlot.map((el, i) => (
                <div className="item">
                  <div className="input-field">
                    <h6>{t('startTime')}</h6>
                    <p>{el.mealTypesSlot.from}</p>
                  </div>
                  <div className="input-field">
                    <h6>{t('endTime')}</h6>
                    <p>{el.mealTypesSlot.to}</p>
                  </div>
                  <div className="input-field">
                    <GetInputField
                      placeholder={'Reservation'}
                      label={'Reservation'}
                      name="reservations"
                      type="text"
                      value={el.reservations}
                      cb={(value) => {
                        handleChange('reservations', value);
                      }}
                      required
                    />
                  </div>
                  <div className="addft-btn">
                    <div>
                      <ActiveButton
                        label={'Update'}
                        arrow={false}
                        cb={() => handleUpdateReservation(el.id)}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-meal-available">{t('noSlotsAvailable')}</p>
            )}
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default UpdateReservations;
