import React from 'react';

const WESpinner = ({ size = 24, color = 'white', margin = 0 }) => {
  return (
    <i
      className="fa fa-spinner fa-spin"
      style={{ fontSize: `${size}px`, color, margin }}
    />
  );
};

export default WESpinner;
