import React from 'react';
import Switch from 'react-switch';

const WEToggle = ({
  checked,
  label = '',
  size = 'medium',
  onColor = '#14cd31',
  onChange = () => {},
  disabled = false,
}) => {
  return (
    <div className={`we-toggle`}>
      <Switch
        onChange={onChange}
        checked={checked}
        onColor={onColor}
        height={26}
        width={55}
        disabled={disabled}
      />
      <p className={`we-icon-text toggle-text ${size}`}>{label}</p>
    </div>
  );
};

export default WEToggle;
