import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  styled,
  Typography,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { ActiveButton } from '../../components/WE-Button';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import http from '../../services/http';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import { validateSlotType, checkDate } from '../../services/validators';
import cancel from '../../assets/images/close.svg';

const style = {
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(event) => cb(event.target.value)}
        required
        // disabled={!editMode}
        type={type}
      />
    </>
  );
};

const MealSlotPopup = ({
  open,
  handleClose,
  t,
  mealTypeSlot,
  handleMealslot,
  allMealtypeSlot,
  useFetchingLoader,
  handleMealstype,
  setAllMealtypeSlot,
  payload,
  setPayload,
  setMealSlot,
  mealSlot,
}) => {
  const [maxtime, setMaxtime] = useState(null);
  const [edit, setEdit] = useState(false);
  const [mealSlotId, setMealSlotId] = useState(null);
  const [errors, setErrors] = useState({});
  // const [allMealtypeSlotCopy, setAllMealtypeSlotCopy] =
  //   useState(allMealtypeSlot);

  useEffect(() => {
    if (mealTypeSlot.from) setMaxtime(mealTypeSlot.from);
  }, []);

  const handleChange = (name, value) => {
    if (name === 'from') {
      let newValue = value && value.format('HH:mm');
      const d = allMealtypeSlot.data;
      setPayload({ ...payload, [name]: newValue });
      let isValid = validateSlotType(newValue);
      if (!isValid) {
        return setErrors({
          ...errors,
          from: 'End time must greater than Start time',
        });
      } else {
        setErrors({ ...errors, from: '' });
      }
    }
    if (name === 'to') {
      let newValue1 = value.format('HH:mm');
      const d1 = allMealtypeSlot.data;
      setPayload({ ...payload, [name]: newValue1 });
      let isValid = validateSlotType(newValue1);
      if (!isValid) {
        return setErrors({
          ...errors,
          to: 'End time must greater than Start time',
        });
      } else {
        setErrors({ ...errors, to: '' });
      }
    }
    if (name === 'maxReservations') {
      setPayload({ ...payload, [name]: value });
      let isValid = validateSlotType(value);
      if (!isValid) {
        return setErrors({
          ...errors,
          maxReservations: 'End time must greater than Start time',
        });
      } else {
        setErrors({ ...errors, maxReservations: '' });
      }
    }
    // let isValid = validateSlotType(value);
    // if (!isValid) {
    //   return setErrors({
    //     ...errors,
    //     to: 'End time must greater than Start time',
    //   });
    // } else {
    //   setErrors({ ...errors, to: '', from: '' });
    // }
  };
  const handleItem = () => {};

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      mealTypeId: mealTypeSlot.id,
      from: payload.from,
      to: payload.to,
      maxReservations: payload.maxReservations,
    };
    // allMealtypeSlot.data.forEach(el => {
    //   // check details.from < el.from
    //   // return
    //   if(details.from)
    // })
    const { isValid, errors } = validateSlotType(details);
    setErrors(errors);
    let aa = '';
    allMealtypeSlot.data.forEach((el) => {
      let isValid = checkDate(details.from, el.to);
      if (isValid) {
        aa = t('existTime');
        return setErrors({
          ...errors,
          from: t('existTime'),
        });
      } else {
        setErrors({ ...errors, from: '' });
      }
    });
    if (aa === t('existTime')) {
      return;
    }

    if (isValid) {
      await handleMealslot({ ...details });
      setPayload({
        mealTypeId: '',
        from: '',
        to: '',
        maxReservations: '',
      });
    }
  };

  const handleDelete = async (id, index) => {
    try {
      const da = await useFetchingLoader(async () => {
        await http.put(`mealTypesSlots/delete/${id}`);
      });
      setTimeout(() => {
        Dialog({
          title: t('deletedSuccessfully'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {},
        });
      }, 150);
      setAllMealtypeSlot((prev) => {
        const prevArr = [...prev.data];
        prevArr.splice(index, 1);
        return { ...prev, data: [...prevArr] };
      });
    } catch (err) {
      if (err === 'error') Alert({ error: t('deleteMealTypeSlotError') });
    }
  };

  const handleEdit = (el) => {
    setMealSlotId(el.id);
    setEdit(true);
    setPayload({ ...el });
  };

  const handleUpdate = async () => {
    let copyallMealtypeSlot = allMealtypeSlot;
    let copyMealData = copyallMealtypeSlot.data;
    const index = copyMealData.findIndex((x) => x.id === mealSlotId);
    copyMealData[index].from = payload.from;
    copyMealData[index].to = payload.to;
    copyMealData[index].maxReservations = payload.maxReservations;
    copyallMealtypeSlot.data = copyMealData;
    let details = {
      mealTypeId: payload.mealTypeId,
      from: payload.from,
      to: payload.to,
      maxReservations: payload.maxReservations,
    };
    try {
      await useFetchingLoader(async () => {
        await http.put(`mealTypesSlots/update/${mealSlotId}`, { details });
      });
      Dialog({
        title: t('editSuccessfully'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          setAllMealtypeSlot(copyallMealtypeSlot);
          setPayload({
            mealTypeId: '',
            from: '',
            to: '',
            maxReservations: '',
          });
          setEdit(false);
        },
      });
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
      setPayload({
        mealTypeId: '',
        from: '',
        to: '',
        maxReservations: '',
      });
      setEdit(false);
    }
  };

  let beforeTime = mealTypeSlot?.from;
  let afterTime = mealTypeSlot?.to;
  const beforeArr = beforeTime
    ? beforeTime
        .trim()
        .split(':')
        .map((el) => el * 1)
    : '';
  const afterArr = afterTime
    ? afterTime
        .trim()
        .split(':')
        .map((el) => el * 1)
    : '';

  let TimeArr = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24,
  ];
  let hourValue = beforeArr[0];
  let hourValue1 = afterArr[0];
  let hourArray = TimeArr.slice(0, hourValue);
  let hourArray1 = TimeArr.slice(hourValue1 + 1);
  let hA = hourArray.concat(hourArray1);

  let minArr = [0, 15, 30, 45];
  let minValue = beforeArr[1];
  let minValue1 = afterArr[1];
  let minArraymm = minArr.splice(0, minArr.indexOf(minValue));
  let minArray = minArr.slice(0, minValue + 1);

  let hourValueaft = afterArr[0];
  let hourArrayaft = TimeArr.slice(0, hourValue);

  let minArr1 = [0, 15, 30, 45];
  let minValueaft = afterArr[1];
  let minArraymm1 = minArr1.splice(minArr1.indexOf(minValueaft) + 1);
  // let minArrayaft = minArr1.slice(minValueaft + 1);
  return (
    <Modal
      show={mealSlot}
      onHide={() => setMealSlot(false)}
      centered
      className="add-employee-popup add-slot-popup add-new-slot"
    >
      <Box sx={style}>
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={() => setMealSlot(false)} />
        </div>
        <div className="top-header">
          {t('mealtypeSlot')}{' '}
          <span>
            {mealTypeSlot.from} - {mealTypeSlot.to}
          </span>
        </div>
        <div id="modal-modal-description">
          <div className="item">
            <div className="input-field">
              <label>{t('startTime')}</label>
              <TimePicker
                value={moment(payload['from'] + ':00', 'HH:mm')}
                showSecond={false}
                minuteStep={15}
                name="from"
                onChange={(value) => {
                  handleChange('from', value);
                }}
                disabledHours={() => hA}
                // disabledMinutes={() => minArraymm}
              />
              {errors && <p className="error-msg">{errors.from}</p>}
            </div>
            <div className="input-field">
              <label>{t('endTime')}</label>
              <TimePicker
                value={moment(payload['to'] + ':00', 'HH:mm')}
                showSecond={false}
                minuteStep={15}
                name="to"
                disabledHours={() => hA}
                // disabledMinutes={() => minArraymm1}
                onChange={(value) => {
                  handleChange('to', value);
                }}
              />
              {errors && <p className="error-msg">{errors.to}</p>}
            </div>
            <div className="input-field">
              <GetInputField
                placeholder={t('maxReservations')}
                label={t('maxReservations')}
                name="maxReservations"
                type="number"
                value={payload['maxReservations']}
                cb={(value) => {
                  handleChange('maxReservations', value);
                }}
                required
              />
              {errors && <p className="error-msg">{errors.maxReservations}</p>}
            </div>
          </div>
          <div className="addft-btn">
            {!edit ? (
              <div onClick={handleFormSubmit} className="add-btn">
                <ActiveButton label={t('add')} arrow={false} />
              </div>
            ) : (
              <div onClick={handleUpdate} className="add-btn">
                <ActiveButton label={'Update'} arrow={false} />
              </div>
            )}
          </div>
          <div className="item-list">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('startTime')}</TableCell>
                    <TableCell>{t('endTime')} </TableCell>
                    <TableCell>{t('maxReservations')}</TableCell>
                    <TableCell>{t('action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allMealtypeSlot &&
                    allMealtypeSlot.data &&
                    allMealtypeSlot.data.map((el, i) => (
                      <TableRow key={i}>
                        <TableCell>{el.from}</TableCell>
                        <TableCell>{el.to}</TableCell>
                        <TableCell>{el.maxReservations}</TableCell>
                        <TableCell>
                          {/* <button
                            className="edit-btn"
                            onClick={() => handleEdit(el)}
                          >
                            {t('edit')}
                          </button>
                          <button
                            className="delete-btn"
                            onClick={() => handleDelete(el.id, i)}
                          >
                            {t('delete')}
                          </button> */}
                          <i
                            class="las la-pen"
                            onClick={() => handleEdit(el)}
                          ></i>
                          <i
                            class="las la-trash"
                            onClick={() => handleDelete(el.id, i)}
                          ></i>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default MealSlotPopup;
