import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';

const TermsCondition = () => {
  const { t } = useTranslation();
  const { lang: language } = useParams();
  return (
    <Layout>
      <Container>
        {language === 'he' ? (
          <div className="terms-condition-page hebrew-text">
            <h2>עודכן לאחרונה: 11 בינואר, 2023</h2>
            <ul>
              <h6>
                <span>1. {'  '}</span>מבוא
              </h6>
              <ul>
                <li>
                  <span>1.1{'  '}</span>המבוא למסמך זה ונספחיו מהווים חלק בלתי
                  נפרד ממנו.
                </li>
                <li>
                  <span>1.2{'  '}</span>כותרות הסעיפים הוכנסו לשם הנוחיות בלבד
                  ואין להם, ולא יינתן להם, כל משקל
                </li>
                <li>
                  <span>1.3{'  '}</span>ההסכם נכתב בלשון זכר מטעמי נוחות בלבד,
                  והוא מכוון לשני המינים כאחד.
                </li>
                <li>
                  <h6>
                    <span>1.4{'  '}</span>הגדרות
                  </h6>
                </li>
                <ul>
                  <li>
                    <span>1.4.1{'  '}</span>"החברה" - וורק איט בע"מ (חברה בהקמה)
                  </li>
                  <li>
                    <span>1.4.2{'  '}</span>"החברה המארחת" - חברה אשר התקשרה עם
                    החברה בהסכם שירותים.
                  </li>
                  <li>
                    <span>1.4.3{'  '}</span>"ההסכם" - מסמך תנאי שימוש זה.
                  </li>
                  <li>
                    <span>1.4.4{'  '}</span>"האתר" – אתר אינטרנט ייעודי, המאפשר
                    למשתמשים הזמנת מנות במטבח ו/או חדר אוכל ו/או מתקן אחר של
                    החברה המארחת, מימוש ההזמנה במטבח והוספת חוות דעת על המנה ועל
                    המטבח.
                  </li>
                  <li>
                    <span>1.4.5{'  '}</span>"משתמשים" – כל מי שעושה שימוש כלשהו
                    באתר, בין אם הוא עובד החברה המארחת או אורח החברה המארחת.
                  </li>
                </ul>
                <li>
                  <span>1.5{'  '}</span>הסכם זה בא להסדיר את מערכת היחסים בין
                  החברה לבין המשתמשים באתר.
                </li>

                <li>
                  <span>1.6{'  '}</span>ההרשמה לאתר והשימוש בו מהווים הסכמה
                  וקבלת תנאי הסכם זה. משתמש שאינו מסכים לתנאי השימוש (חלקם או
                  כולם) המנוסחים בהסכם זה, אינו רשאי לעשות שימוש כלשהו באתר או
                  בחלק מהשירותים המסופקים באמצעותו.
                </li>
                <li>
                  <span>1.7{'  '}</span>תנאי הסכם זה נכונים לכל שימוש באתר,
                  והשימוש במונח אחד נכון גם למונח השני, בשינויים המתחייבים.
                </li>
                <li>
                  <span>1.8{'  '}</span>מובהר בזאת כי תנאי הסכם זה פועלים במקביל
                  ובכפוף לתנאי השימוש של צדדים שלישיים, ככל שיהיו כאלה, ולנהלים
                  הפנימיים של החברה המארחת, והם באים להוסיף עליהם.מובהר בזאת כי
                  תנאי הסכם זה פועלים במקביל ובכפוף לתנאי השימוש של צדדים
                  שלישיים, ככל שיהיו כאלה, ולנהלים הפנימיים של החברה המארחת, והם
                  באים להוסיף עליהם.
                </li>
              </ul>
              <h6>
                <span>2.{'  '}</span>תנאי שימוש
              </h6>
              <ul>
                <li>
                  <span>2.1{'  '}</span>המשתמש מתחייב להימנע מביצוע הפעולות
                  הבאות ביחס לאתר והשירותים המסופקים באמצעותו:
                </li>
                <ul>
                  <li>
                    <span>2.1.1{'  '}</span>גלישה, סריקה או שימוש באתר באמצעות
                    תוכנות מחשב המיועדת לאסוף מידע, או לבצע פעולות בדרך של חיקוי
                    גולש רגיל, לרבות Bots או Crawlers וכדומה.
                  </li>
                  <li>
                    <span>2.1.2{'  '}</span>מניפולציה על כתובת ה-URL של דפים
                    פנימיים, בכדי להגיע לדפים פנימיים אליהם אין לו גישה ישירה
                    (URL Hacking).
                  </li>
                  <li>
                    <span>2.1.3{'  '}</span>העלאה או העברה של תוכן, או ביצוע
                    פעולות, אשר יש בהן משום עבירה על חוקי מדינת ישראל או בכלל;
                    ובכלל זאת להימנע מהעלאה, העברה או פרסום של תוכן אשר יש בו
                    כדי להפר זכות יוצרים, סימן מסחר או סוד מסחרי של אחר, פרסום
                    לשון הרע, דברי תועבה או פגיעה בפרטיותו של אחר.
                  </li>
                  <li>
                    <span>2.1.4{'  '}</span>ביצוע פעולה בעיצוב האתר, קוד המקור,
                    אלמנטים או תכנים המופיעים באתר, אשר הזכות לבצעה נתונה
                    בלעדית, על פי חוק זכות יוצרים התשס"ח-2007, לבעל זכות
                    היוצרים, שלא בהתאם לאמור בהסכם זה.
                  </li>
                  <li>
                    <span>2.1.5{'  '}</span>התנהגות אלימה, בוטה, פלילית או כזו
                    הפוגעת בכל צורה שהיא, כלפי החברה, כלפי החברה המארחת, כלפי
                    משתמשים אחרים באתר או נותני השירותים המסופקים באמצעות האתר
                    (דוגמת ספקי המזון המפעילים את מטבח החברה המארחת).
                  </li>
                </ul>
                <li>
                  <span>2.2{'  '}</span>החברה שומרת לעצמה את הזכות:
                </li>
                <ul>
                  <li>
                    <span>2.2.1{'  '}</span>להפסיק או למנוע את השירות, או חלקים
                    ממנו, ממשתמש אשר הפר סעיף מסעיפי הסכם זה, או בכלל; הכל על פי
                    שיקול דעתה הבלעדי של החברה ו/או של החברה המארחת, ומבלי שתהיה
                    חייבת להודיע על כך מראש למשתמש. לא תישמע כל טענה של המשתמש
                    בעניין זה.
                  </li>
                  <li>
                    <span>2.2.2{'  '}</span>להסיר כל תוכן או לבטל כל פעולה אשר
                    בוצעה על-ידי המשתמש ואשר יש בה משום הפרת סעיף מסעיפי הסכם
                    זה, או בכלל.
                  </li>
                  <li>
                    <span>2.2.3{'  '}</span>החברה תהיה רשאית לחסום גישה לאתר –
                    מכתובות או התקנים אשר לא אושרו על-ידי החברה לשימוש במוצריה,
                    או שעלולים לגרום נזק לחברה או מוצריה – לפי שיקול דעתה הבלעדי
                    של החברה וללא צורך בהודעה מוקדמת.
                  </li>
                </ul>
              </ul>
              <h6>
                <span>3.{'  '}</span>חשבון משתמש
              </h6>
              <ul>
                <li>
                  <span>3.1{'  '}</span>הגישה לשירותים תתאפשר רק למשתמש אשר נרשם
                  לאתר, ומסר לחברה פרטים מסוימים, אשר ייקבעו על-ידי החברה (להלן:
                  "חשבון משתמש" או "החשבון"). קיימת אפשרות, על פי בחירת החברה
                  המארחת, ליצירת חשבון באופן אוטומטי על ידי מערכת המנוהלת על ידי
                  החברה המארחת.
                </li>
                <li>
                  <span>3.2{'  '}</span>ההוראות הבאות יחולו על יצירת חשבון
                  משתמש:
                </li>
                <ul>
                  <li>
                    <span>3.2.1{'  '}</span>אסור ליצור חשבון משתמש המכיל נתונים
                    אשר אינם פרטיו האמיתיים והמלאים של פותח החשבון.
                  </li>
                  <li>
                    <span>3.2.2{'  '}</span>אסור ליצור חשבון משתמש עבור אדם אשר
                    אינו מורשה מטעם החברה המארחת לעשות שימוש באתר ולהזמין
                    באמצעותו מנות ממטבח החברה המארחת.
                  </li>
                  <li>
                    <span>3.2.3{'  '}</span>אסור ליצור חשבון עבור אדם אשר אינו
                    נוכח מול צג המחשב או הטלפון בעת ההרשמה, או אשר אינו מאשר את
                    תנאי הסכם זה. לעניין הוראות אלו יודגש כי התחזות היא עבירה
                    פלילית.
                  </li>
                </ul>
                <li>
                  <span>3.3{'  '}</span>מובהר בזאת כי על אף שהחברה נוקטת באמצעים
                  לאבטחת תוכן חשבון המשתמש, היא אינה יכולה להבטיח בצורה מוחלטת
                  את אי חשיפתו כתוצאה מחדירה לא מורשית, ולמשתמש לא תהיה כל טענה
                  כלפי החברה בגין נזקים, אשר ייגרמו כתוצאה מחדירה לא מורשית
                  לחשבון המשתמש.
                </li>
                <li>
                  <span>3.4{'  '}</span>בעת יצירת חשבון משתמש, ייתכן והחברה תשלח
                  למשתמש מסרון ובו קוד זמני שנועד לצרכי זיהוי והרשמה. המשתמש
                  מאשר לחברה משלוח מסרונים כאמור ומתחייב לשמור על הקוד בסודיות
                  ולצורך הרשמתו האישית בלבד. אין להעביר את הקוד לכל גורם אחר
                </li>
                <li>
                  <span>3.5{'  '}</span>המשתמש מאשר לחברה לשלוח לתיבת הדוא"ל
                  שלו, לפי הפרטים שהוזנו בחשבון המשתמש, עדכונים ואישורים ביחס
                  לפעולות המשתמש באתר, וכל הודעה אחרת, בפרט, מאשר המשתמש לחברה
                  לשלוח לו הודעות אישיות ו/או דוא"ל ככל שתראה בכך החברה צורך לשם
                  אימות הזמנות או תכנים המועלים על ידו.
                </li>
              </ul>
              <h6>
                <span>4.{'  '}</span>תשלום באמצעות האתר
              </h6>
              <ul>
                <li>
                  <span>4.1{'  '}</span>יתכן, כי לצורך תשלום עבור מנות מסוימות,
                  תועבר לעמוד של חברת ו/או חברות הסליקה אשר עמה תעבוד החברה
                  (להלן: "חברת הסליקה").
                </li>
                <li>
                  <span>4.2{'  '}</span>ייתכן כי לצורך תהליך השלמת הרכישה לאחר
                  הזנת פרטי כרטיס האשראי למערכת של חברת הסליקה, תידרש לאמת את
                  זהותך בדרכים אותן תקבע חברת הסליקה מעת לעת.
                </li>
                <li>
                  <span>4.3{'  '}</span>יודגש כי פרטי כרטיס האשראי יהיו מצויים
                  ו/או יישמרו ו/או ינוהלו ע"י חברת הסליקה בלבד ובאחריותה בלבד.
                  החברה אינה שומרת פרטים אלו, ואינה אחראית לאבטחתם ו\או לכל נזק
                  ו\או אובדן ו\או הפסד ו\או הוצאה שייגרמו למשתמש ו\או לצד שלישי
                  כלשהו כתוצאה מכך, לרבות לדליפת נתונים ו/או פריצה לנתונים ו/או
                  לאבטחת מידע של נתונים אלו.
                </li>
                <li>
                  <span>4.4{'  '}</span>המשתמש מצהיר בזאת שהפרטים האישיים שהוא
                  מוסר לחברת הסליקה יהיו פרטים נכונים, מדויקים, עדכניים ומלאים.
                </li>
                <li>
                  <span>4.5{'  '}</span>חל איסור מוחלט לעשות שימוש בפרטים אישיים
                  של אדם אחר ו/או להתחזות לאחר.
                </li>
                <li>
                  <span>4.6{'  '}</span>החברה לא תהיה אחראית בשום מקרה, לתשלום
                  במקום המשתמש, לשיפוי המשתמש או לכל נזק, הוצאה או הפסד שייגרמו
                  לו כתוצאה משימוש בשירותי חברת הסליקה כאמור לעיל.
                </li>
                <li>
                  <span>4.7{'  '}</span>ביטול עסקה בהתאם לתקנות הגנת הצרכן
                  (ביטול עסקה), התשע"א-2010 וחוק הגנת הצרכן, התשמ"א-1981
                </li>
                <li>
                  <span>4.8{'  '}</span>בית העסק לענייני תשלומים הוא מפעיל חדר
                  האוכל בחברה המארחת, לא הפלטפורמה.
                </li>
              </ul>
              <h6>
                <span>5.{'  '}</span>הגבלת אחריות
              </h6>
              <ul>
                <li>
                  <span>5.1{'  '}</span>מובהר בזאת כי האתר והשירותים המסופקים
                  באמצעותו, יסופקו על-ידי החברה כמות שהם (As-Is). החברה לא תישא
                  בשום אחריות שהיא בגין נזק כלשהו שייגרם למשתמש (או למי מטעמו),
                  בגין שימוש באתר או בשירותים המסופקים באמצעותו; או בגין שימוש
                  במוצרים או שירותים של צדדים שלישיים, בין אם שירותים אלה
                  מסופקים באופן דיגיטלי ובין אם בכל אופן אחר.
                </li>
                <li>
                  <span>5.2{'  '}</span>מובהר בזאת כי החברה לא מתחייבת שהמערכת
                  תפעל 100% מהזמן; כי מעת לעת החברה תעדכן ותשדרג את מערכות האתר
                  – דבר שעלול לגרום להפסקה זמנית במתן השירות; וכי אין לחברה כל
                  שליטה על זמינות המוצרים או השירותים המסופקים על-ידי צדדים
                  שלישיים.
                </li>
                <li>
                  <span>5.3{'  '}</span>באחריות המשתמש לבחון, טרם השימוש באתר,
                  כי האתר אכן מתאים לצרכיו, ולמשתמש לא תהיה כל טענה בשל אי התאמת
                  האתר לצרכיו.
                </li>
                <li>
                  <span>5.4{'  '}</span>באחריות המשתמש לוודא טרם השימוש באתר, את
                  תקינות החיבור שלו לרשת האינטרנט – החברה לא תישא בכל אחריות בשל
                  נזק אשר יתכן וייגרם למשתמש עקב בעיות בחיבורו לרשת האינטרנט.
                </li>
                <li>
                  <span>5.5{'  '}</span>החברה לא תהיה אחראית בשום מקרה, לתכנים
                  אשר יועלו, יועברו או יפורסמו באמצעות האפליקציה – לא לתכנים אשר
                  יועלו על ידי החברה המארחת ו/או על ידי מי מטעמה, ולא לתכנים אשר
                  יועלו על ידי משתמשי האתר. האחריות בגין פעולות אלה תחול על
                  המשתמש בלבד, ולא תישמע כל טענה נגד החברה בעניין זה.
                </li>
              </ul>
              <h6>
                <span>6.{'  '}</span>העדר אחריות לפעולות משתמשים
              </h6>
              <ul>
                <li>
                  <span>6.1{'  '}</span>במסגרת שירותי האפליקציה, ובכפוף לאמור
                  בתנאי שימוש אלה, באפשרות ציבור המשתמשים להעלות ולהציג באתר
                  דירוג ו/או חוות דעת מילולית אודות המטבח ו/או מנות המוגשות
                  במטבח (להלן: "תוכן משתמשים").
                </li>
                <li>
                  <span>6.2{'  '}</span>מובהר כי מנגנון העלאת תוכן המשתמשים הוא,
                  במלואו או בחלקו, הליך המתנהל ללא מעורבות אדם, והחברה אינה
                  יכולה לאמת את זהות מעלה התוכן או לבחון את מהות התוכן המועלה
                  טרם הצגתו, ולא יהיו אחראים לתוכן המשתמשים המוצג באתר,
                  לאמינותו, רמת דיוקו או התאמתו לצרכי המשתמשים האחרים.
                </li>
                <li>
                  <span>6.3{'  '}</span>מובהר כי הדירוג ו/או חוות הדעת אינם
                  משקפים את עמדתה של החברה או את דעתה אלא של המשתמשים בלבד,
                  ולמשתמש או לספק, לפי העניין, לא תהיה כל טענה כלפי החברה בגין
                  כל תוכן משתמשים המוצג באתר.
                </li>
                <li>
                  <span>6.4{'  '}</span>מבלי לפגוע באמור בסעיף 6.3, באם למשתמש
                  יסוד להניח כי אדם אחד עומד מאחורי מספר דירוגים של מנה אחת, הוא
                  מוזמן להפנות את תשומת לבה של החברה, אשר שומרת על זכותה לבטל
                  פעולות דירוג שנעשו תוך הפרת תנאי שימוש אלו.
                </li>
              </ul>
              <h6>
                <span>7.{'  '}</span>העלאת תוכן על ידי משתמש
              </h6>
              <ul>
                <li>
                  <span>7.1{'  '}</span>המשתמש מצהיר ומתחייב, כי אין בהעלאת
                  התוכן לאתר, או בשימוש בו על ידי החברה או המשתמשים, בהתאם לתנאי
                  שימוש אלו, בכדי להפר את חוקי מדינת ישראל או בכלל, ובכלל זה
                  להפר זכות יוצרים או סימן מסחר, לגלות סוד מסחרי, או להוות פגיעה
                  בפרטיות, תועבה או פרסום לשון הרע.
                </li>
                <li>
                  <span>7.2{'  '}</span>המשתמש מתחייב שלא להעלות תוכן אשר עלול
                  לפגוע בתקנת הציבור, תוכן אשר יש בו כדי לפגוע בזכויותיו או
                  רגשותיו של אדם, גוף או ציבור, או תוכן פרסומי או מטעה.
                </li>
                <li>
                  <span>7.3{'  '}</span>החברה אינה מתחייבת להציג את התוכן המועלה
                  ושומרת על זכותה לסרב להעלות תוכן או למחקו, בהתאם לאמור בתנאי
                  שימוש אלו.
                </li>
                <li>
                  <span>7.4{'  '}</span>המשתמש שומר בידיו את זכות היוצרים על כל
                  תוכן אשר העלה לאתר, ואשר הוא בעל זכות היוצרים בו, אך מעניק
                  לחברה רישיון, לא בלעדי ושאינו מוגבל בזמן, להעמדת התוכן לרשות
                  הציבור ומשתמשי האפליקציה, ולעשות בו שימוש לפי שיקול דעתה,
                  לרבות שימוש שיווקי
                </li>
                <li>
                  <span>7.5{'  '}</span>מובהר כי המשתמש, כבעל זכות היוצרים בתוכן
                  המשתמשים שהועלה על ידו, אחראי לאכיפת הרשאות השימוש ביחס לתוכן
                  זה.
                </li>
                <li>
                  <span>7.6{'  '}</span>המשתמש מאשר לחברה לבצע עריכה מסוימת של
                  התוכן המועלה לאתר על מנת להתאימו למבנה האתר ומתחייב לעמוד
                  בתנאי העריכה, כפי שאלו מופיעים בדפי הוספת התוכן באתר.
                </li>
              </ul>
              <h6>
                <span>8.{'  '}</span>היעדר אחריות לפרסומות ולקישורים יוצאים
              </h6>
              <ul>
                <li>
                  <span>8.1{'  '}</span>ייתכן ופרסומות למוצרי או שירותי צדדים
                  שלישיים יהיו מוצגות באתר בחסות שירות חיצוני, המתאים את
                  הפרסומות לתוכן הדף. מובהר כי לחברה אין כל שליטה על בחירת
                  הפרסומות המוצגות, סדר הופעתן או אמיתות תוכנן.
                </li>
                <li>
                  <span>8.2{'  '}</span>חלק מהקישורים (Hyper-Links) המופיעים
                  באתר מפנים לאתרים בניהול צדדים שלישיים או בבעלותם. מובהר כי
                  הקישור לאתרים אלו נעשה אך ורק לנוחות המשתמש וכי החברה לא בחנה
                  אתרים אלה, ואינה תומכת בהם או אחראית להם, לתוכנם או לאבטחתם,
                  וכי גלישה, שימוש או מסירת מידע לאתרים אלו היא באחריותו המלאה
                  של המשתמש.
                </li>
                <li>
                  <span>8.3{'  '}</span>יודגש שלא קיימת אבחנה בין סימון קישורים
                  פנימיים לקישורים יוצאים וכי באחריות המשתמש לוודא את כתובת
                  ה-URL אליו מפנה קישור טרם השימוש בו.
                </li>
              </ul>
              <li>
                <span>9.{'  '}</span>הודעה על תוכן מפר - החברה אמונה על שמירת
                חוקי מדינת ישראל – אם נתקלתם בתוכן המפר את חוקי מדינת ישראל או
                בכלל, נודה לכם את תפנו את תשומת לבנו באמצעות משלוח הודעה, המפרטת
                את התוכן המפר ומהות ההפרה, לכתובת הדואר האלקטרוני
                <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
              </li>
              <h6>
                <span>10.{'  '}</span>קניין רוחני
              </h6>
              <ul>
                <li>
                  <span>10.1{'  '}</span>החברה היא הבעלים של מלוא הזכויות, לרבות
                  זכויות הקניין הרוחני (ולרבות סימני מסחר של החברה, ככל שישנם
                  כאלה), בכל השירותים שיסופקו על-ידי החברה, ובכל הכלים שישמשו את
                  החברה במהלך מתן השירותים. כל זאת, לרבות כל שינוי, שיפור, תוספת
                  או יצירה נגזרת של האמור לעיל, ולמעט מוצרים ושירותים של צדדים
                  שלישיים.
                </li>
                <li>
                  <span>10.2{'  '}</span>המשתמש לא יהיה זכאי לעשות כל שימוש באתר
                  או בכל אחד משירותיו, באופן הנוגד את הוראות הסכם זה, ואשר יש בו
                  לפגוע בזכויות החברה או בזכויות של צד שלישי כלשהו – מבלי שקיבל
                  את הסכמת החברה בכתב ומראש.
                </li>
                <li>
                  <span>10.3{'  '}</span>הוראות סעיף 10 זה יחולו אף לאחר הפסקת
                  מתן השירות וסיומו של הסכם זה, מכל סיבה שהיא.
                </li>
              </ul>
              <h6>
                <span>11.{'  '}</span>שינוי תנאים
              </h6>
              <ul>
                <li>
                  <span>11.1{'  '}</span>החברה רשאית לבצע שינויים בהסכם זה או
                  בנספחיו בכל עת, ומבלי שתצטרך לתת על כך הודעה מראש
                </li>
                <li>
                  <span>11.2{'  '}</span>באחריות המשתמש להיכנס לדף זה בכדי לברר
                  האם בוצעו שינויים בהסכם זה.
                </li>
              </ul>
              <h6>
                <span>12.{'  '}</span>שיפוי
              </h6>
              <ul>
                <li>
                  <span>12.1{'  '}</span>המשתמש מתחייב כי ישפה ויפצה את החברה,
                  בגין כל הפסד, נזק או חסר (לרבות הוצאות ושכ"ט עו"ד) שייגרמו
                  לחברה, בקשר עם כל דרישה או תביעה שתופנה אליה, כתוצאה משימושו
                  של המשתמש באתר, מכל גורם שהוא; כל זאת, תוך 30 יום ממועד קבלת
                  דרישת החברה לראשונה בכתב.
                </li>
                <li>
                  <span>12.2{'  '}</span>האמור בסעיף 12 זה אינו גורע מכל זכות או
                  סעד אחרים, העומדים לחברה לפי הסכם זה או לפי כל דין.
                </li>
              </ul>
              <h6>
                <span>13.{'  '}</span>ברירת דין וסמכות שיפוט
              </h6>
              <ul>
                <li>
                  <span>13.1{'  '}</span>סמכות השיפוט הייחודית והבלעדית בכל
                  הקשור לנושאים ולעניינים הנובעים או הקשורים להסכם, תהא אך ורק
                  לבית המשפט המוסמך שמקום מושבו בתל-אביב. הדין שיחול על הסכם זה
                  יהיה הדין הישראלי.
                </li>
              </ul>
            </ul>
          </div>
        ) : language === 'en' || language === 'ru' ? (
          <div className="terms-condition-page">
            <h2>Recently Updated: January 11th, 2023</h2>
            <ul>
              <h6>
                <span>1. </span>Introduction
              </h6>
              <ul>
                <li>
                  <span>1.1</span>The preamble to this document together with
                  its appendices constitutes an inseparable part of the
                  document.
                </li>
                <li>
                  <span>1.2</span>Titles are for convenience only and shall not
                  be used for interpretation.
                </li>
                <li>
                  <span>1.3</span>For convenience only, these Terms are written
                  in the male form, but it is collectively intended for both
                  genders.
                </li>
                <li>
                  <span>1.4</span>For the avoidance of all doubts, it is hereby
                  clarified that the Hebrew version of Terms and Conditions
                  shall apply, and versions in other languages may be set only
                  for convenience; in any case, if any differences occurred
                  between versions – the Hebrew version shall always overcome.
                </li>
                <li>
                  <h6>
                    <span>1.5</span>Definitions
                  </h6>
                </li>
                <ul>
                  <li>
                    <span>1.5.1</span>"The Company" - "WorkEat" Ltd. (a company
                    to be incorporated).
                  </li>
                  <li>
                    <span>1.5.2</span>"The Hosting Company" - a company that
                    entered into an agreement with the Company to provide
                    services.
                  </li>
                  <li>
                    <span>1.5.3</span>"The Agreement" - This Terms of Use
                    document.
                  </li>
                  <li>
                    <span>1.5.4</span>"The Site" - a designated website that
                    allows the Users to order dishes in the kitchen and/or the
                    dining room and/or in another facility of the Hosting
                    Company, dishes that will afterward be prepared in the
                    kitchen, and allows a User to upload an opinion to the site
                    about the dish and about the kitchen.
                  </li>
                  <li>
                    <span>1.5.5</span>"The Users" – whoever uses the Site,
                    whether he is an employee or guest of the Hosting Company.
                  </li>
                </ul>
                <li>
                  <span>1.6</span>The purpose of this agreement is to set the
                  relationship between the Company and the Users of the Site.
                </li>
                <li>
                  <span>1.7</span>The registration to the Site and the use of it
                  serves as a consent and acceptance of the terms of this
                  agreement. A User who does not agree to all or part of these
                  Terms of use is not allowed to use the Site or part of the
                  services that are provided through it in any way whatsoever.
                </li>
                <li>
                  <span>1.8</span>These Terms apply to every use of the Site,
                  and the use of one term in it shall apply, mutatis mutandis,
                  for the other term.
                </li>
                <li>
                  <span>1.9</span>It is hereby clarified that these Terms of Use
                  act simultaneously and are subject to the terms of use of any
                  third parties’ terms of service, if there will be any, as well
                  as subject to the internal procedures of the Hosting Company,
                  and they come to supplement to them.
                </li>
              </ul>
              <h6>
                <span>2.</span>Terms of Us
              </h6>
              <ul>
                <li>
                  <span>2.1</span>The User undertakes to avoid performing the
                  following actions regarding the Site and the services that are
                  provided through it:
                </li>
                <ul>
                  <li>
                    <span>2.1.1</span>To surf, scan or use the Site through
                    computer programs that are intended to gather information or
                    to perform actions by way of imitating a regular surfer,
                    including Bots or Crawlers etc.
                  </li>
                  <li>
                    <span>2.1.2</span>To manipulate URL addresses of internal
                    pages in order to reach internal pages he does not have
                    direct access to (URL Hacking).
                  </li>
                  <li>
                    <span>2.1.3</span>To upload or transfer content, or to do
                    actions, which violate the laws of the State of Israel, or
                    any law; including avoiding from uploading, transferring or
                    publishing content that may infringe copyrights, trademarks,
                    or a trade secret of someone else, to publish defamation,
                    obscenities, or violate the privacy of someone else.
                  </li>
                  <li>
                    <span>2.1.4</span>To do any activity, which is not in
                    accordance with this agreement, in the design of the Site,
                    in the source code, or in elements and contents that appear
                    on the Site, the right of which to do so is exclusively
                    granted according to the Copyright Law, 5778-2007 to the
                    owner of the Sites copyright.
                  </li>
                  <li>
                    <span>2.1.5</span>A violent, blatant, criminal, or other
                    conduct, which may be in any way hurtful towards the
                    Company, the Hosting Company, the other Users of the Site or
                    the service providers supplying through the Site (such as
                    the food suppliers who operate the kitchen of the Hosting
                    Company).
                  </li>
                </ul>
                <li>
                  <span>2.2</span>The Company maintains the right:
                </li>
                <ul>
                  <li>
                    <span>2.2.1</span>To cease or prevent the service, or parts
                    of it, from a User who breached one of the articles of this
                    agreement or any law; all according to the exclusive
                    discretion of the Company and/or the Hosting Company,
                    without it being required to notify the User in advance. No
                    claim by the User in this matter will be heard.
                  </li>
                  <li>
                    <span>2.2.2</span>To remove every content or cancel every
                    activity that was done by the User, which may violate any of
                    the terms of the agreement or any law.
                  </li>
                  <li>
                    <span>2.2.3</span>The Company will be entitled to block any
                    address or device that has not been approved by the Company
                    to use on its products, or which may damage the Company or
                    its products - all according to the Companys exclusive
                    discretion without being required to provide a prior notice.
                  </li>
                </ul>
              </ul>
              <h6>
                <span>3.</span>User Account
              </h6>
              <ul>
                <li>
                  <span>3.1</span>Access to the services will be allowed only to
                  a User who registered to the Site, and who provided to the
                  Company certain details, which will be determined by the
                  Company (hereinafter: "the User Account" or "the Account").
                  There is a possibility, according to the choice of the Hosting
                  Company, to automatically create an Account by a system that
                  is run by the Hosting Company.
                </li>
                <li>
                  <span>3.2</span>The following provisions will apply in
                  creating a User Account:
                </li>
                <ul>
                  <li>
                    <span>3.2.1</span>It is forbidden to create a User Account
                    that contains details that are not the true and full details
                    of the one who is opening the Account.
                  </li>
                  <li>
                    <span>3.2.2</span>It is forbidden to create a User Account
                    for a person who is not authorized on behalf of the Hosting
                    Company to use the Site and to order through it dishes from
                    the kitchen of the Hosting Company.
                  </li>
                  <li>
                    <span>3.2.3</span>It is forbidden to create an Account for a
                    person who is not present in front of the computer screen or
                    telephone while creating the Account, or for whom who does
                    not approve the terms of this agreement. In respect to these
                    provisions, it should be emphasized that impersonation is a
                    criminal offense.
                  </li>
                </ul>
                <li>
                  <span>3.3</span>It is hereby made clear that even though the
                  Company takes measures to secure the content of the Users
                  Account, it cannot absolutely guarantee that the Account will
                  not be exposed because of an unauthorized penetration into the
                  User Account, and therefore, the User will not have any claim
                  against the Company regarding damages that will be caused to
                  him because of an unauthorized penetration into his Account.
                </li>
                <li>
                  <span>3.4</span>The Company may send to the User at the time
                  that the User Account is created, a text message that includes
                  a temporary code, intended for the purposes of identification
                  and registration. The User allows the Company to send such
                  text messages as stated, and he undertakes to keep the code in
                  confidence, to be used only for personal registration. It is
                  forbidden to transfer the code to anyone.
                </li>
                <li>
                  <span>3.5</span>The User allows the Company to send to his
                  e-mail address, according to the one entered into the User
                  Account, updates and confirmations regarding the Users
                  activity on the Site and every other notice, and the User
                  specifically approves to the Company to send him personal
                  notices and/or e-mail messages as far as the Company will
                  consider it necessary for verifying orders or contents that
                  are uploaded by the User.
                </li>
              </ul>
              <h6>
                <span>4.</span>The Payment through the Site
              </h6>
              <ul>
                <li>
                  <span>4.1</span>In the process of paying for certain dishes,
                  you may be transferred to a page of the clearing company/ies
                  with which the Company will work (hereinafter: "the Clearing
                  Company").
                </li>
                <li>
                  <span>4.2</span>It is possible that after you entered the
                  details of the credit card into the Clearing Companys system
                  you may be required for the purpose of completing the purchase
                  to verify your identity by ways that the Clearing Company will
                  determine from time to time.
                </li>
                <li>
                  <span>4.3</span>It should be emphasized that the details of
                  the credit card will be found and/or kept and/or managed only
                  by the Clearing Company, and only to their responsibility and
                  liability. The Company doesn’t keep these details and is not
                  responsible for their security and/or for every damage and/or
                  loss and/or expense that will be caused to the User and/or to
                  certain third party because of loss, including in the case of
                  a data leakage and/or data hacking and/or to the security of
                  the data.
                </li>
                <li>
                  <span>4.4</span>The User hereby states that the personal
                  details he will give to the Clearing Company will be correct,
                  accurate, and up to date.
                </li>
                <li>
                  <span>4.5</span>It is totally forbidden to use the personal
                  details of someone else and/or impersonate someone else.
                </li>
                <li>
                  <span>4.6</span>The Company will not be responsible in any
                  case whatsoever for paying instead of the User, for
                  indemnifying the User for any damage, loss or expense that
                  will be caused to him because he used the services of the
                  Clearing Company.
                </li>
              </ul>
              <h6>
                <span>5.</span>Restriction of Liability
              </h6>
              <ul>
                <li>
                  <span>5.1</span>It is hereby made clear that the Site and the
                  services that are provided through it, will be provided by the
                  Company As-Is. The Company will not incur any liability
                  because of a certain damage that will be caused to the User
                  (or to anyone on his behalf) because of the use of the Site or
                  services that are provided through it; or because of the use
                  of products or services of third parties, whether these
                  services are provided digitally or in any other manner.
                </li>
                <li>
                  <span>5.2</span>It is hereby clarified that the Company
                  doesn't undertake that the system will operate 100% of the
                  time; because the Company will update and upgrade the Sites
                  systems from time to time, which may cause a temporary
                  interruption in providing the service; and the Company does
                  not have any control over the availability of the services
                  that are provided by third parties.
                </li>
                <li>
                  <span>5.3</span>The User is responsible for examining, before
                  the use of the Site, that it suits his needs, and the User
                  will not have any claim because it is not compatible to his
                  needs.
                </li>
                <li>
                  <span>5.4</span>The User is responsible for verifying, before
                  the use of the Site, that he is properly connected to the
                  internet - the Company will not incur any liability for damage
                  that may be caused to the User because of problems in his
                  connection to the internet.
                </li>
                <li>
                  <span>5.5</span>The Company will not be liable in any way for
                  the contents that will be uploaded, transferred, or published
                  through the application- whether those contents will be
                  uploaded by the Hosting Company and/or by anyone on its behalf
                  and/or by the Users of the Site. The liability for these
                  activities will exclusively apply to the User, and no claim
                  will be heard against the Company in this issue.
                </li>
              </ul>
              <h6>
                <span>6.</span>Irresponsibility for the Users' activities
              </h6>
              <ul>
                <li>
                  <span>6.1</span>As part of the application services and
                  subject to these Terms of Use, the Users are entitled to
                  upload and present on the Site a ranking and/or verbal opinion
                  about the kitchen and/or about the dishes that are served in
                  the kitchen (hereinafter: 'the Users' Content').
                </li>
                <li>
                  <span>6.2</span>It is hereby clarified that the mechanism of
                  uploading all or part of the Users' Content is a procedure
                  that is conducted without human involvement, and the Company
                  cannot verify the identity of the one who is uploading the
                  content or examine the essence of the content that is uploaded
                  before it is displayed, and the Company will not be liable for
                  the Users' Content that is displayed on the Site, or for its
                  credibility, accuracy or compatibility to the needs of the
                  other Users.
                </li>
                <li>
                  <span>6.3</span>It is hereby clarified that the ranking and/or
                  opinion do not reflect the position or opinion of the Company,
                  but only reflect the position or opinion of the Users, and the
                  User or supplier, as applicable, will not have any claim
                  against the Company because of any Users' content that is
                  displayed on the Site.
                </li>
                <li>
                  <span>6.4</span>Without derogating from article 6.3 above, if
                  a User has a reason to believe that a single person is behind
                  a number of rankings of one dish, he is welcome to draw the
                  Company's attention to the issue, and the Company maintains
                  its right to cancel such ranking activities that were done in
                  violation of these Terms of Use.
                </li>
              </ul>
              <h6>
                <span>7.</span>Uploading Content by a User
              </h6>
              <ul>
                <li>
                  <span>7.1</span>The User states and undertakes that the
                  uploading of content to the Site, or the use of it by the
                  Company or Users in accordance with these Terms of Use, does
                  not violate the laws of the State of Israel or any law,
                  including an infringement of copyrights or trademarks,
                  disclosing a trade secret, or is not a violation of privacy,
                  an obscenity, or the publication of defamation.
                </li>
                <li>
                  <span>7.2</span>The User undertakes not to upload content that
                  may violate a public policy, content that may violate the
                  rights or hurt the feelings of a person, entity, or of a
                  public, or misleading advertised content.
                </li>
                <li>
                  <span>7.3</span>The Company doesn't guarantee to display any
                  content that is uploaded and reserves its right in accordance
                  with these Terms of Use to refuse to upload content or to
                  delete it.
                </li>
                <li>
                  <span>7.4</span>The User shall be the owner of the copyrights
                  for every content he uploaded to the Site and which he owns,
                  but grants to the Company a perpetual, non-exclusive license
                  for an unlimited time to make the content available to the
                  public and to the Users of the application, and to use it
                  according to the Company's discretion, including for marketing
                  purposes.
                </li>
                <li>
                  <span>7.5</span>It is hereby clarified that the User, as the
                  owner of the copyrights to the Users' Content he uploaded, is
                  responsible for the enforcement of the authorization to use
                  this content.
                </li>
                <li>
                  <span>7.6</span>The User allows the Company to make certain
                  editing to the content that is uploaded to the site in order
                  to adjust it to the format of the Site, and he is obligated to
                  meet the editing terms, as they appear in the content of the
                  edited pages that are on the Site.
                </li>
              </ul>
              <h6>
                <span>8.</span>Irresponsibility for the Advertisements and for
                Outbound Links
              </h6>
              <ul>
                <li>
                  <span>8.1</span>It is possible that advertisements for
                  products or services of third parties may be displayed on the
                  Site under the sponsorship of an external service, which
                  adjusts the advertisements to the content of the page. It is
                  hereby clarified that the Company does not have any control
                  over the choice of the advertisements that are displayed, or
                  over the order of their display, or over the credibility of
                  their content.
                </li>
                <li>
                  <span>8.2</span>Part of the hyperlinks that appear on the Site
                  refer to sites that are managed or owned by third parties. It
                  is hereby clarified that the hyperlinks to these sites were
                  done only for the convenience of the User, and the Company did
                  not examine them, and does not support them or is responsible
                  for them, for their content or for their security, and the
                  User is fully liable for using or delivering of information to
                  these sites.It needs to be made clear that there is no
                  difference between the marking of internal links and the
                  marking of outbound links, and the User is therefore
                  responsible for verifying the URL address to which he refers a
                  link before he uses it.
                </li>
              </ul>
              <h6>
                <span>9.</span>A Notice about Violating Content
              </h6>
              <ul>
                <li>
                  <span>9.1</span>The Company is entrusted with compliance with
                  the laws of the State of Israel – therefore, if you encounter
                  content that violates the laws of the State of Israel or of
                  any law, we would appreciate if you would draw our attention
                  to that by sending a notice, which specifies the violating
                  content and the nature of the violation, to the e-mail address{' '}
                  <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
                </li>
              </ul>
              <h6>
                <span>10.</span>Intellectual Property
              </h6>
              <ul>
                <li>
                  <span>10.1</span>The Company is the owner of all the rights,
                  including the intellectual property rights (and including the
                  Company's trademarks if there are any), to all the services
                  that will be provided by the Company and to all the tools that
                  will be used by the Company in providing the services,
                  including every change, modification, addition, or derivative
                  work of the above, except for products and services supplied
                  by third parties.
                </li>
                <li>
                  <span>10.2</span>The User will not be permitted to use the
                  Site or any of its services in a manner that contradicts the
                  instructions of this agreement, and which may violate the
                  rights of the Company or the rights of a third party – without
                  receiving the Company's written consent in advance.
                </li>
                <li>
                  <span>10.3</span>The instructions of article 10 above will
                  apply even after discontinuing the services and the
                  termination of this agreement due to any reason whatsoever.
                </li>
              </ul>
              <h6>
                <span>11.</span>Change of the Terms
              </h6>
              <ul>
                <li>
                  <span>11.1</span>The Company will be entitled to make at any
                  time changes in this agreement and its appendices without the
                  need to provide advanced notice.
                </li>
                <li>
                  <span>11.2</span>The User is responsible to enter this page in
                  order to figure out whether changes were made to this
                  agreement.
                </li>
              </ul>
              <h6>
                <span>12.</span>Indemnification
              </h6>
              <ul>
                <li>
                  <span>12.1</span>The User is obligated to indemnify and
                  compensate the Company for every loss, damage or shortage
                  (including expenses and attorney fees) that will be caused to
                  the Company due to any demand or claim that will be directed
                  to it by any party whatsoever as a result of the Users use of
                  the Site; the User will indemnify the Company within 30 days
                  from the day he received for the first time the Company's
                  written demand.
                </li>
                <li>
                  <span>12.2</span>What is said in article 12 does not diminish
                  from any other right or remedy the Company has according to
                  this agreement or according to every law.
                </li>
              </ul>
              <h6>
                <span>13.</span>Governing Law and Jurisdiction
              </h6>
              <ul>
                <li>
                  <span>13.1</span>The exclusive and absolute jurisdiction in
                  all the subjects and issues related to the agreement will be
                  given to the competent court in the Tel Aviv-Jaffa District.
                  The laws that will apply to this agreement are the laws of the
                  State of Israel.
                </li>
              </ul>
            </ul>
          </div>
        ) : (
          ''
        )}

        {/* russian content */}
        {/* <div className="terms-condition-page">
          <h2>Recently Updated: January 11th, 2023</h2>
          <ul>
            <h6>
              <span>1. </span>Introduction
            </h6>
            <ul>
              <li>
                <span>1.1</span>The preamble to this document together with its
                appendices constitutes an inseparable part of the document.
              </li>
              <li>
                <span>1.2</span>Titles are for convenience only and shall not be
                used for interpretation.
              </li>
              <li>
                <span>1.3</span>For convenience only, these Terms are written in
                the male form, but it is collectively intended for both genders.
              </li>
              <li>
                <span>1.4</span>For the avoidance of all doubts, it is hereby
                clarified that the Hebrew version of Terms and Conditions shall
                apply, and versions in other languages may be set only for
                convenience; in any case, if any differences occurred between
                versions – the Hebrew version shall always overcome.
              </li>
              <li>
                <h6>
                  <span>1.5</span>Definitions
                </h6>
              </li>
              <ul>
                <li>
                  <span>1.5.1</span>"The Company" - "WorkEat" Ltd. (a company to
                  be incorporated).
                </li>
                <li>
                  <span>1.5.2</span>"The Hosting Company" - a company that
                  entered into an agreement with the Company to provide
                  services.
                </li>
                <li>
                  <span>1.5.3</span>"The Agreement" - This Terms of Use
                  document.
                </li>
                <li>
                  <span>1.5.4</span>"The Site" - a designated website that
                  allows the Users to order dishes in the kitchen and/or the
                  dining room and/or in another facility of the Hosting Company,
                  dishes that will afterward be prepared in the kitchen, and
                  allows a User to upload an opinion to the site about the dish
                  and about the kitchen.
                </li>
                <li>
                  <span>1.5.5</span>"The Users" – whoever uses the Site, whether
                  he is an employee or guest of the Hosting Company.
                </li>
              </ul>
              <li>
                <span>1.6</span>The purpose of this agreement is to set the
                relationship between the Company and the Users of the Site.
              </li>
              <li>
                <span>1.7</span>The registration to the Site and the use of it
                serves as a consent and acceptance of the terms of this
                agreement. A User who does not agree to all or part of these
                Terms of use is not allowed to use the Site or part of the
                services that are provided through it in any way whatsoever.
              </li>
              <li>
                <span>1.8</span>These Terms apply to every use of the Site, and
                the use of one term in it shall apply, mutatis mutandis, for the
                other term.
              </li>
              <li>
                <span>1.9</span>It is hereby clarified that these Terms of Use
                act simultaneously and are subject to the terms of use of any
                third parties’ terms of service, if there will be any, as well
                as subject to the internal procedures of the Hosting Company,
                and they come to supplement to them.
              </li>
            </ul>
            <h6>
              <span>2.</span>Terms of Us
            </h6>
            <ul>
              <li>
                <span>2.1</span>The User undertakes to avoid performing the
                following actions regarding the Site and the services that are
                provided through it:
              </li>
              <ul>
                <li>
                  <span>2.1.1</span>To surf, scan or use the Site through
                  computer programs that are intended to gather information or
                  to perform actions by way of imitating a regular surfer,
                  including Bots or Crawlers etc.
                </li>
                <li>
                  <span>2.1.2</span>To manipulate URL addresses of internal
                  pages in order to reach internal pages he does not have direct
                  access to (URL Hacking).
                </li>
                <li>
                  <span>2.1.3</span>To upload or transfer content, or to do
                  actions, which violate the laws of the State of Israel, or any
                  law; including avoiding from uploading, transferring or
                  publishing content that may infringe copyrights, trademarks,
                  or a trade secret of someone else, to publish defamation,
                  obscenities, or violate the privacy of someone else.
                </li>
                <li>
                  <span>2.1.4</span>To do any activity, which is not in
                  accordance with this agreement, in the design of the Site, in
                  the source code, or in elements and contents that appear on
                  the Site, the right of which to do so is exclusively granted
                  according to the Copyright Law, 5778-2007 to the owner of the
                  Sites copyright.
                </li>
                <li>
                  <span>2.1.5</span>A violent, blatant, criminal, or other
                  conduct, which may be in any way hurtful towards the Company,
                  the Hosting Company, the other Users of the Site or the
                  service providers supplying through the Site (such as the food
                  suppliers who operate the kitchen of the Hosting Company).
                </li>
              </ul>
              <li>
                <span>2.2</span>The Company maintains the right:
              </li>
              <ul>
                <li>
                  <span>2.2.1</span>To cease or prevent the service, or parts of
                  it, from a User who breached one of the articles of this
                  agreement or any law; all according to the exclusive
                  discretion of the Company and/or the Hosting Company, without
                  it being required to notify the User in advance. No claim by
                  the User in this matter will be heard.
                </li>
                <li>
                  <span>2.2.2</span>To remove every content or cancel every
                  activity that was done by the User, which may violate any of
                  the terms of the agreement or any law.
                </li>
                <li>
                  <span>2.2.3</span>The Company will be entitled to block any
                  address or device that has not been approved by the Company to
                  use on its products, or which may damage the Company or its
                  products - all according to the Companys exclusive discretion
                  without being required to provide a prior notice.
                </li>
              </ul>
            </ul>
            <h6>
              <span>3.</span>User Account
            </h6>
            <ul>
              <li>
                <span>3.1</span>Access to the services will be allowed only to a
                User who registered to the Site, and who provided to the Company
                certain details, which will be determined by the Company
                (hereinafter: "the User Account" or "the Account"). There is a
                possibility, according to the choice of the Hosting Company, to
                automatically create an Account by a system that is run by the
                Hosting Company.
              </li>
              <li>
                <span>3.2</span>The following provisions will apply in creating
                a User Account:
              </li>
              <ul>
                <li>
                  <span>3.2.1</span>It is forbidden to create a User Account
                  that contains details that are not the true and full details
                  of the one who is opening the Account.
                </li>
                <li>
                  <span>3.2.2</span>It is forbidden to create a User Account for
                  a person who is not authorized on behalf of the Hosting
                  Company to use the Site and to order through it dishes from
                  the kitchen of the Hosting Company.
                </li>
                <li>
                  <span>3.2.3</span>It is forbidden to create an Account for a
                  person who is not present in front of the computer screen or
                  telephone while creating the Account, or for whom who does not
                  approve the terms of this agreement. In respect to these
                  provisions, it should be emphasized that impersonation is a
                  criminal offense.
                </li>
              </ul>
              <li>
                <span>3.3</span>It is hereby made clear that even though the
                Company takes measures to secure the content of the Users
                Account, it cannot absolutely guarantee that the Account will
                not be exposed because of an unauthorized penetration into the
                User Account, and therefore, the User will not have any claim
                against the Company regarding damages that will be caused to him
                because of an unauthorized penetration into his Account.
              </li>
              <li>
                <span>3.4</span>The Company may send to the User at the time
                that the User Account is created, a text message that includes a
                temporary code, intended for the purposes of identification and
                registration. The User allows the Company to send such text
                messages as stated, and he undertakes to keep the code in
                confidence, to be used only for personal registration. It is
                forbidden to transfer the code to anyone.
              </li>
              <li>
                <span>3.5</span>The User allows the Company to send to his
                e-mail address, according to the one entered into the User
                Account, updates and confirmations regarding the Users activity
                on the Site and every other notice, and the User specifically
                approves to the Company to send him personal notices and/or
                e-mail messages as far as the Company will consider it necessary
                for verifying orders or contents that are uploaded by the User.
              </li>
            </ul>
            <h6>
              <span>4.</span>The Payment through the Site
            </h6>
            <ul>
              <li>
                <span>4.1</span>In the process of paying for certain dishes, you
                may be transferred to a page of the clearing company/ies with
                which the Company will work (hereinafter: "the Clearing
                Company").
              </li>
              <li>
                <span>4.2</span>It is possible that after you entered the
                details of the credit card into the Clearing Companys system you
                may be required for the purpose of completing the purchase to
                verify your identity by ways that the Clearing Company will
                determine from time to time.
              </li>
              <li>
                <span>4.3</span>It should be emphasized that the details of the
                credit card will be found and/or kept and/or managed only by the
                Clearing Company, and only to their responsibility and
                liability. The Company doesn’t keep these details and is not
                responsible for their security and/or for every damage and/or
                loss and/or expense that will be caused to the User and/or to
                certain third party because of loss, including in the case of a
                data leakage and/or data hacking and/or to the security of the
                data.
              </li>
              <li>
                <span>4.4</span>The User hereby states that the personal details
                he will give to the Clearing Company will be correct, accurate,
                and up to date.
              </li>
              <li>
                <span>4.5</span>It is totally forbidden to use the personal
                details of someone else and/or impersonate someone else.
              </li>
              <li>
                <span>4.6</span>The Company will not be responsible in any case
                whatsoever for paying instead of the User, for indemnifying the
                User for any damage, loss or expense that will be caused to him
                because he used the services of the Clearing Company.
              </li>
            </ul>
            <h6>
              <span>5.</span>Restriction of Liability
            </h6>
            <ul>
              <li>
                <span>5.1</span>It is hereby made clear that the Site and the
                services that are provided through it, will be provided by the
                Company As-Is. The Company will not incur any liability because
                of a certain damage that will be caused to the User (or to
                anyone on his behalf) because of the use of the Site or services
                that are provided through it; or because of the use of products
                or services of third parties, whether these services are
                provided digitally or in any other manner.
              </li>
              <li>
                <span>5.2</span>It is hereby clarified that the Company doesn't
                undertake that the system will operate 100% of the time; because
                the Company will update and upgrade the Sites systems from time
                to time, which may cause a temporary interruption in providing
                the service; and the Company does not have any control over the
                availability of the services that are provided by third parties.
              </li>
              <li>
                <span>5.3</span>The User is responsible for examining, before
                the use of the Site, that it suits his needs, and the User will
                not have any claim because it is not compatible to his needs.
              </li>
              <li>
                <span>5.4</span>The User is responsible for verifying, before
                the use of the Site, that he is properly connected to the
                internet - the Company will not incur any liability for damage
                that may be caused to the User because of problems in his
                connection to the internet.
              </li>
              <li>
                <span>5.5</span>The Company will not be liable in any way for
                the contents that will be uploaded, transferred, or published
                through the application- whether those contents will be uploaded
                by the Hosting Company and/or by anyone on its behalf and/or by
                the Users of the Site. The liability for these activities will
                exclusively apply to the User, and no claim will be heard
                against the Company in this issue.
              </li>
            </ul>
            <h6>
              <span>6.</span>Irresponsibility for the Users' activities
            </h6>
            <ul>
              <li>
                <span>6.1</span>As part of the application services and subject
                to these Terms of Use, the Users are entitled to upload and
                present on the Site a ranking and/or verbal opinion about the
                kitchen and/or about the dishes that are served in the kitchen
                (hereinafter: 'the Users' Content').
              </li>
              <li>
                <span>6.2</span>It is hereby clarified that the mechanism of
                uploading all or part of the Users' Content is a procedure that
                is conducted without human involvement, and the Company cannot
                verify the identity of the one who is uploading the content or
                examine the essence of the content that is uploaded before it is
                displayed, and the Company will not be liable for the Users'
                Content that is displayed on the Site, or for its credibility,
                accuracy or compatibility to the needs of the other Users.
              </li>
              <li>
                <span>6.3</span>It is hereby clarified that the ranking and/or
                opinion do not reflect the position or opinion of the Company,
                but only reflect the position or opinion of the Users, and the
                User or supplier, as applicable, will not have any claim against
                the Company because of any Users' content that is displayed on
                the Site.
              </li>
              <li>
                <span>6.4</span>Without derogating from article 6.3 above, if a
                User has a reason to believe that a single person is behind a
                number of rankings of one dish, he is welcome to draw the
                Company's attention to the issue, and the Company maintains its
                right to cancel such ranking activities that were done in
                violation of these Terms of Use.
              </li>
            </ul>
            <h6>
              <span>7.</span>Uploading Content by a User
            </h6>
            <ul>
              <li>
                <span>7.1</span>The User states and undertakes that the
                uploading of content to the Site, or the use of it by the
                Company or Users in accordance with these Terms of Use, does not
                violate the laws of the State of Israel or any law, including an
                infringement of copyrights or trademarks, disclosing a trade
                secret, or is not a violation of privacy, an obscenity, or the
                publication of defamation.
              </li>
              <li>
                <span>7.2</span>The User undertakes not to upload content that
                may violate a public policy, content that may violate the rights
                or hurt the feelings of a person, entity, or of a public, or
                misleading advertised content.
              </li>
              <li>
                <span>7.3</span>The Company doesn't guarantee to display any
                content that is uploaded and reserves its right in accordance
                with these Terms of Use to refuse to upload content or to delete
                it.
              </li>
              <li>
                <span>7.4</span>The User shall be the owner of the copyrights
                for every content he uploaded to the Site and which he owns, but
                grants to the Company a perpetual, non-exclusive license for an
                unlimited time to make the content available to the public and
                to the Users of the application, and to use it according to the
                Company's discretion, including for marketing purposes.
              </li>
              <li>
                <span>7.5</span>It is hereby clarified that the User, as the
                owner of the copyrights to the Users' Content he uploaded, is
                responsible for the enforcement of the authorization to use this
                content.
              </li>
              <li>
                <span>7.6</span>The User allows the Company to make certain
                editing to the content that is uploaded to the site in order to
                adjust it to the format of the Site, and he is obligated to meet
                the editing terms, as they appear in the content of the edited
                pages that are on the Site.
              </li>
            </ul>
            <h6>
              <span>8.</span>Irresponsibility for the Advertisements and for
              Outbound Links
            </h6>
            <ul>
              <li>
                <span>8.1</span>It is possible that advertisements for products
                or services of third parties may be displayed on the Site under
                the sponsorship of an external service, which adjusts the
                advertisements to the content of the page. It is hereby
                clarified that the Company does not have any control over the
                choice of the advertisements that are displayed, or over the
                order of their display, or over the credibility of their
                content.
              </li>
              <li>
                <span>8.2</span>Part of the hyperlinks that appear on the Site
                refer to sites that are managed or owned by third parties. It is
                hereby clarified that the hyperlinks to these sites were done
                only for the convenience of the User, and the Company did not
                examine them, and does not support them or is responsible for
                them, for their content or for their security, and the User is
                fully liable for using or delivering of information to these
                sites.It needs to be made clear that there is no difference
                between the marking of internal links and the marking of
                outbound links, and the User is therefore responsible for
                verifying the URL address to which he refers a link before he
                uses it.
              </li>
            </ul>
            <h6>
              <span>9.</span>A Notice about Violating Content
            </h6>
            <ul>
              <li>
                <span>9.1</span>The Company is entrusted with compliance with
                the laws of the State of Israel – therefore, if you encounter
                content that violates the laws of the State of Israel or of any
                law, we would appreciate if you would draw our attention to that
                by sending a notice, which specifies the violating content and
                the nature of the violation, to the e-mail address{' '}
                <a href="mailto:info@workeat.co.il">info@workeat.co.il.</a>
              </li>
            </ul>
            <h6>
              <span>10.</span>Intellectual Property
            </h6>
            <ul>
              <li>
                <span>10.1</span>The Company is the owner of all the rights,
                including the intellectual property rights (and including the
                Company's trademarks if there are any), to all the services that
                will be provided by the Company and to all the tools that will
                be used by the Company in providing the services, including
                every change, modification, addition, or derivative work of the
                above, except for products and services supplied by third
                parties.
              </li>
              <li>
                <span>10.2</span>The User will not be permitted to use the Site
                or any of its services in a manner that contradicts the
                instructions of this agreement, and which may violate the rights
                of the Company or the rights of a third party – without
                receiving the Company's written consent in advance.
              </li>
              <li>
                <span>10.3</span>The instructions of article 10 above will apply
                even after discontinuing the services and the termination of
                this agreement due to any reason whatsoever.
              </li>
            </ul>
            <h6>
              <span>11.</span>Change of the Terms
            </h6>
            <ul>
              <li>
                <span>11.1</span>The Company will be entitled to make at any
                time changes in this agreement and its appendices without the
                need to provide advanced notice.
              </li>
              <li>
                <span>11.2</span>The User is responsible to enter this page in
                order to figure out whether changes were made to this agreement.
              </li>
            </ul>
            <h6>
              <span>12.</span>Indemnification
            </h6>
            <ul>
              <li>
                <span>12.1</span>The User is obligated to indemnify and
                compensate the Company for every loss, damage or shortage
                (including expenses and attorney fees) that will be caused to
                the Company due to any demand or claim that will be directed to
                it by any party whatsoever as a result of the Users use of the
                Site; the User will indemnify the Company within 30 days from
                the day he received for the first time the Company's written
                demand.
              </li>
              <li>
                <span>12.2</span>What is said in article 12 does not diminish
                from any other right or remedy the Company has according to this
                agreement or according to every law.
              </li>
            </ul>
            <h6>
              <span>13.</span>Governing Law and Jurisdiction
            </h6>
            <ul>
              <li>
                <span>13.1</span>The exclusive and absolute jurisdiction in all
                the subjects and issues related to the agreement will be given
                to the competent court in the Tel Aviv-Jaffa District. The laws
                that will apply to this agreement are the laws of the State of
                Israel.
              </li>
            </ul>
          </ul>
        </div> */}
      </Container>
    </Layout>
  );
};
export default TermsCondition;
