import { useState, useCallback, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';
// Validators
import { required } from '../../services/validators';
import { ActiveButton, DisabledButton } from '../WE-Button';
import WEBackButton from '../../components/WE-BackButton';
import http from '../../services/http';
import { Button } from 'react-bootstrap';
import CibusError from '../../components/CibusError';
//import { useHistory } from 'react-router-dom';

let PaymentManagementForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    error,
    submitFailed,
    valid,
    initialValues,
    change,
    formType,
    paymentMethod,
    Currency,
  } = props;
  const [values, setValues] = useState(initialValues);

  const [paymentOptions, setPaymentOptions] = useState<any>();
  //const history = useHistory();

  const togglePaymentOption = useCallback(
    (paymentOption) => {
      //  if(paymentOption===3)
      //  {
      //   history.push('/paymentManagement?paymenttype=hyp')
      //  }
      // else
      //  {
      //   history.push('/paymentManagement')
      //  }

      setValues({ ...values, selectedPaymentOptions: paymentOption });
      change('selectedPaymentOptions', paymentOption);
    },
    [values]
  );

  const toggleCurrencyOption = useCallback(
    (currencyOption) => {
      setValues({ ...values, currencyId: currencyOption });
      change('currencyId', currencyOption);
    },
    [values]
  );

  useEffect(() => {
    const getData = async () => {
      const getPaymentOptions = await http.get(`kitchens/getPaymentOptions`);

      setPaymentOptions(getPaymentOptions);
    };
    getData();
  }, []);
  return (
    <>
      <div className="payment-management-form-section">
        {formType === 'hyp' ? (
          <form className="form" onSubmit={handleSubmit}>
            <div></div>
            <div className="inputs-wrapper">
              <label>{t('selectPaymentOption')}</label>
              <div className="payment-check-option-area">
                {paymentOptions &&
                  paymentOptions.map((el: any) => (
                    <div
                      className="payment-check-option"
                      key={el.id}
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <input
                        type="radio"
                        name="paymentOptionId"
                        defaultChecked={
                          el.id === paymentMethod?.selectedPaymentOptions ||
                          el.id === values?.selectedPaymentOptions
                        }
                        value={el.id}
                        onClick={() => togglePaymentOption(el.id)}
                      />
                      <label>
                        {el.paymentOption === 'Credit Card'
                          ? t('paymentOption-creditCard')
                          : el.paymentOption === 'Wallet'
                            ? t('wallet')
                            : el.paymentOption === 'Salary'
                              ? t('paymentOption-salary')
                              : el.paymentOption === 'Cibus'
                                ? t('paymentOption-cibus')
                                : ''}{' '}
                      </label>
                    </div>
                  ))}
              </div>
              {values?.selectedPaymentOptions === 5 && (
                <>
                  {' '}
                  <label>{t('selectCurrency')}</label>
                  <div className="payment-check-option-area">
                    {Currency &&
                      Currency.map((el: any) => (
                        <div
                          className="payment-check-option"
                          key={el.id}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <input
                            type="radio"
                            name="currencyId"
                            defaultChecked={
                              el.id == paymentMethod?.currencyId ||
                              el.id == values?.currencyId
                            }
                            value={el.id}
                            onClick={() => toggleCurrencyOption(el.id)}
                          />
                          <label>{el.display} </label>
                        </div>
                      ))}
                  </div>
                </>
              )}
              {values.selectedPaymentOptions === 3 && (
                <>
                  {' '}
                  <Field
                    name={`userName`}
                    containerClass="one-column last-name"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('user')}
                    type="text"
                    // icon="padlock"
                  />
                  <Field
                    name={`personalHashKey`}
                    containerClass="one-column last-name"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('password')}
                    type="password"
                    // icon="padlock"
                  />
                  <Field
                    name={`terminalNo`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('terminalNumber')}
                    // icon="user"
                  />
                  <Field
                    name={`merchantId`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('merchantId')}
                    // icon="user"
                  />
                  <Field
                    name={`companyNumber`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('companyNumber')}
                    // icon="user"
                  />
                  <Field
                    name={`chargeTerminalId`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('chargeTerminalId')}
                    // icon="user"
                  />
                </>
              )}{' '}
              {values.selectedPaymentOptions === 6 && (
                <>
                  {' '}
                  <Field
                    name={`referenceId`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('referenceId')}
                    // icon="user"
                  />
                  <Field
                    name={`posId`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('posId')}
                    // icon="user"
                  />
                  <Field
                    name={`companyId`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('companyId')}
                    // icon="user"
                  />
                  {/* <Field
                    name={`companyCard`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('companyCard')}
                    // icon="user"
                  /> */}
                  <Field
                    name={`applicationId`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('applicationId')}
                    // icon="user"
                  />
                  <Field
                    name={`authorization`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('authorization')}
                    // icon="user"
                  />
                  {/* <Field
                    name={`vatFreePrice`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('vatFreePrice')}
                    // icon="user"
                  /> */}
                  {/*<Field
                    // name={`addOrderUrl`}
                    name={`webAuth`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    // placeholder={t('addOrderUrl')}
                    placeholder={t('webAuth')}
                    // icon="user"
                  />
                  <Field
                    // name={`paymentUrl`}
                    name={`oAuth`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    // placeholder={t('paymentUrl')}
                    placeholder={t('oAuth')}
                    // icon="user"
                />*/}
                  <Field
                    // name={`paymentUrl`}
                    name={`cibusRedirectUrl`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('cibusRedirectUrl')}
                    // icon="user"
                  />
                  <Field
                    name={`addOrderUrl`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('addOrderUrl')}
                    // icon="user"
                  />
                  <Field
                    name={`paymentUrl`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('paymentUrl')}
                    // icon="user"
                  />
                  <Field
                    name={`host`}
                    containerClass="one-column first-name"
                    type="text"
                    component={InputField}
                    validate={[required]}
                    required={true}
                    placeholder={t('host')}
                    // icon="user"
                  />
                </>
              )}
            </div>

            {error && <div className="form-error">{error}</div>}

            <div className="checkboxes-wrapper"></div>

            <div className="settings-btn">
              {submitFailed && !valid ? (
                <DisabledButton
                  label={t('saveInfo')}
                  additionalClassName={'submit-btn'}
                />
              ) : (
                <>
                  <Button type="submit">{t('saveInfo')}</Button>
                  <Button>{t('cancel')}</Button>
                </>
              )}
            </div>
          </form>
        ) : formType === 'nayax' ? (
          <form className="form" onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <WEBackButton />
            </div>
            <p className={'form-explanation'}>
              {t('paymentManagementFormExplanation')}
            </p>
            <div className="inputs-wrapper">
              <Field
                name={`merchantId`}
                containerClass="one-column first-name"
                type="text"
                component={InputField}
                validate={[required]}
                required={true}
                placeholder={t('merchantId')}
                icon="user"
              />

              <Field
                name={`companyNumber`}
                containerClass="one-column first-name"
                type="text"
                component={InputField}
                validate={[required]}
                required={true}
                placeholder={t('companyNumber')}
                icon="user"
              />

              <Field
                name={`personalHashKey`}
                containerClass="one-column last-name"
                component={InputField}
                validate={[required]}
                required={true}
                placeholder={t('personalHashKey')}
                type="text"
                icon="padlock"
              />
            </div>

            {error && <div className="form-error">{error}</div>}

            <div className="checkboxes-wrapper">
              {paymentOptions &&
                paymentOptions.map((el: any) => (
                  <div
                    className="payment-check-option"
                    key={el.id}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <input
                      type="radio"
                      name="paymentOptionId"
                      defaultChecked={
                        el.id === paymentMethod.selectedPaymentOptions ||
                        el.id === values.selectedPaymentOptions
                      }
                      value={el.id}
                      onClick={() => togglePaymentOption(el.id)}
                    />
                    <label>{el.paymentOption} </label>
                  </div>
                ))}
            </div>

            {submitFailed && !valid ? (
              <DisabledButton
                label={t('saveInfo')}
                additionalClassName={'submit-btn'}
              />
            ) : (
              <ActiveButton
                type="submit"
                additionalClassName={'submit-btn'}
                label="{t('saveInfo')}"
              />
            )}
          </form>
        ) : null}
      </div>
      {(values.selectedPaymentOptions ||
        paymentMethod?.selectedPaymentOptions) === 6 ? (
        <CibusError />
      ) : (
        ''
      )}
    </>
  );
};

export default PaymentManagementForm = reduxForm({
  form: 'profile',
  enableReinitialize: true,
  initialValues: {
    paymentOptions: null,
  },
})(PaymentManagementForm);
