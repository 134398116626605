import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../components/WE-Button';
import cancel from '../assets/images/close.svg';
import Storage from '../services/storage';
import back from '../assets/images/back.png';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const LogViewerDetails = () => {
  let { t } = useTranslation();
  const location = useLocation();
  const [details, setDetails] = useState();

  useEffect(() => {
    if (location.state) {
      localStorage.setItem('logData', JSON.stringify(location?.state?.data));
      setDetails(location?.state?.data);
    } else {
      let data = localStorage.getItem('logData');
      setDetails(JSON.parse(data));
    }
  }, [location]);
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div className="view-more-popup">
      <div className="back">
        <img src={back} alt="back" onClick={handleBack} />
      </div>
      <div className="user-detail-container">
        <div className="user-details-row">
          <div className="user-details-block">
            <h6>{t('userName')}:</h6>
            <p>{details?.Name ? details?.Name : '--'}</p>
          </div>
          <div className="user-details-block">
            <h6>{t('role')}:</h6>
            <p>{details?.Role ? details?.Role : '--'}</p>
          </div>
          <div className="user-details-block">
            <h6>{t('description')}:</h6>
            <p>{details?.description ? details?.description : '--'}</p>
          </div>
          <div className="user-details-block">
            <h6>{t('time')}:</h6>
            <p>{details?.time ? details?.time : '--'}</p>
          </div>
          <div className="user-details-block">
            <h6>{t('date')}:</h6>
            <p>{details?.date ? details?.date : '--'}</p>
          </div>

          <div className="user-details-block">
            <h6>{t('action')}:</h6>
            <p>{details?.action ? details?.action : '--'}</p>
          </div>
          <div className="user-details-block">
            <h6>{t('controller')}:</h6>
            <p>{details?.controller ? details?.controller : '--'}</p>
          </div>
          <div className="user-details-block">
            <h6>{t('tableName')}:</h6>
            <p>{details?.tableName ? details?.tableName : '--'}</p>
          </div>
          {/* <div className="user-details-block">
            <h6>{t('date')}:</h6>
            <p>
              {details?.contactPersonName ? details?.contactPersonName : '--'}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default LogViewerDetails;
