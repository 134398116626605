import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { appReducer as app } from './app/reducer';
import { languagesReducer as languages } from './languages/reducer';
import { kitchensReducer as kitchens } from './kitchens/reducer';
import { mealTimeReducer as mealTime } from './mealTime/reducer';
import { userReducer as user } from './user/reducer';
import { securitySettingReducer as securitySetting } from './securitySetting/reducer';
import { companyConfigReducer as companyConfig } from './companyConfig/reducer';

// Combine all reducers
const appReducer = combineReducers({
  form,
  app,
  user,
  languages,
  kitchens,
  mealTime,
  securitySetting,
  companyConfig,
});
export default appReducer;
