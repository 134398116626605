import React, { useEffect, useState } from 'react';
import Check from '../../assets/images/check.svg';

const FormCheckBox = ({
  checked,
  cb,
  input,
  label,
  useFullWidth = false,
  justifyContent = 'center',
}) => {
  const [c, setC] = useState(checked);
  const CheckIcon = <img className="check" src={Check} alt="" />;

  useEffect(() => {}, [c]);
  return (
    <div style={{ display: 'flex', justifyContent }}>
      <div
        className={`we-checkbox keep-logged`}
        onClick={() => {
          cb();
          setC(!c);
        }}
      >
        <input
          {...input}
          className={`checkbox-full ${useFullWidth ? 'full-width-mobile' : ''}`}
          defaultChecked={checked}
          type="checkbox"
          value={checked}
          checked={c}
        />
        {checked ? CheckIcon : <div className="unchecked" />}
        <p>{label}</p>
      </div>
    </div>
  );
};
export default FormCheckBox;
