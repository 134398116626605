import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployee } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
};

const ConfirmationPopup = ({
  open,
  handleClose,
  handleDeleteUser,
  userStatus,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup delete-popup"
    >
      <Box sx={style}>
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <Typography id="modal-modal-description">
          <div className="copy-url">
            <p>
              {userStatus == 5 ? t('deleteUserConf') : t('activateUserConf')}
            </p>
            <div className="confirmation-btn">
              <button className="copy-btn" onClick={handleClose}>
                {t('cancel')}
              </button>
              <button className="delete-btn" onClick={handleDeleteUser}>
                {t('ok')}
              </button>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default ConfirmationPopup;
