import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../../style/yourdish.css';
import { Modal, Button } from 'react-bootstrap';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import http from '../../services/http';
import checkedIcon from '../../assets/images/icons/checked.png';
import DatesMethods from '../../services/dates.js';

const OrderPlacedPopup = ({
  orderPlaced,
  handleOrderPlaced,
  details,
  cafeteriaiMealDish,
  t,
  currentOrder,
  paymentOption,
  paymentKitchenId,
  setCafeteriaiMealDish,
}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/myMeals');
  };
  const [qrImage, setQrImage] = useState();
  useEffect(() => {
    const fetchData1 = async () => {
      if (currentOrder) {
        const result = await http.get(`orders/${currentOrder.id}`);
        setQrImage(result.qrImage);
      }
    };
    fetchData1();
  }, [currentOrder, cafeteriaiMealDish, details]);
  const cibusPayment = () => {
    SwalDialogs.Dialog({
      title: t('cibusPayment'),
      text: t('cibusPaymentAlert'),
      buttons: {
        confirmButtonText: t('confirm'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCibusPayment,
    });
  };

  const finishbtn = () => {
    history.push('/lobby');
  };

  const handleCibusPayment = async () => {
    const res = await http.post(`users/userPaymentByCibus`, {
      orderId: currentOrder?.id,
      price: currentOrder?.orderPrice,
      paymentOptionId: paymentOption,
      kitchenId: paymentKitchenId,
    });
    window.location.href = res.url;
  };

  return (
    <Modal
      show={orderPlaced}
      onHide={handleOrderPlaced}
      className="add-extras-popup order-placed-popup"
      centered
    >
      {/* <Modal.Header
      // closeButton
      >
        <Modal.Title></Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <img className="checked-img" src={checkedIcon} alt="check" />
        <h2>{t('placeOrderMessage')}</h2>

        {details ? (
          <>
            <p>
              {details?.dateToDisplay.split(' ')[0]} | {details?.date} |{' '}
              {details?.mealTypeToDisplay}
            </p>
            {qrImage && (
              <div className="qr-code">
                <img src={`data:image;base64,${qrImage}`} alt="QR_CODE" />
              </div>
            )}
            <ul>
              <li> {details?.dishName}</li>
            </ul>
          </>
        ) : (
          <>
            <p>
              {
                DatesMethods?.format(cafeteriaiMealDish[0]?.meal?.date)?.split(
                  ' '
                )[0]
              }{' '}
              | {cafeteriaiMealDish[0]?.meal.date} |{' '}
              {cafeteriaiMealDish[0]?.meal.mealType.display}
            </p>
            {qrImage && (
              <div className="qr-code">
                <img src={`data:image;base64,${qrImage}`} alt="QR_CODE" />
              </div>
            )}
            <ul>
              {cafeteriaiMealDish.map((el, i) => (
                <li>
                  {/* {i + 1}
                {'.'} */}
                  {el?.dish?.name}
                  <span>{el?.quantity}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="continue-btn" onClick={handleClick}>
          {t('myOrders')}
        </Button>
        <Button className="blue-btn continue-btn" onClick={finishbtn}>
          {t('finish')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderPlacedPopup;
