import moment from 'moment';
import 'moment/locale/he';

const format = (date) => {
  return moment(date).format('dddd | D.M.YYYY');
};

const formatVoucher = (date) => {
  return moment(date).format('dddd , D.M.YYYY hh:mm:ss');
};

const formatServer = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

const ddmmFormat = (date) => {
  return moment(date).format('DD/MM');
};

const dmyyformat = (date) => {
  return moment(date).format('D.M.YY');
};
const ddddformat = (date) => {
  return moment(date).format('dddd');
};

export default {
  format,
  formatServer,
  ddmmFormat,
  dmyyformat,
  ddddformat,
  formatVoucher,
};
