import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import Select from 'react-select';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import User from './User';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import AddLangaugePopup from './AddLangaugePopup';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew } from '../../services/validators';
import CopyUrlUser from './copyUrlUser';
import ConfirmationPopup from './confirmation';
import { Autocomplete } from '@material-ui/lab';
import more from '../../assets/images/more.svg';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/images/Search.svg';
import Tippy from '@tippyjs/react';
import next from '../../assets/images/next.png';
import LanguageViewDetails from './languageViewDetails';
import Excel from 'exceljs';
import moment from 'moment';
import agGridLocales from '../../translations/ag-grid';
import { Button } from 'react-bootstrap';
import { initLanguages } from '../../store/languages/actions';

const initialState = {
  langaugeId: null,
  displayName: '',
  name: '',
  isRTL: '',
  langaugeStatusId: '',
  shortCode: '',
};

const languageManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  CurrentLanguage,
  LanguageStatuses,
  initLanguages,
  companyName,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const lang = localStorage.getItem('language');
  const [tableColumn, setTableColumn] = useState();
  const [languagesToDisplay, setLangaugesToDisplay] = useState();
  const [columnApi, setColumnApi] = useState();
  const [rowCount, setRowCount] = useState();
  const [gridApi, setGridApi] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [payload, setPayload] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const isRTLArray = [
    { value: false, display: 'No' },
    { value: true, display: 'Yes' },
  ];

  useEffect(() => {
    const fetchData1 = async () => {
      let responseData = await useFetchingLoader(
        async () => await getLanagugesData()
      );
    };
    fetchData1();
  }, []);

  const getLanagugesData = async () => {
    await initLanguages();
    const { data } = await http.get('languages/getAllLanguage');
    let languagesData = data?.map((u, i) => ({
      languageId: u?.id,
      displayName: u?.displayName,
      isRTL: u?.isRTL,
      isRTLStatus: u?.isRTL ? t('yes') : t('no'),
      languageStatusId: u.languageStatusId,
      languageStatus: LanguageStatuses.find(
        (el) => el.id === u.languageStatusId
      ).display,
      name: u?.name,
      shortCode: u?.shortCode,
      createdAt: moment(u?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: moment(u?.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    }));
    setLangaugesToDisplay(languagesData);
    setRowCount(languagesData?.length);
  };

  const handleUpdateLanguage = async (data) => {
    const updateLanguage = await http.put(`languages/${data.languageId}`, data);
    if (updateLanguage) {
      setOpen(false);
      Dialog({
        title: t('updatedMessage'),
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: async () => {
          await useFetchingLoader(async () => await getLanagugesData());
          //  window.location.reload();
        },
      });
    }
  };

  //   const handleAddLanguage = async (data) => {
  //     const addKitchenDevice = await http.post('kitchenDevices/create', data);
  //     if (addKitchenDevice) {
  //       setOpen(false);
  //       Dialog({
  //         title: t('addedmessage'),
  //         type: 'success',
  //         buttons: {
  //           showCancelButton: false,
  //           confirmButtonText: t('confirm'),
  //         },
  //         onConfirm: async () => {
  //           await useFetchingLoader(async () => await getLanagugesData());
  //         },
  //       });
  //     }
  //   };

  const handleEditAction = (data) => {
    setPayload({
      languageId: data.languageId,
      displayName: data.displayName,
      shortCode: data.shortCode,
      isRTL: data.isRTL,
      languageStatusId: data.languageStatusId,
      name: data.name,
    });
    setOpen(true);
    setIsUpdate(true);
    setViewDetails(false);
  };
  const handleDeleteAction = async (id) => {
    Dialog({
      title: t('confirmModalTitle'),
      type: 'success',
      buttons: {
        showCancelButton: false,
        confirmButtonText: t('confirm'),
      },
      onConfirm: async () => {
        setViewDetails(false);
        const deleteKitchenDevice = await http.delete(
          `kitchenDevices/deleteKitchenDevice/${id}`
        );
        if (deleteKitchenDevice.status === 200) {
          Dialog({
            title: t('deletedSuccessfully'),
            type: 'success',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('confirm'),
            },
            onConfirm: async () => {
              await useFetchingLoader(async () => await getLanagugesData());
              setOpen(false);
            },
          });
        }
      },
    });
  };
  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload(initialState);
      setIsUpdate(false);
    }
    setOpen(true);
  };
  /************global filter funcitionm */

  const handleFilterChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
    const rowCount = gridApi.getModel().rowsToDisplay.length;
    setRowCount(rowCount);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  /***********Export CSV file */
  const onBtnExport = async (data, exportCsvFileNames) => {
    const headers = Object.keys(data[0]).map((el) => t(el));
    const dataArray = data.map((el) => Object.values(el));
    dataArray.unshift(headers);
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRows(dataArray);
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], {
      type: 'application/octet-binary',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${exportCsvFileNames}.xlsx`;
    a.click();
    a.remove();
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const filterColumns = (data) => {
    if (languagesToDisplay && languagesToDisplay.length) {
      const columns = Object.keys(data[0]);
      const filterColsByKey = columns.filter((c) => c !== 'RoleId');
      return filterColsByKey;
    }
    return [];
  };
  // ag grid data
  const columnDefs = [
    {
      field: 'languageId',
      headerName: '#',
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: 'displayName',
      headerName: t('displayName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: 'shortCode',
      headerName: t('shortCode'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: 'languageStatus',
      headerName: t('status'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: '',
      headerName: t('action'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: 'ActionButton',
      resizable: true,
    },
  ];
  // Tab view
  const columnDefsTab = [
    {
      field: 'languageId',
      headerName: '#',
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
      width: 100,
    },
    {
      field: 'displayName',
      headerName: t('displayName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: 'shortCode',
      headerName: t('shortCode'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: 'languageStatus',
      headerName: t('status'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'languageId',
      headerName: '#',
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
      width: 100,
    },
    {
      field: 'displayName',
      headerName: t('displayName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    // {
    //   field: 'shortCode',
    //   headerName: t('shortCode'),
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    //   headerClass: 'resizable-header',
    //   resizable: true,
    // },
    {
      field: 'languageStatus',
      headerName: t('status'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      headerClass: 'resizable-header',
      resizable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
    // sizeColumnsToFit: true,
    suppressSizeToFit: true,
    width: 'auto',
  };

  const onFirstDataRendered = (params) => {
    // arbitrarily expand a row for presentational purposes
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  return (
    <div className="kitchen-device user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper">
            <div className="search-input">
              <img src={search} alt="search" />
              <input
                type="text"
                id="filter-text-box"
                placeholder={`${t('freeTextSearch')}`}
                onInput={handleFilterChanged}
              />
            </div>
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{rowCount}</span>
              </p>
            </div>
            <div className="actions">
              <img
                src={file}
                alt="add"
                onClick={
                  languagesToDisplay &&
                  Array.isArray(languagesToDisplay) &&
                  languagesToDisplay.length > 0
                    ? () =>
                        onBtnExport(
                          languagesToDisplay,
                          `${companyName}-users-${moment().format(
                            'DD-MMM-YYYY'
                          )}`
                        )
                    : () => {}
                }
              />
              {/* <div className="active-button">
                <Button onClick={() => handleOpen('add')}>
                  <i class="las la-plus"></i>
                  <span>{t('addLanguage')}</span>
                </Button>
              </div> */}

              {/* <img src={adduser} alt="add" onClick={() => handleOpen('add')} /> */}
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={languagesToDisplay}
            columnDefs={tableColumn}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            frameworkComponents={{
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
              ActionButton: (e) => (
                <ActionButton
                  e={e}
                  t={t}
                  handleDeleteAction={handleDeleteAction}
                  handleEditAction={handleEditAction}
                  CurrentLanguage={CurrentLanguage}
                />
              ),
            }}
            debug={false}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={50}
            overlayLoadingTemplate={'<p></p>'}
            paginationNumberFormatter={function (params) {
              return params.value.toLocaleString();
            }}
          ></AgGridReact>
        </div>

        <AddLangaugePopup
          open={open}
          handleClose={handleClose}
          t={t}
          payload={payload}
          setPayload={setPayload}
          errors={errors}
          setErrors={setErrors}
          isUpdate={isUpdate}
          // handleAddLanguage={handleAddLanguage}
          handleUpdateLanguage={handleUpdateLanguage}
          LanguageStatuses={LanguageStatuses}
          isRTLArray={isRTLArray}
        />
        <LanguageViewDetails
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          handleEditAction={handleEditAction}
          handleDeleteAction={handleDeleteAction}
          CurrentLanguage={CurrentLanguage}
        />
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const ActionButton = ({
  e,
  t,
  handleDeleteAction,
  handleEditAction,
  CurrentLanguage,
}) => {
  return (
    <div className="review-btn">
      {CurrentLanguage.id == e.data.languageId || e.data.languageId == 1 ? (
        <p>{t('notEdit')}</p>
      ) : (
        <Button onClick={() => handleEditAction(e.data)}>
          <i class="las la-pen" title="edit"></i>
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  CurrentLanguage: state.languages.current,
  LanguageStatuses: state.app.enums.LanguageStatus,
  companyName: state.companyConfig.companyName,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  initLanguages,
};
export default connect(mapStateToProps, mapDispatchToProps)(languageManagement);
