import moment from 'moment';
import dates from '../services/dates';

const getMealTimesByDate = (date, MealTypes) => {
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});
  date = dates.formatServer(date);
  return MealTypes.reduce((acc, mealType) => {
    let firstTimeOfMeal = MealTypesMap[mealType.id]?.from;
    let lastTimeOfMeal = MealTypesMap[mealType.id]?.to;
    let firstTimeToRealizeMeal = moment([date, firstTimeOfMeal].join(' '));
    let lastTimeToRealizeMeal = moment([date, lastTimeOfMeal].join(' '));

    return {
      ...acc,
      [mealType.id]: {
        starts: firstTimeToRealizeMeal,
        ends: lastTimeToRealizeMeal,
      },
    };
  }, {});
};

export { getMealTimesByDate };
