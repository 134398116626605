import io from 'socket.io-client';
import AppConfig from '../constants/Config';
console.log('server in socket ', AppConfig.server.url);
const socket = io(AppConfig.server.url, {
  transports: ['websocket'],
  rejectUnauthorized: false,
  query: `token=${JSON.parse(localStorage.getItem('token'))}`,
  //  reconnection: false,
});
socket.on('connect', () => {
  console.log('connected');
});

socket.emit('join', localStorage.getItem('userId'));
socket.on('disconnect', () => {
  console.log('Disconnected');
  localStorage.setItem('notificationsSocket', false);
});

socket.on('latestVersion', (data) => {
  if (!localStorage.getItem('version')) {
    localStorage.setItem('version', data.version);
  } else {
    if (data.version !== localStorage.getItem('version')) {
      let langObj = {
        en: 'New version is available, site will be reloaded',
        he: 'האתר יטען עכשיו עם גרסה חדשה',
        ru: 'Доступна новая версия, сайт будет перезагружен',
      };
      const langFromLS =
        localStorage.getItem('language') ??
        localStorage.getItem('defaultLang') ??
        'he';
      const lang = langFromLS.replace(/"/g, '');
      localStorage.setItem('version', data.version);
      alert(langObj[lang]);
      window.location.reload();
    }
  }
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      } else {
        console.log('Reconnect ');
      }
    });
  }, 10000);
};

socket.io.on('close', tryReconnect);

export { socket };
