import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ActiveButton } from '../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../services/validators';
import cancel from '../assets/images/close.svg';
import Storage from '../services/storage';
import back from '../assets/images/back.png';
import { DisabledButton } from '../components/WE-Button';
import JSONFormatter from 'json-formatter-js';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '51%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewCibusErrorDetails = ({
  Languages,
  open,
  details,
  handleClose,
  setDetails,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [viewData, setViewData] = useState(false);
  const [jsonData, setJsondata] = useState();

  const getMealDish = (params) => {
    if (params) {
      const translationsMap = params;
      const language = i18n.language;
      const languageId = Languages.find((lang) => lang.value === language).id;

      return translationsMap[languageId];
    }
    return '';
  };

  const handleViewData = (id) => {
    setViewData(!viewData);
    setJsondata(id);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="view-more-popup view-employee-popup"
    >
      <Box sx={style}>
        <div className="view-detail-head">
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {t('cibusError')}
          </Typography>
          <div className="close-btn-view">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="view-more-popup view-reservation-page">
            <>
              <div className="user-detail-container">
                <div className="user-details-row">
                  <div className="user-details-block">
                    <h6>{t('codeNumber')}:</h6>
                    <p>{details?.number}</p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('name')}:</h6>

                    <p>{details?.name}</p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('english')}:</h6>

                    <p>{details?.english}</p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('hebrew')}:</h6>

                    <p>{details?.hebrew}</p>
                  </div>
                  <div className="user-details-block">
                    <h6>{t('russian')}:</h6>

                    <p>{details?.russian}</p>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = {};
  if (!prefetchData) {
    prefetchData = [];
  }

  jsondataValue = prefetchData?.filter((el) => el.id === jsonData);
  const formatter =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.chargeResponse || '{}'))
      : '';
  let element = document.getElementById('json');

  if (element && formatter !== '') {
    element.innerHTML = '';
    element.appendChild(formatter.render());
  }

  return (
    // <Modal
    //   open={open}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    //   maxWidth="lg"
    //   className="add-employee-popup delete-popup log-json-data"
    // >
    //   <Box sx={style}>
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data reservation-popup ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <h6>{t('updatedData')}</h6>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn" id="json"></div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
    //   </Box>
    // </Modal>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
});

// export default ReservationManagement;
export default connect(mapStateToProps)(ViewCibusErrorDetails);
