import http from '../../services/http';
import i18n from '../../services/i18n';
import moment from 'moment';
import { he } from 'moment/locale/he';
import { fr } from 'moment/locale/fr';
import { de } from 'moment/locale/de';
import { ru } from 'moment/locale/ru';
import action from '../action.js';
import types from './types';
import Storage from '../../services/storage';
import { defaultLanguage } from '../../constants';
import { getLanguagesFromServer } from '../../services/language';

export function setLanguage(lang) {
  return action(async (dispatch) => {
    http.setLanguage(lang?.shortCode);
    await Storage.set('language', lang?.shortCode);
    await Storage.set('languageId', lang?.id);
    i18n.changeLanguage(lang?.shortCode);
    moment.locale(lang?.shortCode);
    dispatch({ type: types.SET_LANGUAGE, payload: lang });
  });
}

export function initLanguages() {
  return action(async (dispatch) => {
    const langName =
      (await Storage.get('language')) ||
      localStorage.getItem('defaultLang') ||
      defaultLanguage;
    http.setLanguage(langName);
    i18n.changeLanguage(langName);
    moment.locale(langName);

    let { data: langs } = await getLanguagesFromServer();

    // await Storage.set('csrfToken', csrf);

    let language = langs.find((l) => l.shortCode == langName);
    if (!language) {
      language = langs.find(
        (l) =>
          l.shortCode == localStorage.getItem('defaultLang') ||
          l.shortCode == defaultLanguage
      );
      await Storage.set('language', language?.shortCode);
      await Storage.set('languageId', language?.id);
      i18n.changeLanguage(language?.shortCode);
      moment.locale(language?.shortCode);
    }
    document.body.classList.add(language?.isRTL ? 'rtl' : 'ltr');
    dispatch({
      type: types.INIT_LANGUAGES,
      payload: {
        current: language,
        options: langs,
      },
    });
  });
}
