import { AgGridReact } from 'ag-grid-react';
import { useFetchingLoader, getReceipts } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getReservations,
  getReportTypeReservations,
  getMealTypeReservations,
} from '../../store/kitchens/actions';
import agGridLocales from '../../translations/ag-grid';
import { setLayoutOptions } from '../../store/app/actions';
import { DisabledButton } from '../../components/WE-Button';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import http from '../../services/http';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import JSONFormatter from 'json-formatter-js';
import cancel from '../../assets/images/close.svg';
import search from '../../assets/icons/search.png';
import next from '../../assets/images/next.png';
import ViewReservationdetails from './viewReservationdetails';
import { useLocation, useHistory } from 'react-router-dom';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import FilterIcon from '../../assets/images/filterIcon.png';
import Pagination from 'react-bootstrap/Pagination';
import Toast from 'react-bootstrap/Toast';
import Excel from 'exceljs';
import { Modal, Box, styled, Typography, Paper } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UpdatePaymentStatus from './updatePaymentStatus';
import Select from 'react-select';

const paddedDoubleDigits = (x) => (x < 10 ? `0${x}` : `${x}`);
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '82.5vh',
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const formatDate = (date, includeTime = false) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  let formattedDate = `${paddedDoubleDigits(day)}/${paddedDoubleDigits(
    month
  )}/${year}`;
  if (includeTime) {
    formattedDate +=
      ' ' +
      paddedDoubleDigits(date.getHours()) +
      ':' +
      paddedDoubleDigits(date.getMinutes());
  }

  return formattedDate;
};

// interface CustomizedState {
//   mealDate:string,
//   mealTypeId:string
// }

const ReservationManagement = ({
  Roles,
  kitchen,
  reservations,
  totalReservations,
  getReservations,
  MealTypes,
  setLayoutOptions,
  useFetchingLoader,
  currentLanguage,
  getPaymentPreferences,
  currentUser,
  companyName,
  attendanceEnabled,
  OrderStatuses,
  PaymentOption,
  getReceipts,
  receipts,
}) => {
  const { t, i18n } = useTranslation();
  const SelectStylesMulti = {
    control: (provided) => ({
      ...provided,
      height: 'auto',
      minHeight: '45px',
      color: '#656565',
      backgroundColor: 'transparent',
      border: '1px solid #9dc79d',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#656565',
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: 70,
      overflow: 'auto',
      padding: '0 10px',
      color: '#656565',
      '::-webkit-scrollbar': {
        width: '3px',
        height: '3px',
      },
      '::-webkit-scrollbar-track': {
        background: '#cacaca',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#00D100',
        borderRadius: '10px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      color: '#656565',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#4b4b4b',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#00D100',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#4b4b4b',
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: '#4b4b4b',
    }),
  };
  let isFilter = false;
  const location = useLocation<any>();
  //const state = location.state as CustomizedState;

  const [reserData, setReserData] = useState([{}]);
  const [, setMaxDate] = useState<any>(false);
  const [viewData, setViewData] = useState(false);
  const lang = localStorage.getItem('language');
  const [gridApi, setGridApi] = useState<any>();
  const [, setColumnApi] = useState<any>();
  const [fromDate, setFromdate] = useState<any>(new Date());
  const [fromDateFormat, setfromDateFormat] = useState<Date>();
  const [toDateFormat, settoDateFormat] = useState<Date>();
  const [toDate, setToDate] = useState<any>(new Date());
  const [contractorList, setContractorList] = useState<any>();
  const [contractor, setContractor] = useState<any>();
  const [contractorSet, setContractorSet] = useState<any>();
  const [jsonData, setJsondata] = useState();
  const [tableColumn, setTableColumn] = useState<any>();
  const [user, setUser] = useState<any>();
  const [reportType, setReportType] = useState<any>('DetailedReservations');
  const [details, setDetails] = useState<any>();
  const [viewDetails, setViewDetails] = useState<any>();
  const [mealTypes, setMealTypes] = useState<any>();
  const [mealType, setMealType] = useState<any>();
  const [dummy, setDummy] = useState<any>();
  let { mealDate, mealTypeId } = location?.state ?? {};
  const [filterOrder, setFilterData] = useState<any>();
  const [showPaymentOption, setShowPaymentOption] = useState();
  const [orderStatusId, setOrderStatusId] = useState<any>();
  const [paymentTypeId, setPaymentTypeId] = useState<any>();
  const [reservationType, setReservationType] =
    useState<string>('Reservations');
  const [searchText, setSearchText] = useState<string>('');
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs] = useState(25); // Records per page
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [columnAndOrder, setColumnAndOrder] = useState<any>({});
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [fromFile, setFromFile] = useState();
  const [isDisableDatepicker, setIsDisableDatepicker] =
    useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [allDishesForDropdown, setAllDishesForDropdown] = useState([]);
  const [selectedDishes, setSelectedDishes] = useState<any>([]);

  const [isOpenReportsHistory, setIsOpenReportHistory] = useState(false);
  const [reportsHistory, setReportsHistory] = useState([]);

  const [changePaymentStatus, setChangePaymentStatus] = useState(false);

  const [changedPaymentParams, setChangedPaymentParams] = useState<any>({});
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      const data = await http.get(`dishes/getAllForFilter/${kitchen.id}`);
      setAllDishesForDropdown(data.data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (kitchen) {
      // useFetchingLoader(async () => {
      const data = getPaymentPreferences(kitchen?.id, '');
      setShowPaymentOption(data.selectedPaymentOptions);
      // });
    }
  }, [kitchen]);
  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservations / newPageSizeLogs));
    }
  }, [reservations]);
  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservations / newPageSizeLogs));
    }
  }, []);
  function addOneMonth(date) {
    let newDate = new Date(date);

    let currentMonth = newDate.getMonth();

    newDate.setMonth(currentMonth + 1);

    if (newDate.getMonth() === currentMonth) {
      newDate.setMonth(0);
      newDate.setFullYear(newDate.getFullYear() + 1);
    }

    return newDate;
  }

  const fetchData = async () => {
    const data = await http.get('activityLogs/getByParams', {
      companyId: 1,
      tableName: 'detailedReservations',
      kitchenId: kitchen.id,
    });
    setReportsHistory(data);
  };

  useEffect(() => {
    fetchData();
  }, [isOpenReportsHistory]);

  useEffect(() => {
    isFilter = true;
    setcurrentPageAllLogs(1);
    setLayoutOptions({
      title: t('reservationManagement'),
    });
    setMealTypes(MealTypes.filter((el: any) => el.kitchenId === kitchen.id));
    let url = window.location.href;
    let split = url.split('?');
    if (split[1]?.split('=')[0] === 'contractor') {
      let contractId = split[1].split('=')[1];
      const today = new Date();
      const lastMonthFirstDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const lastMonthLastDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      setContractorSet(contractId);
      setFromdate(moment(lastMonthFirstDate).format('YYYY-MM-DD'));
      setfromDateFormat(lastMonthFirstDate);
      setToDate(moment(lastMonthLastDate).format('YYYY-MM-DD'));
      settoDateFormat(lastMonthLastDate);
      setContractor(contractId);
      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate: moment(lastMonthFirstDate).format('YYYY-MM-DD'),
          toDate: moment(lastMonthLastDate).format('YYYY-MM-DD'),
          contractorId: contractId,
          mealTypeId: mealTypeId,
          orderStatusId: orderStatusId,
          reservationType: reservationType,
          paymentTypeId: paymentTypeId,
          searchText: searchText,
          fromFile: fromFile,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el.value)
            : [],
          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
        const con = await http.get(`contractors/getAllContractors`);
        setContractorList(con.data);
      });
    } else if (split[1]?.split('=')[0] === 'user') {
      let userId = split[1].split('=')[1].split('&')[0];
      if (split[1].split('=')[2]) {
        setFromdate(split[1].split('=')[2]);
        setfromDateFormat(new Date(split[1].split('=')[2]));
      } else {
        setFromdate(new Date());
      }
      let currentDate = new Date();
      let newDate = addOneMonth(currentDate);
      setToDate(newDate);
      settoDateFormat(new Date(newDate));
      setUser(userId);
      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate:
            split[1].split('=')[2] || new Date().toISOString().split('T')[0],
          toDate: new Date(newDate).toISOString().split('T')[0],
          userId: userId,
          mealTypeId: mealType || mealTypeId,
          orderStatusId: orderStatusId,
          reservationType: reservationType,
          paymentTypeId: paymentTypeId,
          fromFile: fromFile,
          searchText: searchText,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el.value)
            : [],
          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
        const con = await http.get(`contractors/getAllContractors`);
        setContractorList(con.data);
      });
    } else if (mealDate && mealTypeId) {
      setFromdate(moment(new Date(mealDate)).format('YYYY-MM-DD'));
      setToDate(moment(new Date(mealDate)).format('YYYY-MM-DD'));
      setFilterData(true);

      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate: mealDate
            ? moment(mealDate).format('YYYY-MM-DD')
            : new Date().toISOString().split('T')[0],
          toDate: mealDate
            ? moment(mealDate).format('YYYY-MM-DD')
            : new Date().toISOString().split('T')[0],
          mealTypeId: mealType || mealTypeId,
          orderStatusId: orderStatusId,
          reservationType: reservationType,
          paymentTypeId: paymentTypeId,
          fromFile: fromFile,
          searchText: searchText,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el.value)
            : [],
          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
        const con = await http.get(`contractors/getAllContractors`);
        setContractorList(con.data);
      });
      //} else if (split[1]?.split('=')[0] === 'FromDate') {
    } else if (location.state?.backText === 'salaryReportFromDate') {
      //let getDatesFromQueryString = split[1]?.split('=')[1];
      //let getDatesArray = getDatesFromQueryString?.split(',');
      let getDatesArray = location.state?.FromDate;
      let FromFile = location.state?.FromFile;
      setFromFile(FromFile);

      setFromdate(
        location?.state?.FromDate && location?.state?.FromDate[0]
          ? moment(new Date(location?.state?.FromDate[0])).format('YYYY-MM-DD')
          : new Date().toISOString().split('T')[0]
      );
      setToDate(
        location?.state?.FromDate && location?.state?.FromDate.length > 1
          ? moment(
              new Date(
                location?.state?.FromDate[location?.state?.FromDate.length - 1]
              )
            ).format('YYYY-MM-DD')
          : moment(new Date(location?.state?.FromDate[0])).format('YYYY-MM-DD')
      );
      setfromDateFormat(
        location?.state?.FromDate && location?.state?.FromDate[0]
          ? new Date(location?.state?.FromDate[0])
          : new Date()
      );
      settoDateFormat(
        location?.state?.FromDate && location?.state?.FromDate.length > 1
          ? new Date(
              location?.state?.FromDate[location?.state?.FromDate.length - 1]
            )
          : new Date(location?.state?.FromDate[0])
      );
      setIsDisableDatepicker(true);

      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate: location?.state?.FromDate[0]
            ? moment(new Date(location?.state?.FromDate[0])).format(
                'YYYY-MM-DD'
              )
            : new Date().toISOString().split('T')[0],
          toDate:
            location?.state?.FromDate.length > 1
              ? moment(
                  new Date(
                    location?.state?.FromDate[
                      location?.state?.FromDate.length - 1
                    ]
                  )
                ).format('YYYY-MM-DD')
              : location?.state?.FromDate[0]
                ? moment(new Date(location?.state?.FromDate[0])).format(
                    'YYYY-MM-DD'
                  )
                : new Date().toISOString().split('T')[0],
          filterDate: getDatesArray,
          mealTypeId: mealTypeId,
          reservationType: reservationType,
          orderStatusId: orderStatusId,
          paymentTypeId: paymentTypeId,
          fromFile: FromFile,
          searchText: searchText,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el.value)
            : [],
          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
        const con = await http.get(`contractors/getAllContractors`);
        setContractorList(con.data);
      });
    } else {
      /*setFromdate(new Date());
      setToDate(new Date());
      setOrderStatusId(null);
      setPaymentTypeId(null);*/

      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate:
            moment(fromDate).format('YYYY-MM-DD') ??
            new Date().toISOString().split('T')[0],
          toDate:
            moment(toDate).format('YYYY-MM-DD') ??
            new Date().toISOString().split('T')[0],
          reservationType: reservationType,
          mealTypeId: mealType
            ? mealType !== 'undefined'
              ? mealType
              : undefined
            : mealTypeId && mealTypeId !== 'undefined'
              ? mealTypeId
              : undefined,
          orderStatusId: orderStatusId,
          paymentTypeId: paymentTypeId,
          fromFile: fromFile,
          contractorId: contractor,
          currentPageAllLogs: currentPageAllLogs,
          newPageSizeLogs: newPageSizeLogs,
          searchText: searchText,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el.value)
            : [],
          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
        const con = await http.get(`contractors/getAllContractors`);
        setContractorList(con.data);
      });
    }
  }, [kitchen?.id, refresh]);

  useEffect(() => {
    if (isFilter) {
      isFilter = false;
    } else {
      let url = window.location.href;
      let split = url.split('?');
      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate: fromDate
            ? moment(fromDate).format('YYYY-MM-DD')
            : new Date().toISOString().split('T')[0],
          toDate: toDate
            ? moment(toDate).format('YYYY-MM-DD')
            : new Date().toISOString().split('T')[0],
          orderStatusId: orderStatusId,
          reservationType: reservationType,
          mealTypeId: mealType || mealTypeId,
          paymentTypeId: paymentTypeId,
          currentPageAllLogs: currentPageAllLogs,
          newPageSizeLogs: newPageSizeLogs,
          fromFile: fromFile,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el.value)
            : [],
          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          contractorId:
            split[1]?.split('=')[0] === 'contractor'
              ? split[1].split('=')[1]
              : contractor
                ? contractor
                : undefined,
          userId:
            split[1]?.split('=')[0] == 'user'
              ? split[1].split('=')[1].split('&')[0]
              : undefined,
          searchText: searchText,
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
        const con = await http.get(`contractors/getAllContractors`);
        setContractorList(con.data);
      });
    }
  }, [currentPageAllLogs, columnAndOrder]);

  const handleReset = () => {
    let split = window.location.href.split('?')[0];
    window.location.href = split;
    setFromFile(undefined);
    setColumnAndOrder({});
    setSearchText('');
    setReservationType('Reservations');
    setOrderStatusId(undefined);
    setPaymentTypeId(undefined);
    setMealType(undefined);
    setMealType('undefined');
    setUser(undefined);
    setContractor(0);
    setToDate(moment(new Date()).format('YYYY-MM-DD'));
    setFromdate(moment(new Date()).format('YYYY-MM-DD'));
    setfromDateFormat(new Date());
    settoDateFormat(new Date());
    setIsDisableDatepicker(false);
    setcurrentPageAllLogs(1);
    setContractorSet(undefined);
    setSelectedDishes(null);
    setSelectedRoles(null);
  };

  useEffect(() => {
    if (reservations && !filterOrder) {
      gridApi?.setRowData(reservations);
      setReserData(reservations);
    } else {
      let filter = reservations.filter(
        (el: any) =>
          el?.orderStatusId === 1 ||
          el?.orderStatusId === 2 ||
          el?.orderStatusId === 3
      );
      setReserData(filter);
      gridApi?.setRowData(filter);
    }
  }, [reservations, dummy]);

  const goToSalaryReportPage = async ({ e }) => {
    history.push(
      '/salaryReport?FromDate=' +
        moment(e?.data?.updatedAt).format('YYYY-MM-DD')
    );
  };

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();

    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };

  const columnDefs = [
    {
      field: 'createdAt',
      headerName: `#\n${t('reservationDate')}`,
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'OrderDate',
    },
    {
      field: 'phoneNumber',
      headerName: `${t('phoneNumber')}`,
      suppressMovable: true,
      hide: true,
      cellRenderer: (params) => {
        return `${params.data.phoneNumber}`
          ? `${params.data.phoneNumber}`
          : '--';
      },
    },
    {
      field: 'user.firstName',
      headerName: `${t('name')}`,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'user.lastName',
      headerName: `${t('name')}`,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'user',
      headerName: `${t('name')}`,
      suppressMovable: true,
      cellRenderer: 'UserName',
    },
    {
      field: 'mealDish[0].mealDish.meal',
      // cellRenderer: (params) => {
      //   return `${formatDate(
      //     new Date(
      //       params?.data?.mealDish?.[0]?.mealDish?.meal?.date
      //         ? params?.data?.mealDish?.[0]?.mealDish?.meal?.date
      //         : params?.data?.meal?.date
      //     )
      //   )}`;
      // },
      cellRenderer: 'MealTime',
      headerName: `${t('reservationMealDate')}\n${t('slot')}`,
      suppressMovable: true,
    },
    {
      field: 'mealDish[0].mealDish.meal.mealTypeId',
      // cellRenderer: (params) =>
      //   MealTypes?.find(
      //     (mealType) =>
      //       mealType.id ===
      //       params?.data?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
      //   )?.value || '',
      cellRenderer: 'ShowMealTypes',
      headerName: t('mealType'),
      width: 200,
      suppressMovable: true,
    },
    {
      field: 'mealTypeId',
      headerName: `${t('price')}`,
      cellRenderer: 'Price',
      headerClass: 'resizable-header',
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'orderprice',
      headerName: `${t('price')}`,
      cellRenderer: 'Price',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 140,
    },
    {
      field: 'mealDish[0].mealDish.dishes',
      headerName: `${t('quantity')}`,
      cellRenderer: 'Quantity',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 140,
      hide: reservationType !== 'Reservations',
    },
    {
      field: 'mealDish[0].mealDish.dishes.translations',
      cellRenderer: 'DishName',
      headerName: t('dishName'),
      suppressMovable: true,
    },
    {
      field: 'Payment Indication',
      headerName: `${t('paymentMethod')}\n${t('statusPH')}`,
      cellRenderer: 'PaymentIndication',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
    {
      field: 'includeSalary',
      headerName: `${t('isSalary')}`,
      cellRenderer: 'IncludeSalaryFile',
      headerClass: 'resizable-header',
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'Order Status',
      headerName: t('status'),
      cellRenderer: 'OrderStatus',
      suppressMovable: true,
    },
    {
      field: 'Realized Status',
      headerName: t('realizedStatus'),
      cellRenderer: 'ActionButton',
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
    {
      field: 'id',
      headerName: `#\n${t('realizedStatus')}`,
      width: 100,
      suppressMovable: true,
      hide: true,
    },
  ];
  const columnDefsWithoutQuantity = [
    {
      field: 'createdAt',
      headerName: `#\n${t('reservationDate')}`,
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'OrderDate',
    },
    {
      field: 'phoneNumber',
      headerName: `${t('phoneNumber')}`,
      suppressMovable: true,
      hide: true,
      cellRenderer: (params) => {
        return `${params.data.phoneNumber}`
          ? `${params.data.phoneNumber}`
          : '--';
      },
    },
    {
      field: 'user.firstName',
      headerName: `${t('name')}`,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'user.lastName',
      headerName: `${t('name')}`,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'user',
      headerName: `${t('name')}`,
      suppressMovable: true,
      cellRenderer: 'UserName',
    },
    {
      field: 'mealDish[0].mealDish.meal',
      // cellRenderer: (params) => {
      //   return `${formatDate(
      //     new Date(
      //       params?.data?.mealDish?.[0]?.mealDish?.meal?.date
      //         ? params?.data?.mealDish?.[0]?.mealDish?.meal?.date
      //         : params?.data?.meal?.date
      //     )
      //   )}`;
      // },
      cellRenderer: 'MealTime',
      headerName: `${t('reservationMealDate')}\n${t('slot')}`,
      suppressMovable: true,
    },
    {
      field: 'mealDish[0].mealDish.meal.mealTypeId',
      // cellRenderer: (params) =>
      //   MealTypes?.find(
      //     (mealType) =>
      //       mealType.id ===
      //       params?.data?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
      //   )?.value || '',
      cellRenderer: 'ShowMealTypes',
      headerName: t('mealType'),
      width: 200,
      suppressMovable: true,
    },
    {
      field: 'mealTypeId',
      headerName: `${t('price')}`,
      cellRenderer: 'Price',
      headerClass: 'resizable-header',
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'orderprice',
      headerName: `${t('price')}`,
      cellRenderer: 'Price',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 140,
    },
    {
      field: 'mealDish[0].mealDish.dishes.translations',
      cellRenderer: 'DishName',
      headerName: t('dishName'),
      suppressMovable: true,
    },
    {
      field: 'Payment Indication',
      headerName: `${t('paymentMethod')}\n${t('statusPH')}`,
      cellRenderer: 'PaymentIndication',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
    {
      field: 'includeSalary',
      headerName: `${t('isSalary')}`,
      cellRenderer: 'IncludeSalaryFile',
      headerClass: 'resizable-header',
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'Order Status',
      headerName: t('status'),
      cellRenderer: 'OrderStatus',
      suppressMovable: true,
    },
    {
      field: 'Realized Status',
      headerName: t('realizedStatus'),
      cellRenderer: 'ActionButton',
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
    {
      field: 'id',
      headerName: `#\n${t('realizedStatus')}`,
      width: 100,
      suppressMovable: true,
      hide: true,
    },
  ];

  const mobcolumnDefs = [
    {
      field: 'id',
      headerName: `#\n${t('realizedStatus')}`,
      width: 100,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'createdAt',
      headerName: `#\n${t('reservationDate')}`,
      suppressMovable: true,
      cellRenderer: 'OrderDate',
      sortable: true,
      comparator: () => {},
      width: 150,
    },
    {
      field: 'mealDish[0].mealDish.meal',
      cellRenderer: 'MealTime',
      headerName: `${t('reservationMealDate')}\n${t('slot')}`,
      suppressMovable: true,
      width: 100,
    },
    {
      field: 'user.firstName',
      headerName: `${t('name')}`,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'mealDish[0].mealDish.meal.mealTypeId',
      cellRenderer: 'ShowMealTypes',
      headerName: t('mealType'),
      filter: 'agSetColumnFilter',
      filterValueGetter: (args) => {
        return (
          MealTypes?.find(
            (mealType) =>
              mealType.id ===
              args?.data?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
          )?.display || ''
        );
      },
      width: 200,
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'mealDish[0].mealDish.dish.name',
      cellRenderer: 'DishName',
      getQuickFilterText: function (params) {
        let dataArray = params?.data?.mealDish;
        if (dataArray?.length > 0) {
          const finalData = dataArray?.map((ele) => {
            let nameArray = ele?.mealDish?.dish?.name;
            let activeLanguage = currentLanguage.id;
            let name = nameArray[activeLanguage];
            // return `${name} <br/>`;
            return `${name}`;
          });
          return finalData?.toString().replaceAll(',', '');
        }
        if (params?.data?.point) {
          return t('pendingCollectedPoint');
        }
      },
      headerName: t('dishName'),
      suppressMovable: true,
      hide: true,
    },
    {
      field: 'user.lastName',
      headerName: `${t('name')}`,
      suppressMovable: true,
      hide: true,
    },

    {
      field: 'phoneNumber',
      headerName: `${t('phoneNumber')}`,
      suppressMovable: true,
      hide: true,
      cellRenderer: (params) => {
        return `${params.data.phoneNumber}`
          ? `${params.data.phoneNumber}`
          : '--';
      },
    },
    {
      field: 'user',
      headerName: `${t('name')}`,
      suppressMovable: true,
      width: 100,
      cellRenderer: 'UserName',
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  const handleChange = async (name: any, value: any) => {
    setcurrentPageAllLogs(1);
    setFilterData(false);
    if (name === 'fromdate') {
      if (!value) {
        setFromdate(null);
      }
      let maxDate = new Date();
      setMaxDate(moment(maxDate).format('YYYY-MM-DD'));
      setfromDateFormat(value);
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        setToDate(null);
      }
      const tempToDate = new Date(value);

      settoDateFormat(value);
      setToDate(tempToDate);
    } else if (name === 'contractor') {
      if (value == '0') {
        setContractor('undefined');
        handleApplyfilter(
          fromDate,
          toDate,
          undefined,
          mealType,
          orderStatusId,
          paymentTypeId,
          reservationType,
          selectedDishes,
          selectedRoles
        );
      } else {
        let tempContractor = value;
        setContractor(value);
        handleApplyfilter(
          fromDate,
          toDate,
          tempContractor,
          mealType,
          orderStatusId,
          paymentTypeId,
          reservationType,
          selectedDishes,
          selectedRoles
        );
      }
    } else if (name === 'meal_type') {
      setMealType(value);
      mealTypeId = value;
      handleApplyfilter(
        fromDate,
        toDate,
        contractor,
        value,
        orderStatusId,
        paymentTypeId,
        reservationType,
        selectedDishes,
        selectedRoles
      );
    } else if (name === 'order_status') {
      setOrderStatusId(value);
      handleApplyfilter(
        fromDate,
        toDate,
        contractor,
        mealType,
        value,
        paymentTypeId,
        reservationType,
        selectedDishes,
        selectedRoles
      );
    } else if (name === 'payment_type') {
      setPaymentTypeId(value);
      handleApplyfilter(
        fromDate,
        toDate,
        contractor,
        mealType,
        orderStatusId,
        value,
        reservationType,
        selectedDishes,
        selectedRoles
      );
    } else if (name === 'reservation_type') {
      setReservationType(value);
      handleApplyfilter(
        fromDate,
        toDate,
        contractor,
        mealType,
        orderStatusId,
        paymentTypeId,
        value,
        selectedDishes,
        selectedRoles
      );
    } else if (name === 'dishes') {
      setSelectedDishes(value);
      handleApplyfilter(
        fromDate,
        toDate,
        contractor,
        mealType,
        orderStatusId,
        paymentTypeId,
        reservationType,
        value,
        selectedRoles
      );
    } else if (name === 'roles') {
      setSelectedRoles(value);
      handleApplyfilter(
        fromDate,
        toDate,
        contractor,
        mealType,
        orderStatusId,
        paymentTypeId,
        reservationType,
        selectedDishes,
        value
      );
    } else {
      let tempContractor = value;
      let tempToDate = null;
      setContractor(tempContractor);
      handleApplyfilter(
        fromDate,
        tempToDate,
        tempContractor,
        mealType,
        orderStatusId,
        paymentTypeId,
        reservationType,
        selectedDishes,
        selectedRoles
      );
    }
  };
  const handleApplyfilter = async (
    fromDateLocal: any,
    tempToDate: any,
    tempContractor: any,
    mealType: any,
    orderStatusId: any,
    paymentTypeId: any,
    reservationType: any,
    dishes: any,
    roles: any
  ) => {
    if (fromDateLocal == null && tempToDate == null) {
      useFetchingLoader(async () => {
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate: new Date().toISOString().split('T')[0],
          toDate: new Date().toISOString().split('T')[0],
          contractorId: contractor ? contractor : tempContractor,
          userId: user,
          mealTypeId: mealType || mealTypeId,
          orderStatusId: orderStatusId,
          paymentTypeId: paymentTypeId,
          fromFile: fromFile,
          searchText: searchText,
          selectedDishes: selectedDishes
            ? selectedDishes.map((el) => el?.value)
            : [],

          selectedRoles: selectedRoles
            ? selectedRoles.map((el) => el?.value)
            : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
      });
    } else {
      useFetchingLoader(async () => {
        // correct reportType
        await getReservations({
          kitchenId: kitchen?.id,
          fromDate: moment(fromDateLocal).format('YYYY-MM-DD'),
          toDate: toDate
            ? moment(toDate).format('YYYY-MM-DD')
            : moment(tempToDate).format('YYYY-MM-DD'),
          contractorId: tempContractor,
          userId: user,
          mealTypeId: mealType || mealTypeId,
          orderStatusId: orderStatusId,
          paymentTypeId: paymentTypeId,
          reservationType: reservationType,
          fromFile: fromFile,
          searchText: searchText,
          selectedDishes: dishes ? dishes.map((el) => el?.value) : [],
          selectedRoles: roles ? roles.map((el) => el?.value) : [],
          field:
            'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
          order: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        });
      });
    }
  };

  const handleCancelOrder = async (orderId: any, userId: any) => {
    Dialog({
      title: t('confirmModalTitle'),
      type: 'success',
      buttons: {
        showCancelButton: true,
        cancelButtonText: t('cancel'),
        confirmButtonText: t('confirm'),
      },
      onConfirm: async () => {
        try {
          handleviewmore({});
          const cancelOrder = await useFetchingLoader(() =>
            http.put(`orders/${orderId}/cancelByAdmin`, { userId })
          );
          if (cancelOrder.orderStatusId === 3) {
            setTimeout(() => {
              SwalDialogs.Dialog({
                title: t('orderWasCanceled'),
                type: 'success',
                buttons: {
                  showCancelButton: false,
                  confirmButtonText: t('confirm'),
                },
                onConfirm: () => {
                  setReserData((prev: any) => {
                    let prevArr = [...prev];
                    const findIndex = prevArr.findIndex(
                      (el) => el.id === orderId
                    );

                    if (findIndex !== -1) {
                      prevArr[findIndex].orderStatusId = 3;
                      // prevArr.splice(findIndex, 1);
                    }
                    setDummy(true);
                    return [...prevArr];
                  });
                },
              });
            }, 150);
            setViewDetails(false);
          }
        } catch (err) {
          setTimeout(() => {
            SwalDialogs.Dialog({
              title: 'An error has occurred',
              text: `${err}`,
              type: 'error',
              buttons: {
                showCancelButton: false,
                showConfirmButton: false,
              },
            });
          }, 150);
        }
      },
    });
  };

  const handleOpen = async (hash: any, mealId: any, id: any) => {
    const getMealData = await http.get(`meals/${mealId}`);
    if (
      new Date(getMealData?.date).setHours(0, 0, 0, 0) <=
      new Date().setHours(0, 0, 0, 0)
    ) {
      Dialog({
        title: t('confirmModalTitle'),
        type: 'success',
        buttons: {
          showCancelButton: true,
          cancelButtonText: t('cancel'),
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          const getData = async () => {
            try {
              const realizeOrder = await http.post(`orders/realizeByHash`, {
                hash,
                mealId,
              });
              if (
                realizeOrder?.paymentOptionId === 6 &&
                realizeOrder?.paymentStatus
              ) {
                Dialog({
                  title: t('paymentSuccessMessage'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    setRefresh((prev) => !prev);
                  },
                });
              }
              if (realizeOrder?.orderStatusId === 2) {
                Dialog({
                  title: t('realizedOrderText'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    /*setReserData((prev: any) => {
                      let prevArr = [...prev];
                      const findIndex = prevArr.findIndex((el) => el.id === id);
                      if (findIndex !== -1) {
                        prevArr[findIndex].orderStatusId = 2;
                        prevArr[findIndex].realizeDate = moment(
                          new Date()
                        ).format('YYYY-MM-DD HH:mm:ss');
                      }
                     
                      return [...prevArr];
                    });*/
                    setRefresh((prev) => !prev);
                    setDummy((prev) => !prev);
                  },
                });
              }
            } catch (error) {
              Dialog({
                title: error,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  confirmButtonText: t('confirm'),
                },
                onConfirm: () => {},
              });
              window.gtag('event', 'Realize_order', {
                title: 'realize-error',
                client: window.location.host.split('.')[0],
              });
              return error;
            }
          };
          getData();
        },
      });
    } else {
      Dialog({
        title: t('realizeError'),
        type: 'error',
        buttons: {
          showCancelButton: true,
          cancelButtonText: t('cancel'),
          confirmButtonText: t('redemption'),
        },
        onConfirm: () => {
          const getData = async () => {
            try {
              const realizeOrder = await http.post(`orders/realizeByHash`, {
                hash,
                mealId,
              });
              if (
                realizeOrder.paymentOptionId === 6 &&
                realizeOrder.paymentStatus
              ) {
                Dialog({
                  title: t('paymentSuccessMessage'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {},
                });
              }
              if (realizeOrder.orderStatusId === 2) {
                Dialog({
                  title: t('realizedOrderText'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    setReserData((prev: any) => {
                      let prevArr = [...prev];
                      const findIndex = prevArr.findIndex((el) => el.id === id);
                      if (findIndex !== -1) {
                        prevArr[findIndex].orderStatusId = 2;
                        prevArr[findIndex].realizeDate = moment(
                          new Date()
                        ).format('YYYY-MM-DD HH:mm:ss');
                      }

                      return [...prevArr];
                    });
                    setRefresh((prev) => !prev);
                    setDummy((prev) => !prev);
                  },
                });
              }
            } catch (error) {
              Dialog({
                title: error,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  confirmButtonText: t('confirm'),
                },
                onConfirm: () => {},
              });
              window.gtag('event', 'Realize_order', {
                title: 'realize-error',
                client: window.location.host.split('.')[0],
              });
              return error;
            }
          };
          getData();
        },
      });
    }
  };
  /***********Export CSV file */
  const onBtnExport = async (exportCsvFileNames, disableButton) => {
    try {
      if (disableButton) {
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        let url = window.location.href;
        let split = url.split('?');
        let getDatesArray =
          location.state?.backText === 'salaryReportFromDate'
            ? location.state?.FromDate
            : undefined;
        let FromFile =
          location.state?.backText === 'salaryReportFromDate'
            ? location.state?.FromFile
            : undefined;
        const localContractor =
          split[1]?.split('=')[0] === 'contractor'
            ? split[1].split('=')[1]
            : undefined;
        const localUser =
          split[1]?.split('=')[0] === 'user'
            ? split[1].split('=')[1].split('&')[0]
            : undefined;

        const arr = [
          'orderId',
          'createdDate',
          'name',
          'userType',
          'mealDate',
          'mealType',
          'price',
          'dishName',
          'contractor',
          'paymentStatus',
          'status',
          'realizedStatus',
          'realizedMethod',
          'realizedByUserName',
          'realizedByDispenserNameReport',
        ];
        if (kitchen.kitchenPayment.paymentOptionId === 6) {
          arr.push('paymentOrderId');
          arr.push('cardLastDigits');
        }
        if (reservationType === 'Reservations') {
          arr.splice(6, 0, 'quantity');
        }
        if (reservationType !== 'Reservations') {
          arr.splice(6, 10);
        }
        const resArr = arr.map((el) => t(el));
        const res = await http.post(
          `kitchens/${kitchen?.id}/reservations/download`,
          {
            isReport: true,
            headers: resArr,
            filename: exportCsvFileNames,
          },
          {
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            orderId: undefined, // orderId ? Number(orderId) : undefined
            contractorId:
              localContractor && localContractor !== 'undefined'
                ? localContractor
                : contractor && contractor !== 'undefined'
                  ? contractor
                  : undefined,
            userId:
              localUser && localUser !== 'undefined'
                ? localUser
                : user && user !== 'undefined'
                  ? user
                  : undefined,
            mealTypeId: mealType
              ? mealType !== 'undefined'
                ? mealType
                : undefined
              : mealTypeId && mealTypeId !== 'undefined'
                ? mealTypeId
                : undefined,
            filterDate:
              getDatesArray && getDatesArray !== 'undefined'
                ? getDatesArray
                : undefined,
            fromFile:
              FromFile && FromFile !== 'undefined' ? FromFile : undefined,
            orderStatusId:
              orderStatusId && orderStatusId !== 'undefined'
                ? orderStatusId
                : undefined,
            paymentTypeId:
              paymentTypeId && paymentTypeId !== 'undefined'
                ? paymentTypeId
                : undefined,
            reservationType:
              reservationType && reservationType !== 'undefined'
                ? reservationType
                : undefined,
            page: currentPageAllLogs ? currentPageAllLogs : 1,
            limit: newPageSizeLogs ? newPageSizeLogs : 50,
            selectedDishes: selectedDishes
              ? selectedDishes.map((el) => el?.value)
              : [],

            selectedRoles: selectedRoles
              ? selectedRoles.map((el) => el?.value)
              : [],
            findStr: searchText,
          }
        );
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.addRows(res.data);
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {
          type: 'application/octet-binary',
        });
        const urlFile = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlFile;
        a.download = `${exportCsvFileNames}.xlsx`;
        a.click();
        a.remove();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      console.log('e', e);
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  const handleViewData = (id: any) => {
    setViewData(!viewData);
    setJsondata(id);
  };

  const handleFilterChanged = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    setReportType('DetailedReservations');
    if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnDefs);
    } else {
      setTableColumn(
        reservationType !== 'Reservations'
          ? columnDefsWithoutQuantity
          : columnDefs
      );
    }
  }, [showPaymentOption, reservationType]);

  const handleviewmore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  let exportCsvFileNames = '';

  if (reportType === 'MealType') {
    exportCsvFileNames =
      companyName +
      '-' +
      kitchen?.name +
      '-total-by-meals-' +
      '-' +
      moment(fromDate).format('YYYY-MM-DD') +
      '-' +
      moment(toDate).format('YYYY-MM-DD');
  } else {
    exportCsvFileNames =
      companyName +
      '-' +
      kitchen?.name +
      '-' +
      t('reservationExport') +
      '-' +
      moment(new Date()).format('YYYY-MM-DD hh:mm A');
  }

  const [mobFilter, showMobFilter] = useState(false);
  const handleHeight = () => {
    showMobFilter(!mobFilter);
  };
  return (
    <>
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter">
              <div className="filter-section">
                <div className="search-filter">
                  <div className="xyz">
                    <div className="from-date">
                      <label>{t('fromDate')}</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                          fromDateFormat
                            ? fromDateFormat
                            : mealDate
                              ? new Date(mealDate)
                              : new Date()
                        }
                        name="fromDate"
                        disabled={isDisableDatepicker}
                        onChange={(event) => handleChange('fromdate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                      {/* <input
                          type="date"
                          //defaultValue={new Date().toISOString().split('T')[0]}
                          defaultValue={moment(new Date()).format('DD-MM-YYYY')}
                          value={moment(new Date()).format('DD-MM-YYYY')}
                          placeholder="dd/mm/yyyy"
                          name="fromDate"
                          onChange={(event) =>
                            handleChange('fromdate', event.target.value)
                          }
                        /> */}
                    </div>
                    <div className="from-date">
                      <label>{t('toDate')}</label>
                      {/* <input
                          type="date"
                          defaultValue={new Date().toISOString().split('T')[0]}
                          placeholder="dd/mm/yyyy"
                          name="toDate"
                          onChange={(event) =>
                            handleChange('todate', event.target.value)
                          }
                        /> */}
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={
                          toDateFormat
                            ? toDateFormat
                            : mealDate
                              ? new Date(mealDate)
                              : new Date()
                        }
                        name="toDate"
                        disabled={isDisableDatepicker}
                        onChange={(event) => handleChange('todate', event)}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>

                  <div className="search-input-wrapper">
                    <div className="search-input searchBtn">
                      <label>{t('search')}</label>
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder={t('freeTextSearch')}
                        onInput={handleFilterChanged}
                        value={searchText}
                      />
                      <img src={search} alt="search" />
                    </div>
                    {fromDate && toDate ? (
                      <button
                        onClick={() =>
                          handleApplyfilter(
                            fromDate,
                            toDate,
                            contractor,
                            mealType,
                            orderStatusId,
                            paymentTypeId,
                            reservationType,
                            selectedDishes,
                            selectedRoles
                          )
                        }
                        className="search-button"
                      >
                        <img src={search} alt="search" />
                      </button>
                    ) : (
                      ''
                    )}
                    <button
                      onClick={() => handleReset()}
                      className="reset-button"
                    >
                      <img src={cancel} alt="search" />
                    </button>
                    <button
                      onClick={handleHeight}
                      className="search-button filter-btn"
                    >
                      <img src={FilterIcon} alt="filter" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {reportType === 'DetailedReservations' && (
              <div
                className={`xyz select-section select-section-label flex-wrap mb-3 ${
                  mobFilter ? 'show' : ''
                }`}
              >
                {/*<div className="from-date">
                  <label>{t('contractor')}</label>
                  <select
                    name="contractor"
                    onChange={(event) =>
                      handleChange('contractor', event.target.value)
                    }
                    value={contractor}
                    disabled={contractorSet}
                  >
                    <option value="0">{t('allContractor')}</option>

                    {currentUser?.roleId === 7
                      ? currentUser?.contractors &&
                        currentUser?.contractors.map((el: any) => (
                          <option
                            value={el.id}
                            selected={
                              el.id == contractor ? contractor : contractorSet
                            }
                          >
                            {el.name}
                          </option>
                        ))
                      : contractorList &&
                        contractorList.map((el: any) => (
                          <option
                            value={el.id}
                            selected={
                              el.id == contractor ? contractor : contractorSet
                            }
                          >
                            {el.name}
                          </option>
                        ))}
                  </select>
                </div>*/}
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label">
                    {t('contractor')}
                  </label>
                  <Select
                    isMulti={false}
                    name={'contractor'}
                    placeholder={t('allContractor')}
                    isClearable={true}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(event) => {
                      handleChange('contractor', event?.value);
                    }}
                    value={
                      currentUser?.roleId === 7
                        ? currentUser?.contractors &&
                          currentUser?.contractors
                            .map((el: any) => ({
                              value: el.id,
                              label: el.name,
                            }))
                            .find(
                              (elem) =>
                                (contractor ? contractor : contractorSet) ===
                                elem.value
                            )
                        : contractorList &&
                          contractorList
                            .map((el: any) => ({
                              value: el.id,
                              label: el.name,
                            }))
                            .find(
                              (elem) =>
                                (contractor ? contractor : contractorSet) ===
                                elem.value
                            )
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={
                      currentUser?.roleId === 7
                        ? currentUser?.contractors &&
                          currentUser?.contractors.map((el: any) => ({
                            value: el.id,
                            label: el.name,
                          }))
                        : contractorList &&
                          contractorList.map((el: any) => ({
                            value: el.id,
                            label: el.name,
                          }))
                    }
                  />
                </div>
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label">
                    {t('userType')}
                  </label>
                  <Select
                    isMulti={true}
                    name={'role'}
                    placeholder={t('selectUserType')}
                    isClearable={true}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(newData) => {
                      handleChange('roles', newData ?? []);
                    }}
                    value={selectedRoles}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={Roles.filter((el) => el.id !== 5).map((roles) => ({
                      value: roles.id,
                      label: roles.display,
                    }))}
                  />
                </div>
                {/*<div className="from-date">
                  <label>{t('mealTypeInReservation')}</label>
                  <select
                    name="meal_type"
                    value={mealType}
                    onChange={(event) =>
                      handleChange('meal_type', event.target.value)
                    }
                  >
                    {' '}
                    <option value="undefined">{t('selectMealType')}</option>
                    {mealTypes &&
                      mealTypes?.map((el: any) => (
                        <option value={el.id} selected={el.id == mealTypeId}>
                          {el.display}
                        </option>
                      ))}
                  </select>
                </div>*/}
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label reservation-management-dropdown-label">
                    {t('mealTypeInReservation')}
                  </label>
                  <Select
                    isMulti={false}
                    name={'mealTypeInReservation'}
                    placeholder={t('selectMealType')}
                    isClearable={true}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(event) =>
                      handleChange('meal_type', event?.value)
                    }
                    value={
                      mealTypes &&
                      mealTypes
                        ?.map((el: any) => ({
                          value: el.id,
                          label: el.display,
                        }))
                        .find((elem) => mealType === elem.value)
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={
                      mealTypes &&
                      mealTypes?.map((el: any) => ({
                        value: el.id,
                        label: el.display,
                      }))
                    }
                  />
                </div>
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label">
                    {t('dishes')}
                  </label>
                  <Select
                    isMulti={true}
                    name={'dishes'}
                    placeholder={t('selectDishes')}
                    isClearable={true}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(newData) => {
                      handleChange('dishes', newData ?? []);
                    }}
                    value={selectedDishes}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={allDishesForDropdown}
                  />
                </div>
                {/*<div className="from-date">
                  <label>{t('reservationStatus')}</label>
                  <select
                    name="order_status"
                    onChange={(event) =>
                      handleChange('order_status', event.target.value)
                    }
                  >
                    {' '}
                    {
                      <option value="undefined">
                        {t('selectReservationStatus')}
                      </option>
                    }
                    <option value={1} selected={1 == orderStatusId}>
                      {t('pending')}
                    </option>
                    <option value={2} selected={2 == orderStatusId}>
                      {t('realized')}
                    </option>
                    {currentUser.roleId === 1 &&
                      reservationType === 'Reservations' && (
                        <option value={3} selected={3 == orderStatusId}>
                          {t('canceled')}
                        </option>
                      )}
                  </select>
                </div>*/}
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label">
                    {t('reservationStatus')}
                  </label>
                  <Select
                    isMulti={false}
                    name={'reservationStatus'}
                    placeholder={t('selectReservationStatus')}
                    isClearable={true}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(event) =>
                      handleChange('order_status', event?.value)
                    }
                    value={
                      currentUser.roleId === 1 &&
                      reservationType === 'Reservations'
                        ? [
                            { value: 1, label: t('pending') },
                            { value: 2, label: t('realized') },
                            { value: 3, label: t('canceled') },
                          ].find((elem) => orderStatusId === elem.value)
                        : [
                            { value: 1, label: t('pending') },
                            { value: 2, label: t('realized') },
                          ].find((elem) => orderStatusId === elem.value)
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={
                      currentUser.roleId === 1 &&
                      reservationType === 'Reservations'
                        ? [
                            { value: 1, label: t('pending') },
                            { value: 2, label: t('realized') },
                            { value: 3, label: t('canceled') },
                          ]
                        : [
                            { value: 1, label: t('pending') },
                            { value: 2, label: t('realized') },
                          ]
                    }
                  />
                </div>
                {/*<div className="from-date">
                  <label>{t('paymentType')}</label>
                  <select
                    name="payment_type"
                    onChange={(event) =>
                      handleChange('payment_type', event.target.value)
                    }
                  >
                    {' '}
                    <option value="undefined">{t('selectPaymentType')}</option>
                    <option value={3} selected={3 == paymentTypeId}>
                      {t('paymentOption-creditCard')}
                    </option>
                    <option value={4} selected={4 == paymentTypeId}>
                      {t('paymentOption-salary')}
                    </option>
                    <option value={5} selected={5 == paymentTypeId}>
                      {t('wallet')}
                    </option>
                    <option value={6} selected={6 == paymentTypeId}>
                      {t('paymentOption-cibus')}
                    </option>
                    <option value={0} selected={'Invoice' == paymentTypeId}>
                      {t('invoice')}
                    </option>
                  </select>
                </div>*/}
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label">
                    {t('paymentType')}
                  </label>
                  <Select
                    isMulti={false}
                    name={'paymentType'}
                    placeholder={t('selectPaymentType')}
                    isClearable={true}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(event) => {
                      handleChange('payment_type', event?.value ?? undefined);
                    }}
                    value={[
                      { value: 3, label: t('paymentOption-creditCard') },
                      { value: 4, label: t('paymentOption-salary') },
                      { value: 5, label: t('wallet') },
                      { value: 6, label: t('paymentOption-cibus') },
                      { value: 0, label: t('invoice') },
                    ].find((elem) => paymentTypeId === elem.value)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={[
                      { value: 3, label: t('paymentOption-creditCard') },
                      { value: 4, label: t('paymentOption-salary') },
                      { value: 5, label: t('wallet') },
                      { value: 6, label: t('paymentOption-cibus') },
                      { value: 0, label: t('invoice') },
                    ]}
                  />
                </div>
                {/*<div className="from-date">
                  <label>{t('reservationType')}</label>
                  <select
                    name="reservation_type"
                    onChange={(event) =>
                      handleChange('reservation_type', event.target.value)
                    }
                    value={reservationType}
                  >
                    {' '}
                    <option
                      value={'Reservations'}
                      selected={'Reservations' == reservationType}
                    >
                      {t('reservations')}
                    </option>
                    <option
                      value={'Credit points'}
                      selected={'Credit points' == reservationType}
                    >
                      {t('creditPoints')}
                    </option>
                  </select>
                </div>*/}
                <div className="client-lobby-filter-block-datepicker">
                  <label className="client-lobby-filter-block-label">
                    {t('reservationType')}
                  </label>
                  <Select
                    isMulti={false}
                    name={'reservationType'}
                    placeholder={t('reservations')}
                    isSearchable={true}
                    className="client-lobby-filter-block-dropdown green-background dish-dropdown"
                    styles={SelectStylesMulti}
                    onChange={(event) =>
                      handleChange('payment_type', event?.value)
                    }
                    value={[
                      { value: 'Reservations', label: t('reservations') },
                      { value: 'Credit points', label: t('creditPoints') },
                    ].find((elem) => reservationType === elem.value)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(157, 199, 157, 0.3)',
                        primary: '#00D100',
                        neutral20: '#00D100',
                        neutral30: '#656565',
                        neutral40: '#00D100',
                        neutral60: '#00D100',
                        neutral80: '#656565',
                        neutral90: '#656565',
                      },
                    })}
                    options={[
                      { value: 'Reservations', label: t('reservations') },
                      { value: 'Credit points', label: t('creditPoints') },
                    ]}
                  />
                </div>
              </div>
            )}

            {
              <div>
                {fromFile || user ? (
                  <div className="page-info">
                    <p>
                      {fromFile ? (
                        <span>From file: {fromFile}</span>
                      ) : (
                        <span>User id: {user}</span>
                      )}
                    </p>
                  </div>
                ) : (
                  ''
                )}
                <div className="active-button mw-172px">
                  <div className="total-data">
                    <p>
                      {t('totalData')}: <span>{totalReservations}</span>
                    </p>
                  </div>
                  <div className="actions">
                    <i
                      style={{ cursor: 'pointer' }}
                      className="las la-file-alt"
                      onClick={() => {
                        onBtnExport(exportCsvFileNames, disableButton);
                      }}
                    ></i>

                    <i
                      style={{ cursor: 'pointer' }}
                      className="las la-book"
                      onClick={() => {
                        setIsOpenReportHistory(true);
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            }
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={
                reservations.length !== 0 &&
                reserData.length !== 0 &&
                'id' in reserData[0]
                  ? reserData.map((reservation) => ({
                      ...reservation,
                      kitchen,
                    }))
                  : []
              }
              columnDefs={
                reservations.length !== 0 && reserData.length !== 0
                  ? tableColumn
                  : []
              }
              localeText={agGridLocales[i18n.language]}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onSortChanged={() => printSortStateToConsole()}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onGridColumnsChanged={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              frameworkComponents={{
                ActionButton: (e) => (
                  <ActionButton
                    e={e}
                    t={t}
                    handleOpen={handleOpen}
                    roleId={currentUser?.roleId}
                  />
                ),
                OrderStatus: (e) => <OrderStatus e={e} t={t} />,
                MealTime: (e) => <MealTime e={e} formatDate={formatDate} />,
                DishName: (e) => (
                  <DishName e={e} t={t} currentLanguage={currentLanguage} />
                ),
                Quantity: (e) => <Quantity e={e} />,
                Price: (e) => <Price e={e} />,
                IncludeSalaryFile: (e) => (
                  <IncludeSalaryFile
                    e={e}
                    t={t}
                    goToSalaryReportPage={goToSalaryReportPage}
                  />
                ),
                ShowMealTypes: (e) => (
                  <ShowMealTypes e={e} mealTypes={MealTypes} />
                ),
                PaymentIndication: (e) => <PaymentIndication e={e} t={t} />,
                ViewMore: (e) => (
                  <ViewMore handleviewmore={handleviewmore} e={e} />
                ),
                RealizeDate: (e) => <RealizeDate e={e} />,
                OrderDate: (e) => <OrderDate e={e} />,
                UserName: (e) => <UserName e={e} />,
              }}
              reactUi={true}
              enableRtl={lang === '"he"' ? true : false}
              pagination={reportType === 'DetailedReservations' ? false : true}
              paginationPageSize={50}
              overlayLoadingTemplate={'<p></p>'}
              paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
              }}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <p>
                  {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                  {newPageSizeLogs * currentPageAllLogs} of {totalReservations}
                </p>
              </div>
              <Pagination>
                <Pagination.First
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(1);
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs > 1
                      ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                      : '';
                  }}
                />

                <Pagination.Item active>
                  Page {currentPageAllLogs} of{' '}
                  {Math.ceil(totalReservations / newPageSizeLogs)}
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs <
                    Math.ceil(totalReservations / newPageSizeLogs)
                      ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                      : '';
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(
                      Math.ceil(totalReservations / newPageSizeLogs)
                    );
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        open={viewData}
        prefetchData={reserData}
        jsonData={jsonData}
        handleClose={handleViewData}
        t={t}
      />
      <Modal
        open={isOpenReportsHistory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={'view-more-popup view-employee-popup'}
        onClose={() => setIsOpenReportHistory(false)}
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <div className="reservation-title">
              <h5>{t('reportsHistory')}</h5>
            </div>
            <div className="close-btn-view">
              <img
                src={cancel}
                alt="close"
                onClick={() => {
                  setIsOpenReportHistory(false);
                }}
              />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className={`view-more-popup`}>
              {reportsHistory && reportsHistory.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('message')}</strong>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportsHistory.map((el: any) => (
                        <StyledTableRow
                          key={el.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(el?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(el?.createdAt).format('hh:mm:ss  A')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {`${el?.userfirstName} ${el?.userlastName}`}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{el.message}</p>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ViewReservationdetails
        open={viewDetails}
        details={details}
        handleClose={handleviewmore}
        toDate={toDate}
        fromDate={fromDate}
        reservationType={reservationType}
        reportType={reportType}
        handleOpen={handleOpen}
        reserData={reserData}
        setDetails={setDetails}
        handleCancelOrder={handleCancelOrder}
        currentLanguage={currentLanguage}
        setRefresh={setRefresh}
        refresh={refresh}
        attendanceEnabled={attendanceEnabled}
        mealTypes={mealTypes}
        PaymentOption={PaymentOption}
        OrderStatuses={OrderStatuses}
        receipts={receipts}
        getReceipts={getReceipts}
        setChangePaymentStatus={setChangePaymentStatus}
        setChangedPaymentParams={setChangedPaymentParams}
      />
      <UpdatePaymentStatus
        setChangedPaymentParams={setChangedPaymentParams}
        changedPaymentParams={changedPaymentParams}
        changePaymentStatus={changePaymentStatus}
        setChangePaymentStatus={setChangePaymentStatus}
        setRefresh={setRefresh}
      />
      <Toast
        onClose={() => setOpenDownload(false)}
        show={openDownload}
        style={{
          position: 'sticky',
          right: '20px',
          bottom: '70px',
        }}
      >
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            onClick={() => setOpenDownload(false)}
          />
        </Toast.Header>
        <Toast.Body>{t('downloadFile')}</Toast.Body>
      </Toast>
    </>
  );
};
const UserName = ({ e }) => {
  const phoneFromPayment = e?.data.chargeResponse
    ? JSON.parse(e?.data.chargeResponse)?.phone_number
    : '';
  return (
    <p>
      {e?.data?.user?.firstName
        ? e?.data?.user?.firstName + ' ' + e?.data?.user?.lastName
        : e?.data?.phoneNumber
          ? e?.data?.phoneNumber
          : phoneFromPayment
            ? phoneFromPayment
            : '--'}{' '}
    </p>
  );
};

const ShowMealTypes = ({ e, mealTypes }) => {
  let filterPoint = mealTypes?.find(
    (mealType) => mealType.id === e?.data?.mealTypeId
  )
    ? mealTypes?.find((mealType) => mealType.id === e?.data?.mealTypeId)[
        'mealtype|reportName|translation'
      ]?.text ||
      mealTypes?.find((mealType) => mealType.id === e?.data?.mealTypeId)[
        'mealtype|display|translation'
      ]?.text
    : undefined;

  let filter = mealTypes?.find(
    (mealType) =>
      mealType.id === e?.data?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
  )
    ? mealTypes?.find(
        (mealType) =>
          mealType.id === e?.data?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
      )['mealtype|reportName|translation']?.text ||
      mealTypes?.find(
        (mealType) =>
          mealType.id === e?.data?.mealDish?.[0]?.mealDish?.meal?.mealTypeId
      )['mealtype|display|translation']?.text
    : undefined;
  return <p>{filterPoint || filter}</p>;
};

const ViewMore = ({ e, handleviewmore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleviewmore(e.data)} />
    </div>
  );
};

const Price = ({ e }) => {
  return (
    <p>
      {e?.data?.point
        ? e?.data?.point
        : e.data?.paymentMethod === 'Wallet'
          ? e?.data?.orderPrice
          : '₪' + e?.data?.orderPrice}
    </p>
  );
};

const RealizeDate = ({ e }) => {
  return (
    <p>
      {e?.data?.realizeDate
        ? e?.data?.realizeDate?.split('T')?.[0] +
          ' ' +
          e?.data?.realizeDate?.split('T')?.[1]?.split(':')[0] +
          ':' +
          e?.data?.realizeDate?.split('T')?.[1]?.split(':')[1]
        : ''}
    </p>
  );
};

const OrderDate = ({ e }) => {
  return (
    <>
      <div className="order-with-status">
        <div className="orderId">
          <p>{e?.data?.id ?? ''}</p>
        </div>
        <p>
          {e?.data?.createdAt
            ? formatDate(new Date(e?.data?.createdAt), true)
            : ''}
        </p>
      </div>
    </>
  );
};

const OrderStatus = ({ e, t }) => {
  return (
    <div className="order-with-status">
      <div className="action-btn realized-button">
        {e.data.point ? (
          e.data.status === true ? (
            <DisabledButton label={t('approvedPointMsg')} />
          ) : (
            <DisabledButton label={t('pending')} />
          )
        ) : (
          e.data.orderStatusId === 1 && <DisabledButton label={t('pending')} />
        )}
        {e.data.orderStatusId === 2 && <DisabledButton label={t('realized')} />}
        {e.data.orderStatusId === 3 && <span>{t('canceled')}</span>}
      </div>
    </div>
  );
};
const MealTime = ({ e, formatDate }) => {
  const date = moment(
    e?.data?.mealDish?.[0]?.mealDish?.meal?.date
      ? e?.data?.mealDish?.[0]?.mealDish?.meal?.date
      : e?.data?.meal?.date
  );
  const dayOfWeekText = date.format('dddd');
  return (
    <div className="flex flex-column m-0">
      <p className="m-0">
        {formatDate(
          new Date(
            e?.data?.mealDish?.[0]?.mealDish?.meal?.date
              ? e?.data?.mealDish?.[0]?.mealDish?.meal?.date
              : e?.data?.meal?.date
          )
        )}
      </p>
      <p className="m-0"> {`${dayOfWeekText}`}</p>
    </div>
  );
};
const Quantity = ({ e }) => {
  let dataArray = e?.data?.mealDish ? e?.data?.mealDish[0].mealDish.dishes : [];
  let finalData = 0;
  if (dataArray && dataArray?.length > 0) {
    finalData = dataArray.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0);
  }
  return <p> {finalData}</p>;
};
const DishName = ({ e, t, currentLanguage }) => {
  let dataArray = e?.data?.mealDish ? e?.data?.mealDish[0].mealDish.dishes : [];
  let finalData = [];
  if (dataArray && dataArray?.length > 0) {
    finalData = dataArray?.map((ele) => {
      return `${ele.translations[currentLanguage.id]}`;
    });
  }
  if (e?.data?.point) {
    return t('pendingCollectedPoint');
  }

  return (
    <p> {e?.data?.point ? finalData?.toString() : finalData?.join(', ')}</p>
  );
};

const ActionButton = ({ e, t, handleOpen, roleId }) => {
  return (
    <div className="order-with-status">
      <div className="payment-status">
        {/* <p>{e?.data?.point ? '' : e?.data?.id}</p> */}
        <p>
          {e?.data?.point && e?.data?.status
            ? e?.data?.updatedAt
              ? formatDate(new Date(e?.data?.updatedAt), true)
              : ''
            : e?.data?.realizeDate
              ? formatDate(new Date(e?.data?.realizeDate), true)
              : ''}
        </p>
      </div>
      <div className="action-btn realized-button">
        {e.data.orderStatusId === 2 ? (
          <DisabledButton label={t('realized')} />
        ) : e.data.orderStatusId === 1 ? (
          roleId === 9 || roleId === 10 ? (
            'Pending'
          ) : (
            <button
              className="edit-btn"
              onClick={() => {
                handleOpen(
                  e.data.hash,
                  e.data.mealDish[0].mealDish.mealId,
                  e.data.id
                );
              }}
            >
              {t('realizeOrder')}
            </button>
          )
        ) : e.data.orderStatusId === 3 ? (
          t('notRealized')
        ) : (
          <DisabledButton label={t('pendingCollectedPoint')} />
        )}
      </div>
    </div>
  );
};

const IncludeSalaryFile = ({ e, t, goToSalaryReportPage }) => {
  return (
    <div className="paymentStatus-section">
      {e?.data?.point > 0 ? (
        e?.data?.reported ? (
          <>
            <span
              onClick={() => goToSalaryReportPage({ e })}
              className="link-css"
            >
              {moment(e?.data?.updatedAt).format('DD/MM/YYYY HH:mm')}
            </span>
            <div className="order-status success">
              <i className="fa fa-circle" />
              <span>{t('success')}</span>
            </div>
          </>
        ) : (
          <>
            <div className="order-status pending">
              <i className="fa fa-circle" />
              <span>{t('pending')}</span>
            </div>
          </>
        )
      ) : e?.data?.reported ? (
        <>
          <span
            onClick={() => goToSalaryReportPage({ e })}
            className="link-css"
          >
            {moment(e?.data?.updatedAt).format('DD/MM/YYYY HH:mm')}
          </span>
          <div className="order-status success">
            <i className="fa fa-circle" />
            <span>{t('success')}</span>
          </div>
        </>
      ) : (
        <>
          <div className="order-status pending">
            <i className="fa fa-circle" />
            <span>{t('pending')}</span>
          </div>
        </>
      )}
    </div>
  );
};

const PaymentIndication = ({ e, t }) => {
  const dateNow = new Date();

  let expDate = new Date();

  if (e?.data && e?.data?.mealDish) {
    expDate = new Date(
      e?.data?.mealDish[0]?.mealDish?.meal?.date +
        'T' +
        e?.data?.mealDish[0]?.mealDish?.meal?.mealType?.to
    );
  }
  const isExp = expDate < dateNow;
  return (
    <div className="paymentStatus-section">
      <div className="payment-status">
        <p>
          {' '}
          {e?.data?.paymentOptionId === 5
            ? t('wallet')
            : e?.data?.paymentOptionId === 4
              ? t('paymentOption-salary')
              : e?.data?.paymentOptionId === 3
                ? t('paymentOption-creditCard')
                : e?.data?.paymentOptionId === 6
                  ? t('paymentOption-cibus')
                  : e?.data?.paymentMethod === 'Invoice'
                    ? t('invoice')
                    : '--'}
        </p>
      </div>
      {e.data?.paymentMethod === 'Cibus' ? (
        e?.data?.paymentStatus ? (
          <div className="order-status success">
            <i className="fa fa-circle" />
            <span>{t('success')}</span>
          </div>
        ) : (
          <div className="order-status pending">
            <i className="fa fa-circle" />
            <span>{t('pending')}</span>
          </div>
        )
      ) : e.data?.paymentMethod === 'Invoice' ? (
        e?.data?.paymentStatus && e?.data?.orderStatusId !== 3 ? (
          <div className="order-status success">
            <i className="fa fa-circle" />
            <span>{t('success')}</span>
          </div>
        ) : e?.data?.orderStatusId !== 3 ? (
          <div className="order-status pending">
            <i className="fa fa-circle" />
            <span>{t('pending')}</span>
          </div>
        ) : (
          <div className="order-status failed">
            <i className="fa fa-circle" />
            <span>{t('canceled')}</span>
          </div>
        )
      ) : e?.data?.point ? (
        e?.data?.status ? (
          e?.data?.reported ? (
            <div className="order-status success">
              <i className="fa fa-circle" />
              <span>{t('success')}</span>
            </div>
          ) : (
            <div className="order-status success">
              <i className="fa fa-circle" />
              <span>{t('isAproved')}</span>
              {/*<span>{moment(e?.data?.updatedAt).format('HH:mm')}</span>*/}
            </div>
          )
        ) : (
          <div className="order-status pending">
            <i className="fa fa-circle" />
            <span>{t('pending')}</span>
          </div>
        )
      ) : e.data?.paymentMethod === 'Salary' &&
        e?.data?.paymentOptionId !== 3 ? (
        e?.data?.reported && e.data.orderStatusId !== 3 ? (
          <div className="order-status success">
            <i className="fa fa-circle" />
            <span>{t('success')}</span>
          </div>
        ) : (
          <>
            {e.data.orderStatusId !== 3 && isExp ? (
              <div className="order-status success">
                <i className="fa fa-circle" />
                <span>{t('isAproved')}</span>
              </div>
            ) : e.data.orderStatusId !== 3 || !e.data.paymentStatus ? (
              <div className="order-status pending">
                <i className="fa fa-circle" />
                <span>{t('pending')}</span>
              </div>
            ) : (
              <div className="order-status failed">
                <i className="fa fa-circle" />
                <span>{t('canceled')}</span>
              </div>
            )}
          </>
        )
      ) : e?.data?.paymentStatus ? (
        <div className="order-status success">
          <i className="fa fa-circle" />
          <span>{t('success')}</span>
        </div>
      ) : (
        <div className="order-status pending">
          <i className="fa fa-circle" />
          <span>{t('pending')}</span>
        </div>
      )}
    </div>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = {};

  if (!prefetchData || !Array.isArray(prefetchData)) {
    prefetchData = [];
  }

  jsondataValue = prefetchData?.filter((el: any) => el.id === jsonData);
  const formatter =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.chargeResponse || '{}'))
      : '';
  let element = document.getElementById('json');

  if (element && formatter !== '') {
    element.innerHTML = '';
    element.appendChild(formatter.render());
  }

  return (
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data reservation-popup ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <h6>{t('updatedData')}</h6>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn" id="json"></div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  totalReservations: state.kitchens.totalReservations,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  currentUser: state.user,
  companyName: state.companyConfig.companyName,
  attendanceEnabled: state.companyConfig.menuSettings.attendanceEnabled,
  OrderStatuses: state.app.enums.OrderStatus,
  PaymentOption: state.app.enums.PaymentOption,
  receipts: state.app.receipts,
  Roles: state.app.enums.Role,
});

const mapDispatchToProps = {
  getReservations,
  getReportTypeReservations,
  getMealTypeReservations,
  setLayoutOptions,
  useFetchingLoader,
  getPaymentPreferences,
  getReceipts,
};

// export default ReservationManagement;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationManagement);
