import React from 'react';

import Chart from 'react-google-charts';
// Link https://react-google-charts.com/

const PieChart = ({ title = '', data, size, colors }) => {
  return (
    <>
      {data && (
        <Chart
          // width={`${size.w}px`}
          // height={`${size.h}px`}
          chartType="PieChart"
          data={[['', ''], ...data]}
          options={{
            title,
            legend: 'none',
            backgroundColor: 'transparent',
            colors: [...colors, '#a6bcd0'],
          }}
          // rootProps={{ 'data-testid': '1' }}
        />
      )}
    </>
  );
};

const BarChart = ({ title = '', data, size }) => {
  return (
    <>
      {data && (
        <Chart
          //  width={`${size.w}px`}
          //  height={`${size.h}px`}
          chartType="ColumnChart"
          data={[['', '', { role: 'style' }], ...data]}
          options={{
            title,
            legend: 'none',
            backgroundColor: 'transparent',
            height: 400,
            hAxis: {
              title: 'Dish name',
            },
            vAxis: {
              title: 'Number of reservations',
            },
          }}
          // rootProps={{ 'data-testid': '1' }}
        />
      )}
    </>
  );
};

const LineChart = ({ title = '', data, size, colors }) => {
  return (
    <>
      {data && (
        <Chart
          // width={`${size.w}px`}
          // height={`${size.h}px`}

          chartType="LineChart"
          data={[['', ''], ...data]}
          options={{
            connectNullData: true,
            title,
            legend: 'none',
            backgroundColor: 'transparent',
            colors: [...colors, '#a6bcd0'],
            height: 400,
            hAxis: {
              title: 'Date',
            },

            vAxis: {
              title: 'Review score',
            },
          }}
          // rootProps={{ 'data-testid': '1' }}
        />
      )}
    </>
  );
};

export { PieChart, LineChart, BarChart };
