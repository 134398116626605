import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import '../../style/myOrders.scss';
import menu1 from '../../assets/newimages/category1.svg';
import banner from '../../assets/newimages/banner.svg';
import { DynamicStars } from '../../components/RatingStars';
import defaultImage from '../../assets/images/defaultImage.png';
import enums from '../../services/enums';
import chefDishImg from '../../assets/images/chef_mealDish.png';
import plusIcon from '../../assets/newimages/plus-icon.svg';
import minusIcon from '../../assets/newimages/minus-icon.svg';
import http from '../../services/http';
import { DialogWithInput } from '../../components/Dialogs/SwalDialogs';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MuiButton from '@mui/material/Button';

const DishPopup = ({
  dishPopup,
  handleDishPopup,
  orderDetails,
  myOrder,
  t,
  orderData,
  paymentOption,
  multipleDish,
  mealDishArray,
  setMealDishArray,
  setCafeteriaiMealDish,
  setUpdate,
  user,
  update,
  onCreateOrder,
  filterMeal,
  removeOrder,
  editPerm,
}) => {
  const dishCategory = enums.display(
    'DishCategory',
    orderDetails?.dish?.dishCategoryId
  );

  const [quantity, setQuantity] = useState(1);

  const handleOrder = () => {
    setQuantity(1);
    myOrder(
      orderDetails?.dish?.id,
      orderDetails?.mealId,
      orderDetails?.id,
      orderDetails?.dish?.price,
      quantity
    );
    setConfirmPopup(true);
  };
  const [value, setValue] = useState();
  const [oneTimePopup, setOneTimePopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  useEffect(() => {
    let ddd = orderData?.find((el) => el?.dishId === orderDetails?.dishId);
    setValue(ddd);
  });

  const openInventoryModal = (quantity) => {
    // setMyOrdersPopup(true);
    //setOpenQuantityModel(true)
    DialogWithInput({
      title: `${t('enterQuantityFor')} ${orderDetails?.dish?.name}`,
      type: 'number',
      onConfirm: async (event) => {
        const prev = [...mealDishArray];
        const foundElem = prev.find((el) => el.mealDishId === orderDetails?.id);
        foundElem.quantity = Number(
          event.value ? (event.value > 0 ? event.value : quantity) : quantity
        );
        foundElem.price = orderDetails?.dish?.price;
        let array = [];
        mealDishArray &&
          mealDishArray.map(async (el, i) => {
            const mealDishData = await http.get(`mealsDishes/${el.mealDishId}`);
            mealDishData['quantity'] = el.quantity;
            array.push(mealDishData);
            setCafeteriaiMealDish(array);
          });
        setQuantity(
          Number(
            event.value ? (event.value > 0 ? event.value : quantity) : quantity
          )
        );
        setMealDishArray(mealDishArray);
        setUpdate(true);
      },
      buttons: {
        confirmButtonText: t('saveInfo'),
        cancelButtonText: t('back'),
      },
    });
  };
  const handleIncrease = () => {
    const prev = [...mealDishArray];
    const foundElem = prev.find((el) => el.mealDishId === orderDetails?.id);
    if (foundElem) {
      foundElem.quantity = foundElem?.quantity ? foundElem?.quantity + 1 : 2;

      foundElem.price = orderDetails?.dish?.price;

      let array = [];
      mealDishArray &&
        mealDishArray.map(async (el, i) => {
          const mealDishData = await http.get(`mealsDishes/${el.mealDishId}`);
          mealDishData['quantity'] = el.quantity;

          array.push(mealDishData);
          setCafeteriaiMealDish(array);
        });
      setQuantity(foundElem?.quantity);
      setMealDishArray(mealDishArray);
      setUpdate(true);
    } else {
      setQuantity((prev) => prev + 1);
    }
  };

  const handleDecrease = () => {
    const prev = [...mealDishArray];
    const foundElem = prev.find((el) => el.mealDishId === orderDetails?.id);
    if (foundElem) {
      foundElem.quantity = foundElem?.quantity ? foundElem?.quantity - 1 : 2;
      foundElem.price = orderDetails?.dish?.price;
      let array = [];
      mealDishArray &&
        mealDishArray.map(async (el, i) => {
          const mealDishData = await http.get(`mealsDishes/${el.mealDishId}`);
          mealDishData['quantity'] = el.quantity;

          array.push(mealDishData);
          setCafeteriaiMealDish(array);
        });
      setQuantity(foundElem?.quantity);
      setMealDishArray(mealDishArray);
      setUpdate(true);
    } else {
      setQuantity((prev) => {
        if (prev > 1) {
          return prev - 1;
        }
        return prev;
      });
    }
  };
  const handleOnCreateOrder = async () => {
    const res = await http.get(`users/getUserKitchen/${user.id}`);
    onCreateOrder(res, filterMeal);
    setQuantity(1);
  };
  const closePopup = () => {
    setQuantity(1);
    removeOrder(orderDetails?.id);
    setConfirmPopup(false);
    setOneTimePopup(true);
  };
  return (
    <>
      <Modal show={dishPopup} onHide={handleDishPopup} className="dishModal">
        <Modal.Header closeButton></Modal.Header>
        <ModalBody>
          <div className={`menu-category-meat-box`}>
            <div className={`meat-box-img`}>
              <img
                src={
                  orderDetails?.dish?.image
                    ? orderDetails?.dish?.image == 'h' ||
                      orderDetails?.dish?.image === 'null'
                      ? defaultImage
                      : orderDetails?.dish?.image
                    : defaultImage
                }
                alt=""
              />
              {orderDetails?.isChefMealDish ? (
                <img
                  src={chefDishImg ? chefDishImg : ''}
                  alt=""
                  className="chef-img"
                />
              ) : (
                ''
              )}
            </div>
            <div className="meat-box-detail">
              <div className="meat-box-text">
                <h3>{orderDetails?.dish?.name}</h3>
                <span className="description">
                  {orderDetails?.dish?.description}
                </span>
                <span className="category">
                  {dishCategory && dishCategory}﹒
                  {orderDetails?.dish?.kitchen?.name}
                </span>
              </div>
              <div
                className={
                  ((user.roleId === 4 ||
                    user.roleId === 6 ||
                    user.roleId === 8) &&
                    !multipleDish &&
                    orderDetails?.dish?.price) ||
                  multipleDish
                    ? 'meat-box-price'
                    : 'meat-box-price justify-content-end'
                }
              >
                {(user.roleId === 4 ||
                  user.roleId === 6 ||
                  user.roleId === 8) &&
                !multipleDish &&
                orderDetails?.dish?.price ? (
                  paymentOption == 4 ? (
                    ''
                  ) : (
                    <div className="dishInfo-inner">
                      <span>
                        {paymentOption == 5
                          ? ''
                          : orderDetails?.dish?.price !== 0
                            ? '₪'
                            : ''}
                        {orderDetails?.dish?.price}{' '}
                      </span>
                    </div>
                  )
                ) : (
                  ''
                )}

                {multipleDish ? (
                  <div className="myorder-info-section">
                    <div className="myorder-price-section">
                      <div className="myorder-quantity">
                        <>
                          <Button
                            onClick={() => handleDecrease()}
                            className={`remove-quantity ${
                              quantity === 1 ? 'disable-value' : ''
                            }`}
                          >
                            <img src={minusIcon} alt="" />
                          </Button>
                          <span>{quantity}</span>
                          <Button onClick={() => handleIncrease()}>
                            <img src={plusIcon} alt="" />
                          </Button>
                        </>
                      </div>
                      {(user.roleId === 4 ||
                        user.roleId === 6 ||
                        user.roleId === 8) &&
                        orderDetails?.dish?.price !== 0 &&
                        (paymentOption == 4 ? (
                          ''
                        ) : (
                          <h6>
                            {paymentOption == 5 ? '' : ' ₪'}
                            {orderDetails?.dish?.price * quantity}
                          </h6>
                        ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="meat-box-ratings-dish-popup">
                  <div className="meat-box-rating">
                    <DynamicStars value={orderDetails?.dish?.rating} />
                  </div>

                  <div className="meat-box-raters">
                    <span>
                      ({t('raters')} {orderDetails?.dish?.numOfRaters})
                    </span>
                  </div>
                </div>
              </div>

              <Button
                className="continue-btn"
                onClick={handleOrder}
                disabled={value?.dishId ? true : false}
              >
                {value?.dishId ? (
                  <span>{t('alreadyAdded')} </span>
                ) : (
                  <span>{t('addToOrder')} </span>
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={confirmPopup && !oneTimePopup && multipleDish && !editPerm}
        onHide={() => closePopup()}
        centered
      >
        <Modal.Header className="m10" closeButton>
          <p className="swal2-title center">{t('orderDetails')}</p>
        </Modal.Header>
        <ModalBody className="m10">
          <p className="swal2-html-container text-center">{t('backOrEdit')}</p>
        </ModalBody>
        <Modal.Footer className="flex-center m10">
          <Button
            onClick={() => {
              setConfirmPopup(false);
              setOneTimePopup(true);
            }}
          >
            {t('continue')}
          </Button>

          <Button
            className="continue-btn-custom"
            onClick={() => {
              setConfirmPopup(false);
              handleOnCreateOrder();
            }}
          >
            {t('checkout')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DishPopup;
