import React, { useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployee,
  validateEmployeenew,
  validateUserWallet,
  validateUserWalletNew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '20px 30px',
  p: 4,
  borderRadius: '5px',
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        // disabled={!editMode}
        type={type}
      />
    </>
  );
};

const UpdateWalletPopup = ({
  open,
  handleClose,
  Roles,
  Kitchens,
  UserStatuses,
  isUpdate,
  setKitchensIds,
  kitchensIds,
  t,
  errors,
  setErrors,
  user,
  openWallet,
  handleWalletClose,
  setWalletPayload,
  walletPayload,
  handleWalletUpdateUser,
}) => {
  const KitchensMap = Kitchens.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const RolesMap_ValueId = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});

  const [role] = useState(4);
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });

  const handleChange = (name, value) => {
    const { isValid, error } = validateUserWallet(value, name);
    setErrors({ ...errors, ...error });
    setWalletPayload({ ...walletPayload, [name]: value });
  };

  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',
      borderRadius: '5px',
    }),
  };

  const handleWalletFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      id: walletPayload.id,
      amount: walletPayload.amount,
    };

    const { isValid, error } = validateUserWalletNew(details);
    setErrors({ ...errors, ...error });
    if (isValid) {
      await handleWalletUpdateUser(details);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div className="user-popup-heading">
          <h6>{t('UpdateWalletAMount')}</h6>
          <div className="close-button">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
        </div>
        <Typography id="modal-modal-description">
          <div className="input-field">
            <GetInputField
              placeholder={t('walletAmount')}
              label={t('walletAmount')}
              name="amount"
              type="number"
              value={walletPayload['amount']}
              cb={(value) => {
                handleChange('amount', value);
              }}
            />
            {errors && (
              <p className="error-msg">{errors.amount || errors.amount}</p>
            )}
          </div>
          <div className="add-employee-btn">
            {/* <Button onClick={handleWalletUpdateUser}>{t('update')}</Button> */}
            <Button onClick={handleWalletFormSubmit}>{t('update')}</Button>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default UpdateWalletPopup;
