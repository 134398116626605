import { AgGridReact } from 'ag-grid-react';
import { useFetchingLoader } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getReportTypeReservations } from '../../store/kitchens/actions';
import agGridLocales from '../../translations/ag-grid';
import { setLayoutOptions } from '../../store/app/actions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import JSONFormatter from 'json-formatter-js';
import cancel from '../../assets/images/close.svg';
import search from '../../assets/icons/search.png';
import next from '../../assets/images/next.png';
import { useLocation } from 'react-router-dom';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import Pagination from 'react-bootstrap/Pagination';
import http from '../../services/http';
import ViewReservationdetails from './viewReservationdetails';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import Toast from 'react-bootstrap/Toast';
import { useHistory } from 'react-router-dom';
import Excel from 'exceljs';

import { Modal, Box, styled, Typography, Paper } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// interface CustomizedState {
//   mealDate:string,
//   mealTypeId:string
// }
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: 1200,
  width: '100%',
  height: '100vh',
  minHeight: '82.5vh',
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const ReservationByUser = ({
  kitchen,
  reservations,
  getReportTypeReservations,
  useFetchingLoader,
  getPaymentPreferences,
  totalReservations,
  currentLanguage,
  MealTypes,
  setLayoutOptions,
  options,
  currentUser,
  companyName,
  fieldNamesSettings,
}) => {
  const { t, i18n } = useTranslation();
  const location = useLocation<any>();
  //const state = location.state as CustomizedState;

  const [reserData, setReserData] = useState([{}]);
  const [viewData, setViewData] = useState(false);

  const [gridApi, setGridApi] = useState<any>();
  const [, setColumnApi] = useState<any>();
  const [fromDate, setFromdate] = useState<any>(new Date());
  const [fromDateFormat, setfromDateFormat] = useState<any>();
  const [toDateFormat, settoDateFormat] = useState<any>();
  const [toDate, setToDate] = useState<any>(new Date());
  const [jsonData, setJsondata] = useState();
  const [tableColumn, setTableColumn] = useState<any>();
  const [viewDetails, setViewDetails] = useState<any>();
  const { mealDate } = location?.state ?? {};
  const [filterOrder, setFilterData] = useState<any>();
  const [showPaymentOption, setShowPaymentOption] = useState();
  const [searchText, setSearchText] = useState<any>('');
  const [roleId, setRoleId] = useState<string>('');
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs] = useState(50); // Records per page
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [columnAndOrder, setColumnAndOrder] = useState<any>({});
  const [reportType] = useState<any>('TotalUser');
  const [openDownload, setOpenDownload] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const [kitchenID, setKitchenID] = useState<number | string>(
    currentUser?.KitchenId[0]
  );
  const [isOpenReportsHistory, setIsOpenReportHistory] = useState(false);
  const [reportsHistory, setReportsHistory] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (kitchen) {
      // useFetchingLoader(async () => {
      const data = getPaymentPreferences(kitchen?.id, '');
      setShowPaymentOption(data.selectedPaymentOptions);
      // });
    }
  }, [kitchen]);
  useEffect(() => {
    setLayoutOptions({
      title: t('totalsByUsers'),
    });
    if (reservations && !filterOrder) {
      gridApi?.setRowData(reservations);
      setReserData(reservations);
    } else {
      let filter = reservations.filter(
        (el: any) =>
          el?.orderStatusId === 1 ||
          el?.orderStatusId === 2 ||
          el?.orderStatusId === 3
      );
      setReserData(filter);
      gridApi?.setRowData(filter);
    }
  }, [reservations]);
  const lang = localStorage.getItem('language');
  const fetchDataHistory = async () => {
    const data = await http.get('activityLogs/getByParams', {
      companyId: 1,
      tableName: 'reservationByUser',
      kitchenId: kitchen.id,
    });
    setReportsHistory(data);
  };
  useEffect(() => {
    fetchDataHistory();
  }, [isOpenReportsHistory]);
  const columnTotalUserDefs = [
    {
      field: 'firstName',
      headerName: `${t('firstNamePH')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.firstName ? `${params?.data?.firstName}` : '--';
      },
    },
    {
      field: 'lastName',
      headerName: `${t('lastNamePH')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.lastName ? `${params?.data?.lastName}` : '--';
      },
    },
    {
      field: 'cardNumber',
      headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
        ? fieldNamesSettings
            ?.find((el) => el.value === 'cardId')
            ?.translations.find((el) => el.language.shortCode == i18n.language)
            ?.text
        : t('cardId'),
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.cardNumber &&
          `${params?.data?.cardNumber}` != 'null'
          ? `${params?.data?.cardNumber}`
          : '--';
      },
    },
    {
      field: 'employeeNumber',
      headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
        ? fieldNamesSettings
            .find((el) => el.value === 'employeeId')
            .translations?.find((el) => el.language.shortCode == i18n.language)
            ?.text
        : t('employeeId'),
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.employeeNumber &&
          `${params?.data?.employeeNumber}` != 'null'
          ? `${params?.data?.employeeNumber}`
          : '--';
      },
    },
    {
      field: 'userType',
      headerName: ` ${t('userType')}`,
      cellRenderer: (params) => {
        return params?.data?.userType && `${params?.data?.userType}` != 'null'
          ? `${params?.data?.userType}`
          : '--';
      },
    },
    {
      field: 'contractorName',
      headerName: t('contractorName'),
      sortable: false,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.order?.user.contractors &&
          params?.data?.order?.user.contractors.length
          ? `${params?.data?.order?.user.contractors
              .map((elem) => elem.name)
              .join(', ')}`
          : '--';
      },
    },
    {
      field: 'totalReservation',
      headerName: ` ${t('totalReservation')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.totalReservation &&
          `${params?.data?.totalReservation}` != 'null'
          ? `${params?.data?.totalReservation}`
          : '--';
      },
    },
    {
      field: 'totalRealized',
      headerName: ` ${t('totalRealized')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.totalRealized &&
          `${params?.data?.totalRealized}` != 'null'
          ? `${params?.data?.totalRealized}`
          : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  const mobcolumnTotalUserDefs = [
    {
      field: 'firstName',
      headerName: `${t('firstNamePH')}`,
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.firstName ? `${params?.data?.firstName}` : '--';
      },
    },
    {
      field: 'lastName',
      headerName: `${t('lastNamePH')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.lastName ? `${params?.data?.lastName}` : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservations / newPageSizeLogs));
    }
  }, [reservations]);

  useEffect(() => {
    if (reservations) {
      setpageCountsLogs(Math.ceil(totalReservations / newPageSizeLogs));
    }
  }, []);

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const fetchData = async (
    fromDateParam: any = undefined,
    toDateParam: any = undefined,
    kitchenId: any = undefined,
    roleIdParam: any = undefined
  ) => {
    setKitchenID((kitchenIDprev) => {
      useFetchingLoader(async () => {
        await getReportTypeReservations(
          kitchenId ?? kitchenIDprev ?? kitchen?.id,
          moment(fromDateParam || fromDate).format('YYYY-MM-DD'),
          moment(toDateParam || toDate).format('YYYY-MM-DD'),
          currentPageAllLogs,
          newPageSizeLogs,
          roleIdParam || roleIdParam === ''
            ? roleIdParam
            : roleId
              ? roleId
              : undefined,
          searchText ? searchText : undefined,
          'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
          'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
          currentUser.KitchenId
        );
      });
      return kitchenId ?? kitchenIDprev;
    });
  };
  const handleReset = () => {
    setColumnAndOrder({});
    setSearchText('');
    setToDate(moment(new Date()).format('YYYY-MM-DD'));
    setFromdate(moment(new Date()).format('YYYY-MM-DD'));
    setfromDateFormat(new Date());
    settoDateFormat(new Date());
    setRoleId('');
    setKitchenID(kitchen.id);
    setcurrentPageAllLogs(1);
    history.replace('/reservationByUser', {});
  };
  useEffect(() => {
    if (location?.state?.userType) {
      setFromdate(location?.state?.fromDate);
      setfromDateFormat(new Date(location?.state?.fromDate));
      settoDateFormat(new Date(location?.state?.toDate));
      setToDate(location?.state?.toDate);
      setKitchenID(location?.state?.kitchenId);
      setRoleId(location?.state?.userType);
      fetchData(
        location?.state?.fromDate,
        location?.state?.toDate,
        location?.state?.kitchenId,
        location?.state?.userType
      );
    } else {
      fetchData();
    }
  }, [columnAndOrder, currentPageAllLogs]);

  const handleChange = async (name: any, value: any) => {
    setFilterData(false);
    if (name === 'fromdate') {
      if (!value) {
        setFromdate(null);
      }
      setfromDateFormat(value);
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        setToDate(null);
      }
      const tempToDate = new Date(value);
      settoDateFormat(value);
      setToDate(tempToDate);
    }
    /*if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnTotalUserDefs);
    } else {
      setTableColumn(columnTotalUserDefs);
    }*/
  };
  const handleApplyfilter = async (fromDate: any, tempToDate: any) => {
    setcurrentPageAllLogs(1);
    useFetchingLoader(async () => {
      await getReportTypeReservations(
        kitchenID ?? kitchen?.id,
        moment(fromDate).format('YYYY-MM-DD'),
        toDate
          ? moment(toDate).format('YYYY-MM-DD')
          : moment(tempToDate).format('YYYY-MM-DD'),
        currentPageAllLogs,
        newPageSizeLogs,
        roleId ? roleId : undefined,
        searchText ? searchText : undefined,
        'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
        'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        currentUser.KitchenId
      );
    });
  };

  const handleOpen = async (hash: any, mealId: any, id: any) => {
    const getMealData = await http.get(`meals/${mealId}`);
    if (
      new Date(getMealData?.date).setHours(0, 0, 0, 0) <=
      new Date().setHours(0, 0, 0, 0)
    ) {
      Dialog({
        title: t('confirmModalTitle'),
        type: 'success',
        buttons: {
          showCancelButton: true,
          cancelButtonText: t('cancel'),
          confirmButtonText: t('confirm'),
        },
        onConfirm: () => {
          const getData = async () => {
            try {
              const realizeOrder = await http.post(`orders/realizeByHash`, {
                hash,
                mealId,
              });
              if (
                realizeOrder.paymentOptionId === 6 &&
                realizeOrder.paymentStatus
              ) {
                Dialog({
                  title: t('paymentSuccessMessage'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {},
                });
              }
              if (realizeOrder.orderStatusId === 2) {
                Dialog({
                  title: t('realizedOrderText'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    setReserData((prev: any) => {
                      let prevArr = [...prev];
                      const findIndex = prevArr.findIndex((el) => el.id === id);
                      if (findIndex !== -1) {
                        prevArr[findIndex].orderStatusId = 2;
                        prevArr[findIndex].realizeDate = moment(
                          new Date()
                        ).format('YYYY-MM-DD HH:mm:ss');
                      }
                      return [...prevArr];
                    });
                  },
                });
              }
            } catch (error) {
              Dialog({
                title: error,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  confirmButtonText: t('confirm'),
                },
                onConfirm: () => {},
              });
              window.gtag('event', 'Realize_order', {
                title: 'realize-error',
                client: window.location.host.split('.')[0],
              });
              return error;
            }
          };
          getData();
        },
      });
    } else {
      Dialog({
        title: t('realizeError'),
        type: 'error',
        buttons: {
          showCancelButton: true,
          cancelButtonText: t('cancel'),
          confirmButtonText: t('redemption'),
        },
        onConfirm: () => {
          const getData = async () => {
            try {
              const realizeOrder = await http.post(`orders/realizeByHash`, {
                hash,
                mealId,
              });
              if (
                realizeOrder.paymentOptionId === 6 &&
                realizeOrder.paymentStatus
              ) {
                Dialog({
                  title: t('paymentSuccessMessage'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {},
                });
              }
              if (realizeOrder.orderStatusId === 2) {
                Dialog({
                  title: t('realizedOrderText'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    confirmButtonText: t('confirm'),
                  },
                  onConfirm: () => {
                    setReserData((prev: any) => {
                      let prevArr = [...prev];
                      const findIndex = prevArr.findIndex((el) => el.id === id);
                      if (findIndex !== -1) {
                        prevArr[findIndex].orderStatusId = 2;
                        prevArr[findIndex].realizeDate = moment(
                          new Date()
                        ).format('YYYY-MM-DD HH:mm:ss');
                      }
                      return [...prevArr];
                    });
                  },
                });
              }
            } catch (error) {
              Dialog({
                title: error,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  confirmButtonText: t('confirm'),
                },
                onConfirm: () => {},
              });
              window.gtag('event', 'Realize_order', {
                title: 'realize-error',
                client: window.location.host.split('.')[0],
              });
              return error;
            }
          };
          getData();
        },
      });
    }
  };
  /***********Export CSV file */
  const onBtnExport = async (exportCsvFileNames) => {
    try {
      if (disableButton || reservations.length === 0) {
        console.log('Disabled or empty table', exportCsvFileNames);
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'firstName',
          'lastName',
          fieldNamesSettings?.find((el) => el.value === 'cardId')
            ? fieldNamesSettings
                ?.find((el) => el.value === 'cardId')
                ?.translations.find(
                  (el) => el.language.shortCode == i18n.language
                )?.text
            : 'cardId',
          fieldNamesSettings?.find((el) => el.value === 'employeeId')
            ? fieldNamesSettings
                ?.find((el) => el.value === 'employeeId')
                ?.translations.find(
                  (el) => el.language.shortCode == i18n.language
                )?.text
            : 'employeeId',
          'userType',
          'contractorName',
          'totalReservations',
          'totalRealized',
        ];
        const resArr = arr.map((el) => t(el));
        const filterMealType = MealTypes.filter(
          (el) => el.kitchenId == kitchen.id
        );

        filterMealType.forEach((element) => {
          resArr.push(
            `${
              element['mealtype|reportName|translation']?.text
                ? element['mealtype|reportName|translation']?.text
                : element?.display
            } ${t('reservations')}`
          );
          resArr.push(
            `${
              element['mealtype|reportName|translation']?.text
                ? element['mealtype|reportName|translation']?.text
                : element?.display
            } ${t('realized')}`
          );
        });
        const res = await http.post(
          `kitchens/${kitchenID}/reportTypeReservations/download`,
          {
            isReport: true,
            headers: resArr,
            mealTypeFormat: filterMealType,
            filename: exportCsvFileNames,
          },
          {
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            page: currentPageAllLogs,
            limit: newPageSizeLogs,
            roleId: roleId ? roleId : '',
            findStr: searchText ? searchText : '',
          }
        );
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.addRows(res.data);
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {
          type: 'application/octet-binary',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${exportCsvFileNames}.xlsx`;
        a.click();
        a.remove();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  const handleViewData = (id: any) => {
    setViewData(!viewData);
    setJsondata(id);
  };

  const handleCancelOrder = async (orderId: any, userId: any) => {
    Dialog({
      title: t('confirmModalTitle'),
      type: 'success',
      buttons: {
        showCancelButton: true,
        cancelButtonText: t('cancel'),
        confirmButtonText: t('confirm'),
      },
      onConfirm: async () => {
        try {
          const cancelOrder = await http.put(
            `orders/${orderId}/cancelByAdmin`,
            { userId }
          );
          if (cancelOrder.orderStatusId === 3) {
            SwalDialogs.Dialog({
              title: t('orderWasCanceled'),
              type: 'success',
              buttons: {
                showCancelButton: false,
                confirmButtonText: t('confirm'),
              },
              onConfirm: () => {
                setReserData((prev: any) => {
                  let prevArr = [...prev];
                  const findIndex = prevArr.findIndex(
                    (el) => el.id === orderId
                  );

                  if (findIndex !== -1) {
                    prevArr[findIndex].orderStatusId = 3;
                    // prevArr.splice(findIndex, 1);
                  }
                  return [...prevArr];
                });
              },
            });
            setViewDetails(false);
          }
        } catch (err) {
          SwalDialogs.Dialog({
            title: 'An error has occurred',
            text: `${err}`,
            type: 'error',
            buttons: {
              showCancelButton: false,
              showConfirmButton: false,
            },
          });
        }
      },
    });
  };
  const handleFilterChanged = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnTotalUserDefs);
    } else {
      setTableColumn(columnTotalUserDefs);
    }
  }, [showPaymentOption]);

  const handleviewmore = (data) => {
    setDetails(data);
    setViewDetails(!viewDetails);
  };

  return (
    <>
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter">
              <div className="filter-section">
                <div className="search-filter">
                  {false ? ( // this
                    <div className="xyz">
                      <div className="from-date">
                        <label>{t('fromDate')}</label>
                        <input
                          type="date"
                          defaultValue={moment(
                            new Date(
                              new Date().getFullYear(),
                              new Date().getMonth() - 1,
                              1
                            )
                          ).format('YYYY-MM-DD')}
                          placeholder="dd/mm/yyyy"
                          name="fromDate"
                          onChange={(event) =>
                            handleChange('fromdate', event.target.value)
                          }
                        />
                      </div>
                      <div className="from-date">
                        <label>{t('toDate')}</label>
                        <input
                          type="date"
                          defaultValue={moment(
                            new Date(
                              new Date().getFullYear(),
                              new Date().getMonth(),
                              0
                            )
                          ).format('YYYY-MM-DD')}
                          placeholder="dd/mm/yyyy"
                          name="toDate"
                          onChange={(event) =>
                            handleChange('todate', event.target.value)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="xyz">
                      <div className="from-date">
                        <label>{t('fromDate')}</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={
                            fromDateFormat
                              ? fromDateFormat
                              : mealDate
                                ? new Date(mealDate)
                                : new Date()
                          }
                          disabled={location?.state?.userType}
                          name="fromDate"
                          onChange={(event) => handleChange('fromdate', event)}
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                      <div className="from-date">
                        <label>{t('toDate')}</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={
                            toDateFormat
                              ? toDateFormat
                              : mealDate
                                ? new Date(mealDate)
                                : new Date()
                          }
                          disabled={location?.state?.userType}
                          name="toDate"
                          onChange={(event) => handleChange('todate', event)}
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                  )}
                  <div className="flex-default gap-3 w-100 flex-default-mob">
                    <div className="from-date">
                      <label>{t('userType')}</label>
                      <select
                        name="userType"
                        onChange={(event) => {
                          setRoleId(event.target.value);
                          setcurrentPageAllLogs(1);
                          fetchData(
                            undefined,
                            undefined,
                            undefined,
                            event.target.value
                          );
                        }}
                        value={roleId}
                        disabled={location?.state?.userType}
                      >
                        <option value="">{t('selectUserType')}</option>
                        <option value="1">{t('admin')}</option>
                        <option value="2">{t('siteManager')}</option>
                        <option value="3">{t('kitchenManager')}</option>
                        <option value="4">{t('client')}</option>
                        <option value="6">{t('contractorEmployee')}</option>
                        <option value="7">{t('contractorAdmin')}</option>
                        <option value="8">{t('guestUsers')}</option>
                        <option value="9">{t('kitchenWorker')}</option>
                      </select>
                    </div>
                    <div className="from-date">
                      <label>{t('kitchens')}</label>
                      <select
                        name="kitchens"
                        onChange={(event) => {
                          fetchData(
                            undefined,
                            undefined,
                            event.target.value,
                            undefined
                          );
                          setKitchenID(event.target.value);
                        }}
                        value={kitchenID}
                        disabled={location?.state?.userType}
                      >
                        {(currentUser?.roleId == 1
                          ? options
                          : options?.filter(
                              (el) => currentUser?.KitchenId?.includes(el.id)
                            )
                        ).map((el) => (
                          <option value={el.id}>{el.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex-default flex-default-mob">
                    <div className="search-input searchBtn">
                      <label>{t('search')}</label>
                      <input
                        type="text"
                        id="filter-text-box"
                        placeholder={t('freeTextSearch')}
                        onInput={handleFilterChanged}
                        value={searchText}
                      />
                      <img src={search} alt="search" />
                    </div>
                    <div className="flex-default">
                      {fromDate && toDate ? (
                        <button
                          onClick={() => handleApplyfilter(fromDate, toDate)}
                          className="search-button"
                        >
                          <img src={search} alt="search" />
                        </button>
                      ) : (
                        ''
                      )}
                      <button
                        onClick={() => handleReset()}
                        className="reset-button"
                      >
                        <img src={cancel} alt="search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="active-button mw-172px">
              <div className="total-data">
                <p>
                  {t('totalData')}: <span>{totalReservations}</span>
                </p>
              </div>
              <div className="actions">
                <i
                  style={{ cursor: 'pointer' }}
                  className="las la-file-alt"
                  onClick={() => {
                    onBtnExport(
                      `${companyName}-${
                        MealTypes.filter((el) => el.kitchenId == kitchenID)
                          .length !== 0
                          ? MealTypes.filter(
                              (el) => el.kitchenId == kitchenID
                            )[0]?.kitchen?.name
                          : kitchen.name
                      }-${t('totalByUsers')}-${moment(fromDate).format(
                        'YYYY-MM-DD'
                      )}-${moment(toDate).format('YYYY-MM-DD')}`
                    );
                  }}
                ></i>
                <i
                  style={{ cursor: 'pointer' }}
                  className="las la-book"
                  onClick={() => {
                    setIsOpenReportHistory(true);
                  }}
                ></i>
              </div>
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={
                reservations.length !== 0 && reserData.length !== 0
                  ? reserData.map((reservation) => ({
                      ...reservation,
                      kitchen,
                    }))
                  : []
              }
              onSortChanged={() => printSortStateToConsole()}
              columnDefs={
                tableColumn !== undefined && reservations.length !== 0
                  ? tableColumn
                  : []
              }
              localeText={agGridLocales[i18n.language]}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onGridColumnsChanged={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              frameworkComponents={{
                ViewMore: (e) => (
                  <ViewMore handleviewmore={handleviewmore} e={e} />
                ),
              }}
              reactUi={true}
              enableRtl={lang === '"he"' ? true : false}
              overlayLoadingTemplate={'<p></p>'}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <p>
                  {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                  {newPageSizeLogs * currentPageAllLogs} of {totalReservations}
                </p>
              </div>
              <Pagination>
                <Pagination.First
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(1);
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs > 1
                      ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                      : '';
                  }}
                />

                <Pagination.Item active>
                  Page {currentPageAllLogs} of{' '}
                  {Math.ceil(totalReservations / newPageSizeLogs)}
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs <
                    Math.ceil(totalReservations / newPageSizeLogs)
                      ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                      : '';
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(
                      Math.ceil(totalReservations / newPageSizeLogs)
                    );
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        open={viewData}
        prefetchData={reserData}
        jsonData={jsonData}
        handleClose={handleViewData}
        t={t}
      />
      <Modal
        open={isOpenReportsHistory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={'view-more-popup view-employee-popup'}
        onClose={() => setIsOpenReportHistory(false)}
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <div className="reservation-title">
              <h5>{t('reportsHistory')}</h5>
            </div>
            <div className="close-btn-view">
              <img
                src={cancel}
                alt="close"
                onClick={() => {
                  setIsOpenReportHistory(false);
                }}
              />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className={`view-more-popup`}>
              {reportsHistory && reportsHistory.length > 0 ? (
                <TableContainer
                  component={Paper}
                  className="audit-table-container"
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{`${t('date')} ${t('time')}`}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('user')}</strong>
                        </StyledTableCell>
                        <StyledTableCell className="p-2" width={125}>
                          <strong>{t('message')}</strong>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportsHistory.map((el: any) => (
                        <StyledTableRow
                          key={el.id}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell className="p-2">
                            <p className="audit-details-text">
                              {moment(el?.createdAt).format('YYYY-MM-DD')}
                            </p>
                            <p className="audit-details-text">
                              {moment(el?.createdAt).format('hh:mm:ss  A')}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">
                              {`${el?.userfirstName} ${el?.userlastName}`}
                            </p>
                          </StyledTableCell>
                          <StyledTableCell align="center" className="p-2">
                            <p className="audit-details-text">{el.message}</p>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="d-flex m-0 justify-content-center align-items-center mt-5">
                  <Typography className="history-text-styles fs-5">
                    {t('emptyHistoryMess')}
                  </Typography>
                </div>
              )}
            </div>
          </Typography>
        </Box>
      </Modal>
      <ViewReservationdetails
        open={viewDetails}
        details={details}
        handleClose={handleviewmore}
        reportType={reportType}
        handleOpen={handleOpen}
        reserData={reserData}
        setDetails={setDetails}
        handleCancelOrder={handleCancelOrder}
        currentLanguage={currentLanguage}
        kitchenParam={String(kitchenID)}
        setChangePaymentStatus={() => {}}
        setChangedPaymentParams={() => {}}
        setRefresh={() => {}}
      />
      <Toast
        onClose={() => setOpenDownload(false)}
        show={openDownload}
        style={{
          position: 'sticky',
          right: '20px',
          bottom: '70px',
        }}
      >
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
            onClick={() => setOpenDownload(false)}
          />
        </Toast.Header>
        <Toast.Body>{t('downloadFile')}</Toast.Body>
      </Toast>
    </>
  );
};
const ViewMore = ({ e, handleviewmore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleviewmore(e.data)} />
    </div>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  prefetchData,
  jsonData,
  t,
}) => {
  let jsondataValue = {};
  if (!prefetchData) {
    prefetchData = [];
  }

  jsondataValue = prefetchData?.filter((el: any) => el.id === jsonData);
  const formatter =
    jsondataValue && jsondataValue[0]
      ? new JSONFormatter(JSON.parse(jsondataValue[0]?.chargeResponse || '{}'))
      : '';
  let element = document.getElementById('json');

  if (element && formatter !== '') {
    element.innerHTML = '';
    element.appendChild(formatter.render());
  }

  return (
    <>
      {open ? <div className="bg-shadow" /> : ''}
      <div
        className={`add-employee-popup delete-popup log-json-data reservation-popup ${
          open ? 'open-json-popup' : ''
        }`}
      >
        <div className="close-btn">
          <img src={cancel} alt="close" onClick={handleClose} />
        </div>
        <h6>{t('updatedData')}</h6>
        <div id="modal-modal-description">
          <div className="copy-url">
            <div className="confirmation-btn" id="json"></div>
            <button className="copy-btn" onClick={handleClose}>
              {t('ok')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  reservations: state.kitchens.reservations,
  options: state.kitchens.options,
  currentUser: state.user,
  totalReservations: state.kitchens.totalReservations,
  kitchen: state.kitchens.current,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  MealTypes: state.app.enums.MealType,
  companyName: state.companyConfig.companyName,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = {
  getReportTypeReservations,
  setLayoutOptions,
  useFetchingLoader,
  getPaymentPreferences,
};

// export default ReservationManagement;
export default connect(mapStateToProps, mapDispatchToProps)(ReservationByUser);
