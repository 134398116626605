import react from 'react';

const CookiePopup = ({ cookiePop, handleClose, t }) => {
  return (
    <div className="cookie-popup">
      <div className="cookie-content">
        <p>{t('cookieContent')}</p>
        <div className="cookie-btn">
          <button type="submit" className="accept-btn" onClick={cookiePop}>
            {t('acceptbtn')}
          </button>
          <button type="submit" className="reject-btn" onClick={handleClose}>
            {t('rejectbtn')}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CookiePopup;
