import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import http from '../../services/http';
import dates from '../../services/dates';
import { useTranslation } from 'react-i18next';
import { getAssetUrl } from '../../services/aws';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { StaticStars } from '../../components/RatingStars';
import enums from '../../services/enums';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DishImage from './DishImage';
import { sleep } from '../../services/utils';
import WEIcon from '../../components/WE-IconButton';
import WEBackButton from '../../components/WE-BackButton';
import Layout from '../../components/Layout';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import {
  Alert,
  Dialog,
  SuccessDailog,
} from '../../components/Dialogs/SwalDialogs';
import next from '../../assets/images/next.png';
import ViewWalletDetails from './ViewWalletDetails';
import UpdateWalletPopup from './UpdateWalletPopup';

const initialiState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: 4,
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'created',
};
const initialiWalletState = {
  id: null,
  amount: '',
};
// @ts-ignore
const OrderVoucher = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  fieldNamesSettings,
}) => {
  const [details, setDetails] = useState(null);
  const [username, setuserName] = useState();
  const [empNumber, setEmpNumber] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [data, setData] = useState(null);

  const [openWallet, setOpenWallet] = useState(false);
  const [walletPayload, setWalletPayload] = useState();

  const [kitchensIds, setKitchensIds] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [payload, setPayload] = useState(initialiState);
  const [errors, setErrors] = useState({});
  const [dummy, setDummy] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};

  const { t, i18n } = useTranslation();
  setLayoutOptions({ showKitchen: true });

  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);
  useEffect(() => {
    const fetchData1 = async () => {
      let url = window.location.href;
      let walletId = url.split('?')[1];
      let responseData = '';
      let userWalletInfo = '';
      if (walletId) {
        responseData = await useFetchingLoader(
          async () => await http.get(`users/getWalletHistory/${walletId}`)
        );
        userWalletInfo = await useFetchingLoader(
          async () => await http.get(`users/getUserWalletInfo/${walletId}`)
        );
      }
      if (!walletId && user?.walletId) {
        responseData = await useFetchingLoader(
          async () => await http.get(`users/getWalletHistory/${user?.walletId}`)
        );
        userWalletInfo = await useFetchingLoader(
          async () =>
            await http.get(`users/getUserWalletInfo/${user?.walletId}`)
        );
      }
      setWalletPayload(userWalletInfo);
      let {
        userWalletHistory: data,
        userCredentials: {
          firstName = '',
          lastName = '',
          employeeId = '',
          cardId = '',
        },
      } = responseData || {};
      let fullName = firstName + ' ' + lastName;
      setuserName(fullName);
      setEmpNumber(employeeId);
      setCardNumber(cardId);
      data.sort((b, a) => a.id - b.id);

      if (data) {
        let userWallet = data.map((u, i) => {
          let amount = u?.creditAmount
            ? '+' + u?.creditAmount
            : -u?.debitAmount;
          let creditDateDebitDate = u?.creditDate?.split('T')[0]
            ? u?.creditDate?.split('T')[0]
            : u?.debitDate?.split('T')[0];

          return {
            amount: amount,
            userName: u?.user?.firstName,
            //creditDate: u?.creditDate?.split('T')[0],
            // debitDate: u?.debitDate?.split('T')[0],
            creditDateDebitDate: creditDateDebitDate,
            remainingAmount: u?.remainingAmount,
            //kitchenName: u?.mealDish[0]?.mealDish?.dish?.kitchen?.name,
            kitchenName: u?.meal?.kitchen?.name
              ? u?.meal?.kitchen?.name
              : u?.user
                ? t('textManually')
                : u?.mealDish[0]?.mealDish?.dish?.kitchen?.name,
            mealType: u?.meal?.mealType?.display
              ? u?.meal?.mealType?.display
              : u?.user
                ? t('textManually')
                : u?.mealDish[0]?.mealDish?.meal?.mealType?.display,
          };
        });
        setDetails(userWallet);
      }
    };
    fetchData1();
  }, [dummy]);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setData(data);
  };

  const handleUpdateUserWallet = (data) => {
    setWalletPayload(data);
    setOpenWallet(true);
  };

  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      setPayload(initialiState);
      setIsUpdate(false);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setKitchensIds([]);
    setErrors({});
  };

  const handleWalletOpen = (value) => {
    if (!value) {
      setIsWalletUpdate(true);
    } else {
      // setWalletPayload(initialiWalletState);
      setIsUpdate(false);
    }
    setOpenWallet(true);
  };
  const handleWalletClose = () => {
    setOpenWallet('');
  };

  const handleWalletUpdateUser = async (userData) => {
    let details = {
      id: userData.id,
      amount: userData.amount,
    };
    try {
      const response = await useFetchingLoader(
        async () => await http.post(`users/updateUserWallet`, details)
      );
      if (response) {
        setTimeout(
          () =>
            Dialog({
              title: t('updatedMessage'),
              type: 'success',
              buttons: {
                showCancelButton: false,
                confirmButtonText: t('confirm'),
              },
              onConfirm: () => {
                handleWalletClose();
                // window.location.reload(true);
                setDummy((prev) => !prev);
              },
            }),
          150
        );
      }
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const columnDefs = [
    // {
    //   field: 'creditDate',
    //   headerName: t('creditDate'),
    //   sortable: true,
    //   filter: true,
    //   headerClass: 'resizable-header',
    //   // cellRenderer: (params) => {return params.value.split('T')[0]}
    // },
    {
      field: 'creditDateDebitDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      headerClass: 'resizable-header',
      cellRenderer: (params) => {
        return `${params.data.creditDateDebitDate}`
          ? `${params.data.creditDateDebitDate}`
          : '--';
      },
      // cellRenderer: (params) => {return params.value.split('T')[0]}
    },
    {
      field: 'amount',
      headerName: t('walletAmountWord'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.amount}` ? `${params.data.amount}` : '--';
      },
    },
    // {
    //   field: 'debitAmount',
    //   headerName: t('debitAmount'),
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    // },
    // {
    //   field: 'debitDate',
    //   headerName: t('debitDate'),
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    //   // cellRenderer: (params) => {return params.value.split('T')[0]}
    // },
    {
      field: 'kitchenName',
      headerName: t('kitchenName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data['kitchenName']}` &&
          `${params.data['kitchenName']}` != 'undefined'
          ? `${params.data['kitchenName']}`
          : '--';
      },
      //cellRenderer: (params) => {return params.value.split('T')[0]}
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data['mealType']}` &&
          `${params.data['mealType']}` != 'undefined'
          ? `${params.data['mealType']}`
          : '--';
      },
      // cellRenderer: (params) => {return params.value.split('T')[0]}
    },
    {
      field: 'remainingAmount',
      headerName: t('remainingAmount'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.remainingAmount}` &&
          `${params.data.remainingAmount}` != 'undefined'
          ? `${params.data.remainingAmount}`
          : '--';
      },
    },
    {
      field: 'userName',
      headerName: t('UserName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.userName}` &&
          `${params.data.userName}` != 'undefined'
          ? `${params.data.userName}`
          : '--';
      },
    },
  ];
  const lang = localStorage.getItem('language');

  const columnDefsTab = [
    // {
    //   field: 'creditDate',
    //   headerName: t('creditDate'),
    //   sortable: true,
    //   filter: true,
    //   headerClass: 'resizable-header',
    //   // cellRenderer: (params) => {return params.value.split('T')[0]}
    // },
    {
      field: 'creditDateDebitDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      headerClass: 'resizable-header',
      // cellRenderer: (params) => {return params.value.split('T')[0]}
    },
    {
      field: 'amount',
      headerName: t('walletAmountWord'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    // {
    //   field: 'debitAmount',
    //   headerName: t('debitAmount'),
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    // },
    // {
    //   field: 'debitDate',
    //   headerName: t('debitDate'),
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    //   // cellRenderer: (params) => {return params.value.split('T')[0]}
    // },

    {
      field: 'remainingAmount',
      headerName: t('remainingAmount'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: 'userName',
      headerName: t('UserName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const columnDefsMob = [
    // {
    //   field: 'creditDate',
    //   headerName: t('creditDate'),
    //   sortable: true,
    //   filter: true,
    //   headerClass: 'resizable-header',
    //   // cellRenderer: (params) => {return params.value.split('T')[0]}
    // },
    {
      field: 'creditDateDebitDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      headerClass: 'resizable-header',
      // cellRenderer: (params) => {return params.value.split('T')[0]}
    },
    {
      field: 'amount',
      headerName: t('walletAmountWord'),
      sortable: true,
      filter: true,
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
    // sizeColumnsToFit: true,
    suppressSizeToFit: true,
    width: 'auto',
  };
  const gridOptions = {
    rowHeight: 40,
    groupHeaderHeight: 40,
    headerHeight: 40,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  return (
    <div class="wallet-page global-data-table">
      <div className="container">
        <WEBackButton text={'Back'} redirectHistory={backLink} />
        <p className="wallet-user-details">
          <span>
            {t('userName')} : {username}
          </span>{' '}
          |{' '}
          <span>
            {fieldNamesSettings?.find((el) => el.value == 'employeeId')
              ? fieldNamesSettings
                  .find((el) => el.value == 'employeeId')
                  .translations?.find(
                    (el) => el.language.shortCode == i18n.language
                  )?.text
              : t('employeeIdPH')}
            : {empNumber}
          </span>{' '}
          |{' '}
          <span>
            {fieldNamesSettings?.find((el) => el.value === 'cardId')
              ? fieldNamesSettings
                  ?.find((el) => el.value === 'cardId')
                  ?.translations.find(
                    (el) => el.language.shortCode == i18n.language
                  )?.text
              : t('cardIdPH')}{' '}
            : {cardNumber}
          </span>
          |{' '}
          {user?.roleId == 1 || user?.roleId == 2 || user?.roleId == 3 ? (
            <span>
              <button
                type="button"
                onClick={() => handleUpdateUserWallet(walletPayload)}
                class="update-wallet-text"
              >
                {t('UpdateWalletAMount')}
              </button>
            </span>
          ) : (
            ''
          )}
        </p>
        <AgGridReact
          className="ag-theme-alpine"
          rowData={
            details?.length
              ? details?.map((reservation) => ({
                  ...reservation,
                }))
              : []
          }
          columnDefs={tableColumn}
          reactUi={lang === '"he"' ? false : true}
          enableRtl={lang === '"he"' ? true : false}
          domLayout={'autoHeight'}
          gridOptions={gridOptions}
          rowSelection="multiple"
          onGridReady={(params) => {
            // setGridApi(params.api);
            // setColumnApi(params.columnApi);
            params.api.sizeColumnsToFit();
            document.querySelector('.ag-pinned-left-cols-container').remove();
          }}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();
          }}
          frameworkComponents={{
            PopupCellRenderer: (e) => (
              <PopupCellRenderer
                handleOpen={handleOpen}
                e={e}
                setUpdate={setUpdate}
                setPayload={setPayload}
                setIsEdit={setIsEdit}
                handleDeleteUser={handleDeleteUser}
                t={t}
                kitchensIds={kitchensIds}
                setKitchensIds={setKitchensIds}
                Kitchens={Kitchens}
                Roles={Roles}
                ResendVerification={ResendVerification}
                UserStatuses={UserStatuses}
                CopyDataUrl={CopyDataUrl}
                handleConfirmation={handleConfirmation}
                setShowContractor={setShowContractor}
                contractorList={contractorList}
              />
            ),
            ViewMore: (e) => <ViewMore e={e} handleViewMore={handleViewMore} />,
          }}
          debug={false}
          overlayLoadingTemplate={'<p></p>'}
          rowGroupPanelShow={'always'}
          pivotPanelShow={'always'}
          enableRangeSelection={true}
          pagination={false}
          paginationPageSize={10}
          paginationNumberFormatter={function (params) {
            return '[' + params.value.toLocaleString() + ']';
          }}
        ></AgGridReact>
      </div>
      <ViewWalletDetails
        open={viewDetails}
        details={data}
        handleClose={handleViewMore}
      />
      <UpdateWalletPopup
        open={openWallet}
        handleClose={handleWalletClose}
        newUserFlag={true}
        isEdit={true}
        // payload={payload}
        walletPayload={walletPayload}
        setPayload={setPayload}
        setWalletPayload={setWalletPayload}
        setIsEdit={true}
        isUpdate={true}
        kitchensIds={kitchensIds}
        setKitchensIds={setKitchensIds}
        t={t}
        errors={errors}
        setErrors={setErrors}
        user={user}
        handleWalletUpdateUser={handleWalletUpdateUser}
      />
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };

export default connect(mapStateToProps, mapDispatchToProps)(OrderVoucher);
