import React, { useState, useEffect } from 'react';
import http from '../../services/http';
import moment from 'moment';
import DisplayMealDishes from '../../components/DishComponents/DisplayMealDishes';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sleep } from '../../services/utils';
import ShowPreviousContainer from '../../components/ShowPrevious';
import Dish from '../../components/DishComponents/Dish';
import CafeteriaDish from '../../components/DishComponents/CafeteriaDish';
import dates from '../../services/dates';
import WEIcon from '../../components/WE-IconButton';
import { useHistory, useLocation } from 'react-router';
import WEBackButton from '../../components/WE-BackButton';
import back from '../../assets/images/left-arrow.png';
import BackLink from '../../components/BackLink';
import { ActiveButton, DisabledButton } from '../../components/WE-Button.jsx';
import { ValueCache } from 'ag-grid-community';
/* Meal dishes is a wrapper for the DisplayMealDishes component */
const MealDishes = ({
  setLayoutOptions,
  mealTime,
  kitchen,
  MealTypes,
  MealDishStatuses,
  useLoader,
  useFetchingLoader,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};

  const [mealDishes, setMealDishes] = useState([]);
  const [mealSlotId, setMealSlotId] = useState(null);
  const [multipleDish, setMultipleDish] = useState();
  const [mealDishArray, setMealDishArray] = useState([]);
  const { date, mealTypeId, slotId, editId, orderId } = useParams();
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);

  const MealDishStatusMap = MealDishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});

  /* Fetches the MealDishes and updates 'mealDishes' */
  useEffect(() => {
    const fetchData1 = async () => {
      const getMultiPleDish = await http.get(`mealTypes/${mealTypeId}`);
      setMultipleDish(getMultiPleDish?.multipleDish);
      let dd = editId?.split('-');
      var numberArray = [];
      length = dd?.length;
      for (let j = 0; j < length; j++) numberArray.push(parseInt(dd[j]));
      setLayoutOptions({
        title: editId ? `${t('changeDish')}` : t('chooseDish'),
        showDatePicker: false,
        isDatePickerClickable: !editId,
      });

      // setLayoutOptions({title: editId ? `${t('changeDishFor')} ${dates.dmyyformat(date)} ${MealTypesMap[mealTypeId]}` : t('chooseDishForMeal'), showDatePicker: !editId});
      if (!kitchen) return;
      const { data } = await useFetchingLoader(
        async () =>
          await http.get(`mealsDishes`, {
            filter: {
              date: new Date(date),
              mealTypeId,
              kitchenId: kitchen.id,
              mealDishStatusId: MealDishStatusMap.active,
            },
          })
      );

      const filterMap = {};
      let i = 100; //set last order to 100
      data.forEach(function (item) {
        const order = item.order || i++;
        if (!filterMap[order]) {
          filterMap[order] = item;
        }
      });

      const result = [];

      for (const order in filterMap) {
        result.push(filterMap[order]);
      }

      result.sort(function (a, b) {
        return a.order - b.order;
      });
      setMealDishes(result);

      let aa = result.filter((el) => numberArray.includes(el.dishId));

      let myArr = [];
      aa.map((el) => myArr.push({ mealDishId: el.id, quantity: 1 }));
      setMealDishArray(myArr);
    };
    fetchData1();
  }, [mealTime, kitchen, MealTypes]);

  const goToOrderSummaryHandler = (isChecked, id, value) => {
    if (isChecked) {
      setMealDishArray((oldArray) => {
        let dd = oldArray.findIndex((el) => el.mealDishId === id);
        if (dd !== -1) {
          oldArray[dd].quantity = value;
          return oldArray;
        } else return [...oldArray, { mealDishId: id, quantity: value }];
      });
    } else {
      setMealDishArray((oldArray) =>
        oldArray.filter((el) => el.mealDishId !== id)
      );
    }
  };
  const goToOrderSummary = (mealArray, orderId) => {
    // let a = mealArray.join('-');
    if (mealDishes) {
      history.push({
        pathname: `/cafeteriaOrderSummary/${date}/${mealTypeId}/${
          slotId ? slotId : null
        }${orderId ? '/' + orderId : ''}`,
        state: {
          mealDishArray: mealArray,
        },
      });
      // history.push(
      //   `/cafeteriaOrderSummary/${date}/${mealTypeId}/${a}/${
      //     slotId ? slotId : ''
      //   }/${orderId ? orderId : ''}`
      // );
    }
  };

  return (
    <>
      <div className="reservation-title">
        {/* <WEBackButton text={backText} redirectHistory={backLink} /> */}
        {/* <div className="back-button-remove">
          <img src={back} alt="back" />
          <WEBackButton text={backText} redirectHistory={backLink} />
        </div> */}
        <BackLink text={backText} redirectHistory={backLink} />
        {date && mealTypeId && (
          <div className={`title-date `}>
            {`${dates.format(date)} / ${MealTypesMap_Id[mealTypeId]}`}
          </div>
        )}
      </div>

      {multipleDish === 1 ? (
        mealDishes && mealDishes.length ? (
          <div className="cafeteria-dishes-section">
            <div className="dishes-container">
              <div className="dish-cards">
                {mealDishes.map((mealDishData) => (
                  <CafeteriaDish
                    MealTypesMap_Id={MealTypesMap_Id}
                    mealDishData={mealDishData}
                    mealSlotId={slotId}
                    key={mealDishData.id}
                    selectedField={mealDishArray.find((el) =>
                      el.mealDishId == mealDishData.id ? true : false
                    )}
                    goToOrderSummaryHandler={goToOrderSummaryHandler}
                    orderId={orderId}
                  />
                ))}
              </div>
            </div>
            <ActiveButton
              label={t('Continue')}
              additionalClassName={'text'}
              cb={() => {
                goToOrderSummary(mealDishArray, orderId);
              }}
              arrow={true}
            />
          </div>
        ) : (
          <div className="no-meals">
            <p>{t('noMeals')}</p>
          </div>
        )
      ) : mealDishes && mealDishes.length ? (
        <div className="dishes-container">
          <div className="dish-cards">
            {mealDishes.map((mealDishData) => (
              <Dish
                MealTypesMap_Id={MealTypesMap_Id}
                mealDishData={mealDishData}
                mealSlotId={slotId}
                key={mealDishData.id}
                selected={mealDishData.dishId === Number(editId)}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="no-meals">
          <p>{t('noMeals')}</p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  mealTime: state.mealTime,
  MealTypes: state.app.enums.MealType,
  MealDishStatuses: state.app.enums.MealDishStatus,
});

const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(MealDishes);
