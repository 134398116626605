import './App.scss';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './constants/Routes';
import AppBoot from './AppBoot/AppBootContainer';
import Loader from './components/Loader';
import { FetchingLoader } from './components/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toggleLoader } from './store/app/actions';
import { connect } from 'react-redux';
import './style/mealTypes.css';
import './global.css';
import { useEffect } from 'react';
import { getCompanyParametersByDomain } from './store/companyConfig/actions';

function App({
  showLoader,
  showFetchingLoader,
  user,
  getCompanyParametersByDomain,
}) {
  useEffect(() => {
    const fetchData = async () => {
      const domain =
        window.location.hostname === 'localhost'
          ? 'develop-app'
          : window.location.hostname.split('.')[0];
      await getCompanyParametersByDomain(domain);
    };
    fetchData();
  }, []);
  return (
    <div className="App">
      {showLoader ? (
        <Loader /> // Main Loader
      ) : (
        showFetchingLoader && <FetchingLoader />
      )}
      <Router>
        <AppBoot toggleLoader={toggleLoader}>
          <Routes user={user} />
        </AppBoot>
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => ({
  showLoader: state.app.showLoader,
  showFetchingLoader: state.app.showFetchingLoader,
  user: state.user,
});

const mapDispatchToProps = {
  getCompanyParametersByDomain,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
