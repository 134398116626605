import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import addicon from '../../assets/images/icons/add.svg';
import deleteicon from '../../assets/images/icons/delete.svg';
import editicon from '../../assets/images/icons/edit.svg';
import securityicon from '../../assets/images/icons/security.svg';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import mealIcon from '../../assets/images/icons/fork-plate-knife.svg';
const KitchenDetails = ({
  show,
  handleClose,
  data,
  handleMealTypes,
  handleDelete,
  handleEditKitchen,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [details, setDetails] = useState();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="add-mealtypes-popup details-meal-typ-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('kitchenDetail')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-more-popup">
          <div className="user-detail-container kitchen-detail-container">
            <div className="user-details-row">
              {data
                ? Object.values(data.translations).map((el) => (
                    <div className="user-details-block">
                      <h6>
                        {el.languageId === 1
                          ? 'Hebrew'
                          : el.languageId === 2
                            ? 'English'
                            : 'Russian'}
                      </h6>
                      <p>{el.name}</p>
                    </div>
                  ))
                : ''}
              <div className="user-details-block">
                <h6>{t('terminalNumber')}</h6>
                <p>{data?.terminalNumber ? data?.terminalNumber : '--'}</p>
              </div>

              {/* <div className="right-listing"> */}
              {/* <div className="user-details-block">
                <h6>Actions</h6>
                <section className="actions">
                  <section>
                    <div
                      className={`icon-wrapper`}
                      onClick={() => history.push(`/security?${data.id}`)}
                    >
                      <img src={securityicon} />
                    </div>
                  </section>
                  <section>
                    <div
                      className={`icon-wrapper`}
                      onClick={() => handleMealTypes(data.id)}
                    >
                      <img src={addicon} />
                    </div>
                  </section>
                  <img
                    src={editicon}
                    onClick={() => handleEditKitchen(data.id)}
                  />
                  <img src={deleteicon} />
                </section>
              </div> */}
              <div className="modal-footer-btn">
                <Button onClick={() => handleMealTypes(data.id)}>
                  <img src={mealIcon} />
                  {t('add')}
                </Button>
                <Button onClick={() => handleEditKitchen(data.id)}>
                  <img src={editicon} />
                  {t('edit')}
                </Button>
                <Button onClick={() => handleDelete(data.id)}>
                  <img src={deleteicon} />
                  {t('delete')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default KitchenDetails;
