import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const kitchenDeviceViewDetails = ({
  open,
  details,
  handleClose,
  handleDeleteAction,
  handleEditAction,
  handleCopyToken,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="view-more-popup"
      >
        <Box sx={style}>
          <div className="view-detail-head">
            {/* <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('user')}
            </Typography> */}
            <div className="reservation-title">
              <h5>
                {t('kitchenDevice')}
                {t('reservationNumber')}
                {details?.kitchenDeviceId}
              </h5>
              <h6>{details?.displayName ? details?.displayName : ''}</h6>
            </div>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className="user-detail-container">
              <div className="user-details-row">
                <div className="user-details-block">
                  <h6>{t('token')}:</h6>
                  <p>{details?.token ? details?.token : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('deviceType')}:</h6>
                  <p>{details?.device_type ? details?.device_type : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('location')}:</h6>
                  <p>{details?.location ? details?.location : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('kitchenName')}:</h6>
                  <p>{details?.kitchenName ? details?.kitchenName : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('createdAt')}:</h6>
                  <p>{details?.createdAt ? details?.createdAt : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('updatedAt')}:</h6>
                  <p>{details?.updatedAt ? details?.updatedAt : '--'}</p>
                </div>

                <div className="modal-footer-btn">
                  <Button
                    onClick={() => {
                      handleEditAction(details);
                    }}
                  >
                    <i class="las la-pen" title="edit"></i>
                    {t('edit')}
                  </Button>
                  <Button
                    onClick={() =>
                      handleDeleteAction(details['kitchenDeviceId'])
                    }
                  >
                    <i class="las la-trash" title="delete"></i>
                    {t('delete')}
                  </Button>
                </div>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default kitchenDeviceViewDetails;
