import React, { useEffect, useState } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew, validateUsers } from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import Storage from '../../services/storage';
import back from '../../assets/images/back.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    backgroundColor: 'transparent',
    border: '1px solid #e3e3e3',
    boxShadow: 'none',
    borderRadius: '5px',
  }),
};

const ViewWalletDetails = ({
  open,
  details,
  handleClose,
  UserStatuses,
  setKitchensIds,
  setPayload,
  Kitchens,
  Roles,
  handleOpen,
  handleConfirmation,
  ResendVerification,
  CopyDataUrl,
  handleWallet,
  handleOrderLink,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="view-more-popup"
      >
        <Box sx={style}>
          <div className="view-detail-head">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              {t('wallet')}
            </Typography>
            <div className="close-btn-view">
              <img src={cancel} alt="close" onClick={handleClose} />
            </div>
          </div>
          <Typography id="modal-modal-description">
            <div className="user-detail-container">
              <div className="user-details-row">
                {/* <div className="user-details-block"> 
                  <h6>{t('creditDate')}:</h6>
                  <p>{details?.creditDate}</p>
                </div> */}
                <div className="user-details-block">
                  <h6>{t('date')}:</h6>
                  <p>{details?.creditDateDebitDate}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('walletAmountWord')}</h6>
                  <p>{details?.amount ? details?.amount : '--'}</p>
                </div>
                {/* <div className="user-details-block">
                  <h6>{t('debitAmount')}:</h6>
                  <p>{details?.debitAmount ? details?.debitAmount : '--'}</p>
                </div> */}

                {/* <div className="user-details-block">
                  <h6>{t('debitDate')}:</h6>
                  <p>{details?.debitDate ? details?.debitDate : '--'}</p>
                </div> */}
                <div className="user-details-block">
                  <h6>{t('kitchenName')}:</h6>
                  <p>{details?.kitchenName ? details?.kitchenName : '--'}</p>
                </div>
                <div className="user-details-block">
                  <h6>{t('mealType')}:</h6>
                  <p>{details?.mealType ? details?.mealType : '--'}</p>
                </div>

                <div className="user-details-block">
                  <h6>{t('remainingAmount')}:</h6>
                  <p>
                    {details?.remainingAmount ? details?.remainingAmount : '--'}
                  </p>
                </div>
                <div className="user-details-block">
                  <h6>{t('UserName')}:</h6>
                  <p>{details?.userName ? details?.userName : '--'}</p>
                </div>
              </div>
            </div>
          </Typography>
          <div className="view-popup-btn">
            <button className="close-modal-btn" onClick={handleClose}>
              {t('close')}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default ViewWalletDetails;
