import action from '../action.js';
import types from './types';
import http from '../../services/http';

export function getCompanyParametersByDomain(domain) {
  return action(async (dispatch) => {
    let data = await http.get(`companiesSettings/getCompanyByDomain/${domain}`);
    localStorage.setItem('companyId', data.companyId);
    localStorage.setItem('defaultLang', data.defaultLanguage);
    dispatch({ type: types.GET_PARAMETER_BY_DOMAIN, payload: data });
  });
}
export function getCompanyParametersByCompanyId(id) {
  return action(async (dispatch) => {
    let data = await http.get(`companiesSettings/getCompanyById/${id}`);
    dispatch({ type: types.GET_PARAMETER_BY_COMPANY_ID, payload: data });
  });
}
export function getSalaryConfigByCompanyId(id) {
  return action(async (dispatch) => {
    let data = await http.get(`companiesSettings/getSalarySettingsById/${id}`);
    dispatch({ type: types.GET_SALARY_CONFIG_BY_COMPANY_ID, payload: data });
  });
}
export function getUserConfigByCompanyId(id) {
  return action(async (dispatch) => {
    let data = await http.get(`companiesSettings/getUsersSettingsById/${id}`);
    dispatch({ type: types.GET_USER_CONFIG_BY_COMPANY_ID, payload: data });
  });
}
