// import { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { useTranslation } from 'react-i18next';
import InputField from '../InputFields/InputField';

// Validators
import { required } from '../../services/validators';

import { ActiveButton, DisabledButton } from '../WE-Button';

const OtpFrom = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, error, valid, submitFailed } = props;

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <div className="inputs-wrapper">
        <span>{t('labelEnterOtp')}</span>
        <Field
          name="otpCode"
          containerClass="employee-id"
          type="text"
          component={InputField}
          validate={[required]}
          placeholder={t('placeholderOtp')}
          icon="icon-employee-card"
        />

        <div className="flex-column">
          {submitFailed && !valid ? (
            <DisabledButton label={t('verify')} additionalClassName={'btn'} />
          ) : (
            <ActiveButton
              type="submit"
              additionalClassName={'btn'}
              label={t('verify')}
              arrow={true}
            />
          )}
        </div>
      </div>

      {error && <div className="form-error">{error}</div>}
    </Form>
  );
};

export default reduxForm({
  form: 'otpFrom',
  initialValues: {},
})(OtpFrom);
