import types from './types';

// Set initial state
const initialState = {
  date: new Date(),
  mealTypeId: null,
};

export function mealTimeReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_MEALTIME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
