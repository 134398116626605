import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import enums from '../services/enums';
import http from '../services/http';
const InitialDataStatus = {
  NOT_LOADED: 'NOT_LOADED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  LOADING_FAILED: 'LOADING_FAILED',
};
import { connect } from 'react-redux';
import {
  getCompanyParametersByCompanyId,
  getCompanyParametersByDomain,
} from '../store/companyConfig/actions';
import {
  setLayoutOptions,
  useFetchingLoader,
  setPushNotification,
  setNotificationNumber,
} from '../store/app/actions';
import { socket } from '../services/socket';
class AppBoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: InitialDataStatus.NOT_LOADED,
    };
    // ReactGA.initialize('UA-143933671-1', {
    //     debug: false
    // });
  }

  async componentDidMount() {
    try {
      if (!localStorage.getItem('companyId')) {
        const domain =
          window.location.hostname === 'localhost'
            ? 'develop-app'
            : window.location.hostname.split('.')[0];
        await this.props.getCompanyParametersByDomain(domain);
      }
      this.setState({ initialData: InitialDataStatus.LOADING });
      this.props.toggleLoader(true);
      await this.getInitialData();
      this.props.toggleLoader(false);
      this.setState({ initialData: InitialDataStatus.LOADED });
    } catch (err) {
      console.error(err);
      console.error('Failed to load initial data from server');
      this.setState({ initialData: InitialDataStatus.LOADING_FAILED });
    }
  }

  async getInitialData() {
    const { setEnums, initLanguages, getUser, getKitchens, getMealTypes } =
      this.props;
    try {
      await initLanguages();

      let _enums = await enums.init();
      await setEnums(_enums);
      await getMealTypes();
    } catch (err) {
      if (err === 'no-company-id') {
        window.location.reload();
      }
    }
    try {
      let user = await getUser();
      if (user) {
        await getKitchens(user.companyId);
        await this.props.getCompanyParametersByCompanyId(this.props.companyId);
      }
      if (localStorage.getItem('token')) {
        socket.on('notification', (params) => {
          this.props.setNotificationNumber(params.count);
          this.props.setPushNotification(params.notification);
        });
        localStorage.setItem('notificationsSocket', true);
        socket.emit('user_connected', user.id);
      }
      console.log('Registered user, redirect to homepage');
    } catch (e) {
      console.log('Unknown user');
    }
  }

  render() {
    const page = window.location.hash.split('#/')[1];
    return (
      <>
        {page.startsWith('login') ? (
          <Helmet>
            <meta property="og:title" content={page.toLocaleUpperCase()} />
            <title>{page.toLocaleUpperCase()}</title>
          </Helmet>
        ) : (
          ''
        )}

        {this.state.initialData === InitialDataStatus.LOADED &&
          this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyId: state.companyConfig.companyId,
});

const mapDispatchToProps = {
  getCompanyParametersByDomain,
  getCompanyParametersByCompanyId,
  setNotificationNumber,
  setPushNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppBoot));
