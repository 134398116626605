import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Alert } from '../components/Dialogs/SwalDialogs';
import http from '../services/httpV2';
import ResetPasswordForm from '../components/Forms/ResetPasswordForm';
import { useParams } from 'react-router';
import * as SwalDialogs from '../components/Dialogs/SwalDialogs';
import { Dialog } from '../components/Dialogs/SwalDialogs';
import GuestScreen from './Guests/GuestScreen';
import LoginBanner from './Login/loginBanner';

// @ts-ignore
const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dialog, setDailog] = useState(false);
  const { type, token } = useParams();
  const isReset = type === 'reset';

  const checkLinkExpire = async () => {
    if (isReset && token) {
      const checkToken = await http.get(`users/checkResetLink`, {
        token,
        isReset,
      });
      if (
        checkToken === 'invalidPasswordToken' ||
        checkToken === 'resetPasswordTimeout'
      ) {
        setDailog(true);
      }
    }
  };

  useEffect(() => {
    // let title = isReset ? t(`${type}Password`) : t('welcomeToWorkeat');
    // setLayoutOptions({ title });
    if (isReset && token) checkLinkExpire();
  }, []);

  const handleSubmit = async (form) => {
    let res;
    const csrf = await http.get(`getCsrfToken`);
    try {
      res = await http.put(`users/updatePassword`, {
        token,
        newPassword: form.newPassword,
        isReset,
        _csrf: csrf.csrfToken,
        companyId: localStorage.getItem('companyId'),
      });
      if (res.errorMessage === 'passwordPolicy') {
        SwalDialogs.Dialog({
          text:
            t('character') +
            ' ' +
            res.errorLetter +
            ' ' +
            t('isNotVaild') +
            ' ' +
            t('passwordSecurity'),
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
        });
      } else {
        SwalDialogs.Dialog({
          title: t('passwordUpdatedTitle'),
          text: t('passwordUpdatedText'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
          onConfirm: () => history.push(`/login`),
        });
      }
    } catch (err) {
      // if (err.name === 'passwordPolicy') {
      //   SwalDialogs.Dialog({
      //     text: t('passwordSecurity'),

      //     type: 'error',
      //     buttons: {
      //       showCancelButton: false,
      //       confirmButtonText: t('passwordUpdatedConfirmButtonText'),
      //     },
      //   });
      // }
      //else
      if (err.name === 'resetPasswordTimeout') {
        SwalDialogs.Dialog({
          text: t('resetPasswordExpired'),

          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
          onConfirm: () => history.push(`/login`),
        });
      } else {
        SwalDialogs.Dialog({
          text: t('resetPasswordExpired'),
          type: 'error',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('passwordUpdatedConfirmButtonText'),
          },
          onConfirm: () => history.push(`/login`),
        });
      }
    }
  };
  const handleClose = () => {
    setDailog(false);
  };

  return (
    <>
      <Helmet>
        <body className="login-page-padding"></body>
      </Helmet>

      <GuestScreen
        content={
          <div className="reset-password">
            <ResetPasswordForm
              onSubmit={handleSubmit}
              isReset={isReset}
              type={type}
            />
            <div className="align-right privacy-text mob-view-privacy">
              {!localStorage.getItem('token') ? (
                <>
                  <Link to="/privacy-policy">Privacy Policy</Link>

                  <Link to="/terms-condition"> Terms & Conditions</Link>
                </>
              ) : (
                ''
              )}
            </div>
            <LoginBanner />
          </div>
        }
        dailog={dialog}
        handleClose={handleClose}
        t={t}
      />
    </>
  );
};

// const Description = ({ t, type }) => (
//   <div
//     style={{
//       display: 'flex',
//       justifyContent: 'center',
//       marginBottom: '20px',
//       fontSize: '20px',
//     }}
//     dangerouslySetInnerHTML={{ __html: t(`${type}PasswordDescription`) }}
//   />
// );

export default ResetPassword;
